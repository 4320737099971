<template>
  <Line
    v-if="data.length > 0 && labels.length > 0"
    id="fast"
    :options="chartOptions"
    :data="chartData"
  />
  <div class="tailwind">
    <div v-show="showUnfinished">
      <Unfinished type="fast" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Line } from "vue-chartjs";
import Unfinished from "./Unfinished.vue";

export default {
  components: { Line, Unfinished },
  props: {
    data: {
      type: Object,
      required: true,
    },
    labels: {
      type: Object,
      required: true,
    },
    showUnfinished: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["route"]),
    chartData() {
      return {
        labels: this.labels,
        datasets: this.data,
      };
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          tooltip: this.setTooltip(),
          title: {
            display: true,
            text: "Spoed artikelen",
            font: {
              size: 20,
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Dag",
            },
          },
          y: {
            title: {
              display: true,
              text: "Percentage %",
            },
          },
        },
        layout: {
          padding: {
            top: 20,
            bottom: 20,
          },
        },
      };
    },
  },
  methods: {
    setTooltip() {
      return {
        boxPadding: 4,
        callbacks: {
          title: (item) => {
            return `${item[0].dataset.label} (${item[0].label})`;
          },
          label: (item) => {
            if (item.dataset.label.includes("Artikelen")) {
              if (item.dataset.label.includes("aantal")) {
                return [
                  `Aantal ratio: ${this.data[item.datasetIndex].raw[item.dataIndex].fast_count_ratio}%`,
                  `Aantal artikelen: ${this.data[item.datasetIndex].raw[item.dataIndex].fast_count}`,
                  `Aantal afgeronde artikelen: ${this.data[item.datasetIndex].raw[item.dataIndex].fast_done_count}`,
                ];
              } else {
                return [
                  `Omzet ratio: ${this.data[item.datasetIndex].raw[item.dataIndex].fast_revenue_ratio}%`,
                  `Omzet artikelen: €${this.data[item.datasetIndex].raw[item.dataIndex].fast_revenue}`,
                  `Omzet afgeronde artikelen: €${this.data[item.datasetIndex].raw[item.dataIndex].fast_done_revenue}`,
                ];
              }
            } else {
              if (item.dataset.label.includes("aantal")) {
                return [
                  `Aantal ratio: ${this.data[item.datasetIndex].raw[item.dataIndex].fast_count_ratio_order}%`,
                  `Aantal opdrachten: ${this.data[item.datasetIndex].raw[item.dataIndex].fast_count_order}`,
                  `Aantal afgeronde opdrachten: ${this.data[item.datasetIndex].raw[item.dataIndex].fast_done_count_order}`,
                ];
              } else {
                return [
                  `Omzet ratio: ${this.data[item.datasetIndex].raw[item.dataIndex].fast_revenue_ratio_order}%`,
                  `Omzet opdrachten: €${this.data[item.datasetIndex].raw[item.dataIndex].fast_revenue_order}`,
                  `Omzet afgeronde opdrachten: €${this.data[item.datasetIndex].raw[item.dataIndex].fast_done_revenue_order}`,
                ];
              }
            }
          },
        },
      };
    },
  },
};
</script>
