<template>
  <div class="tailwind">
    <div
      v-if="showOverlay"
      class="#bg-red-600 #bg-opacity-100 #fixed #top-0 #left-0 #w-full #h-full flash-overlay"
    >
      <span class="#float-right #text-white #text-2xl #mr-2 #mt-2" @click="showOverlay = false"
        >&#10006;</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showOverlay: false,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  mounted() {
    window.Echo.channel("incoming_calls").listen("IncomingCall", () => {
      this.showOverlay = true;
    });

    window.Echo.channel("incoming_calls").listen("IncomingCallPickedUp", () => {
      this.showOverlay = false;
    });

    window.Echo.channel("incoming_calls").listen("IncomingCallCancelled", () => {
      this.showOverlay = false;
    });
  },
};
</script>
<style>
.flash-overlay {
  z-index: 9999;
  animation: flash 4s infinite ease-in-out;
}

@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 0;
  }
  25%,
  75% {
    opacity: 0.6;
  }
}
</style>
