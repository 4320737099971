<template>
  <h1>
    Orders overzicht
    <img v-if="loading" src="/admin/images/loading.gif" style="margin-bottom: -9px" />
    <a class="filter-btn manage_button" @click="isShowFilter = !isShowFilter">Filteren</a>
    <a class="filter-btn manage_button" @click="confirmExport">CSV Export</a>
  </h1>
  <div class="tailwind">
    <div v-show="isShowFilter" class="#grid #grid-cols-2 #gap-4">
      <div>
        <JoinFilter
          v-model="filters.orders"
          table="orders"
          :columns="orderColumns"
          label="Opdracht"
          @loading="loadingStatus"
        />
      </div>
      <div class="#mt-1">
        <JoinFilter
          v-model="filters.article"
          table="articles"
          :columns="articleColumns"
          label="Artikel"
          @loading="loadingStatus"
        />
      </div>
      <div class="#mt-1">
        <JoinFilter
          v-model="filters.sending"
          table="sending"
          :columns="sendingColumns"
          label="Verzending"
          @loading="loadingStatus"
        />
      </div>
      <div class="#mt-1">
        <JoinFilter
          v-model="filters.customer"
          table="customers"
          :columns="customerColumns"
          label="Klant"
          @loading="loadingStatus"
        />
      </div>
    </div>
    <div v-if="isAdminLevel(4, 9) && isRowSelected" class="#mt-2 #space-x-2">
      <AdminButton btn-class="primary" @click="restoreSendDocs">
        Herstel verzendlabels
      </AdminButton>
    </div>
    <NotificationMessage
      v-if="message"
      class="#mt-2"
      type="success"
      :message="message"
      @close="message = null"
    />
    <table id="orders" class="display double-row" style="width: 100%">
      <thead>
        <tr>
          <th></th>
          <th>Nr</th>
          <th>Factuur</th>
          <th>Besteldatum</th>
          <th>Bedrag</th>
          <th>Productie</th>
          <th>Klant ID</th>
          <th>Naam</th>
          <th>Bedrijf</th>
          <th>Status</th>
          <th>Betaalmethode</th>
          <th>Kortingscode</th>
          <th>B (mm)</th>
          <th>H (mm)</th>
          <th>G (kg)</th>
        </tr>
      </thead>
    </table>
  </div>
</template>
<script>
import JoinFilter from "../components/JoinFilter.vue";
import AdminButton from "../components/AdminButton.vue";
import NotificationMessage from "../components/NotificationMessage.vue";
import { mapGetters } from "vuex";

export default {
  components: { AdminButton, JoinFilter, NotificationMessage },
  data() {
    return {
      articleColumns: [
        { type: "select", text: "Accessoires groep", value: "accessory_group" },
        { type: "select", text: "Accessoires item", value: "accessory_item" },
        { type: "integer", text: "Aantal", value: "number", width: "#w-20" },
        { type: "integer", text: "Aantal pagina's", value: "copies", width: "#w-20" },
        { type: "select", text: "Achterzijde", value: "backside" },
        { type: "select", text: "Afmeting voor prijsberekening", value: "size4calc" },
        { type: "select", text: "Afmeting voor printen", value: "size4print" },
        { type: "select", text: "Afwerking", value: "finishing" },
        { type: "select", text: "Afwerking #2", value: "finishing2" },
        { type: "select", text: "Afwerking #3", value: "finishing3" },
        { type: "select", text: "Afwerking extra", value: "finishing_extra" },
        { type: "select", text: "Afwerking printmode", value: "finishing_printmode" },
        { type: "option", text: "Beeldvullend", value: "fullscreen", options: this.yesNoOptions() },
        { type: "string", text: "Bestandsinformatie", value: "file_info" },
        { type: "select", text: "Btw tarief", value: "taxrate" },
        { type: "select", text: "Campagne", value: "campaign" },
        { type: "select", text: "Coating", value: "coating" },
        { type: "integer", text: "Custom afmeting hoogte", value: "size_custom_height" },
        { type: "integer", text: "Custom afmeting breedte", value: "size_custom_width" },
        { type: "select", text: "Lamineren", value: "laminating" },
        { type: "select", text: "Nieten", value: "stapling" },
        { type: "select", text: "Papiersoort", value: "papertype" },
        { type: "select", text: "Papiersoort omslag", value: "papertype_cover" },
        { type: "select", text: "Ponsen", value: "punching" },
        { type: "select", text: "Printer", value: "printer" },
        { type: "select", text: "Printzijde", value: "printside" },
        { type: "select", text: "Product", value: "product" },
        { type: "option", text: "Randloos", value: "borderless", options: this.yesNoOptions() },
        { type: "select", text: "Ronde hoeken", value: "round_corner" },
        { type: "select", text: "Rug", value: "spine" },
        { type: "string", text: "Scan response", value: "scan_response" },
        { type: "select", text: "Snijden", value: "cutting" },
        { type: "select", text: "Status", value: "status" },
        { type: "option", text: "Test print", value: "testprint", options: this.yesNoOptions() },
        { type: "select", text: "Voorzijde", value: "frontside" },
        { type: "select", text: "Vouwen", value: "folding" },
      ],
      customerColumns: [
        { type: "string", text: "Klant ID", value: "id" },
        { type: "string", text: "Bedrijf", value: "company" },
        { type: "string", text: "Naam", value: "name_contact" },
      ],
      filters: {
        article: {
          accessory_group: [],
          accessory_item: [],
          backside: [],
          borderless: "",
          campaign: [],
          coating: [],
          copies: {},
          cutting: [],
          file_info: "",
          finishing: [],
          finishing_extra: [],
          finishing_printmode: [],
          finishing2: [],
          finishing3: [],
          folding: [],
          frontside: [],
          fullscreen: "",
          laminating: [],
          number: {},
          papertype: [],
          papertype_cover: [],
          printer: [],
          printside: [],
          product: [],
          punching: [],
          round_corner: [],
          scan_response: "",
          size_custom_height: {},
          size_custom_width: {},
          size4calc: [],
          size4print: [],
          spine: [],
          stapling: [],
          status: [],
          taxrate: [],
          testprint: "",
        },
        customer: {
          id: "",
          company: "",
          name_contact: "",
        },
        orders: {
          amount: {},
          api_created: "",
          couponcode: "",
          couponcode_options: "",
          creation_datetime: {},
          delay_message: "",
          delays: {},
          delivery_datetime: {},
          id: "",
          invoice_number: {},
          internal_delivery_datetime: {},
          lastedit: {},
          payment_method: [],
          posted_datetime: {},
          production_method: [],
          rollable: "",
          status: [],
          sub_status: [],
          with_tube: "",
        },
        sending: {
          anonymous: "",
          country: [],
          delivery_instruction: "",
          delivery_option: [],
          delivery_method: [],
          depth: {},
          height: {},
          lastedit: {},
          parcel_count: {},
          weight: {},
          width: {},
        },
      },
      interval: null,
      isRowSelected: false,
      isShowFilter: false,
      loading: true,
      message: "",
      orderColumns: [
        { type: "string", text: "Nr", value: "id" },
        { type: "integer", text: "Aantal keer vertraagd", value: "delays", width: "#w-20" },
        { type: "option", text: "Api", value: "api_created", options: this.yesNoOptions() },
        { type: "integer", text: "Bedrag", value: "amount", width: "#w-20", fieldType: "price" },
        { type: "select", text: "Betaalwijze", value: "payment_method" },
        { type: "integer", text: "Factuur nummer", value: "invoice_number", width: "#w-20" },
        { type: "option", text: "Koker", value: "with_tube", options: this.yesNoOptions() },
        { type: "string", text: "Kortingscode", value: "couponcode" },
        {
          type: "option",
          text: "Kortingscode opties",
          value: "couponcode_options",
          options: this.yesNoOptions(),
        },
        {
          type: "date",
          text: "Interne leverdatum",
          value: "internal_delivery_datetime",
          width: "#w-24",
        },
        { type: "date", text: "Laatst aangepast", value: "lastedit", width: "#w-24" },
        { type: "date", text: "Leverdatum", value: "delivery_datetime", width: "#w-24" },
        { type: "option", text: "Oprolbaar", value: "rollable", options: this.yesNoOptions() },
        { type: "select", text: "Productiewijze", value: "production_method" },
        { type: "select", text: "Status", value: "status" },
        { type: "select", text: "Substatus", value: "sub_status" },
        {
          type: "option",
          text: "Vertragingsmelding",
          value: "delay_message",
          options: this.yesNoOptions(),
        },
        { type: "date", text: "Wanneer aangemaakt", value: "creation_datetime", width: "#w-24" },
        { type: "date", text: "Wanneer geplaatst", value: "posted_datetime", width: "#w-24" },
      ],
      sendingColumns: [
        {
          type: "integer",
          text: "Aantal zendingen",
          value: "parcel_count",
          width: "#w-20",
        },
        { type: "string", text: "Bezorginstructie", value: "delivery_instruction" },
        {
          type: "option",
          text: "Blanco versturen",
          value: "anonymous",
          options: this.yesNoOptions(),
        },
        { type: "integer", text: "Breedte", value: "width", width: "#w-20" },
        { type: "integer", text: "Diepte", value: "depth", width: "#w-20" },
        { type: "integer", text: "Gewicht", value: "weight", width: "#w-20" },
        { type: "integer", text: "Hoogte", value: "height", width: "#w-20" },
        { type: "date", text: "Laatst aangepast", value: "lastedit", width: "#w-24" },
        { type: "select", text: "Land", value: "country" },
        { type: "select", text: "Leveroptie", value: "delivery_option" },
        { type: "select", text: "Leverwijze", value: "delivery_method" },
      ],
      table: null,
    };
  },
  computed: {
    ...mapGetters(["route", "isAdminLevel"]),
    exportUrl() {
      const route = this.route("orders.export");
      const queryParameters = [];
      const numberAndDateFilters = [
        "number",
        "copies",
        "delays",
        "amount",
        "parcel_count",
        "width",
        "depth",
        "weight",
        "height",
        "lastedit",
        "delivery_datetime",
        "creation_datetime",
        "posted_datetime",
        "invoice_number",
        "internal_delivery_datetime",
      ];

      for (const [categoryKey, filters] of Object.entries(this.filters)) {
        for (const [filterKey, filter] of Object.entries(filters)) {
          if (filter && Object.keys(filter).length) {
            if (numberAndDateFilters.includes(filterKey)) {
              for (const [key, value] of Object.entries(filter)) {
                if (Array.isArray(value)) {
                  for (const val of value) {
                    queryParameters.push(`${categoryKey}[${filterKey}][${key}][]=${val}`);
                  }
                } else {
                  queryParameters.push(`${categoryKey}[${filterKey}][${key}]=${value}`);
                }
              }
              continue;
            }

            if (Array.isArray(filter)) {
              for (const value of filter) {
                queryParameters.push(`${categoryKey}[${filterKey}][]=${value}`);
              }
            } else {
              queryParameters.push(`${categoryKey}[${filterKey}]=${filter}`);
            }
          }
        }
      }

      const exportUrl = route + (queryParameters.length > 0 ? `?${queryParameters.join("&")}` : "");

      return exportUrl;
    },
  },
  watch: {
    filters: {
      handler() {
        // this.updateUrl();
        this.table.ajax.reload();
      },
      deep: true,
    },
  },
  mounted() {
    let params = new URL(document.location).searchParams;

    params.forEach((value, key) => {
      if (key.includes("orders") || key.includes("article") || key.includes("sending")) {
        let table = "";
        let columns = {};

        if (key.includes("orders")) {
          table = "orders";
          columns = this.orderColumns;
        } else if (key.includes("article")) {
          table = "article";
          columns = this.articleColumns;
        } else if (key.includes("sending")) {
          table = "sending";
          columns = this.sendingColumns;
        }

        let category = "";
        const matches = key.match(/\[(.*?)\]/);

        if (matches) {
          category = matches[1];
        }

        const foundColumn = columns.find((column) => column.value === category);

        if (foundColumn && ["option", "string"].includes(foundColumn.type)) {
          this.filters[table][category] = value;
        }

        if (foundColumn && foundColumn.type === "integer") {
          if (key.includes("condition")) {
            this.filters[table][category]["condition"] = value;
          } else if (key.includes("value")) {
            value = params.getAll(key);
            this.filters[table][category]["value"] = value;
          }
        }

        if (foundColumn && foundColumn.type === "select") {
          if (key.includes("[]")) {
            value = params.getAll(key);
          }

          this.filters[table][category] = value;
        }

        if (foundColumn && foundColumn.type === "date") {
          if (key.includes("condition")) {
            this.filters[table][category]["condition"] = value;
          } else if (key.includes("formatted_date")) {
            if (key.includes("[formatted_date][]")) {
              value = params.getAll(key);
            }

            this.filters[table][category]["formatted_date"] = value;
          } else if (key.includes("format")) {
            this.filters[table][category]["format"] = value;
          }
        }
      }
    });

    this.buildTable();
    this.loading = false;
  },
  beforeUnmount() {
    clearInterval(this.interval);
    this.table.destroy();
    this.table = null;
  },
  methods: {
    buildTable() {
      this.table = $("#orders").DataTable({
        ajax: {
          url: this.route("orders.index") + this.exportUrl.replace("/orders/export", ""),
          data: (d) => {
            for (const [categoryKey, category] of Object.entries(this.filters)) {
              for (const [filterKey, filter] of Object.entries(category)) {
                if (filter && Object.keys(filter).length > 0) {
                  if (!d[categoryKey]) {
                    d[categoryKey] = {};
                  }

                  d[categoryKey][filterKey] = filter;
                }
              }
            }
          },
        },
        order: [[1, "desc"]],
        searching: false,
        pageLength: 250,
        preDrawCallback: () => {
          this.loading = true;
        },
        drawCallback: () => {
          this.loading = false;
        },
        select: {
          style: "os",
          selector: "td:not(.link)",
        },
        columnDefs: [
          {
            orderable: false,
            className: "select-checkbox",
            targets: 0,
          },
        ],
        columns: [
          {
            searchable: false,
            sortable: false,
            data: null,
            defaultContent: "",
            className: "select-checkbox",
          },
          {
            data: "id",
            render: function (data, type, row) {
              if (!data) return "";

              var html = document.createElement("textarea");
              html.innerHTML = row.encoded_id;

              return `<a href="/admin.php?page=orders&sub=detail&id=${data}" class="link">${html.value}</a>`;
            },
          },
          {
            data: "invoice_number",
            render: function (data) {
              if (!data) return "";

              return `<a href="${data.url}" class="link"><img src="/admin/images/icon_invoice.png" border="0" title="Factuur">${data.number}</a>`;
            },
          },
          {
            data: "posted_datetime",
            render: function (data) {
              if (!data) return "";

              const parts = data.split(" ");
              if (parts.length === 2) {
                data = parts[0] + "<br>" + parts[1];
              }

              return data;
            },
          },
          {
            data: "amount",
            render: function (data) {
              if (!data) return "";

              return `€ ${data}`;
            },
          },
          {
            data: "production_method",
            render: function (data) {
              if (!data || data.value === "standard") {
                return "";
              } else if (data.value === "slow") {
                return `<font color="#31ce29">${data.description}</font>`;
              } else if (data.value === "budget") {
                return `<font color="green">${data.description}</font>`;
              } else if (data.value === "fast") {
                return `<font color="red">${data.description}</font>`;
              } else return data.description;
            },
          },
          {
            data: "customer.id",
            render: function (data) {
              if (!data) return "";

              const url = `<a title="${data}" href="/admin.php?page=customers&sub=detail&customer_id=${data}" class="link">${data}</a>`;
              return `<div class="ellipsis" style="max-width:105px;">${url}</div>`;
            },
          },
          {
            data: "customer.name_contact",
            render: function (data) {
              if (!data) return "";

              const url = `<a title="${data.name}" href="/admin.php?page=customers&sub=detail&customer_id=${data.id}" class="link">${data.name}</a>`;
              return `<div class="ellipsis" style="max-width:105px;">${url}</div>`;
            },
          },
          {
            data: "customer.company",
            render: function (data) {
              if (!data) return "";

              const title = `<a title="${data}">${data}</a>`;
              return `<div class="ellipsis" style="max-width:105px;">${title}</div>`;
            },
          },
          {
            data: "status",
            render: function (data) {
              if (!data) return "";

              const parts = data.split(" ");
              if (parts.length === 3) {
                return parts[0] + " " + parts[1] + "<br>" + parts[2];
              }
              return data;
            },
          },
          { data: "payment_method" },
          {
            data: "couponcode",
            render: function (data) {
              if (!data) return "";

              const title = `<a title="${data}">${data.toLowerCase()}</a>`;
              return `<div class="ellipsis" style="max-width:105px;">${title}</div>`;
            },
          },
          { data: "width" },
          { data: "height" },
          { data: "weight" },
        ],
      });

      this.table.on("select", () => {
        this.isRowSelected = this.table.rows({ selected: true }).ids().toArray().length;
      });

      this.table.on("deselect", () => {
        this.isRowSelected = this.table.rows({ selected: true }).ids().toArray().length;
      });
    },
    yesNoOptions() {
      return [
        { label: "Ja", value: "yes" },
        { label: "Nee", value: "no" },
      ];
    },
    loadingStatus(loading) {
      this.loading = loading;
    },
    confirmExport() {
      const numberOfRows = prompt("Vul een limiet in");
      const exportUrl = this.updateExportUrl(numberOfRows);

      if (isNaN(numberOfRows) || numberOfRows <= 0) {
        alert("Voer een geldig nummer in.");
        return;
      }

      if (confirm(`Bevestig dat je ${numberOfRows} opdrachten wilt exporteren?`)) {
        window.location.href = exportUrl;
      }
    },
    updateUrl() {
      let documentUrl = window.location.origin + window.location.pathname;
      documentUrl = documentUrl + "?page=orders&sub=overview&sub_sub=general";
      const searchUrl = this.exportUrl.replace("/orders/export", "");

      if (searchUrl) {
        const url = `${documentUrl}${searchUrl.startsWith("/") ? "" : "&"}${searchUrl.slice(1)}`;
        window.history.replaceState({}, "", url);
      }
    },
    updateExportUrl(numberOfRows) {
      const order = this.table.order();
      const column = this.table.settings().init().columns;

      const sign = this.exportUrl.includes("?") ? "&" : "?";
      let exportUrl =
        this.exportUrl + `${sign}sort=${column[order[0][0]].data}&direction=${order[0][1]}`;
      exportUrl += `&limit=${numberOfRows}`;

      return exportUrl;
    },
    async restoreSendDocs() {
      if (
        !confirm(
          `Bevestig dat je de verzendlabels voor de geselecteerde opdrachten wilt herstellen`,
        )
      ) {
        return;
      }

      const ids = this.table.rows({ selected: true }).ids().toArray();

      const response = await fetch(this.route("orders.restore.send-docs"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id: ids }),
      });

      if (response.ok) {
        this.message = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
  },
};
</script>
