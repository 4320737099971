<template>
  <Modal :open="open" :width="1030" @close="open = false">
    <div class="tailwind">
      <div v-if="successMessage" class="#flex #justify-center #items-center #h-48">
        <span class="#text-center #font-bold #text-lg #text-black">{{ successMessage }} </span>
      </div>
      <div v-else class="#h-full #grow">
        <div class="#font-bold #text-lg">
          <span v-if="type == 'internal_in_order'">Terug in Opdracht</span>
          <span v-else>Klachten</span>
        </div>
        <div v-if="page == 0">
          <span v-if="type == 'internal_in_order'" class="#font-medium #text-sm #block"
            >Opdracht {{ order_id }}. Selecteer de artikelen die terug in opdracht moeten.
          </span>
          <span v-else class="#font-medium #text-sx #block"
            >Opdracht {{ order_id }}. Selecteer de artikelen waarover de klacht gaat.
          </span>
          <hr />
          <br />
          <span @click="selectAllArticles">              <input
                class="#text-center #w-6 #pl-1 #cursor-pointer"
                :checked="selectedArticles.length === articles.length"
                type="checkbox"
              /> {{ selecttext }}</span>
          <table class="#mt-5 #w-full">
            <tr
              v-for="(article, index) in articles"
              :key="article.id"
              class="#text-sm #h-full"
              :class="index % 2 === 0 ? '#bg-[#eeeeee]' : '#bg-[#fff]'"
              @click="() => selectArticle(article)"
            >
              <td class="#text-center #w-5 #pl-1">
                <input type="checkbox" :checked="selectedArticles.includes(article)" />
              </td>
              <td class="#pl-2 #w-[52px]">
                <img
                  class="#max-w-[50px] #max-h-[50px]"
                  :src="article.image ? article.image : '/admin/images/cart-empty-document.png'"
                  onerror="this.onerror=null; this.src='/admin/images/cart-empty-document.png'; this.style.padding = '10px'"
                />
              </td>
              <td class="#w-100 #pl-2 #text-[12px]">
                <div>
                  <div>
                    <span>
                      Artikel {{ article.id }} - {{ article.number }}x {{ article.product }} </span
                    ><br />
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="page == 1">
          <span class="#font-medium #text-sm #block"
            >Opdracht {{ order_id }}. Klik op een categorie en selecteer de klacht.</span
          >
          <hr />
          <div class="#mt-2 #mb-3">
            <nav class="#flex #h-10">
              <div
                v-for="category in uniqueCategories"
                :key="category"
                :class="{
                  '#text-[#000000] #px-5 #py-2.5 #leading-5 #font-medium #text-[15px] #relative #cursor-pointer': true,
                  '#rounded-md  #bg-slate-100': category === selectedCategory,
                  '#border-b-2  #border-brightcyan': selectedMistakeCategory === category,
                }"
                @click="() => selectCategory(category)"
              >
                {{ category }}
              </div>
            </nav>
          </div>
          <div v-if="selectedCategory">
            <button
              v-for="mistake in getCategoryMistakes(selectedCategory)"
              :key="mistake.id"
              :class="mistakeBtnStyle(mistake)"
              @click="() => selectMistake(mistake)"
            >
              {{ mistake.text }}
            </button>
          </div>
        </div>
        <div v-if="page == 2">
          <span class="#font-medium #text-sm #block #mb-5"
            >Opdracht {{ order_id }}. Seleteer over welke actie je feedback wilt gegeven.
          </span>
          <hr />
          <div>
            <button
              v-for="action in actions"
              :key="action.id"
              :title="action.article_id"
              :class="actionBtnStyle(action)"
              @click="() => selectAction(action.id)"
            >
              {{ action.text }}
            </button>
          </div>
          <span class="#font-medium #text-sm #block #mb-5">Voer evt Toelichting in. </span>
          <hr />
          <textarea
            v-model="mistakeComment"
            class="#p-2 #border-2 #rounded-md #mt-5"
            placeholder=""
            rows="3"
            cols="70"
          />
        </div>
        <div v-if="page == 3 && type == 'internal_in_order'" class="#h-full">
          <span class="#font-medium #text-sm #block"
            >Opdracht {{ order_id }}. Kies een oplossing</span
          >
          <hr />
          <!-- Backorder -->
          <div class="#h-full #mt-5">
            <table class="#mt-5 #w-full">
              <tr
                v-for="(backorder, index) in backorderArticles"
                :key="backorder.id"
                class="#text-sm #h-full"
                :class="index % 2 === 0 ? '#bg-[#eeeeee]' : '#bg-[#fff]'"
              >
                <td class="#pl-2 #w-[52px]">
                  <img
                    class="#max-w-[50px] #max-h-[50px]"
                    :src="
                      backorder.image ? backorder.image : '/admin/images/cart-empty-document.png'
                    "
                    onerror="this.onerror=null; this.src='/admin/images/cart-empty-document.png'; this.style.padding = '10px'"
                  />
                </td>
                <td class="#w-[16rem] #pl-2 #text-[12px]">
                  <div>
                    <div>
                      <span>
                        Artikel {{ backorder.id }} - {{ backorder.number }}x
                        {{ backorder.product }} </span
                      ><br />
                    </div>
                  </div>
                </td>
                <td class="#w-20 #pl-2 #text-[12px]">
                  <input
                    v-model="backorder.again"
                    placeholder="Nalevering"
                    type="number"
                    class="#m-1 #w-20 #h-10 #transition-all #text-[13px] #box-border #text-center #text-black #font-medium #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #flex #items-center #justify-center"
                    min="0"
                    :max="backorder.number"
                    @input="validateInputNumber(backorder)"
                  />
                </td>
                <td class="#pl-2 #text-[12px]">Reden: {{ selectedMistakeText }}</td>
              </tr>
            </table>
            <div v-if="checkSorryCard && type != 'internal_in_order'" class="#flex #items-center #gap-x-8 #relative #group #pt-3">
              <h3 class="#font-medium #text-xs #block #text-gray-900 #w-[180px]">
                Sorry kaart toevoegen
              </h3>
              <label class="#relative #inline-flex #items-center #cursor-pointer">
                <input
                  v-model="sorryCard"
                  type="checkbox"
                  true-value="true"
                  false-value="false"
                  class="#sr-only #peer"
                />
                <div
                  class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
                />
              </label>
            </div>
            <div
              v-if="checkWaitingStatus && type != 'internal_in_order'"
              class="#flex #items-center #gap-x-8 #relative #group #pt-3"
            >
              <h3 class="#font-medium #text-xs #block #text-gray-900 #w-[180px]">
                Opdracht in de wacht zetten
              </h3>
              <label class="#relative #inline-flex #items-center #cursor-pointer">
                <input
                  v-model="waitingStatus"
                  type="checkbox"
                  true-value="true"
                  false-value="false"
                  class="#sr-only #peer"
                />
                <div
                  class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
                />
              </label>
            </div>
            <div v-if="checkAccessory && type != 'internal_in_order'" class="#flex #items-center #gap-x-8 #relative #group #pt-3">
              <h3 class="#font-medium #text-xs #block #text-gray-900 #w-[180px]">
                Accessoire(s) opnieuw leveren
              </h3>
              <label class="#relative #inline-flex #items-center #cursor-pointer">
                <input
                  v-model="accessory"
                  type="checkbox"
                  true-value="true"
                  false-value="false"
                  class="#sr-only #peer"
                />
                <div
                  class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
                />
              </label>
            </div>
            <div v-if="checkCourier && type != 'internal_in_order'" class="#flex #items-center #gap-x-8 #relative #group #pt-3">
              <h3 class="#font-medium #text-xs #block #text-gray-900 #w-[180px]">
                Opnieuw versturen met koerier
              </h3>
              <label class="#relative #inline-flex #items-center #cursor-pointer">
                <input
                  v-model="courier"
                  type="checkbox"
                  true-value="true"
                  false-value="false"
                  class="#sr-only #peer"
                />
                <div
                  class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
                />
              </label>
            </div>
          </div>
        </div>

        <div v-else-if="page == 3" class="#h-full">
          <span class="#font-medium #text-sm #block"
            >Opdracht {{ order_id }}. Kies een oplossing</span
          >
          <hr />
          <button
            v-for="solution in solutions"
            :key="solution.id"
            class="#align-top #m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer"
            :class="solutionBtnStyle(solution)"
            @click="() => selectSolution(solution.id)"
          >
            {{ solution.title }}
          </button>
          <!-- Backorder -->
          <div v-if="selectedSolution == 1" class="#h-full #mt-5">
            <table class="#mt-5 #w-full">
              <tr
                v-for="(backorder, index) in backorderArticles"
                :key="backorder.id"
                class="#text-sm #h-full"
                :class="index % 2 === 0 ? '#bg-[#eeeeee]' : '#bg-[#fff]'"
              >
                <td class="#pl-2 #w-[52px]">
                  <img
                    class="#max-w-[50px] #max-h-[50px]"
                    :src="
                      backorder.image ? backorder.image : '/admin/images/cart-empty-document.png'
                    "
                    onerror="this.onerror=null; this.src='/admin/images/cart-empty-document.png'; this.style.padding = '10px'"
                  />
                </td>
                <td class="#w-[16rem] #pl-2 #text-[12px]">
                  <div>
                    <div>
                      <span>
                        Artikel {{ backorder.id }} - {{ backorder.number }}x
                        {{ backorder.product }} </span
                      ><br />
                    </div>
                  </div>
                </td>
                <td class="#w-20 #pl-2 #text-[12px]">
                  <input
                    v-model="backorder.again"
                    placeholder="Nalevering"
                    type="number"
                    class="#m-1 #w-20 #h-10 #transition-all #text-[13px] #box-border #text-center #text-black #font-medium #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #flex #items-center #justify-center"
                    min="0"
                    :max="backorder.number"
                    @input="validateInputNumber(backorder)"
                  />
                </td>
                <td class="#pl-2 #text-[12px]">Reden: {{ selectedMistakeText }}</td>
              </tr>
            </table>
            <div v-if="checkSorryCard" class="#flex #items-center #gap-x-8 #relative #group #pt-3">
              <h3 class="#font-medium #text-xs #block #text-gray-900 #w-[180px]">
                Sorry kaart toevoegen
              </h3>
              <label class="#relative #inline-flex #items-center #cursor-pointer">
                <input
                  v-model="sorryCard"
                  type="checkbox"
                  true-value="true"
                  false-value="false"
                  class="#sr-only #peer"
                />
                <div
                  class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
                />
              </label>
            </div>
            <div
              v-if="checkWaitingStatus"
              class="#flex #items-center #gap-x-8 #relative #group #pt-3"
            >
              <h3 class="#font-medium #text-xs #block #text-gray-900 #w-[180px]">
                Opdracht in de wacht zetten
              </h3>
              <label class="#relative #inline-flex #items-center #cursor-pointer">
                <input
                  v-model="waitingStatus"
                  type="checkbox"
                  true-value="true"
                  false-value="false"
                  class="#sr-only #peer"
                />
                <div
                  class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
                />
              </label>
            </div>
            <div v-if="checkAccessory" class="#flex #items-center #gap-x-8 #relative #group #pt-3">
              <h3 class="#font-medium #text-xs #block #text-gray-900 #w-[180px]">
                Accessoire(s) opnieuw leveren
              </h3>
              <label class="#relative #inline-flex #items-center #cursor-pointer">
                <input
                  v-model="accessory"
                  type="checkbox"
                  true-value="true"
                  false-value="false"
                  class="#sr-only #peer"
                />
                <div
                  class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
                />
              </label>
            </div>
            <div v-if="checkCourier" class="#flex #items-center #gap-x-8 #relative #group #pt-3">
              <h3 class="#font-medium #text-xs #block #text-gray-900 #w-[180px]">
                Opnieuw versturen met koerier
              </h3>
              <label class="#relative #inline-flex #items-center #cursor-pointer">
                <input
                  v-model="courier"
                  type="checkbox"
                  true-value="true"
                  false-value="false"
                  class="#sr-only #peer"
                />
                <div
                  class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
                />
              </label>
            </div>
          </div>
          <!-- Discount -->
          <div v-if="selectedSolution == 2" class="#h-full #mt-5">
            <div class="#col-span-full">
              <label for="about" class="#block #text-sm #font-medium #leading-6 #text-gray-900"
                >Bedrag</label
              >
              <div class="#mt-2 #mb-2">
                <input v-model="couponAmount" type="number" class="#p-2 #border-2 #rounded-md" />
              </div>
              <label for="about" class="#block #text-sm #font-medium #leading-6 #text-gray-900"
                >Omschrijving</label
              >
              <div class="#mt-2 #mb-2">
                <textarea
                  v-model="couponComment"
                  class="#p-2 #border-2 #rounded-md"
                  rows="3"
                  cols="70"
                />
              </div>
              <label for="about" class="#block #text-sm #font-medium #leading-6 #text-gray-900"
                >Kortingscode</label
              >
              <div class="#mt-2 #mb-2">
                <input v-model="couponCode" type="text" class="#p-2 #border-2 #rounded-md" />
              </div>
              <div>Waarde van verkeerde artikelen: {{ formattedTotalCosts() }}</div>
            </div>
          </div>
          <!-- Register -->
          <div v-if="selectedSolution == 3" class="#h-full #mt-5">
            <table class="#mt-5 #w-full">
              <tr
                v-for="(backorder, index) in backorderArticles"
                :key="backorder.id"
                class="#text-sm #h-full"
                :class="index % 2 === 0 ? '#bg-[#eeeeee]' : '#bg-[#fff]'"
              >
                <td class="#pl-2 #w-[52px]">
                  <img
                    class="#max-w-[50px] #max-h-[50px]"
                    :src="
                      backorder.image ? backorder.image : '/admin/images/cart-empty-document.png'
                    "
                    onerror="this.onerror=null; this.src='/admin/images/cart-empty-document.png'; this.style.padding = '10px'"
                  />
                </td>
                <td class="#w-[16rem] #pl-2 #text-[12px]">
                  <div>
                    <div>
                      <span>
                        Artikel {{ backorder.id }} - {{ backorder.number }}x
                        {{ backorder.product }}</span
                      >
                    </div>
                  </div>
                </td>
                <td class="#w-20 #pl-2 #text-[12px]">
                  <input
                    v-model="backorder.again"
                    placeholder="Nalevering"
                    type="number"
                    class="#m-1 #w-20 #h-10 #transition-all #text-[13px] #box-border #text-center #text-black #font-medium #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #flex #items-center #justify-center"
                    min="0"
                    :max="backorder.number"
                    @input="validateInputNumber(backorder)"
                  />
                </td>
                <td class="#pl-2 #text-[12px]">Reden: {{ selectedMistakeText }}</td>
              </tr>
            </table>
            <div v-if="checkCanceled" class="#flex #items-center #gap-x-8 #relative #group #pt-3">
              <h3 class="#font-medium #text-sm #block #text-gray-900">Opdracht annuleren</h3>
              <label class="#relative #inline-flex #items-center #cursor-pointer">
                <input
                  v-model="canceled"
                  type="checkbox"
                  true-value="true"
                  false-value="false"
                  class="#sr-only #peer"
                />
                <div
                  class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
                />
              </label>
            </div>
            <div v-if="canceled" class="#flex #items-center #gap-x-8 #relative #group #pt-3">
              <h3 class="#font-medium #text-sm #block #text-gray-900">Terugstoren</h3>
              <label class="#relative #inline-flex #items-center #cursor-pointer">
                <input
                  v-model="refund"
                  type="checkbox"
                  true-value="true"
                  false-value="false"
                  class="#sr-only #peer"
                />
                <div
                  class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
                />
              </label>
            </div>
          </div>
          <!-- Credit -->
          <div v-if="selectedSolution == 4" class="#h-full #mt-5">C</div>
        </div>
      </div>
      <div
        v-if="!successMessage && !complete"
        class="#grid #grid-cols-[auto_120px_120px_180px] #pt-5 ;"
      >
        <button
          v-if="BackBtnStyle() && !complete"
          class="#m-1 #grid-flow-row #w-40 #h-10 #flex-1 #transition-all #box-border #text-white #text-[13px] #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer hover:#border-0 #bg-brightcyan enabled:hover:#bg-[#31b0dc] disabled:#opacity-25"
          :disabled="!back()"
          @click="BackStap"
        >
          Vorige Stap
        </button>
        <span v-else />
        <span />
        <span />
        <button
          v-if="page == 2 && type == 'internal_in_order'"
          class="#m-1 #grid-flow-row #w-40 #h-10 #flex-1 #transition-all #box-border #text-white #text-[13px] #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer hover:#border-0 #bg-brightcyan enabled:hover:#bg-[#31b0dc] disabled:#opacity-25"
          :disabled="!ready"
          @click="NextStap"
        >
          Volgende Stap
        </button>

        <button
        v-else-if="page == 3 && type == 'internal_in_order'"
          class="#m-1 #grid-flow-row #w-40 #h-10 #flex-1 #transition-all #box-border #text-white #text-[13px] #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer hover:#border-0 #bg-brightcyan enabled:hover:#bg-[#31b0dc] disabled:#opacity-25"
          :disabled="!ready"
          @click="Complete"
        >
          Terug in opdracht
        </button>

        <button
          v-else-if="page == 3 && !complete "
          class="#m-1 #grid-flow-row #w-40 #h-10 #flex-1 #transition-all #box-border #text-white #text-[13px] #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer hover:#border-0 #bg-brightcyan enabled:hover:#bg-[#31b0dc] disabled:#opacity-25"
          :disabled="!selectedSolution"
          @click="Complete"
        >
          Verwerk klacht
        </button>
        <button
          v-else-if="!complete"
          class="#m-1 #grid-flow-row #w-40 #h-10 #flex-1 #transition-all #box-border #text-white #text-[13px] #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer hover:#border-0 #bg-brightcyan enabled:hover:#bg-[#31b0dc] disabled:#opacity-25"
          :disabled="!ready"
          @click="NextStap"
        >
          Volgende Stap
        </button>
      </div>
      <div
        v-if="complete && !successMessage"
        class="#grid #grid-cols-[auto_120px_120px_180px] #pt-5 ;r"
      >
        <span />
        <span />
        <span />
        <button
          disabled
          class="#m-1 #grid-flow-row #w-40 #h-10 #flex-1 #transition-all #box-border #text-white #text-[13px] #font-medium #px-1 #py-1 #rounded-md #border-0 #bg-brightcyan disabled:#opacity-25"
        >
          <svg-use
            id="spinner"
            type="solid"
            class="#h-[1.75em] #w-[2em] #fill-inkdropdark #inline #animate-spin"
          />
          Verwerk klacht
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import SvgUse from "../../vue3/components/SvgUse.vue";
import { mapGetters } from "vuex";

const initialState = () => {
  return {
    article_id: null,
    order_id: null,
    page: 0,
    type: null,
    open: false,
    mistakes: [],
    numbers: [],
    articles: [],
    totalCosts: "",
    responeArticles: [],
    backorderAgain: null,
    actions: [],
    sorryCard: false,
    canceled: false,
    refund: false,
    waitingStatus: false,
    checkWaitingStatus: true,
    accessory: false,
    checkAccessory: false,
    courier: false,
    checkCourier: false,
    complete: false,
    checkSorryCard: true,
    checkCanceled: true,
    solutions: [],
    selectedMistake: null,
    selectedMistakeText: "",
    selectedMistakeCategory: "",
    selectedCategory: "Afwerking",
    selectedNumbers: null,
    selectedActions: [],
    selectedArticles: [],
    selectedBackorder: [],
    selectedArticleIds: [],
    backorderArticles: [],
    backorderData: [],
    successMessage: "",
    mistakeComment: "",
    couponAmount: 7.5,
    couponCode: "",
    couponComment:
      "U ontvangt een kortingscode ter compensatie van de situatie welke ontstaan is. Nogmaals excuses voor het ongemak.",
    totalnumbers: null,
    selecttext: "Selecteer alles",
    selectedSolution: null,
  };
};

export default {
  components: {
    Modal,
    SvgUse,
  },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters(["session", "route"]),

    //Als er een reden is geselecteerd en een aantal is ingevuld kan je op in opdracht klikken
    ready() {
      if (this.complete) {
        return false;
      }
      if (this.selectedArticles.length > 0 && this.page === 0) {
        return true;
      }
      if (this.selectedMistake) {
        return true;
      }

      return false;
    },
    loading() {
      return this.mistakes.length === 0;
    },
    //For Reach om 1 categorie terug te krijgen voor de kopje boven de klachten
    uniqueCategories() {
      const categories = new Set();
      this.mistakes.forEach((mistaket) => {
        categories.add(mistaket.category);
      });
      return Array.from(categories);
    },
  },

  //Zodra de vue wordt geopend laad hij de data in
  created() {
    this.emitter.on("mistake", (mistake) => {
      let reload = false;
      if (this.order_id && mistake.order_id && this.order_id !== mistake.order_id) {
        reload = true;
      }
      if (this.article_id && mistake.article_id && this.article_id !== mistake.article_id) {
        reload = true;
      }
      if (this.customer_id && mistake.customer_id && this.customer_id !== mistake.customer_id) {
        reload = true;
      }
      if (this.type && mistake.type && this.type !== mistake.type) {
        reload = true;
      }
      if (this.page === 0) {
        reload = true;
      }

      if (reload) {
        this.reset();
        this.type = mistake.type;
        this.article_id = mistake.article_id;
        this.order_id = mistake.order_id;
        this.couponCode = this.order_id + "_SORRY";
        this.customer_id = mistake.customer_id;
        this.getArticles(this.order_id);
        this.getSolutions();
      }

      this.open = true;
    });
  },
  beforeUnmount() {
    this.emitter.off("mistake");
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
    },
    back() {
      if (this.page !== 0) {
        return true;
      }

      return false;
    },

    //Ophalen van de categorieën.
    getCategoryMistakes(category) {
      return this.mistakes.filter((mistaket) => mistaket.category === category);
    },
    //Ophalen van de kleur voor de buttons
    mistakeBtnStyle(mistake) {
      return {
        "#align-top #m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border  #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #bg-brightcyan #text-white":
          this.selectedMistake === mistake.id,
        "#align-top #m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border  #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #bg-[#f1eff0] #text-black":
          this.selectedMistake !== mistake.id,
      };
    },
    solutionBtnStyle(solution) {
      return {
        "#bg-brightcyan #text-white": this.selectedSolution === solution.id,
        "#bg-[#f1eff0] #text-black": this.selectedSolution !== solution.id,
      };
    },
    BackBtnStyle() {
      return this.page !== 0;
    },
    actionBtnStyle(action) {
      return {
        "#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-white #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #bg-brightcyan  #items-center #justify-center ":
          this.selectedActions.includes(action.id),
        "#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-black #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #bg-[#f1eff0]  #items-center #justify-center":
          !this.selectedActions.includes(action.id),
      };
    },
    NextStap() {
      console.log(this.backorderArticles);
      this.page++;
      if (this.page == 3 || (this.page == 2 && this.type == "internal_in_order")) {
        this.getArticles(this.order_id, this.selectedArticleIds);
        if (this.selectedArticles.some((article) => article.accessory === true)) {
          this.checkAccessory = true;
          this.accessory = true;
        }
        if (this.selectedBackorder.length == 0) {
          this.selectedArticleIds.forEach((articleid) => {
            this.selectedBackorder.push(articleid);
          });
        }
      }
    },
    BackStap() {
      this.page--;
      if (this.selectedActions.length > 0) {
        this.selectedActions = [];
      }
    },
    Complete() {
      this.complete = true;

      if (
        this.selectedSolution == 1 ||
        this.selectedSolution == 3 ||
        this.selectedSolution == 2 ||
        (this.page == 3 && this.type == "internal_in_order")
      ) {
        this.placeBackInOrder();
      }
    },
    selectSolution(solutionId) {
      this.selectedSolution = solutionId;
      return true;
    },
    selectMistake(mistake) {
      this.selectedMistakeCategory = mistake.category;
      this.selectedMistake = mistake.id;
      this.selectedMistakeText = mistake.text;
      this.getActions(this.selectedArticleIds, mistake.id);

      return true;
    },
    selectCategory(category) {
      this.selectedCategory = category;
      return true;
    },
    selectAllArticles() {
      if (this.selectedArticles.length === this.articles.length) {
        this.selectedArticles = [];
        this.selecttext = "Selecteer alles";
      } else {
        this.selectedArticles = this.articles;
        this.selectedArticleIds = this.selectedArticles.map((article) => article.id);
        this.getMistakes(this.selectedArticleIds);
        this.selecttext = "Deselecteer alles";
      }
    },
    //Zorgt ervoor dat jem meerdere acties kan selecteren
    selectArticle(article) {
      // Check if the article is already in the selectedArticles array
      const articleIndex = this.selectedArticles.findIndex(
        (selectedArticle) => selectedArticle.id === article.id,
      );

      if (articleIndex > -1) {
        // Create a new array without the deselected article
        this.selectedArticles = this.selectedArticles.filter(
          (selectedArticle) => selectedArticle.id !== article.id,
        );
      } else {
        // Create a new array by adding the newly selected article
        this.selectedArticles = [...this.selectedArticles, article];
      }

      // Update the selectedArticleIds array accordingly
      this.selectedArticleIds = this.selectedArticles.map((article) => article.id);

      if (this.page != 3) {
        this.getMistakes(this.selectedArticleIds);
      }
    },
    selectAction(actionId) {
      if (this.selectedActions.includes(actionId)) {
        // Als de actie al geselecteerd is, verwijder deze dan uit de geselecteerde acties
        const index = this.selectedActions.indexOf(actionId);
        if (index > -1) {
          this.selectedActions.splice(index, 1);
        }
      } else {
        // Voeg de actie toe aan de geselecteerde acties
        this.selectedActions.push(actionId);
      }
    },
    validateInputNumber(backorder) {
      if (backorder.again > backorder.number) {
        backorder.again = backorder.number;
      }
    },
    //Ophalen van de artikelen
    async getArticles(order_id, article_id) {
      const response = await fetch(this.route("articles"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          order_id: order_id,
          article_id: article_id,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.responeArticles = await response.json();

      if (order_id && article_id == null) {
        this.articles = this.responeArticles;
      }
      if (article_id) {
        this.backorderArticles = this.responeArticles;
        this.totalCosts = this.backorderArticles.reduce(
          (total, backorder) => total + backorder.costs,
          0,
        );
      }

      if (this.articles.length == 1) {
        this.articles.forEach((article) => {
          this.selectArticle(article);
        });
      }

      if (this.articles.some((article) => article.product === "Sorry kaart")) {
        this.checkSorryCard = false;
      }

      if (this.articles.some((article) => article.courier === true)) {
        this.checkCourier = true;
        this.courier = true;
      }
      return;
    },
    formattedTotalCosts() {
      // Formatteer het bedrag naar het gewenste formaat met 2 cijfers achter de komma
      return this.totalCosts.toLocaleString("nl-NL", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    //Ophalen van de Klacht soorten
    async getMistakes(article_id) {
      const response = await fetch(this.route("mistakes"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          article_id: article_id,
          order_id: this.order_id,
          type: this.type,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.mistakes = await response.json();
    },
    //Ophalen Acties
    async getActions(article_id, mistake_id) {
      const response = await fetch(this.route("actions"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          order_id: this.order_id,
          article_id: article_id,
          mistake_id: mistake_id,
          type: this.type,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }

      this.actions = await response.json();

      if (this.actions) {
        for (const action of this.actions) {
          if (action.filtered) {
            this.selectAction(action.id);
          }
        }
      }
    },
    async getSolutions() {
      const response = await fetch(this.route("solutions"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.solutions = await response.json();
    },

    //Het terug in opdracht plaatsen
    async placeBackInOrder() {
      const response = await fetch(this.route("externalMistake"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          order_id: this.order_id,
          articles: this.backorderArticles,
          mistake: this.selectedMistake,
          actions: this.selectedActions,
          comment: this.mistakeComment,
          accessory: this.accessory,
          courier: this.courier,
          coupon_comment: this.couponComment,
          coupon_amount: this.couponAmount,
          coupon_code: this.couponCode,
          type: this.type,
          solution: this.selectedSolution,
          customer: this.customer_id,
          sorry_card: this.sorryCard,
          canceled: this.canceled,
          refund: this.refund,
          waiting_status: this.waitingStatus,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.response = await response.json();
      if (this.type == "internal_in_order") {
        this.successMessage = "Staan terug in opdracht";
      } else {
        this.successMessage = "Klacht is ingevoerd.";
      }

      setTimeout(() => {
        this.reset();
        this.open = false;
        this.successMessage = "";
        location.reload();
      }, 2000);
    },
  },
};
</script>
<style>
.spin {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
