<template>
  <h1>Feedback toevoegen</h1>
  <AdminFeedbackform></AdminFeedbackform>
</template>
<script>
import AdminFeedbackform from "../components/AdminFeedbackform.vue";

export default {
  components: {
    AdminFeedbackform,
  },
};
</script>
