<template>
  <div class="tailwind">
    <div
      class="#fixed #-bottom-5 #right-10 #w-[250px] #max-h-[350px] #text-base #bg-inkdropdark #text-white #p-5 #pb-10 #rounded-[6px] #rounded-tr-none #transition-all #duration-300 #ease-in-out"
      :class="{ '!#max-h-0 !#py-[10px]': !openContainer }"
    >
      <div
        class="#bg-inkdropdark #absolute #right-0 #top-[-31px] #flex #justify-center #items-center #px-[20px] #rounded-t-[6px] #cursor-pointer #transition-colors hover:#bg-inkdropdarklight"
        @click="toggleContainer"
      >
        <svg-use
          id="angle-down"
          type="regular"
          class="#w-[1.25em] #h-[1.25em] #fill-white #py-[5px] #box-content"
          :class="{ '#rotate-180': !openContainer }"
        />
      </div>
      <h3 class="#text-white #text-xl #m-0 #rounded-full">Printers</h3>
      <table class="#w-full #text-base #mb-[10px] #border-spacing-4">
        <tbody>
          <template v-for="printer in printers" :key="printer.id">
            <tr v-if="session('employee_id') == printer?.employee?.id">
              <td class="#w-[15%]">
                <svg-use
                  :id="printer.name.toLowerCase()"
                  type="custom-icons"
                  class="#w-[1.25em] #h-[1.25em] #fill-white #py-[5px] #box-content"
                />
              </td>
              <td v-if="printer.employee" class="#w-1/2 #group #cursor-pointer">
                {{ printer.name }}
                <svg-use
                  id="trash"
                  type="solid"
                  class="#inline #w-[0.75em] #h-[0.75em] #mt-[-2px] #ml-1 #fill-red-600 #cursor-pointer #transition-all #opacity-0 hover:!#opacity-50 group-hover:#opacity-100"
                  @click="setOperator(null, printer)"
                />
              </td>
              <td v-else class="#w-1/2">
                {{ printer.name }}
              </td>
              <td class="#w-[35%] #text-end #pl-[35px]">
                <div class="#flex #justify-end #items-center #h-5 #gap-x-[5px]">
                  <p>{{ printer.waiting_job_count }}</p>
                  <div
                    v-if="printer.status"
                    :title="printer.status.status"
                    class="#inline-block #w-[10px] #h-[10px] #rounded-full"
                    :class="colorStatus(printer.status.status)"
                  />
                  <div
                    v-else
                    class="#inline-block #w-[10px] #h-[10px] #rounded-full #bg-inkdroplight"
                  />
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="#relative">
        <a
          href="javascript:;"
          class="#relative #z-[1] #text-sm #text-white #w-full #border-box #text-left #flex #justify-between #items-center #rounded-[4px] #px-[12px] #py-[6px] #border-solid #border-white #border-[1px] hover:#bg-inkdropdarklight #transition-all"
          @click="openPrinters = !openPrinters"
        >
          Printer toevoegen
          <svg-use id="circle-plus" type="solid" class="#w-[1em] #h-[1em] #fill-white" />
        </a>
        <div
          v-if="openPrinters"
          class="#absolute #left-0 #bottom-[30px] #flex #flex-col #text-inkdropdark #py-4 #w-full #bg-white #rounded-t-[6px] #shadow-admin #z-[1]"
        >
          <a
            v-for="printer in printers"
            :key="printer.id"
            href="javascript:;"
            class="#py-1 #px-5 #text-sm hover:#bg-inkdroplight"
            :class="{ '#opacity-30 hover:#bg-white': printer.employee }"
            @click="
              setOperator(session('employee_id'), printer);
              openPrinters = false;
            "
          >
            {{ printer.name }}
            <span v-if="printer.employee"> ({{ printer.employee.firstname }}) </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { printerJobStatusColor } from "../helpers";
import SvgUse from "../../vue3/components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  data() {
    return {
      printers: [],
      openPrinters: false,
      openContainer: localStorage.getItem("printer-operator-open")
        ? JSON.parse(localStorage.getItem("printer-operator-open"))
        : true,
    };
  },
  computed: {
    ...mapGetters(["route", "session"]),
    allowed() {
      if (!this.session("printer_select_box_id")) {
        return null;
      }

      for (const printer of this.printers) {
        if (this.session("printer_select_box_id") === printer.id) {
          return this.session("employee_id") == printer?.employee?.id;
        }
      }

      return null;
    },
  },
  watch: {
    allowed(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        window.location.href = window.location.href;
      }
      if (oldValue === false && newValue === true) {
        window.location.href = window.location.href;
      }
    },
    openContainer(newValue) {
      localStorage.setItem("printer-operator-open", newValue);
    },
  },
  async created() {
    await this.getStatus();
    this.listenForUpdates();
  },
  methods: {
    async getStatus() {
      const response = await fetch(this.route("printers.status"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.printers = await response.json();
    },
    async setOperator(employee_id, printer) {
      if (printer.employee && employee_id) {
        return;
      }

      const response = await fetch(this.route("printers.operator"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          employee_id: employee_id,
          printer_id: printer.id,
        }),
      });

      if (!response.ok) {
        await response.text();

        return;
      }
      await response.json();
    },
    listenForUpdates() {
      for (const printer of this.printers) {
        window.Echo.channel(`printers_${printer.id}`).listen("PrinterJobCreated", ({ job }) => {
          const index = this.printers.findIndex((p) => p.id === printer.id);
          this.printers[index].waiting_job_count += 1;
        });
        window.Echo.channel(`printers_${printer.id}`).listen(
          "PrinterJobUpdate",
          ({ id, key, value }) => {
            const removeAt = ["COMPLETED", "ABORTED", "HELD"];
            if (key === "status" && removeAt.includes(value)) {
              const index = this.printers.findIndex((p) => p.id === printer.id);
              this.printers[index].waiting_job_count -= 1;
            }
          },
        );
        window.Echo.channel(`printers_${printer.id}`).listen(
          "SwitchPrinterOperator",
          ({ printer, operator }) => {
            const index = this.printers.findIndex((p) => p.id === printer.id);
            this.printers[index].employee = operator;
          },
        );
      }
    },
    colorStatus(status) {
      return printerJobStatusColor(status, true);
    },
    toggleContainer() {
      this.openContainer = !this.openContainer;
    },
  },
};
</script>
