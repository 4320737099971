<template>
  <div v-for="task of tasks" :key="task.id">
    <p class="#text-sm #font-bold #-mb-8">{{ task.name }}</p>
    <table :id="`cs-task-${task.id}`" class="display" style="width: 100%">
      <thead>
        <tr>
          <th>Medewerker</th>
          <th>Gestart om</th>
          <th>Geëindigd om</th>
        </tr>
      </thead>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    tasks: {
      type: Array,
      required: true,
    },
  },
  emits: ["refresh-data"],
  data() {
    return {
      table: {},
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    tasks: {
      handler() {
        this.tasks.forEach((task) => {
          this.table[`#cs-task-${task.id}`].destroy();
          this.buildTable(task);
        });
      },
      immediate: false,
    },
  },
  mounted() {
    this.tasks.forEach((task) => {
      this.buildTable(task);
    });
  },
  methods: {
    buildTable(task) {
      this.table[`#cs-task-${task.id}`] = $(`#cs-task-${task.id}`).DataTable({
        select: false,
        order: [[1, "asc"]],
        serverSide: false,
        data: task.employees,
        columns: [
          { data: "firstname" },
          {
            data: "pivot.started_at",
            render: (data) => {
              return moment(data, moment.ISO_8601).format("DD-MM-YYYY HH:mm");
            },
          },
          {
            data: "pivot.ended_at",
            render: (data) => {
              return data ? moment(data, moment.ISO_8601).format("DD-MM-YYYY HH:mm") : "";
            },
          },
        ],
      });
    },
  },
};
</script>
