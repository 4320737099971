<template>
  <div class="#text-sm #font-medium #text-center #text-gray-500 #border-b #border-gray-200">
    <ul class="#flex #flex-wrap #-mb-px">
      <li v-for="tab in tabs" :key="tab.value" class="#me-2">
        <a
          class="#inline-block #p-2 #border-b-2 #border-transparent #rounded-t-lg #cursor-pointer"
          :class="view === tab.value ? '#text-blue-600 #border-blue-600 #active' : ''"
          @click="selectView(tab.value)"
        >
          {{ tab.text }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    tabs: {
      type: Object,
      required: true,
    },
    view: {
      type: String,
      required: true,
    },
  },
  emits: ["view"],
  methods: {
    selectView(view) {
      this.$emit("view", view);
    },
  },
};
</script>
