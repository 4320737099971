import { createStore } from "vuex";

export const loadStore = async () => {
  let response = await fetch("/setup");
  response = await response.json();

  const store = createStore({
    state() {
      return {
        callNotifications: JSON.parse(localStorage.getItem("callNotifications") ?? false),
        config: response.config,
        routes: response.routes,
        session: response.session,
        isProduction: response.is_production,
      };
    },
    getters: {
      route:
        (state) =>
        (name, params = {}) => {
          if (state.routes[name] === undefined) {
            console.warn(`Route ${name} not found`);
            return null;
          }
          let url = state.routes[name];
          for (const [key, value] of Object.entries(params)) {
            url = url.replace(`{${key}}`, value);
          }

          return url;
        },
      session:
        (state) =>
        (key = "", fallback = null) => {
          let level = state.session;
          if (key) {
            for (const part of key.split(".")) {
              try {
                if (part in level) {
                  level = level[part];
                } else {
                  return fallback;
                }
              } catch (error) {
                return fallback;
              }
            }
          }
          return level;
        },
      config:
        (state) =>
        (key = "", fallback = null) => {
          let level = state.config;
          if (key) {
            for (const part of key.split(".")) {
              try {
                if (part in level) {
                  level = level[part];
                } else {
                  return fallback;
                }
              } catch (error) {
                return fallback;
              }
            }
          }
          return level;
        },
      isAdminLevel:
        (state) =>
        (...levels) => {
          const { session: { user_level = null } = {} } = state;
          if (!user_level) {
            return false;
          }

          for (const level of levels) {
            if (user_level.includes(level)) {
              return true;
            }
          }

          return false;
        },
      isProduction: (state) => () => {
        return state.isProduction;
      },
    },
    mutations: {
      notifications(state, payload) {
        localStorage.setItem("callNotifications", payload);
        state.callNotifications = payload;
      },
    },
  });

  return store;
};
