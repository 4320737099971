export default {
  data() {
    return {
      submitting: false,
      formErrors: {},
    };
  },
  methods: {
    clearAllErrors() {
      this.formErrors = {};
    },
    clearErrors(field) {
      delete this.formErrors[field];
    },
    hasError(field) {
      return !Array.isArray(this.formErrors) && field in this.formErrors;
    },
    firstError(field) {
      if (!this.hasError(field)) {
        return null;
      }

      return this.formErrors[field][0];
    },
  },
};
