<template>
  <label class="#font-bold">{{ label }}</label>
  <a class="#text-indigo-700 #cursor-pointer #ml-1 #underline" @click="clearIntegerFilters"
    >Wissen</a
  >
  <p
    v-if="error.text && error.type"
    :class="error.type === 'warning' ? '#text-red-600' : '#text-sky-600'"
  >
    {{ error.text }}
  </p>
  <div class="#flex #mt-1">
    <select v-model="selectedCondition" class="#rounded #h-8 #text-xs #border #border-black">
      <option value="" selected="">Conditie</option>
      <option value="=">=</option>
      <option value="!=">!=</option>
      <option value="<">&lt;</option>
      <option value="<=">&lt;=</option>
      <option value=">=">>=</option>
      <option value=">">></option>
      <option value="between">tussen</option>
      <option value="!between">niet tussen</option>
      <option value="empty">leeg</option>
      <option value="!empty">niet leeg</option>
    </select>
    <div v-show="selectedCondition">
      <input
        v-show="isSingleVisible"
        v-model="number.single"
        type="number"
        class="#border #border-black #rounded #h-8 #ml-1 #pl-2"
        min="0"
        @input="handleInteger('single')"
      />
      <input
        v-show="isRangeVisible"
        v-model="number.from"
        :class="width"
        class="#border #border-black #rounded #h-8 #ml-1 #pl-2"
        type="number"
        min="0"
        @input="handleInteger('range')"
      />
      <span v-show="isRangeVisible" class="#mr-1 #ml-1 seperator">en</span>
      <input
        v-show="isRangeVisible"
        v-model="number.to"
        :class="width"
        class="#border #border-black #rounded #h-8 #ml-1 #pl-2"
        type="number"
        min="0"
        @input="handleInteger('range')"
      />
    </div>
  </div>
</template>
<script>
import CommonFilterMixin from "../mixins/filter";
import debounce from "lodash.debounce";

export default {
  mixins: [CommonFilterMixin],
  props: {
    fieldType: {
      type: String,
      default: "integer",
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      error: {
        text: null,
        type: null,
      },
      number: {
        single: null,
        from: null,
        to: null,
      },
      selectedCondition: null,
      type: null,
    };
  },
  watch: {
    selectedCondition() {
      this.clearSelectedNumbers();

      if (["empty", "!empty"].includes(this.selectedCondition)) {
        this.$emit("update:modelValue", { condition: this.selectedCondition });
      }

      if (
        (this.number.single || (this.number.from && this.number.to)) &&
        !["empty", "!empty"].includes(this.selectedCondition)
      ) {
        this.handleInteger();
      }
    },
  },
  methods: {
    handleInteger: debounce(function (type) {
      if (type) {
        this.type = type;
      }

      const data = {
        condition: this.selectedCondition,
        value: this.type === "single" ? this.number.single : [this.number.from, this.number.to],
      };

      const isValid = this.validateFields();

      if (isValid) {
        this.$emit("update:modelValue", data);
      }
    }, 500),
    clearSelectedNumbers() {
      if (["between", "!between"].includes(this.selectedCondition)) {
        this.number.single = "";
      }

      if (["=", "!=", "<", "<=", ">=", ">"].includes(this.selectedCondition)) {
        this.number.from = "";
        this.number.to = "";
      }
    },
    clearIntegerFilters() {
      this.error = {};
      this.selectedCondition = "";
      this.number.single = "";
      this.number.from = "";
      this.number.to = "";

      this.$emit("update:modelValue", {});
    },
  },
};
</script>
