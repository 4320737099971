<template>
  <Modal :open="open">
    <div class="tailwind">
      <div class="#flex #justify-between #items-center #mb-[20px]">
        <h2 class="#text-[16.5px] #font-bold">
          Welkom {{ capitalized(employee_username) }}, je hebt ongelezen berichten
        </h2>
        <AdminButton size="sm" class="#w-max" btn-class="secondary" href="admin.php?page=logout">
          Log uit
        </AdminButton>
      </div>
      <h3 v-if="newsItems.length > 0" class="#text-inkdropdark #font-bold">Nieuws</h3>
      <transition-group name="list" tag="div">
        <div
          v-for="newsItem in newsItems"
          :key="newsItem.id"
          class="#flex #items-start #justify-start #gap-x-[10px] #py-[10px] #border-t-[1px] #border-solid #border-[#d5d5d5]"
        >
          <a :href="newsItem.image" target="_blank" class="#bg-[#d5d5d5] #min-w-[100px] #h-[70px]">
            <img :src="newsItem.thumb" class="#min-w-[100px] #h-[70px] #object-cover" />
          </a>
          <div>
            <p class="#text-[#707070] #mb-[3px]">{{ newsItem.created_at }}</p>
            <h4 class="#text-inkdropdark #text-[15px] #font-bold #mb-[3px]">
              {{ newsItem.title }}
            </h4>
            <p class="#mb-[6px]" v-html="newsItem.message"></p>
            <AdminButton
              size="sm"
              class="#w-max"
              btn-class="primary"
              @click="ReadItem(newsItem.id, 'news')"
            >
              Gelezen
            </AdminButton>
          </div>
        </div>
      </transition-group>
      <h3 v-if="workspaceItems.length > 0" class="#text-inkdropdark #font-bold">Werkplek</h3>
      <transition-group name="list" tag="div">
        <div
          v-for="workspaceItem in workspaceItems"
          :key="workspaceItem.id"
          class="#flex #items-start #justify-start #gap-x-[10px] #p-[10px] #my-[5px] #rounded-[4px] #bg-[#fcf8e3] #border-[1px] #border-solid #border-[#faebcc] #text-[#8a6d3b]"
        >
          <a
            :href="workspaceItem.target"
            target="_blank"
            class="#bg-[#d5d5d5] #min-w-[100px] #h-[70px]"
          >
            <img :src="workspaceItem.image" class="#min-w-[100px] #h-[70px] #object-cover" />
          </a>
          <div>
            <p class="#text-[#8a6d3b] #mb-[3px]">{{ workspaceItem.created_at }}</p>
            <h4 class="#text-[#8a6d3b] #text-[15px] #font-bold #mb-[3px]">
              {{ workspaceItem.title }}
            </h4>
            <p class="#mb-[6px]">
              {{ workspaceItem.content }}
            </p>
            <AdminButton
              size="sm"
              class="#w-max"
              btn-class="primary"
              @click="ReadItem(workspaceItem.id, 'workspace')"
            >
              Gelezen
            </AdminButton>
          </div>
        </div>
      </transition-group>
      <h3 v-if="feedbackItems.length > 0" class="#text-inkdropdark #font-bold">Feedback</h3>
      <transition-group name="list" tag="div">
        <div
          v-for="feedbackItem in feedbackItems"
          :key="feedbackItem.id"
          class="#flex #items-center #justify-between #gap-x-[10px] #py-[10px] #border-t-[1px] #border-solid #border-[#d5d5d5]"
        >
          <div class="#flex #items-stretch #gap-x-[15px]">
            <div
              class="#min-w-[15px] #max-w-[15px]"
              :class="getFeedbackColor(feedbackItem.type)"
            ></div>
            <div>
              <p class="#text-[#707070] #mb-[3px]">{{ feedbackItem.created_at }}</p>
              <p class="#mb-[6px]" v-html="feedbackItem.description"></p>
              <AdminButton
                size="sm"
                class="#w-max"
                btn-class="primary"
                @click="ReadItem(feedbackItem.id, 'feedback')"
              >
                Gelezen
              </AdminButton>
              <AdminButton
                v-if="feedbackItem.order_id"
                :href="`/admin.php?page=orders&sub=detail&id=${feedbackItem.order_id}&messages=false`"
                size="sm"
                class="#w-max #ml-[8px]"
                btn-class="grey"
                icon="angle-right"
                target="_blank"
              >
                Bekijk bestelling
              </AdminButton>
            </div>
          </div>
        </div>
      </transition-group>
      <h3 v-if="warningItems.length > 0" class="#text-inkdropdark #font-bold">Waarschuwingen</h3>
      <transition-group name="list" tag="div">
        <div
          v-for="warningItem in warningItems"
          :key="warningItem.id"
          class="#flex #items-center #justify-between #gap-x-[10px] #py-[10px] #border-t-[1px] #border-solid #border-[#d5d5d5]"
        >
          <div class="#flex #items-stretch #gap-x-[15px]">
            <div>
              <p class="#text-[#707070] #mb-[3px]">{{ warningItem.created_at }}</p>
              <p class="#mb-[6px]" v-html="warningItem.description"></p>
              <AdminButton
                size="sm"
                class="#w-max"
                btn-class="primary"
                @click="ReadItem(warningItem.id, 'warning')"
              >
                Gelezen
              </AdminButton>
            </div>
          </div>
        </div>
      </transition-group>
      <h3 v-if="reminderItems.length > 0" class="#text-inkdropdark #font-bold">Herinnering</h3>
      <transition-group name="list" tag="div">
        <div
          v-for="reminderItem in reminderItems"
          :key="reminderItem.id"
          class="#flex #items-center #justify-between #gap-x-[10px] #py-[10px] #border-t-[1px] #border-solid #border-[#d5d5d5]"
        >
          <div class="#flex #items-stretch #gap-x-[15px]">
            <div>
              <p class="#text-[#707070] #mb-[3px]">{{ reminderItem.created_at }}</p>
              <p class="#mb-[6px]" v-html="reminderItem.description"></p>
              <AdminButton
                size="sm"
                class="#w-max"
                btn-class="primary"
                @click="readReminder(reminderItem.id, reminderItem.type)"
              >
                Gelezen
              </AdminButton>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "../components/Modal.vue";
import AdminButton from "../components/AdminButton.vue";

export default {
  components: {
    Modal,
    AdminButton,
  },
  data() {
    return {
      newsItems: [],
      feedbackItems: [],
      reminderItems: [],
      warningItems: [],
      workspaceItems: [],
    };
  },
  computed: {
    ...mapGetters(["route", "session"]),
    open() {
      return (
        this.newsItems.length > 0 ||
        this.feedbackItems.length > 0 ||
        this.reminderItems.length > 0 ||
        this.workspaceItems.length > 0 ||
        this.warningItems.length > 0
      );
    },
  },
  async created() {
    this.employee_username = this.session("username");

    const urlParams = new URLSearchParams(window.location.search);
    let showMessages = urlParams.get("messages");

    if (showMessages) {
      return;
    }

    const response = await fetch(this.route("messages.index"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!response.ok) {
      await response.text();

      return;
    }

    const { feedback, news, reminders, workspace, warnings } = await response.json();
    this.newsItems = news;
    this.feedbackItems = feedback;
    this.reminderItems = reminders;
    this.workspaceItems = workspace;
    this.warningItems = warnings;
  },
  methods: {
    getFeedbackColor(type) {
      if (type === 0) {
        return "#bg-[#DD2C2C]";
      } else if (type === 1) {
        return "#bg-[#31CE29]";
      } else {
        return "#bg-[#ED9E44]";
      }
    },
    capitalized(name) {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);

      return capitalizedFirst + rest;
    },
    async ReadItem(id, type) {
      const response = await fetch(this.route("messages.read"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          type: type,
          id: id,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }

      const { success = false } = await response.json();
      if (!success) {
        return;
      }

      if (type === "news") {
        this.newsItems = this.newsItems.filter((element) => element.id !== id);
      } else if (type === "feedback") {
        this.feedbackItems = this.feedbackItems.filter((element) => element.id !== id);
      } else if (type === "workspace") {
        this.workspaceItems = this.workspaceItems.filter((element) => element.id !== id);
      } else if (type === "warning") {
        this.warningItems = this.warningItems.filter((element) => element.id !== id);
      } else if (type === "reminder") {
        this.reminderItems = this.reminderItems.filter((element) => element.id !== id);
      }
    },
    async readReminder(id, type) {
      const response = await fetch(this.route("messages.read"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          type: "reminder",
          id: id,
          reminder: type,
        }),
      });

      if (!response.ok) {
        await response.text();

        return;
      }

      const { success = false } = await response.json();

      if (!success) {
        return;
      }

      this.reminderItems = this.reminderItems.filter((element) => element.id !== id);
    },
  },
};
</script>

<style>
.list-leave-active {
  transition: all 200ms;
}
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
