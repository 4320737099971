<template>
  <div v-if="loading === true" class="#space-y-2 #h-80 #pr-4 #overflow-y-scroll #overflow-x-clip">
    <div v-for="reminder in 3" :key="reminder" class="#flex #justify-between #gap-x-2 #w-full">
      <div class="#group #flex #justify-start #gap-x-2 #w-full">
        <div class="loader-animation #w-1 #min-w-1 #rounded-full #bg-inkdroplight"></div>
        <div class="loader-animation #rounded-[3px] #bg-inkdroplight #w-full #h-20"></div>
      </div>
    </div>
  </div>
  <div v-else class="#space-y-8 #h-80 #pr-4 #overflow-y-scroll #overflow-x-clip">
    <transition-group
      v-if="urgentReminders.length > 0"
      name="list"
      tag="div"
      class="#space-y-2 #w-full"
    >
      <div
        v-for="reminder in urgentReminders"
        :key="reminder.id"
        class="#flex #justify-between #gap-x-2 #w-full"
      >
        <div class="#group #flex #justify-start #gap-x-2">
          <div class="#w-1 #min-w-1 #rounded-full" :class="getMessageColor(reminder.color)"></div>
          <div>
            <div class="#flex #justify-between">
              <h4 class="#font-bold #mb-1">{{ reminder.title }}</h4>
              <p class="#opacity-50">{{ formatTime(reminder.created_at) }}</p>
            </div>
            <div class="#flex #justify-between #items-start #gap-x-3">
              <p v-html="reminder.content"></p>
              <AdminButton
                size="xs"
                btn-class="primary"
                class="#opacity-0 group-hover:#opacity-100"
                @click="$emit('deleteTask', reminder.id)"
              >
                <svg-use id="check" type="regular" class="#h-[14px] #w-[17px] #fill-white" />
              </AdminButton>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
    <transition-group
      v-if="regularReminders.length > 0"
      name="list"
      tag="div"
      class="#space-y-2 #w-full"
    >
      <div
        v-for="reminder in regularReminders"
        :key="reminder.id"
        class="#flex #justify-between #gap-x-2 #w-full"
      >
        <div class="#group #flex #justify-start #gap-x-2">
          <div class="#w-1 #min-w-1 #rounded-full" :class="getMessageColor(reminder.color)"></div>
          <div>
            <div class="#flex #justify-between">
              <h4 class="#font-bold #mb-1">{{ reminder.title }}</h4>
              <p class="#opacity-50">{{ formatTime(reminder.created_at) }}</p>
            </div>
            <div class="#flex #justify-between #items-start #gap-x-3">
              <p>{{ reminder.content }}</p>
              <AdminButton
                size="xs"
                btn-class="primary"
                class="#opacity-0 group-hover:#opacity-100"
                @click="$emit('deleteTask', reminder.id)"
              >
                <svg-use id="check" type="regular" class="#h-[14px] #w-[17px] #fill-white" />
              </AdminButton>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import AdminButton from "./AdminButton.vue";
import SvgUse from "../../vue3/components/SvgUse.vue";

export default {
  components: {
    AdminButton,
    SvgUse,
  },
  props: {
    reminders: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["deleteTask"],
  data() {
    return {};
  },
  computed: {
    urgentReminders() {
      return this.reminders.filter(
        (reminder) => reminder.color === "red" || reminder.color === "orange",
      );
    },
    regularReminders() {
      return this.reminders.filter(
        (reminder) => reminder.color === "blue" || reminder.color === "green",
      );
    },
  },
  methods: {
    formatTime(date) {
      return moment(date).format("HH:mm");
    },
    getMessageColor(color) {
      if (color === "red") {
        return "#bg-red-600";
      }
      if (color === "orange") {
        return "#bg-orange-400";
      }
      if (color === "green") {
        return "#bg-green-400";
      }

      return "#bg-blue-400";
    },
  },
};
</script>

<style>
.list-leave-active {
  transition: all 200ms;
}
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
