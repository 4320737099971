<template>
  <div class="keywords">
    <modal :open="open" @close="$emit('close')">
      <div class="header">
        <span>Editing review {{ review.id }}</span>
      </div>
      <div class="content">
        <template v-for="field in Object.keys(fields)">
          <span class="label">{{ fields[field] }}</span>
          <span class="value">{{ review[field] }}</span>
        </template>
        <span class="label">Keywords</span>
        <div class="keywords noselect">
          <span>Actieve</span>
          <span></span>
          <span>Suggesties</span>
          <select ref="set" class="set noselect" multiple>
            <option v-for="keyword in set" :value="keyword.keyword">
              {{ keyword.keyword }}
            </option>
          </select>
          <div class="controls">
            <div style="width: max-content" class="tailwind">
              <div
                class="#group #p-[5px] #box-content #border-[1px] #border-solid #border-inkdropdark #rounded-full #cursor-pointer hover:#bg-inkdropdark"
                @click="addSuggested"
              >
                <svg-use
                  id="arrow-left"
                  type="regular"
                  class="#h-[1.25em] #w-[1.25em] #fill-inkdropdark group-hover:#fill-white"
                />
              </div>
            </div>
            <div style="width: max-content" class="tailwind">
              <div
                class="#group #mt-2 #p-[5px] #box-content #border-[1px] #border-solid #border-inkdropdark #rounded-full #cursor-pointer hover:#bg-inkdropdark"
                @click="removeSet"
              >
                <svg-use
                  id="arrow-right"
                  type="regular"
                  class="#h-[1.25em] #w-[1.25em] #fill-inkdropdark group-hover:#fill-white"
                />
              </div>
            </div>
          </div>
          <select ref="suggested" class="suggested noselect" multiple>
            <option v-for="keyword in suggested" :value="keyword.keyword">
              {{ keyword.keyword }}
            </option>
          </select>
          <div class="add">
            <label>Toevoegen</label>
            <input v-model="adding" />
            <button @click="add">Toevoegen</button>
          </div>
        </div>
      </div>
      <div class="footer">
        <span></span>
        <button @click="save">Opslaan</button>
        <button @click="close">Annuleren</button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "../components/Modal.vue";
import SvgUse from "../../vue3/components/SvgUse.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Modal,
    SvgUse,
  },
  data() {
    return {
      id: 0,
      open: false,
      review: {},
      set: [],
      suggested: [],
      fields: {
        id: "ID",
        title: "Titel",
        description: "Omschrijving",
      },
      adding: "",
    };
  },
  created() {
    const that = this;
    $("table.data.nieuws").on("click", "td.open-keywords", function () {
      var $tr = $(this).closest("tr");
      var $td = $tr.find("td:first");
      // ES: First column contains the review database ID
      that.load($td.html());
    });
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    add() {
      const toAdd = this.adding;
      this.adding = "";

      if (!this.set.find((keyword) => keyword.keyword === toAdd)) {
        this.set.push({ id: 0, keyword: toAdd });
      }
    },
    addSuggested() {
      const ref = this.$refs.suggested;
      if (ref && ref.selectedOptions) {
        let toAdd = [].slice.call(ref.selectedOptions);
        while (toAdd.length) {
          let idx = this.suggested.findIndex((item) => item.keyword === toAdd[0].value);
          if (idx >= 0) {
            this.set.push(...this.suggested.splice(idx, 1));
          }
          toAdd[0].selected = false;
          toAdd.shift();
        }
      }
    },
    removeSet() {
      const ref = this.$refs.set;
      if (ref && ref.selectedOptions) {
        let toRemove = [].slice.call(ref.selectedOptions);
        while (toRemove.length) {
          let idx = this.set.findIndex((item) => item.keyword === toRemove[0].value);
          if (idx >= 0) {
            this.suggested.push(...this.set.splice(idx, 1));
          }
          toRemove[0].selected = false;
          toRemove.shift();
        }
      }
    },
    handleResponse(response) {
      Object.assign(this.review, response.review);
      this.set.splice(0);
      this.set.push(...response.set);
      this.suggested.splice(0);
      this.suggested.push(...response.suggested);
    },
    async load(id) {
      let response = await fetch("/reviews/" + id + "/keywords");
      response = await response.json();

      this.handleResponse(response);

      this.id = id;
      this.open = true;
    },
    async save() {
      let response = await fetch("/reviews/" + this.id + "/keywords", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          keywords: this.set.map((keyword) => keyword.keyword),
        }),
      });
      response = await response.json();

      this.handleResponse(response);

      $('table.data.nieuws tr[data-id="' + this.id + '"] td.keywords').html(
        response.set.map((keyword) => keyword.keyword).join(","),
      );
    },
    close() {
      this.open = false;
    },
  },
};
</script>

<style>
.keywords .header {
  font-size: 16px;
  font-weight: bold;
}
.keywords .content {
  display: grid;
  grid-template-columns: 120px auto;
  width: 100%;
}
.keywords .content span {
  padding: 10px;
}
.keywords .content span.label {
  padding-left: 0;
}
.keywords .content .keywords {
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  padding-left: 10px;
  padding-bottom: 10px;
}
.keywords .content .keywords span {
  padding-left: 0;
}
.keywords .content .keywords select {
  overflow: auto;
  width: calc(100% - 20px);
  min-height: 200px;
}
.keywords .content .keywords select option {
  padding: 5px;
}
.keywords .content .keywords select:focus {
  outline: none;
}
.keywords .content .keywords .fa-arrow-left,
.keywords .content .keywords .fa-arrow-right {
  border: 1px solid black;
  border-radius: 12px;
  padding: 5px;
  margin-top: 10px;
}
.keywords .content .keywords .fa-arrow-left:hover,
.keywords .content .keywords .fa-arrow-right:hover {
  border: 2px solid black;
  padding: 6px;
}
.keywords .content .keywords .add {
  margin-top: 10px;
}
.keywords .content .keywords .add input {
  margin-left: 20px;
  outline: none;
  padding: 3px;
  margin-top: -3px;
}
.keywords .footer {
  display: grid;
  grid-template-columns: auto 120px 120px;
  margin-top: 10px;
}
.keywords .footer button {
  margin-right: 10px;
}
</style>
