<template>
  <Modal :open="showPopup" :width="600" @close="showPopup = false">
    <div class="tailwind">
      <h2 class="#text-[16.5px] #font-bold #mb-[20px]">
        Let op, er staan taken open waar nu niemand mee bezig is
      </h2>
      <p class="#mb-6">Ga naar het taakverdelingen scherm om te kijken om welke taken dit gaat.</p>
      <AdminButton href="admin.php?page=home&sub=ks" @click="showPopup = false">
        Naar overzicht
      </AdminButton>
    </div>
  </Modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import AdminButton from "../components/AdminButton.vue";

export default {
  components: {
    Modal,
    AdminButton,
  },
  data() {
    return {
      showPopup: false,
    };
  },
  mounted() {
    window.Echo.channel("unassigned_tasks").listen("UnassignedTasks", () => {
      this.showPopup = true;
    });
  },
};
</script>
