<template>
  <div v-if="active" :class="s.container">
    <p :class="s.number">
      {{ number }}
    </p>
    <p :class="s.counter">
      {{ time }}
    </p>
    <div v-if="status === 'pending'" :class="s.buttonContainer">
      <div class="tailwind" :class="[s.button, s.accept]">
        <svg-use
          id="spinner"
          type="solid"
          class="#h-[1.75em] #w-[2em] #fill-inkdropdark #animate-spin"
        />
      </div>
    </div>
    <div v-else :class="s.buttonContainer">
      <div class="tailwind" :class="[s.button, s.mute]" @click="toggleMuted">
        <svg-use
          :id="muted ? 'microphone' : 'microphone-slash'"
          type="solid"
          class="#h-[1.75em] #w-[2em] #fill-inkdropdark"
        />
      </div>
      <div class="tailwind" :class="[s.button, s.end]" @click="hangup">
        <svg-use id="phone-hangup" type="solid" class="#h-[1.75em] #w-[2em] #fill-white" />
      </div>
    </div>
  </div>
</template>

<script>
import { Device } from "@twilio/voice-sdk";
import { mapGetters, mapState } from "vuex";
import SvgUse from "../../vue3/components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  data() {
    return {
      active: false,
      call: null,
      status: null,
      muted: false,
      number: null,
      timer: null,
      ringer: null,
      seconds: 0,
    };
  },
  computed: {
    ...mapGetters(["route"]),
    ...mapState(["callNotifications"]),
    time() {
      if (this.timer) {
        return moment.utc(this.seconds * 1000).format(this.seconds < 3600 ? "mm:ss" : "HH:mm:ss");
      }

      if (this.status === "ringing") {
        return "Gaat over";
      }

      if (this.status === "pending") {
        return "Inkomend";
      }

      return "Verbinden";
    },
  },
  watch: {
    status(value, oldValue) {
      if (value !== oldValue) {
        console.log("Twilio call status: " + value);
      }
    },
    callNotifications: {
      immediate: true,
      handler(value, oldValue) {
        if (this.isPWA()) {
          if (value) {
            this.startNotifications();
          } else {
            this.stopNotifications();
          }
        } else {
          console.log("No notifications, not in PWA mode");
        }
      },
    },
    call(call) {
      if (call) {
        console.log("Twilio call started");

        call.on("accept", async () => {
          console.log("Twilio call event: accept");
          this.startTimer();
          this.muted = call.isMuted();
          this.status = call.status();
        });
        call.on("sample", async () => {
          this.status = call.status();
        });
        call.on("disconnect", async () => {
          console.log("Twilio call event: disconnect");

          call.removeAllListeners();
          await this.device.audio.unsetInputDevice();
          this.stopIncoming();
          this.stopTimer();
          this.reset();
        });
        call.on("error", (error) => {
          console.log("Twilio call event: error");
          if (error.code !== 31005) {
            alert("Onbekende bug, meld aan IT");
          } else if (this.status === null) {
            alert(
              "Kan niet verbinden, klopt het nummer wel, check de landcode? Meld aan IT als het nummer wel klopt",
            );
          }
        });
        call.on("cancel", async () => {
          console.log("Twilio call event: cancel");
        });
        call.on("reject", () => {
          console.log("Twilio call event: reject");
        });
        call.on("transportClose", () => {
          console.log("Twilio call event: transportClose");
        });
        // call.on('volume', () => {
        //   console.log('Twilio call event: volume')
        // });
      }
    },
  },
  created() {
    this.emitter.on("take-call", ({ id, number }) => {
      this.number = number;
      this.active = true;
      this.startIncoming(id);
    });
  },
  mounted() {
    window.addEventListener("keyup", this.keyUpListener);

    $(document).on("click", "a[href^='tel']", (event) => {
      event.preventDefault();
      this.ring(event.target.href.replace("tel:", ""));
    });
    this.emitter.on("dial", (number) => {
      this.ring(number);
    });
  },
  beforeUnmount() {
    this.emitter.off("take-call");
    this.emitter.off("dial");

    $(document).off("click", "a[href^='tel']");
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.ringer) {
      clearInterval(this.ringer);
    }
  },
  unmounted() {
    window.removeEventListener("keyup", this.keyUpListener);
  },
  methods: {
    reset() {
      this.active = false;
      this.call = null;
      this.status = null;
      this.muted = false;
      this.number = null;
      this.timer = null;
      this.ringer = null;
      this.seconds = 0;
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.seconds++;
      }, 1000);
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    isPWA() {
      return ["standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia("(display-mode: " + displayMode + ")").matches,
      );
    },
    async hangup() {
      this.call.disconnect();
    },
    async toggleMuted() {
      this.call.mute(!this.call.isMuted());
      this.muted = this.call.isMuted();
    },
    async createDevice() {
      if (!this.device) {
        let response = await fetch(this.route("call.token"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({}),
        });
        const { token } = await response.json();

        this.device = new Device(token, {
          allowIncomingWhileBusy: false,
          appName: "P&B Admin",
          appVersion: "1.0.0",
          closeProtection: true,
          logLevel: 5,
        });

        await this.setOutputAudio();
      }
    },
    async setOutputAudio() {
      let stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      let speaker = "default";
      this.device.audio.availableOutputDevices.forEach((device, id) => {
        // console.log('Twillio output device found: ' + id)
        if (id === "communications") {
          speaker = id;
        }
      });

      await this.device.audio.speakerDevices.set(speaker);
      await this.device.audio.ringtoneDevices.set("default");

      console.log("Twilio speaker device set: " + speaker);
      console.log("Twilio ringtone device set: default");

      stream.getTracks().forEach((track) => track.stop());
    },
    async setInputAudio() {
      let stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      let input = "default";
      this.device.audio.availableInputDevices.forEach((device, id) => {
        // console.log('Twillio input device found: ' + id)
        if (id === "communications") {
          input = id;
        }
      });

      await this.device.audio.setInputDevice(input);

      console.log("Twilio input device set: " + input);

      stream.getTracks().forEach((track) => track.stop());
    },
    async startNotifications() {
      await this.createDevice();
      const ring = async () => {
        let response = await fetch(this.route("call.queue"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({}),
        });
        if (response.ok) {
          const { queue } = await response.json();
          if (queue > 0) {
            if (!this.device.isBusy) {
              console.log("People in queue, playing sound");
              this.device.audio.ringtoneDevices.test(
                "https://media.twiliocdn.com/sdk/js/client/sounds/releases/1.0.0/incoming.mp3",
              );
            } else {
              console.log("People in queue, already on call, not playing sound");
            }
          } else {
            console.log("No people in queue, not playing sound");
          }
        } else {
          await response.text();
        }
      };
      ring();
      this.ringer = setInterval(ring, 2500);
    },
    async stopNotifications() {
      if (this.ringer) {
        clearInterval(this.ringer);
      }
    },
    async startIncoming(id) {
      await this.createDevice();
      await this.setInputAudio();

      this.device.on("registered", async (device) => {
        console.log("Twilio device event: registered");
        if (!this.call) {
          let response = await fetch(this.route("call.take"), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              id: id,
            }),
          });
          if (response.ok) {
            const { success } = await response.json();
            if (success) {
              console.log("Requested call from queue");
            } else {
              await this.device.audio.unsetInputDevice();
              this.stopIncoming();
              this.number = null;
              this.active = false;
            }
          } else {
            await response.text();
          }
        }
      });
      this.device.on("registering", (device) => {
        console.log("Twilio device event: registering");
      });

      this.device.on("incoming", async (call) => {
        console.log("Twilio device event: incoming");
        console.log(call);

        if (!this.call) {
          this.number = call.parameters.From;
          this.active = true;
          this.call = call;
          this.status = call.status();

          call.accept({ rtcConstraints: {} });
        }
      });

      this.device.register();
    },
    async stopIncoming() {
      this.device.on("unregistered", (device) => {
        console.log("Twilio device event: unregistered");
        this.device.removeAllListeners();
      });

      this.device.unregister();
    },
    async ring(number) {
      if (number.startsWith("0031")) {
        number = number.replace("0031", "+31");
      } else if (number.startsWith("0")) {
        number = number.replace("0", "+31");
      }

      this.number = number;
      this.active = true;

      await this.createDevice();
      await this.setInputAudio();

      let response = await fetch(this.route("call.store"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          number: this.number,
        }),
      });
      const { call } = await response.json();

      this.call = await this.device.connect({
        params: {
          To: call,
        },
      });
    },
    keyUpListener(e) {
      if (this.call && this.active) {
        const regex = /[0-9#*+]/;

        if (regex.test(e.key)) {
          this.call.sendDigits(e.key);
        }
      }
    },
  },
};
</script>

<style module="s">
.container {
  top: auto !important;
  left: 10px !important;
  bottom: 10px !important;
  right: auto !important;
  position: fixed !important;

  width: 200px;
  height: 200px;
  background-color: #3c3c3c;
  border: solid;
  border-color: #0099ff;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.number {
  color: white;
  font-size: 20px;
  margin: 0px;
  margin-bottom: 10px;
}

.counter {
  color: white;
  font-size: 11px;
  margin: 0px;
  margin-bottom: 10px;
}

.buttonContainer {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f7;
  border-radius: 50%;
  aspect-ratio: 1;
  margin: 0px;
  flex: 1;
  border: 0px;
  width: 20px;
  height: 20px;
  padding: 15px;
  cursor: pointer;
}

.icon {
  width: 100%;
  height: 100%;
}

.mute {
}

.end {
  background-color: #e6007d;
}

.accept {
  background-color: #28a745;
}
</style>
