<template>
  <div class="tailwind">
    <AdminButton btn-class="secondary" type="button" class="#mt-2" @click="open = true">
      Gesprek aanvragen
    </AdminButton>
    <Modal :open="open" @close="open = false">
      <div>
        <label for="message" class="#block #text-sm #font-medium #text-gray-900"> Reden </label>
        <textarea
          id="message"
          v-model="body.reason"
          rows="4"
          class="#h-1/2 #w-full #block #p-2.5 #text-sm #text-gray-900 #bg-gray-50 #rounded-lg #border #border-gray-300 #focus:ring-blue-500 #focus:border-blue-500"
        ></textarea>
      </div>
      <div>
        <div class="#mt-5">
          <input
            id="conversation-management"
            v-model="body.email"
            type="radio"
            value="stanley@printenbind.nl"
          />
          <label for="conversation-management" class="#text-xs #inline-block #ml-1 #align-middle">
            Stanley
          </label>
        </div>
        <div>
          <input id="conversation-hr" v-model="body.email" type="radio" value="hr@printenbind.nl" />
          <label for="conversation-hr" class="#text-xs #inline-block #ml-1 #align-middle">
            HR
          </label>
        </div>
        <div>
          <button
            type="submit"
            class="#mt-2 #text-white #bg-blue-700 #hover:bg-blue-800 #focus:ring-4 #focus:ring-blue-300 #font-medium #rounded-lg #text-xs #px-2 #py-2 #me-2 #mb-2 #focus:outline-none"
            @click="handleRequestConversation"
          >
            Verstuur
          </button>
          <div v-if="message" class="#text-sm #text-green-800" role="alert">
            <span class="#font-medium">{{ message }}</span>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import AdminButton from "../components/AdminButton.vue";
import Modal from "../components/Modal.vue";
import { mapGetters } from "vuex";

export default {
  components: { Modal, AdminButton },
  data() {
    return {
      body: {
        email: null,
        reason: null,
      },
      message: "",
      open: false,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  methods: {
    async handleRequestConversation() {
      const response = await fetch(this.route("employees.request.conversation"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(this.body),
      });

      if (response.ok) {
        this.message = await response.json();
        setTimeout(() => {
          this.open = false;
        }, 2000);
      } else if (!response.ok) {
        await response.text();
      }
    },
  },
};
</script>
