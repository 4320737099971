<template>
  <transition
    :enter-from-class="s.fadeEnterFrom"
    :leave-to-class="s.fadeLeaveTo"
    :enter-active-class="s.fadeEnterActive"
    :leave-active-class="s.fadeLeaveActive"
    @before-enter="beforeOpen"
    @after-leave="afterOpen"
  >
    <div v-if="open" :class="s.backdrop" @click="$emit('close')">
      <div :class="s.dialog" :style="dialogStyle" @click.stop="">
        <div class="content-slot" :class="s.content">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ["close"],
  computed: {
    dialogStyle() {
      let style = {};
      if (this.width) {
        style.width = this.width + "px";
      }
      return style;
    },
  },
  methods: {
    beforeOpen() {
      const width = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.paddingRight = `${width}px`;
      document.body.style.overflow = "hidden";
    },
    afterOpen() {
      document.body.style.paddingRight = null;
      document.body.style.overflow = null;
    },
  },
};
</script>

<style module="s">
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.content {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
  padding: 15px;
}
@media (min-width: 768px) {
  .dialog {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  .content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}

.fadeEnterActive,
.fadeLeaveActive {
  transition: opacity 0.3s ease;
}

.fadeEnterFrom,
.fadeLeaveTo {
  opacity: 0;
}

.fadeEnterActive .dialog,
.fadeLeaveActive .dialog {
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;
}

.fadeLeaveTo .dialog,
.fadeEnterFrom .dialog {
  transform: translate(0, -25%);
}
</style>
