<template>
  <div :class="s.container" @click="onClick">
    <div :class="s.column">
      <img :src="`/admin/images/printers/${type}.png`" :class="s.image" />
    </div>
    <div :class="s.column">
      <table>
        <tbody>
          <tr>
            <td :class="s.label">Naam:</td>
            <td>{{ getName }}</td>
          </tr>
          <tr>
            <td :class="s.label">Merk:</td>
            <td>{{ getBrand }}</td>
          </tr>
          <tr>
            <td :class="s.label">Type:</td>
            <td>{{ type }}</td>
          </tr>
          <tr>
            <td :class="s.label">Status:</td>
            <td>-</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div :class="s.column">
      <table>
        <tbody>
          <tr>
            <td :class="s.label">Vandaag:</td>
            <td>{{ today_job_count }} jobs</td>
          </tr>
          <tr>
            <td :class="s.label"></td>
            <td>{{ today_sheet_sum }} sheets</td>
          </tr>
          <tr>
            <td :class="s.label"></td>
            <td>{{ today_articles_count }} artikelen</td>
          </tr>
          <tr>
            <td :class="s.label"></td>
            <td>{{ today_orders_count }} orders</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div :class="s.column">
      <table>
        <tbody>
          <tr>
            <td :class="s.label">Wachtrij:</td>
            <td>{{ waiting_job_count }} jobs</td>
          </tr>
          <tr>
            <td :class="s.label"></td>
            <td>{{ waiting_sheet_sum }} sheets</td>
          </tr>
          <tr>
            <td :class="s.label"></td>
            <td>{{ waiting_articles_count }} artikelen</td>
          </tr>
          <tr>
            <td :class="s.label"></td>
            <td>{{ waiting_orders_count }} orders</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    brand: {
      type: String,
      required: true,
    },
    created_at: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    updated_at: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    waiting_job_count: {
      type: Number,
      required: true,
    },
    waiting_sheet_sum: {
      type: Number,
      required: true,
    },
    waiting_articles_count: {
      type: Number,
      required: true,
    },
    waiting_orders_count: {
      type: Number,
      required: true,
    },
    today_job_count: {
      type: Number,
      required: true,
    },
    today_sheet_sum: {
      type: Number,
      required: true,
    },
    today_articles_count: {
      type: Number,
      required: true,
    },
    today_orders_count: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      window.location.assign(
        `/admin.php?page=machines&sub=printers&sub_sub=overview&printer=${this.id}`,
      );
    },
  },
  computed: {
    ...mapGetters(["route"]),
    getName() {
      return this.name.charAt(0).toUpperCase() + this.name.slice(1);
    },
    getBrand() {
      return this.brand.charAt(0).toUpperCase() + this.brand.slice(1);
    },
  },
};
</script>

<style module="s">
.image {
  width: 100%;
}

.container {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 0px;
  cursor: pointer;
}

.container:hover {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.035);
}

.column {
  display: flex;
  flex: 1;
  justify-content: center;
}

.label {
  font-weight: bold;
  padding-right: 10px;
}
</style>
