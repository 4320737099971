<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["route"]),
  },
  mounted() {
    this.check();
    this.interval = setInterval(this.check, 10000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    async check() {
      let response = await fetch(this.route("auth.check"), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      if (response.ok) {
        response = await response.json();
      } else if (response.status === 401) {
        window.location.assign("/admin.php");
      }
    },
  },
};
</script>
