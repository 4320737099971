<template>
  <h1>Statistieken</h1>
  <div class="tailwind">
    <table
      class="#font-[arial] #text-left #ml-px #mb-2 #border-solid #border-[#3C3C3C] #border-2 #border-collapse #w-full"
    >
      <thead>
        <tr>
          <th
            class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          >
            Periode
          </th>
          <th
            class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          >
            Modus
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="#p-1 #align-top #w-1/5">
            <div class="#flex #flex-1">
              <div class="#flex #flex-1 #flex-col">
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('today')"
                  >Vandaag</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('yesterday')"
                  >Gister</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('week')"
                  >Deze week</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('month')"
                  >Deze maand</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('year')"
                  >Dit jaar</a
                >
              </div>
              <div class="#flex #flex-1 #flex-col">
                <input
                  ref="from"
                  v-model="from"
                  type="text"
                  readonly=""
                  placeholder="Van"
                  class="#px-1 #border #border-solid #border-black #mb-1"
                />
                <input
                  ref="to"
                  v-model="to"
                  type="text"
                  readonly=""
                  placeholder="Tot"
                  class="#px-1 #border #border-solid #border-black"
                />
              </div>
            </div>
          </td>
          <td class="#p-1 #align-top">
            <div>
              <input v-model="mode" type="radio" value="amount" />
              <label class="#inline-block #ml-1 #align-middle">Aantal oproepen</label>
            </div>
            <div>
              <input v-model="mode" type="radio" value="missed" />
              <label class="#inline-block #ml-1 #align-middle">Gemiste oproepen</label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    v-if="Object.entries(statistics).length > 0"
    class="call-statistics-container #flex #items-center #justify-center"
  >
    <Doughnut id="call-statistics" :options="chartOptions" :data="chartData" :plugins="plugins" />
  </div>
</template>

<script>
import { Doughnut } from "vue-chartjs";
import { mapGetters } from "vuex";
import ChartDataLabels from "chartjs-plugin-datalabels";
import DatePickerFilter from "../mixins/DatePickerFilter";

export default {
  components: { Doughnut },
  mixins: [DatePickerFilter],
  data() {
    return {
      colors: [],
      data: [],
      body: {
        from: moment().subtract(1, "weeks").startOf("week").format("DD-MM-YYYY HH:mm:ss"),
        to: moment().subtract(1, "weeks").endOf("week").format("DD-MM-YYYY HH:mm:ss"),
      },
      from: moment().subtract(1, "weeks").startOf("week").format("DD-MM-YYYY HH:mm:ss"),
      labels: [],
      mode: "amount",
      percentage: 0,
      percentages: [],
      statistics: [],
      to: moment().subtract(1, "weeks").endOf("week").format("DD-MM-YYYY HH:mm:ss"),
      totalNumberOfCalls: 0,
      totalNumberOfMissedCalls: 0,
    };
  },
  computed: {
    plugins() {
      return [
        ChartDataLabels,
        {
          id: "innerLabel",
          afterDatasetDraw: (chart, args) => this.setInnerLabel(chart, args),
        },
      ];
    },
    ...mapGetters(["route"]),
    chartData() {
      this.setData();

      return {
        labels: this.labels,
        datasets: [
          {
            data: this.percentages,
            backgroundColor: this.colors,
            hoverOffset: 4,
          },
        ],
      };
    },
    chartOptions() {
      return {
        layout: {
          padding: 50,
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: this.setDataLabels(),
          tooltip: this.setTooltip(),
        },
        responsive: true,
      };
    },
  },
  watch: {
    mode: {
      handler() {
        this.setData();
      },
    },
    body: {
      handler() {
        this.fetchStatistics();
      },
      deep: true,
    },
    from: "filterPeriod",
    to: "filterPeriod",
  },
  mounted() {
    this.fetchStatistics();

    this.$nextTick(() => {
      this.setupDatePickers();
    });
  },
  methods: {
    async fetchStatistics() {
      let response = await fetch(this.route("call.statistics"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(this.body),
      });

      response = await response.json();

      if (response.success) {
        this.statistics = response.statistics;
        this.totalNumberOfCalls = response.total_number_of_calls;
        this.totalNumberOfMissedCalls = response.total_number_of_missed_calls;
        this.percentage = response.percentage;
      }
    },
    setDataLabels() {
      return {
        labels: {
          employee: {
            color: "#565d64",
            font: {
              weight: "bold",
              size: "12px",
            },
            anchor: "end",
            align: "end",
            textAlign: "center",
            rotation: function (ctx) {
              const valuesBefore = ctx.dataset.data
                .slice(0, ctx.dataIndex)
                .reduce((a, b) => a + b, 0);
              const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
              const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum) * 360;

              return rotation < 180 ? rotation - 90 : rotation + 90;
            },
            formatter: (value, context) => {
              return this.labels[context.dataIndex];
            },
          },
          amount: {
            color: "white",
            font: {
              weight: "bold",
              size: "12fpx",
            },
            anchor: "center",
            align: "center",
            formatter: (value, context) => {
              return this.mode === "amount"
                ? this.data[context.dataIndex].number_of_calls
                : this.data[context.dataIndex].number_of_missed_calls;
            },
          },
        },
      };
    },
    setTooltip() {
      return {
        bodyAlign: "center",
        boxPadding: 4,
        callbacks: {
          label: (item) => {
            const label = this.labels[item.dataIndex];
            return [
              label,
              `Aantal oproepen: ${this.data[item.dataIndex].number_of_calls}`,
              `Gemiste oproepen: ${this.data[item.dataIndex].number_of_missed_calls}`,
              `Percentage: ${this.data[item.dataIndex].percentage}%`,
            ];
          },
        },
      };
    },
    setData() {
      const randomNum = () => Math.floor(Math.random() * 255);
      this.percentages = [];
      this.labels = [];
      this.colors = [];
      this.data = [];

      for (const [key, value] of Object.entries(this.statistics)) {
        this.colors.push(`rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`);
        this.labels.push(value.firstname);
        this.data.push({
          number_of_calls: value.number_of_calls,
          number_of_missed_calls: value.number_of_missed_calls,
          percentage: value.percentage,
        });

        const item = this.mode === "amount" ? value.number_of_calls : value.number_of_missed_calls;
        const total =
          this.mode === "amount" ? this.totalNumberOfCalls : this.totalNumberOfMissedCalls;

        this.percentages.push(Math.max((item / total) * 100, 1.5));
      }
    },
    setInnerLabel(chart, args) {
      const { ctx } = chart;
      const meta = args.meta;

      if (meta.data[0]) {
        const xCoor = meta.data[0].x;
        const yCoor = meta.data[0].y;

        ctx.save();
        ctx.textAlign = "center";
        ctx.fillStyle = "#565d64";
        ctx.font = "18px sans-serif";

        const labelTotalNumberOfCalls = `Aantal oproepen: ${this.totalNumberOfCalls}`;
        const labelTotalNumberOfMissedCalls = `Gemiste oproepen: ${this.totalNumberOfMissedCalls}`;
        const labelPercentage = `Percentage: ${this.percentage}%`;

        ctx.fillText(labelTotalNumberOfCalls, xCoor, yCoor - 24);
        ctx.fillText(labelTotalNumberOfMissedCalls, xCoor, yCoor);
        ctx.fillText(labelPercentage, xCoor, yCoor + 24);

        ctx.restore();
      }
    },
  },
};
</script>
<style>
.call-statistics-container {
  height: 600px;
  width: 600px;
}
</style>
