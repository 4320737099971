<template>
  <div class="#relative #shadow-md #sm:rounded-lg">
    <table class="#w-full #text-sm #text-left #rtl:text-right #text-gray-500 #mt-2">
      <thead class="#text-xs #text-gray-700 #bg-gray-50 #sticky #top-0">
        <tr>
          <th scope="col" class="#px-6 #py-3">Floormanager</th>
          <th scope="col" class="#px-6 #py-3">Aantal ingewerkte medewerkers</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="stat in floorManagerStats"
          :key="stat"
          class="#bg-white #border-b #hover:bg-gray-50 #cursor-pointer"
        >
          <td class="#px-6 #font-bold">{{ stat.floor_mananger }}</td>
          <td class="#px-6">{{ stat.number_of_employees_taught }}</td>
        </tr>
        <tr
          v-if="floorManagerStats && floorManagerStats.length === 0"
          class="#bg-white #border-b #hover:bg-gray-50"
        >
          <td colspan="2" class="#text-center #px-6 #py-4">Geen resultaten</td>
        </tr>
      </tbody>
    </table>
  </div>

  <table id="trained" class="display" style="width: 100%">
    <thead>
      <tr>
        <th>Ingewerkt door</th>
        <th>Medewerker</th>
        <th>Vaardigheid</th>
        <th>Ingewerkt op</th>
      </tr>
    </thead>
  </table>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    floorManagerStats: {
      type: Object,
      required: true,
    },
  },
  emits: ["loading-status"],
  data() {
    return {
      table: null,
    };
  },
  watch: {
    data: {
      handler() {
        if (this.table) {
          this.table.destroy();
        }

        this.buildTable();
      },
    },
  },
  methods: {
    buildTable() {
      this.table = $("#trained").DataTable({
        serverSide: false,
        order: [[3, "desc"]],
        data: this.data,
        preDrawCallback: () => {
          this.$emit("loading-status", true);
        },
        drawCallback: () => {
          this.$emit("loading-status", false);
        },
        columns: [
          { data: "taught_by" },
          { data: "employee" },
          { data: "skill" },
          {
            data: "taught_by_on",
            render: (data) => {
              return moment(data, moment.ISO_8601).isValid()
                ? moment(data, moment.ISO_8601).format("DD-MM-YYYY")
                : "";
            },
          },
        ],
      });
    },
  },
};
</script>
