<template>
  <h1>
    Mislukt
    <img v-if="loading" src="/admin/images/loading.gif" :class="s.spinner" />
    <a class="filter-btn manage_button" @click="isShowFilter = !isShowFilter">Filteren</a>
  </h1>
  <div class="tailwind">
    <div v-show="isShowFilter" class="#flex #space-x-4">
      <div class="#w-1/4">
        <div>
          <SelectFilter v-model="filters.action" label="Acties" :options="actions" />
        </div>
        <div class="#mt-2">
          <SelectFilter v-model="filters.profile" label="Profielen" :options="profiles" />
        </div>
        <div class="#mt-2">
          <SelectFilter v-model="filters.exit_code" label="Exit codes" :options="exitCodes" />
        </div>
        <div class="#mt-2">
          <SelectFilter v-model="filters.reason_code" label="Reason codes" :options="reasonCodes" />
        </div>
      </div>
      <div class="#w-3/4">
        <div>
          <IntegerFilter v-model="filters.memory" label="Geheugen" />
        </div>
        <div class="#mt-2">
          <IntegerFilter v-model="filters.runtime" label="Looptijd" />
        </div>
        <div class="#mt-2">
          <DateFilter v-model="filters.date" label="Aangemaakt om" />
        </div>
      </div>
    </div>
    <table id="pdftoolbox_failed" class="display" style="width: 100%">
      <thead>
        <tr>
          <th></th>
          <th>Actie</th>
          <th>Profiel</th>
          <th>Inputs</th>
          <th>Error code</th>
          <th>Reason code</th>
          <th>Geheugen</th>
          <th>Looptijd</th>
          <th>Aangemaakt om</th>
          <th>Acties</th>
        </tr>
      </thead>
    </table>
  </div>
</template>
<script>
import DateFilter from "../components/DateFilter.vue";
import IntegerFilter from "../components/IntegerFilter.vue";
import { mapGetters } from "vuex";
import SelectFilter from "../components/SelectFilter.vue";

export default {
  components: { DateFilter, IntegerFilter, SelectFilter },
  data() {
    return {
      actions: [],
      exitCodes: [],
      filters: {
        action: [],
        date: {},
        profile: [],
        exit_code: [],
        memory: {},
        reason_code: [],
        runtime: {},
      },
      interval: null,
      isShowFilter: false,
      loading: true,
      memory: {},
      profiles: [],
      reasonCodes: [],
      table: null,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    filters: {
      handler() {
        this.loading = true;
        this.fetchOptions();
        this.table.ajax.reload();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchOptions();
    this.buildTable();

    document.querySelector("#pdftoolbox_failed").addEventListener("click", this.openDetail);
    this.loading = false;
  },
  beforeUnmount() {
    clearInterval(this.interval);
    this.table.destroy();
    this.table = null;
    document.removeEventListener("click", this.openDetail);
  },
  methods: {
    buildTable() {
      this.table = $("#pdftoolbox_failed").DataTable({
        ajax: {
          url: this.route("pdftoolbox.failed"),
          data: (d) => {
            for (const [key, value] of Object.entries(this.filters)) {
              if (value) {
                d[key] = value;
              }
            }
          },
        },
        order: [[8, "desc"]],
        select: false,
        drawCallback: () => {
          this.loading = false;
        },
        columns: [
          { data: "id", visible: false },
          { data: "action" },
          { data: "profile" },
          {
            data: "inputs",
            render: function (data) {
              let html = '<div class="ellipsis" style="max-width:200px;">';
              data = data ?? [];

              if (data.length >= 1) {
                const file = data[0];
                let name = file.split("/").pop();
                html += `<a title="${file}">${name}</a>`;

                if (data.length > 1) {
                  html += ` <b>(+${data.length - 1})<b>`;
                }
              }

              return html + "</div>";
            },
          },
          { data: "exit_code" },
          { data: "reason_code" },
          {
            data: "memory",
            render: function (data) {
              return data ? `${data} MB` : "";
            },
          },
          {
            data: "runtime",
            render: function (data) {
              return data !== null ? `${data} s` : "";
            },
          },
          { data: "created_at" },
          {
            searchable: false,
            sortable: false,
            data: null,
            className: "center",
            render: function (data) {
              return '<a href="javascript:;" class="editor_details #text-indigo-700 #cursor-pointer #underline">Details</a>';
            },
          },
        ],
      });
    },
    async fetchOptions() {
      const filters = {};

      for (const [key, value] of Object.entries(this.filters)) {
        if (Object.keys(value).length !== 0) {
          filters[key] = value;
        }
      }

      let response = await fetch(this.route("pdftoolbox.failed.options"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(filters),
      });

      const pdfToolboxOptions = await response.json();

      if (pdfToolboxOptions.success) {
        this.actions = pdfToolboxOptions.actions;
        this.exitCodes = pdfToolboxOptions.exit_codes;
        this.profiles = pdfToolboxOptions.profiles;
        this.reasonCodes = pdfToolboxOptions.reason_codes;
      }
    },
    openDetail(e) {
      e.preventDefault();
      const row = this.table.row($(e.target).parents("tr")).data();
      const url = new URL(window.parent.location.href);

      url.searchParams.set("log_id", row.DT_RowId);
      window.parent.location.href = url.toString();
    },
  },
};
</script>
<style module="s">
.spinner {
  margin-bottom: -9px;
}
</style>
