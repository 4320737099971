<template>
  <h1>Productie statistieken</h1>

  <div class="tailwind">
    <Tabs :tabs="tabs" :view="view" @view="setView" />
    <div v-if="view === 'all'">
      <DayChart :data="day.datasets" :labels="day.labels" :show-unfinished="false" />
      <FastChart :data="fast.datasets" :labels="fast.labels" :show-unfinished="false" />
      <MorningChart :data="morning.datasets" :labels="morning.labels" :show-unfinished="false" />
      <AfternoonChart
        :data="afternoon.datasets"
        :labels="afternoon.labels"
        :show-unfinished="false"
      />
      <WorkedInAdvanceChart
        :data="workedInAdvance.datasets"
        :labels="workedInAdvance.labels"
        :show-tables="false"
      />
      <OverdueChart :data="overdue.datasets" :labels="overdue.labels" :show-tables="false" />
      <NumberOfWorkedHoursChart
        :data="numberOfWorkedHours.datasets"
        :labels="numberOfWorkedHours.labels"
      />
    </div>
    <div v-if="view === 'day'">
      <DayChart :data="day.datasets" :labels="day.labels" :show-unfinished="true" />
    </div>
    <div v-if="view === 'fast'">
      <FastChart :data="fast.datasets" :labels="fast.labels" :show-unfinished="true" />
    </div>
    <div v-if="view === 'morning'">
      <MorningChart :data="morning.datasets" :labels="morning.labels" :show-unfinished="true" />
    </div>
    <div v-if="view === 'afternoon'">
      <AfternoonChart
        :data="afternoon.datasets"
        :labels="afternoon.labels"
        :show-unfinished="true"
      />
    </div>
    <div v-if="view === 'worked_in_advance'">
      <WorkedInAdvanceChart
        :data="workedInAdvance.datasets"
        :labels="workedInAdvance.labels"
        :show-tables="true"
      />
      <WorkedInAdvanceRevenueChart
        :data="workedInAdvanceRevenue.datasets"
        :labels="workedInAdvanceRevenue.labels"
      />
    </div>
    <div v-if="view === 'overdue'">
      <OverdueChart :data="overdue.datasets" :labels="overdue.labels" :show-tables="true" />
    </div>
    <div v-if="view === 'number_of_worked_hours'">
      <NumberOfWorkedHoursChart
        :data="numberOfWorkedHours.datasets"
        :labels="numberOfWorkedHours.labels"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Tabs from "../components/Tabs.vue";
import AfternoonChart from "../components/AfternoonChart.vue";
import DayChart from "../components/DayChart.vue";
import FastChart from "../components/FastChart.vue";
import MorningChart from "../components/MorningChart.vue";
import NumberOfWorkedHoursChart from "../components/NumberOfWorkedHoursChart.vue";
import OverdueChart from "../components/OverdueChart.vue";
import WorkedInAdvanceChart from "../components/WorkedInAdvanceChart.vue";
import WorkedInAdvanceRevenueChart from "../components/WorkedInAdvanceRevenueChart.vue";

export default {
  components: {
    AfternoonChart,
    DayChart,
    FastChart,
    MorningChart,
    NumberOfWorkedHoursChart,
    OverdueChart,
    WorkedInAdvanceChart,
    WorkedInAdvanceRevenueChart,
    Tabs,
  },
  data() {
    return {
      afternoon: {
        datasets: [],
        labels: [],
      },
      day: {
        datasets: [],
        labels: [],
      },
      fast: {
        datasets: [],
        labels: [],
      },
      morning: {
        datasets: [],
        labels: [],
      },
      numberOfWorkedHours: {
        datasets: [],
        labels: [],
      },
      overdue: {
        datasets: [],
        labels: [],
      },
      tabs: [
        { text: "Alle", value: "all" },
        { text: "Dag", value: "day" },
        { text: "Spoed", value: "fast" },
        { text: "Ochtend (10u)", value: "morning" },
        { text: "Middag (16u)", value: "afternoon" },
        { text: "Vooruitgewerkt", value: "worked_in_advance" },
        { text: "Verplaatst", value: "overdue" },
        { text: "Aantal gewerkte uren", value: "number_of_worked_hours" },
      ],
      view: "all",
      workedInAdvance: {
        datasets: [],
        labels: [],
      },
      workedInAdvanceRevenue: {
        datasets: [],
        labels: [],
      },
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  mounted() {
    this.fetchProductionStatistics();
  },
  methods: {
    async fetchProductionStatistics() {
      let response = await fetch(this.route("production.statistics.index"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      response = await response.json();

      if (response.success) {
        this.afternoon = {
          datasets: response.afternoon.datasets,
          labels: response.afternoon.labels,
        };

        this.day = {
          datasets: response.day.datasets,
          labels: response.day.labels,
        };

        this.fast = {
          datasets: response.fast.datasets,
          labels: response.fast.labels,
        };

        this.morning = {
          datasets: response.morning.datasets,
          labels: response.morning.labels,
        };

        this.numberOfWorkedHours = {
          datasets: response.number_of_worked_hours.datasets,
          labels: response.number_of_worked_hours.labels,
        };

        this.overdue = {
          datasets: response.overdue.datasets,
          labels: response.overdue.labels,
        };

        this.workedInAdvance = {
          datasets: response.worked_in_advance.datasets,
          labels: response.worked_in_advance.labels,
        };

        this.workedInAdvanceRevenue = {
          datasets: response.worked_in_advance_revenue.datasets,
          labels: response.worked_in_advance_revenue.labels,
        };
      }
    },
    setView(view) {
      this.view = view;
    },
  },
};
</script>
