<template>
  <table v-if="!loading" id="jobs" class="display" style="width: 100%">
    <thead>
      <tr>
        <th />
        <th>Naam</th>
        <th>Printer</th>
        <th>Artikel</th>
        <th>Order</th>
        <th>Klant</th>
        <th>Status</th>
        <th>Voortgang</th>
        <th>Papier soort</th>
        <th>Formaat</th>
        <th>Verstuurd om</th>
        <th />
      </tr>
    </thead>
  </table>
</template>

<script>
import { createApp } from "vue";
import { mapGetters } from "vuex";
import PrinterJobDetails from "./PrinterJobDetails.vue";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      jobs: [],
      table: null,
    };
  },
  async created() {
    let response = await fetch(this.route("printers.jobs.index", { printer: this.id }), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    this.jobs = await response.json();
    this.loading = false;
  },
  mounted() {
    window.Echo.channel(`printers_${this.id}`).listen("PrinterJobCreated", ({ job }) => {
      this.jobs.push(job);
      this.table.row.add(job).draw(false);
    });
    window.Echo.channel(`printers_${this.id}`).listen("PrinterJobUpdate", ({ id, key, value }) => {
      const index = this.jobs.findIndex((item) => item.id === id);
      if (index === -1) {
        console.warn(`Job ${id} not found for update ${key} : ${value}`);
        return;
      }
      const row = this.table.row((_idx, d) => d.id === id);

      const removeAt = ["COMPLETED", "ABORTED", "HELD"];
      if (key === "status" && removeAt.includes(value)) {
        this.jobs = this.jobs.filter((item) => item.id !== id);
        row.remove().draw(false);
        return;
      } else {
        this.jobs[index][key] = value;
        row.invalidate().draw(false);
        if (row.child.isShown()) {
          row.child(this.getDetails(this.jobs[index])).show();
        }
      }
    });
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    loading(val, oldval) {
      if (val === false) {
        this.$nextTick(this.buildTable);
      } else if (this.table) {
        this.table.destroy();
        this.table = null;
      }
    },
  },
  methods: {
    buildTable() {
      this.table = $("#jobs").DataTable({
        serverSide: false,
        searching: false,
        paging: false,
        select: {
          style: "os",
          selector: "td:not(.link)",
        },
        data: this.jobs,
        order: [[10, "asc"]],
        columns: [
          {
            searchable: false,
            sortable: false,
            data: null,
            defaultContent: "",
            className: "select-checkbox",
          },
          { data: "title" },
          { data: "printer.name" },
          {
            data: "article.id",
            className: "link",
            render: function (data, type, row) {
              return `<a href="admin.php?page=orders&sub=detail&id=${row.article.order.id}&article_id=${data}" class="link">${data}</a>`;
            },
          },
          {
            data: "article.order.id",
            className: "link",
            render: function (data, type, row) {
              return `<a href="admin.php?page=orders&sub=detail&id=${data}" class="link">${data}</a>`;
            },
          },
          {
            data: "article.order.customer.id",
            className: "link",
            render: function (data, type, row) {
              return `<a href="/admin.php?page=customers&sub=detail&customer_id=${data}" class="link">${data}</a>`;
            },
          },
          { data: "status" },
          {
            name: "sheets",
            data: "sheets",
            render: function (data, type, row) {
              if (!data) {
                return "-";
              }
              return `${row.status_sheets} / ${data}`;
            },
          },
          { data: "paper_type" },
          { data: "paper_size" },
          {
            name: "created_at",
            data: "created_at",
            render: function (data, type, row) {
              var date = moment(data, moment.ISO_8601);

              if (type == "sort") {
                return date.format("X");
              }
              if (date.isSame(moment(), "day")) {
                return date.format("HH:mm:ss");
              }

              return date.format("DD-MM-YYYY HH:mm:ss");
            },
          },
          {
            className: "dt-control",
            orderable: false,
            data: null,
            defaultContent: "",
          },
        ],
      });
      $("#jobs tbody").on("click", "td.dt-control", (event) => {
        event.stopPropagation();
        var tr = $(event.target).closest("tr");
        var row = this.table.row(tr);

        if (row.child.isShown()) {
          row.child.hide();
          tr.removeClass("shown");
        } else {
          row.child(this.getDetails(row.data())).show();
          tr.addClass("shown");
        }
      });
    },
    getDetails(job) {
      const div = document.createElement("div");
      createApp(PrinterJobDetails, job).mount(div);

      return div;
    },
  },
};
</script>

<style module="s"></style>
