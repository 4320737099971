<template>
  <div class="tailwind">
    <div>
      <span class="#font-bold">{{ label }}</span>
      <a class="#text-indigo-700 #cursor-pointer #ml-2 #underline" @click="clearDateFilters"
        >Wissen</a
      >
    </div>
    <p
      v-if="error.text && error.type"
      :class="error.type === 'warning' ? '#text-red-600' : '#text-sky-600'"
    >
      {{ error.text }}
    </p>
    <div class="#flex #space-x-2 filter-container #mt-1">
      <select
        v-model="selectedCondition"
        class="#rounded #h-8 #text-xs #border #border-black"
        :class="s.selectCondition"
      >
        <option value="" selected>Conditie</option>
        <option value="=">=</option>
        <option value="!=">!=</option>
        <option value=">">></option>
        <option value="<">&lt;</option>
        <option value="between">tussen</option>
        <option value="!between">niet tussen</option>
        <option value="empty">leeg</option>
        <option value="!empty">niet leeg</option>
      </select>
      <select
        v-if="selectedCondition && !['empty', '!empty'].includes(selectedCondition)"
        v-model="selectedFormat"
        class="#border #border-black #rounded #h-8"
      >
        <option value="yyyy">yyyy</option>
        <option value="MM-yyyy" selected>mm-yyyy</option>
        <option value="dd-MM-yyyy">dd-mm-yyyy</option>
        <option value="dd-MM-yyyy hh">dd-mm-yyyy HH</option>
        <option value="dd-MM-yyyy hh:mm">dd-mm-yyyy HH:mm</option>
        <option value="hh">HH</option>
        <option value="hh:mm">HH:mm</option>
      </select>
      <div v-show="selectedFormat">
        <input
          v-show="isSingleVisible"
          id="single-picker"
          ref="singlePicker"
          v-model="selectedPickerDates.single"
          type="text"
          readonly="true"
          class="filter-airdatepicker single #rounded #h-8 #border #border-black #pl-1"
        />
        <input
          v-show="isRangeVisible"
          id="from-picker"
          ref="fromPicker"
          v-model="selectedPickerDates.from"
          type="text"
          readonly="true"
          class="filter-airdatepicker from #rounded #h-8 #border #border-black #pl-1 #mr-1"
          :class="width"
        />
        <input
          v-show="isRangeVisible"
          id="to-picker"
          ref="toPicker"
          v-model="selectedPickerDates.to"
          type="text"
          readonly="true"
          class="filter-airdatepicker to #rounded #h-8 #border #border-black #pl-1"
          :class="width"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "air-datepicker/air-datepicker.css";
import AirDatepicker from "air-datepicker";
import CommonFilterMixin from "../mixins/filter";
import localeNl from "air-datepicker/locale/nl";

export default {
  mixins: [CommonFilterMixin],
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      error: {
        text: null,
        type: null,
      },
      pickerInstances: {
        singlePicker: null,
        fromPicker: null,
        toPicker: null,
      },
      selectedCondition: null,
      singleDateError: null,
      selectedFormat: null,
      selectedPickerDates: {
        single: null,
        from: null,
        to: null,
      },
      unix: {
        single: null,
        from: null,
        to: null,
      },
    };
  },
  watch: {
    selectedFormat() {
      this.clearSelectedDates();
      this.initializeAirDatepickers();
    },
    selectedCondition() {
      this.clearSelectedDates();

      if (["empty", "!empty"].includes(this.selectedCondition)) {
        this.$emit("update:modelValue", { condition: this.selectedCondition });
      }
    },
  },
  mounted() {
    this.initializeAirDatepickers();
  },
  methods: {
    initializeAirDatepickers() {
      const pickers = Object.keys(this.pickerInstances);

      for (const picker of pickers) {
        if (this.pickerInstances[picker]) {
          this.pickerInstances[picker].destroy();
        }

        let options = {
          autoClose: true,
          locale: localeNl,
          onSelect: this.handleDateSelect,
        };

        if (["dd-MM-yyyy hh", "dd-MM-yyyy hh:mm"].includes(this.selectedFormat)) {
          options.dateFormat = this.selectedFormat.split(" ")[0];
          options.timepicker = true;
          options.timeFormat = this.selectedFormat.split(" ")[1];
          if (this.selectedFormat === "dd-mm-yyyy hh") {
            options.minMinutes = 0;
            options.maxMinutes = 0;
          }
        }

        if (["yyyy", "MM-yyyy", "dd-MM-yyyy"].includes(this.selectedFormat)) {
          options.dateFormat = this.selectedFormat;
          if (this.selectedFormat === "yyyy") {
            options.view = "years";
            options.minView = "years";
          }
          if (this.selectedFormat === "MM-yyyy") {
            options.view = "months";
            options.minView = "months";
          }
        }

        if (["hh", "hh:mm"].includes(this.selectedFormat)) {
          options.timepicker = true;
          options.onlyTimepicker = true;
          options.timeFormat = this.selectedFormat;
          if (this.selectedFormat === "hh") {
            options.minMinutes = 0;
            options.maxMinutes = 0;
          }
        }

        this.pickerInstances[picker] = new AirDatepicker(this.$refs[picker], options);
      }
    },
    handleDateSelect({ date, formattedDate, datepicker }) {
      const datePickerId = datepicker.$el.id;

      switch (datePickerId) {
        case "single-picker":
          this.selectedPickerDates.from = "";
          this.selectedPickerDates.to = "";
          this.selectedPickerDates.single = formattedDate;
          this.unix.single = moment(date).unix();
          break;
        case "from-picker":
          this.selectedPickerDates.single = "";
          this.selectedPickerDates.from = formattedDate;
          this.unix.from = moment(date).unix();
          break;
        case "to-picker":
          this.selectedPickerDates.single = "";
          this.selectedPickerDates.to = formattedDate;
          this.unix.to = moment(date).unix();
          break;
      }

      const isValid = this.validateDate();

      if (isValid) {
        const data = {
          condition: this.selectedCondition,
          format: this.selectedFormat,
          formatted_date:
            datePickerId === "single-picker" ? this.unix.single : [this.unix.from, this.unix.to],
        };

        this.$emit("update:modelValue", data);
      }
    },
    clearDateFilters() {
      this.error = {};
      this.selectedCondition = null;
      this.selectedFormat = null;
      this.clearSelectedDates();

      this.$emit("update:modelValue", {});
    },
    clearSelectedDates() {
      this.selectedPickerDates.single = "";
      this.selectedPickerDates.from = "";
      this.selectedPickerDates.to = "";
    },
  },
};
</script>
<style module="s">
.selectCondition {
  font-size: 9px !important;
}
</style>
