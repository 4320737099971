<template>
  <div>
    <select
      :value="modelValue"
      :class="{ '!#border-[#d9534f]': error }"
      class="#block #border-neutral-300 #border-[1px] #rounded-[4px] #w-full #py-2 #px-1.5 #outline-none #transition-all focus:#shadow-brightcyan focus:#border-brightcyan focus:#shadow-focus"
      @change="(event) => $emit('update:modelValue', event.target.value)"
    >
      <option disabled value="">Selecteer</option>
      <option v-for="(label, key) in items" :key="key" :value="key">
        {{ label }}
      </option>
    </select>
    <p
      class="#truncate #transition-all #absolute #whitespace-nowrap #text-[11px] #min-w-max #top-[-7px] #text-[#707070] #w-max #left-4 #right-full #bg-white #px-2 #-ml-2 #rounded-md peer-focus:#text-[11px] peer-focus:#top-[-7px] peer-focus:#text-[#707070] peer-focus:#max-w-max peer-focus:#min-w-max peer-active:#top-[-7px] peer-active:#text-[11px] peer-active:#text-[#707070] #select-none #pointer-events-none #box-border"
    >
      {{ label }} <span v-if="required" class="#text-vividmagenta">*</span>
    </p>
    <p v-if="error" class="#text-[#d9534f] #font-semibold #text-sm #mt-2">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    items: {
      type: Object,
      required: true,
    },
    error: {
      type: String,
      required: false,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["update:modelValue"],
};
</script>
