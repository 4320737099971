<template>
  <table id="callback_requests_table" class="display" style="width: 100%">
    <thead>
      <tr>
        <th />
        <th>Nummer</th>
        <th>Notitie</th>
        <th>Klant ID</th>
        <th>Naam</th>
        <th>Bedrijf</th>
        <th>Datum</th>
        <th>Tijdvak</th>
        <th>Acties</th>
      </tr>
    </thead>
  </table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      requests: [],
      table: null,
      interval: null,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  mounted() {
    this.buildTable();
    this.interval = setInterval(() => {
      this.table.ajax.reload();
    }, 60000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
    this.table.destroy();
    this.table = null;
  },
  methods: {
    async finished(id) {
      const response = await fetch(this.route("callback.finished"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: id,
        }),
      });

      if (response.status === 422) {
        const {
          errors: {
            id: [error],
          },
        } = await response.json();
        alert(error);
      } else {
        await response.text();
      }
      this.table.ajax.reload();
    },
    async noPickup(id) {
      const response = await fetch(this.route("callback.noPickup"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: id,
        }),
      });

      if (response.status === 422) {
        const {
          errors: {
            id: [error],
          },
        } = await response.json();
        alert(error);
      } else {
        await response.text();
      }
      this.table.ajax.reload();
    },
    buildTable() {
      this.table = $("#callback_requests_table").DataTable({
        ajax: this.route("callback.index"),
        searching: false,
        paging: false,
        select: false,
        sort: false,
        columns: [
          {
            searchable: false,
            data: null,
            width: "0px",
            render: function (data, type, row) {
              return "";
            },
          },
          {
            data: "phone",
            render: (data, type, row) => {
              if (!this.isPWA()) {
                return data;
              }

              return `<a href="tel:${data}">${data}</a>`;
            },
          },
          { data: "subject" },
          { data: "customer_id" },
          { data: "customer.name_contact" },
          { data: "customer.company" },
          {
            data: "date",
            width: "75px",
            render: function (data, type, row) {
              var date = moment(data, moment.ISO_8601);
              date.set({
                hour: row.from.split(":")[0],
                minute: row.from.split(":")[1],
              });

              if (date.isSame(moment(), "day")) {
                return "Vandaag";
              }
              if (date.isSame(moment().add(1, "days"), "day")) {
                return "Morgen";
              }
              if (date.isSame(moment().add(2, "days"), "day")) {
                return "Overmorgen";
              }

              return date.format("DD-MM-YYYY");
            },
          },
          {
            data: "from",
            width: "75px",
            render: function (data, type, row) {
              return `${row.from} - ${row.to}`;
            },
          },
          {
            searchable: false,
            data: null,
            className: "center",
            width: "225px",
            render: (data, type, row) => {
              let html = '<a href="javascript:;" class="custom_finished">Afgehandeld</a>';
              html += ' | <a href="javascript:;" class="custom_no_pickup">Niet opgenomen</a>';
              html += ' | <a href="javascript:;" class="editor_remove">Verwijderen</a>';

              if (!this.isPWA()) {
                html = "Gebruik de admin app om de bellijst te gebruiken";
              }

              return html;
            },
          },
        ],
        rowCallback: function (row, data) {
          let from = moment(data.date, moment.ISO_8601);
          from.set({
            hour: data.from.split(":")[0],
            minute: data.from.split(":")[1],
          });

          let to = moment(data.date, moment.ISO_8601);
          to.set({
            hour: data.to.split(":")[0],
            minute: data.to.split(":")[1],
          });

          let status = "darkgreen";
          if (from.isSame(moment(), "day")) {
            status = "green";
          }
          if (moment().isBetween(from, to, undefined, "[)")) {
            status = "orange";
          }
          if (to.isSameOrBefore(moment())) {
            status = "red";
          }

          for (const call of data.calls) {
            if (call.status === "in-progress") {
              $(row)
                .find("td:eq(0)")
                .addClass("cyaan")
                .prop("title", `Ingesprek met ${call.employee.firstname}`);

              return;
            }
          }

          if (status === "orange") {
            window.sw.showNotification("Tijdslot aangebroken, tijd om een klant bellen", {
              tag: data.phone,
            });
          }

          $(row)
            .find("td:eq(0)")
            .addClass(status + "prio");
        },
      });
      $("#callback_requests_table").on("click", "a.custom_finished", (e) => {
        e.preventDefault();
        const row = this.table.row($(e.target).parents("tr"));
        this.finished(row.data().id);
      });
      $("#callback_requests_table").on("click", "a.custom_no_pickup", (e) => {
        e.preventDefault();
        const row = this.table.row($(e.target).parents("tr"));
        this.noPickup(row.data().id);
      });
      $("#callback_requests_table").on("click", "a.editor_remove", (e) => {
        e.preventDefault();

        const editor = new $.fn.dataTable.Editor({
          ajax: this.route("callback.index"),
          table: "#callback_requests_table",
          fields: [],
        });
        editor.remove($(e.target).parents("tr"), {
          title: editor.i18n.remove.title,
          message: editor.i18n.remove.confirm[1],
          buttons: editor.i18n.remove.button,
        });
      });
    },
    isPWA() {
      return ["standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia("(display-mode: " + displayMode + ")").matches,
      );
    },
  },
};
</script>

<style module="s"></style>
