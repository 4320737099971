<template>
  <div v-if="pendingTasks.length === 0" class="#grid #grid-cols-2 #gap-y-4 #gap-x-4">
    <div
      v-for="item in 10"
      :key="item"
      class="loader-animation #shadow-admin #px-4 #py-5 #h-[75px]"
    ></div>
  </div>
  <div v-else class="#grid #grid-cols-2 #gap-y-2 #gap-x-2">
    <div
      v-for="task in pendingTasks"
      :key="task.name"
      :class="isMyTask(task)"
      class="#relative #shadow-admin #bg-white #text-center #leading-normal #flex #flex-col #justify-start #items-stretch #px-4 #pt-2"
    >
      <div class="#flex">
        <div :class="getColor(task.color)" class="#min-w-2 #rounded-full #h-[40px]"></div>
        <div class="#py-5 #px-2 #flex #flex-col #justify-center #h-[40px]">
          <p class="#text-[25px] #font-bold #text-left #leading-none">
            {{ task.count }}
          </p>
        </div>
      </div>
      <p class="#text-[11px] #text-left #mb-2 #mt-2">{{ task.name }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    employeeid: {
      type: Number,
      default: 0,
    },
    pendingTasks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(["route", "session"]),
  },
  mounted() {},

  methods: {
    getColor(color) {
      if (color === "red") {
        return "#bg-red-500";
      } else if (color === "orange") {
        return "#bg-orange-500";
      } else if (color === "green") {
        return "#bg-green-500";
      } else {
        return "#bg-brightcyan";
      }
    },
    isMyTask(task) {
      for (const employee of task.employees) {
        if (employee.id === this.employeeid) {
          return "#opacity-100";
        }
      }
      if (["asma", "dewi"].includes(this.session("username"))) {
        return "#opacity-100";
      }
      return "#opacity-25";
    },
  },
};
</script>
