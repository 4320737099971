<template>
  <h1>Fouten statistieken</h1>
  <div class="tailwind">
    <table
      class="#font-[arial] #text-left #ml-px #mb-2 #border-solid #border-[#3C3C3C] #border-2 #border-collapse #w-full"
    >
      <thead>
        <tr>
          <th
            v-for="header of headers"
            :key="header"
            class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-if="['all', 'delivery'].includes(body.view)" class="#p-1 #align-top #w-1/5">
            <div class="#flex #flex-1">
              <div class="#flex #flex-1 #flex-col">
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('today')"
                  >Vandaag</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('yesterday')"
                  >Gister</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('week')"
                  >Deze week</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('month')"
                  >Deze maand</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('year')"
                  >Dit jaar</a
                >
              </div>
              <div class="#flex #flex-1 #flex-col">
                <input
                  ref="from"
                  v-model="from"
                  type="text"
                  readonly=""
                  placeholder="Van"
                  class="#px-1 #border #border-solid #border-black #mb-1"
                />
                <input
                  ref="to"
                  v-model="to"
                  type="text"
                  readonly=""
                  placeholder="Tot"
                  class="#px-1 #border #border-solid #border-black"
                />
              </div>
            </div>
          </td>
          <td v-if="['delivery'].includes(body.view)" class="#p-1 #align-top">
            <div>
              <input id="week" v-model="body.period" type="radio" value="week" />
              <label for="week" class="#inline-block #ml-1 #align-middle">Per week</label>
            </div>
            <div>
              <input id="month" v-model="body.period" type="radio" value="month" />
              <label for="month" class="#inline-block #ml-1 #align-middle">Per maand</label>
            </div>
          </td>
          <td v-if="['all'].includes(body.view)" class="#p-1 #align-top">
            <div>
              <input id="category" v-model="body.type" type="radio" value="category" />
              <label for="category" class="#inline-block #ml-1 #align-middle">Categorie</label>
            </div>
            <div>
              <input id="station" v-model="body.type" type="radio" value="sub_category" />
              <label for="station" class="#inline-block #ml-1 #align-middle">Station</label>
            </div>
          </td>
          <td v-if="['all'].includes(body.view)" class="#p-1 #align-top">
            <div class="#flex">
              <div class="#flex #flex-col">
                <div
                  v-for="category of categories"
                  v-show="body.type === 'category' && categories.length > 0"
                  :key="category"
                >
                  <input
                    :id="category.value"
                    v-model="selectedCategories"
                    type="checkbox"
                    :value="category.id"
                  />
                  <label :for="category.value" class="#inline-block #ml-1 #align-middle">
                    {{ category.value }}
                  </label>
                </div>
                <div
                  v-for="station of stations"
                  v-show="body.type === 'sub_category' && stations.length > 0"
                  :key="station"
                >
                  <input
                    :id="station.id"
                    v-model="selectedStations"
                    type="checkbox"
                    :value="station.id"
                  />
                  <label :for="station.id" class="#inline-block #ml-1 #align-middle">
                    {{ station.value }}
                  </label>
                </div>
              </div>
            </div>
          </td>
          <td class="#p-1 #align-top">
            <div>
              <input id="amount" v-model="mode" type="radio" value="amount" />
              <label for="amount" class="#inline-block #ml-1 #align-middle">Aantallen</label>
            </div>
            <div>
              <input id="costs" v-model="mode" type="radio" value="costs" />
              <label for="costs" class="#inline-block #ml-1 #align-middle">Kosten</label>
            </div>
            <div v-if="['delivery', 'month', 'station'].includes(body.view)">
              <input id="percentage" v-model="mode" type="radio" value="percentage" />
              <label for="percentage" class="#inline-block #ml-1 #align-middle">Percentage</label>
            </div>
          </td>
          <td v-if="body.view !== 'delivery'" class="#p-1 #align-top">
            <div>
              <input id="articles" v-model="body.group" type="radio" value="articles" />
              <label for="articles" class="#inline-block #ml-1 #align-middle">Artikelen</label>
            </div>
            <div>
              <input id="orders" v-model="body.group" type="radio" value="orders" />
              <label for="orders" class="#inline-block #ml-1 #align-middle">Opdrachten</label>
            </div>
          </td>
          <td v-if="['month'].includes(body.view)" class="#p-1 #align-top #w-1/5">
            <div>
              <SelectFilter v-model="body.category" :options="categoriesLineChart" />
            </div>
            <div v-if="body.category && body.category.length > 0" class="#mt-1">
              <SelectFilter v-model="body.sub_category" :options="subCategoriesLineChart" />
            </div>
          </td>
          <td class="#p-1 #align-top">
            <div>
              <input id="all" v-model="body.view" type="radio" value="all" />
              <label for="all" class="#inline-block #ml-1 #align-middle">Alle</label>
            </div>
            <div>
              <input id="delivery" v-model="body.view" type="radio" value="delivery" />
              <label for="delivery" class="#inline-block #ml-1 #align-middle">Bezorging</label>
            </div>
            <div>
              <input id="mistakes-month" v-model="body.view" type="radio" value="month" />
              <label for="mistakes-month" class="#inline-block #ml-1 #align-middle"
                >Fouten per maand</label
              >
            </div>
            <div>
              <input id="mistakes-station" v-model="body.view" type="radio" value="station" />
              <label for="mistakes-station" class="#inline-block #ml-1 #align-middle">
                Fouten per maand (stations)
              </label>
            </div>
          </td>
          <td v-if="['all', 'month', 'station'].includes(body.view)" class="#p-1 #align-top">
            <select v-model="body.employee" class="#rounded #h-5 #border #border-black">
              <option value="" selected></option>
              <option v-for="employee in employees" :key="employee.id" :value="employee.id">
                {{ employee.firstname }}
              </option>
            </select>
          </td>
        </tr>
      </tbody>
      <p v-if="error" class="#text-red-600 #ml-1 #mb-2">
        <b>{{ error }}</b>
      </p>
    </table>
    <div v-if="body.view === 'month'">
      <p
        class="#text-sm #underline #text-indigo-700 #cursor-pointer"
        @click="showFormules = !showFormules"
      >
        Toon formules
      </p>
      <div v-if="showFormules">
        <p class="#text-left #rtl:text-right #text-sm">
          <b>Fouten percentagen van totale bestellingen:</b>
          (aantal fouten in de maand / aantal orders in de maand) * 100
        </p>
        <p class="#text-left #rtl:text-right #text-sm">
          <b>Percentage fouten in verhouding tot omzet:</b>
          (schade kosten in de maand / omzet in de maand) * 100
        </p>
      </div>
    </div>
    <div v-if="['all'].includes(body.view)" class="#grid #grid-cols-2 #gap-4">
      <AllMistakeChart
        :data="allMistakes"
        :mode="mode"
        :categories="categories"
        :selected-categories="selectedCategories"
        :selected-stations="selectedStations"
        :stations="stations"
        :type="body.type"
      />
      <ExternalMistakeChart
        :data="externalMistakes"
        :mode="mode"
        :categories="categories"
        :selected-categories="selectedCategories"
        :selected-stations="selectedStations"
        :stations="stations"
        :type="body.type"
      />
      <InternalMistakeChart
        :data="internalMistakes"
        :mode="mode"
        :categories="categories"
        :selected-categories="selectedCategories"
        :selected-stations="selectedStations"
        :stations="stations"
        :type="body.type"
      />
    </div>
    <div v-if="body.view === 'delivery'">
      <MistakeLineChartDelivery :data="mistakesDelivery" :mode="mode" :period="body.period" />
    </div>
    <div v-if="body.view === 'month'">
      <MistakeLineChart
        :data="allMistakesLineChart"
        :mode="mode"
        :revenue="revenue"
        :group="body.group"
      />
    </div>
    <div v-if="body.view === 'station'">
      <MistakeLineChartStation
        :data="stationLineChart"
        :mode="mode"
        :stations="stationsLineChart"
        :group="body.group"
      />
    </div>
  </div>
</template>
<script>
import AllMistakeChart from "../components/AllMistakeChart.vue";
import DatePickerFilter from "../mixins/DatePickerFilter";
import ExternalMistakeChart from "../components/ExternalMistakeChart.vue";
import InternalMistakeChart from "../components/InternalMistakeChart.vue";
import { mapGetters } from "vuex";
import MistakeLineChart from "../components/MistakeLineChart.vue";
import MistakeLineChartDelivery from "../components/MistakeLineChartDelivery.vue";
import MistakeLineChartStation from "../components/MistakeLineChartStation.vue";
import SelectFilter from "../components/SelectFilter.vue";

export default {
  components: {
    AllMistakeChart,
    ExternalMistakeChart,
    InternalMistakeChart,
    MistakeLineChart,
    MistakeLineChartDelivery,
    MistakeLineChartStation,
    SelectFilter,
  },
  mixins: [DatePickerFilter],
  data() {
    return {
      allMistakes: {},
      allMistakesLineChart: {},
      body: {
        category: [],
        employee: null,
        from: null,
        group: "articles",
        period: "month",
        sub_category: [],
        to: null,
        type: "category",
        view: "all",
      },
      categories: [],
      categoriesLineChart: [],
      employees: [],
      error: "",
      externalMistakes: {},
      internalMistakes: {},
      mistakesDelivery: {},
      mode: "amount",
      pickers: null,
      revenue: {},
      selectedCategories: ["ALL"],
      selectedStations: ["ALL"],
      showFormules: false,
      stationLineChart: {},
      stationsLineChart: [],
      stations: [],
      subCategoriesLineChart: [],
    };
  },
  computed: {
    ...mapGetters(["route"]),
    headers() {
      if (this.body.view === "all") {
        return ["Periode", "Type", "Opties", "Modus", "Groep", "Weergave", "Medewerkers"];
      } else if (this.body.view === "delivery") {
        return ["Periode", "Periode", "Modus", "Weergave"];
      } else if (this.body.view === "month") {
        return ["Modus", "Groep", "Categorieën", "Weergave", "Medewerkers"];
      } else {
        return ["Modus", "Groep", "Weergave", "Medewerkers"];
      }
    },
  },
  watch: {
    body: {
      handler() {
        if (["all", "delivery"].includes(this.body.view)) {
          this.$nextTick(() => {
            this.setupDatePickers();
          });
        }

        if (this.body.view === "month") {
          this.fetchOptionsMonth();
        }

        this.fetchMistakeStatistics();
      },
      deep: true,
    },
    selectedCategories: {
      handler(newValue, oldValue) {
        const selectedCategory = newValue.filter((element) => !oldValue.includes(element))[0];

        if (
          this.selectedCategories.includes("ALL") &&
          selectedCategory &&
          selectedCategory !== "ALL"
        ) {
          const index = this.selectedCategories.indexOf("ALL");
          this.selectedCategories.splice(index, 1);
        } else if (selectedCategory === "ALL") {
          this.selectedCategories = ["ALL"];
        }
      },
    },
    selectedStations: {
      handler(newValue, oldValue) {
        const selectedStation = newValue.filter((element) => !oldValue.includes(element))[0];

        if (this.selectedStations.includes("ALL") && selectedStation && selectedStation !== "ALL") {
          const index = this.selectedStations.indexOf("ALL");
          this.selectedStations.splice(index, 1);
        } else if (selectedStation === "ALL") {
          this.selectedStations = ["ALL"];
        }
      },
    },
    from: "filterPeriod",
    to: "filterPeriod",
  },
  mounted() {
    this.fetchOptions();
    this.fetchMistakeStatistics();
  },
  methods: {
    async fetchMistakeStatistics() {
      this.error = "";
      let routeName = "";

      if (["all"].includes(this.body.view)) {
        routeName = "mistakes.statistics";
      } else if (["month"].includes(this.body.view)) {
        routeName = "mistakes.month.statistics";
      } else if (["station"].includes(this.body.view)) {
        routeName = "mistakes.station.statistics";
      } else if (["delivery"].includes(this.body.view)) {
        routeName = "mistakes.statistics.delivery";
      }

      try {
        const response = await fetch(this.route(routeName), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify(this.body),
        });

        const mistakesStats = await response.json();

        if (mistakesStats.success) {
          if (["all"].includes(this.body.view)) {
            this.setDataDoughnutChart(mistakesStats);
          } else if (["month"].includes(this.body.view)) {
            this.setDataLineChart(mistakesStats);
          } else if (["station"].includes(this.body.view)) {
            this.setDataStationLineChart(mistakesStats);
          } else if (["delivery"].includes(this.body.view)) {
            this.setDataDeliveryLineChart(mistakesStats);
          }
        } else {
          this.error = mistakesStats.message;
        }
      } catch (error) {
        this.error = "An error occurred while fetching data.";
      }
    },
    clearFilters(view) {
      this.body = {
        category: [],
        employee: null,
        from: null,
        group: "articles",
        period: "month",
        sub_category: [],
        to: null,
        type: "category",
        view: view,
      };
      this.from = null;
      this.mode = "amount";
      this.to = null;
      this.selectedCategories = ["ALL"];
      this.selectedStations = ["ALL"];
    },
    setDataDoughnutChart(mistakesStats) {
      this.allMistakes = mistakesStats.all_mistakes;
      this.externalMistakes = mistakesStats.external_mistakes;
      this.internalMistakes = mistakesStats.internal_mistakes;
    },
    setDataLineChart(mistakesStats) {
      this.allMistakesLineChart = mistakesStats.all_mistakes;
      this.revenue = mistakesStats.revenue;
    },
    setDataStationLineChart(mistakesStats) {
      this.stationLineChart = mistakesStats.mistakes;
      this.stationsLineChart = mistakesStats.stations;
    },
    setDataDeliveryLineChart(mistakesStats) {
      this.mistakesDelivery = mistakesStats.mistakes;
    },
    async fetchOptions() {
      try {
        const response = await fetch(this.route("mistakes.options"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        });

        const options = await response.json();

        if (options.success) {
          this.categories = options.categories;
          this.stations = options.stations;
          this.employees = options.employees;
        }
      } catch (error) {
        this.error = "An error occurred while fetching filter options.";
      }
    },
    async fetchOptionsMonth() {
      try {
        const response = await fetch(this.route("mistakes.options.month"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify(this.body),
        });

        const options = await response.json();

        if (options) {
          this.categoriesLineChart = options.categories;
          this.subCategoriesLineChart = options.sub_categories;
        }
      } catch (error) {
        this.error = "An error occurred while fetching filter options.";
      }
    },
  },
};
</script>
<style>
.mistake-chart-container {
  height: 600px;
  width: 600px;
}
</style>
