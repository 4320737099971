<template>
  <h1>Prestatie punten</h1>
  <div class="tailwind">
    <div class="flex items-center #mb-2">
      <p class="#text-xs #font-bold">Type</p>
      <div>
        <input id="positive" v-model="body.type" type="radio" value="all" />
        <label for="positive" class="#inline-block #ml-1 #align-middle">Alle</label>
      </div>
      <div>
        <input id="positive" v-model="body.type" type="radio" value="positive" />
        <label for="positive" class="#inline-block #ml-1 #align-middle">Positief</label>
      </div>
      <div>
        <input id="negative" v-model="body.type" type="radio" value="negative" />
        <label for="negative" class="#inline-block #ml-1 #align-middle">Negatief</label>
      </div>
    </div>
  </div>
  <table id="performance_points" class="display" style="width: 100%">
    <thead>
      <tr>
        <th>Positie</th>
        <th>Titel</th>
        <th>Waarde</th>
        <th>Automatische zin</th>
        <th></th>
      </tr>
    </thead>
  </table>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      body: {
        type: "all",
      },
      editor: null,
      positionColumnVisible: false,
      table: null,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    "body.type"() {
      this.positionColumnVisible = false;

      if (["positive", "negative"].includes(this.body.type)) {
        this.positionColumnVisible = true;
      }

      this.rebuildTable();
    },
  },
  mounted() {
    this.buildTable();
  },
  beforeUnmount() {
    this.table.destroy();
    this.table = null;
    this.editor = null;
  },
  methods: {
    buildTable() {
      this.table = $("#performance_points")
        .DataTable({
          ajax: {
            url: this.route("performance.points.index"),
            data: (d) => {
              if (this.body.type) {
                d["type"] = this.body.type;
              }
            },
          },
          dom: "Bfrtip",
          buttons: [
            {
              text: "Nieuw prestatie punt",
              action: () => {
                this.editor.create({
                  title: "Nieuw prestatie punt",
                  buttons: "Toevoegen",
                });
              },
            },
          ],
          sort: false,
          columns: [
            { data: "position", className: "reorder", visible: this.positionColumnVisible },
            { data: "title" },
            {
              data: "value",
              render: (data) => {
                return `<span><b>${data}</b></span>`;
              },
            },
            { data: "sentence" },
            {
              data: null,
              className: "dt-center editor-edit",
              defaultContent: `<a href="javascript:;">${svgUse({ id: "pen", width: "11px", height: "11px", fill: "#24212b" })}</a>`,
              orderable: false,
              searchable: false,
            },
          ],
          rowReorder: {
            dataSrc: "position",
            selector: ".reorder",
          },
        })
        .on("row-reorder.dt", (event, data) => {
          const reorderedRows = [];

          for (var i = 0; i < data.length; i++) {
            reorderedRows.push({ id: data[i].node.id, position: data[i].newPosition + 1 });
          }

          this.updateOrderPerformancePoints(reorderedRows);
        });

      this.editor = new $.fn.dataTable.Editor({
        ajax: this.route("performance.points.edit"),
        fields: [
          {
            label: "Title:",
            name: "title",
          },
          {
            label: "Waarde:",
            name: "value",
            attr: {
              type: "number",
            },
          },
          {
            label: "Automatische zin:",
            name: "sentence",
            type: "textarea",
          },
        ],
        table: "#performance_points",
      });

      this.table.on("click", "td.editor-edit", (e) => {
        e.preventDefault();

        this.editor.edit(e.target.closest("tr"), {
          title: "Prestatie punt wijzigen",
          buttons: "Wijzigen",
        });
      });
    },
    async updateOrderPerformancePoints(data) {
      let response = await fetch(this.route("performance.points.reorder"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(data),
      });

      response = await response.json();

      if (response.success) {
        this.rebuildTable();
      }
    },
    rebuildTable() {
      if (this.table) {
        this.table.destroy();
        this.buildTable();
      }
    },
  },
};
</script>
