export default {
  computed: {
    hasData() {
      if (this.data) {
        return Object.entries(this.data).length > 0;
      }

      return false;
    },
    chartData() {
      return {
        labels: this.labels[0],
        datasets: [
          {
            backgroundColor: this.colors[0],
            borderWidth: 2.5,
            data: this.percentages[0],
            fill: "origin",
          },
          {
            backgroundColor: this.colors[1],
            borderWidth: 2.5,
            data: this.percentages[1],
            datalabels: {
              display: false,
            },
            fill: "origin",
          },
        ],
      };
    },
  },
  methods: {
    setInnerLabel(chart, args, label, subLabel, fontSize) {
      const { ctx } = chart;
      const meta = args.meta;

      if (meta.data[0]) {
        const xCoor = meta.data[0].x;
        const yCoor = meta.data[0].y;

        ctx.save();
        ctx.textAlign = "center";
        ctx.fillStyle = "#565d64";
        ctx.font = `${fontSize} sans-serif`;
        ctx.fillText(label, xCoor, yCoor);

        if (subLabel) {
          ctx.fillText(subLabel, xCoor, yCoor + 24);
        }
        ctx.restore();
      }
    },
  },
};
