<template>
  <span
    class="tailwind"
    :class="s.container"
    :style="[style, dynamic]"
    :title="title"
    @click="click"
  >
    <svg-use :id="icon" type="solid" class="#w-3 #h-3 #fill-current" />
  </span>
</template>

<script>
import SvgUse from "../../vue3/components/SvgUse.vue";
export default {
  components: {
    SvgUse,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    style: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["click"],
  computed: {
    dynamic() {
      if (!this.disabled) {
        return {};
      }

      return {
        backgroundColor: "white",
        color: "grey",
      };
    },
  },
  methods: {
    click() {
      if (!this.disabled) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style module="s">
.container {
  background-color: white;
  border: 1px solid silver;
  border-radius: 0.25em;
  padding: 0.5em;
  margin-left: 5px;
  cursor: pointer;
  z-index: 9999;
  color: black;
}
.container:hover {
  box-shadow: inset 0 0 0 9999px rgba(0, 0, 0, 0.035);
}
</style>
