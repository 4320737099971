export default {
  data() {
    return {
      from: null,
      isFilterPeriod: false,
      to: null,
    };
  },
  beforeUpdate() {
    this.isFilterPeriod = false;
  },
  methods: {
    setRange(range) {
      if (range === "today") {
        this.from = moment().startOf("day").hour(2).format("DD-MM-YYYY HH:mm:ss");
        this.to = moment().add(1, "days").startOf("day").hour(2).format("DD-MM-YYYY HH:mm:ss");
      } else if (range === "yesterday") {
        this.from = moment()
          .subtract(1, "days")
          .startOf("day")
          .hour(2)
          .format("DD-MM-YYYY HH:mm:ss");
        this.to = moment().startOf("day").hour(2).format("DD-MM-YYYY HH:mm:ss");
      } else if (range === "week") {
        this.from = moment().startOf("week").hour(2).format("DD-MM-YYYY HH:mm:ss");
        this.to = moment().startOf("week").add(1, "weeks").hour(2).format("DD-MM-YYYY HH:mm:ss");
      } else if (range === "month") {
        this.from = moment().startOf("month").hour(2).format("DD-MM-YYYY HH:mm:ss");
        this.to = moment().startOf("month").add(1, "months").hour(2).format("DD-MM-YYYY HH:mm:ss");
      } else if (range === "year") {
        this.from = moment().startOf("year").hour(2).format("DD-MM-YYYY HH:mm:ss");
        this.to = moment().startOf("year").add(1, "years").hour(2).format("DD-MM-YYYY HH:mm:ss");
      } else if (range === "last_month") {
        this.from = moment()
          .subtract(1, "months")
          .startOf("month")
          .hour(2)
          .format("DD-MM-YYYY HH:mm:ss");
        this.to = moment().startOf("month").hour(2).format("DD-MM-YYYY HH:mm:ss");
      } else if (range === "last_three_months") {
        this.from = moment()
          .subtract(3, "months")
          .startOf("month")
          .hour(2)
          .format("DD-MM-YYYY HH:mm:ss");
        this.to = moment().startOf("month").hour(2).format("DD-MM-YYYY HH:mm:ss");
      } else if (range === "last_six_months") {
        this.from = moment()
          .subtract(6, "months")
          .startOf("month")
          .hour(2)
          .format("DD-MM-YYYY HH:mm:ss");
        this.to = moment().startOf("month").hour(2).format("DD-MM-YYYY HH:mm:ss");
      } else if (range === "last_year") {
        this.from = moment()
          .subtract(1, "years")
          .startOf("year")
          .hour(2)
          .format("DD-MM-YYYY HH:mm:ss");
        this.to = moment().startOf("year").hour(2).format("DD-MM-YYYY HH:mm:ss");
      } else if (range === "test") {
        this.from = "26-06-2023 02:00:00";
        this.to = "27-06-2023 02:00:00";
      }
    },
    setupDatePickers() {
      this.pickers = $.timepicker.datetimeRange($(this.$refs.from), $(this.$refs.to), {
        minInterval: 1000 * 60 * 60, // 1hr
        changeMonth: true,
        changeYear: true,
        dateFormat: "dd-mm-yy",
        timeFormat: "HH:mm:ss",
        start: {
          onClose: () => {
            this.$refs.from.dispatchEvent(new Event("input"));
          },
        },
        end: {
          onClose: () => {
            this.$refs.to.dispatchEvent(new Event("input"));
          },
        },
      });
    },
    filterPeriod() {
      if (this.from !== this.body.from || this.to !== this.body.to) {
        this.body.from = this.from;
        this.body.to = this.to;
        this.isFilterPeriod = true;
      }
    },
  },
};
