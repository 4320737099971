<template>
  <div :class="s.container">
    <div :class="s.col">
      <div :class="s.row">
        <div :class="s.title">Printer:</div>
        <div :class="s.data">{{ printer["name"] }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Artikel:</div>
        <div :class="s.data">{{ article["id"] }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Order:</div>
        <div :class="s.data">{{ article["order"]["id"] }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Klant ID:</div>
        <div :class="s.data">{{ article["order"]["customer"]["id"] }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Klant naam:</div>
        <div :class="s.data">
          {{ article["order"]["customer"]["name_contact"] }}
        </div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Klant bedrijf:</div>
        <div :class="s.data">
          {{ article["order"]["customer"]["company"] }}
        </div>
      </div>
    </div>
    <div :class="s.col">
      <div :class="s.row">
        <div :class="s.title">Naam:</div>
        <div :class="s.data">{{ title }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Bestand:</div>
        <div :class="s.data">{{ fileName }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Kleur modus:</div>
        <div :class="s.data">{{ color_mode }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Papier type:</div>
        <div :class="s.data">{{ paper_type }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Papier grootte:</div>
        <div :class="s.data">{{ paper_size }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Papier kleur:</div>
        <div :class="s.data">{{ paper_color }}</div>
      </div>
    </div>
    <div :class="s.col">
      <div :class="s.row">
        <div :class="s.title">Status:</div>
        <div :class="s.data">{{ status }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Duplex:</div>
        <div :class="s.data">{{ duplex }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">180 graden draaien:</div>
        <div :class="s.data">{{ rotate_180 ? "Ja" : "Nee" }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Uitvoer lade:</div>
        <div :class="s.data">{{ tray }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Zoom:</div>
        <div :class="s.data">{{ scale }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Kopieën:</div>
        <div :class="s.data">{{ copies }}</div>
      </div>
    </div>
    <div :class="s.col">
      <div :class="s.row">
        <div :class="s.title">Wachtrij:</div>
        <div :class="s.data">{{ queue }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Uitvoer sorteren:</div>
        <div :class="s.data">{{ sorting ? "Ja" : "Nee" }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Omslag type:</div>
        <div :class="s.data">{{ cover_type || "Geen" }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Omslag kleur modus:</div>
        <div :class="s.data">{{ cover_color_mode || "n.v.t" }}</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Beeld verschuiving</div>
        <div :class="s.data">{{ imageShift }} - mm</div>
      </div>
      <div :class="s.row">
        <div :class="s.title">Verstuurd om:</div>
        <div :class="s.data">{{ sendAt }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    file: {
      type: String,
      required: true,
    },
    color_mode: {
      type: String,
      required: true,
    },
    paper_size: {
      type: String,
      required: true,
    },
    paper_type: {
      type: String,
      required: true,
    },
    paper_color: {
      type: String,
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    duplex: {
      type: String,
      required: true,
    },
    rotate_180: {
      type: Boolean,
      required: true,
    },
    tray: {
      type: String,
      required: true,
    },
    copies: {
      type: Number,
      required: true,
    },
    queue: {
      type: String,
      required: true,
    },
    sorting: {
      type: Boolean,
      required: true,
    },
    cover_type: {
      type: String,
      required: false,
    },
    cover_color_mode: {
      type: String,
      required: false,
    },
    image_shift: {
      type: Object,
      required: true,
    },
    printer: {
      type: Object,
      required: true,
    },
    article: {
      type: Object,
      required: true,
    },
    created_at: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    fileName() {
      return this.file.split("/").pop();
    },
    sendAt() {
      var date = moment(this.created_at, moment.ISO_8601);

      return date.format("DD-MM-YYYY HH:mm:ss");
    },
    imageShift() {
      const { frontX, frontY, backX, backY } = this.image_shift;
      return `${frontX} ${frontY} ${backX} ${backY}`;
    },
  },
  mounted() {},
});
</script>

<style module="s">
.container {
  display: flex;
}
.col {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.row {
  display: flex;
}
.title {
  font-weight: bold;
}
.data {
  margin-left: 10px;
}
</style>
