<template>
  <Modal :open="open" @close="open = false">
    <form :class="s.form" @submit.prevent="() => call(number)">
      <label for="number">Nummer om te bellen</label>
      <input
        id="number"
        v-model="number"
        type="tel"
        name="number"
        :class="s.input"
        placeholder="+31612345678"
      />
      <button type="submit" :class="s.button">Bel</button>
    </form>
    <table class="data" :class="s.table">
      <thead>
        <tr>
          <th>Naam</th>
          <th>Nummer</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in numbers" :key="`number_${index}`">
          <tr :class="index % 2 === 0 ? s.even : s.uneven">
            <td>{{ item.name }}</td>
            <td>
              <a :title="`Bel ${item.name}`" href="javascript:;" @click="() => call(item.number)">{{
                item.number
              }}</a>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </Modal>
</template>

<script>
import Modal from "../components/Modal.vue";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      open: false,
      number: "",
      numbers: [
        { name: "IMAGO", number: "+31206304200" },
        { name: "Solo (koerier)", number: "+31203333531" },
        { name: "PostNL", number: "+31882255612" },
        { name: "PostNL (zware pakketten)", number: "+31882363764" },
        { name: "PostNL (ochtendbezorging)", number: "+319002255825" },
        { name: "Bart (grote baas)", number: "+31648520774" },
        { name: "Stanley (kleine baas)", number: "+31615101626" },
        { name: "XXL pakket", number: "+31206144030" },
        { name: "Julia (HR)", number: "+31618989783" },
        { name: "Marijn (IT en Printen)", number: "+31650571114" },
        { name: "Jelle (IT)", number: "+31616709501" },
        { name: "Asma (KS)", number: "+31613179946" },
      ],
    };
  },
  watch: {
    open(value) {
      if (!value) {
        this.number = "";
      }
    },
  },
  created() {
    this.emitter.on("open-call-dialer", () => {
      this.open = true;
    });
  },
  beforeUnmount() {
    this.emitter.off("open-call-dialer");
  },
  methods: {
    call(number) {
      this.emitter.emit("dial", number);
      this.open = false;
    },
  },
};
</script>

<style module="s">
.table {
  width: 100%;
}

.even {
  background-color: #eeeeee;
}

.uneven {
  background-color: white;
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 3px;
  padding-right: 3px;
}

.input {
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
}

.button {
  min-width: 100px;
}
</style>
