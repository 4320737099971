<template>
  <h1>Logs</h1>
  <table id="printer-employee-log" class="display" style="width: 100%">
    <thead>
      <tr>
        <th></th>
        <th>Printer</th>
        <th>Werknemer</th>
        <th>Geklokt</th>
        <th>Tijd</th>
      </tr>
    </thead>
  </table>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      table: null,
      interval: null,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  mounted() {
    this.buildTable();
    this.interval = setInterval(() => {
      this.table.ajax.reload();
    }, 60000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
    this.table.destroy();
    this.table = null;
  },
  methods: {
    buildTable() {
      this.table = $("#printer-employee-log").DataTable({
        ajax: this.route("printers.employees.logs"),
        select: false,
        columns: [
          { data: "id", visible: false },
          { data: "printer.name" },
          { data: "employee.firstname" },
          { data: "clocked" },
          {
            data: "created_at",
            render: function (data) {
              return moment(data, moment.ISO_8601).format("DD-MM-YYYY HH:mm:ss");
            },
          },
        ],
      });
    },
  },
};
</script>
