<template>
  <h1>
    Artikelen
    <img v-if="loading" src="/admin/images/loading.gif" style="margin-bottom: -9px" />
  </h1>
  <div class="tailwind">
    <div class="#grid #grid-cols-2 #gap-4">
      <JoinFilter
        v-model="filters.article"
        table="articles"
        :columns="articleColumns"
        label="Artikelen"
        @loading="loadingStatus"
      />
      <JoinFilter
        v-model="filters.orders"
        table="orders"
        :columns="orderColumns"
        label="Opdrachten"
        @loading="loadingStatus"
      />
    </div>
    <div v-if="isAdminLevel(4, 9)" class="#mt-2 #space-x-2">
      <AdminButton btn-class="primary" @click="restoreProductionReceipts">
        Herstel productiebonnen
      </AdminButton>
      <AdminButton btn-class="secondary" @click="processFiles">Bestanden verwerken</AdminButton>
    </div>
    <NotificationMessage
      v-if="message"
      class="#mt-2"
      type="success"
      :message="message"
      @close="message = null"
    />
    <table id="article-overview" class="display" style="width: 100%">
      <thead>
        <tr>
          <th></th>
          <th>ID</th>
          <th>Opdrachtnummer</th>
          <th>Klantnummer</th>
          <th>Status</th>
          <th>Bedrag</th>
          <th>Aantal</th>
          <th>Product</th>
          <th>Printer</th>
          <th>Bestanden</th>
        </tr>
      </thead>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import JoinFilter from "../components/JoinFilter.vue";
import AdminButton from "../components/AdminButton.vue";
import NotificationMessage from "../components/NotificationMessage.vue";

export default {
  components: { AdminButton, JoinFilter, NotificationMessage },
  data() {
    return {
      loading: true,
      message: "",
      table: null,
      articleColumns: [
        { type: "integer", text: "Aantal", value: "number", width: "#w-20" },
        { type: "string", text: "Bestandsnamen", value: "filenames" },
        { type: "select", text: "Bestandsmethode", value: "add_file_method" },
        { type: "string", text: "ID", value: "id" },
        { type: "string", text: "Klantnummer", value: "customer_id" },
        { type: "string", text: "Opdrachtnummer", value: "order_id" },
        { type: "select", text: "Printer", value: "printer" },
        { type: "select", text: "Product", value: "product" },
        { type: "select", text: "Product groep", value: "product_group" },
        { type: "select", text: "Status", value: "status" },
      ],
      orderColumns: [
        {
          type: "date",
          text: "Interne leverdatum",
          value: "internal_delivery_datetime",
          width: "#w-24",
        },
        { type: "select", text: "Status", value: "status" },
      ],
      filters: {
        article: {
          add_file_method: [],
          customer_id: "",
          filenames: "",
          id: "",
          number: {},
          order_id: "",
          printer: [],
          product: [],
          product_group: [],
          status: [],
        },
        orders: {
          internal_delivery_datetime: {
            condition: "between",
            format: "dd-MM-yyyy hh:mm",
            formatted_date: [moment().startOf("day").unix(), moment().endOf("day").unix()],
          },
          status: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters(["route", "isAdminLevel"]),
  },
  watch: {
    filters: {
      handler() {
        console.log(this.filters);
        this.table.ajax.reload();
      },
      deep: true,
    },
  },
  mounted() {
    this.buildTable();
  },
  methods: {
    loadingStatus(loading) {
      this.loading = loading;
    },
    buildTable() {
      this.table = $("#article-overview").DataTable({
        order: [[1, "desc"]],
        searching: false,
        pageLength: 250,
        preDrawCallback: () => {
          this.loading = true;
        },
        drawCallback: () => {
          this.loading = false;
        },
        select: {
          style: "os",
          selector: "td:not(.link)",
        },
        columnDefs: [
          {
            orderable: false,
            className: "select-checkbox",
            targets: 0,
          },
        ],
        ajax: {
          url: this.route("articles.overview"),
          data: (d) => {
            for (const [categoryKey, category] of Object.entries(this.filters)) {
              for (const [filterKey, filter] of Object.entries(category)) {
                if (filter && Object.keys(filter).length > 0) {
                  if (!d[categoryKey]) {
                    d[categoryKey] = {};
                  }

                  d[categoryKey][filterKey] = filter;
                }
              }
            }
          },
        },
        columns: [
          {
            searchable: false,
            sortable: false,
            data: null,
            defaultContent: "",
            className: "select-checkbox",
          },
          { data: "id" },
          {
            data: "order_id",
            render: function (data) {
              return `<a href="/admin.php?page=orders&sub=detail&id=${data}" class="link" target="_blank"rel=" noopener noreferrer">${data}</a>`;
            },
          },
          {
            data: "customer_id",
            render: function (data) {
              return `<a href="/admin.php?page=customers&sub=detail&customer_id=${data}" class="link" target="_blank"rel=" noopener noreferrer">${data}</a>`;
            },
          },
          { data: "status" },
          {
            data: "amount",
            render: (data) => {
              return `€ ${data}`;
            },
          },
          { data: "number" },
          { data: "product" },
          { data: "printer" },
          {
            data: "filenames",
            sortable: false,
          },
        ],
      });
    },
    async restoreProductionReceipts() {
      let action = "all";
      let ids = this.table.rows().ids().toArray();

      if (this.table.rows({ selected: true }).ids().toArray().length) {
        action = "selected";
        ids = this.table.rows({ selected: true }).ids().toArray();
      }

      if (
        !confirm(
          `Bevestig dat je de productiebonnen voor ${action === "all" ? "alle" : "de geselecteerde"} artikelen wilt herstellen`,
        )
      ) {
        return;
      }

      const response = await fetch(this.route("articles.restore.production-receipts"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id: ids }),
      });

      if (response.ok) {
        this.message = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
    async processFiles() {
      let action = "all";
      let ids = this.table.rows().ids().toArray();

      if (this.table.rows({ selected: true }).ids().toArray().length) {
        action = "selected";
        ids = this.table.rows({ selected: true }).ids().toArray();
      }

      if (
        !confirm(
          `Bevestig dat je de bestanden voor ${action === "all" ? "alle" : "de geselecteerde"} artikelen wilt verwerken`,
        )
      ) {
        return;
      }

      const response = await fetch(this.route("articles.process.files"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ id: ids }),
      });

      if (response.ok) {
        this.message = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
  },
};
</script>
