<template>
  <input
    :checked="modelValue"
    type="checkbox"
    @change="$emit('update:modelValue', $event.target.checked)"
  />
  <label class="label-align-middle #ml-1">{{ label }}</label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:modelValue"],
};
</script>
