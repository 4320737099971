<template>
  <div :class="s.container">
    <div v-for="column in columns" :key="`column_${column}`" :class="s.column">
      <div v-for="tray in columnTrays(column)" :key="tray.id" :class="s.tray">
        <div :class="s.left">
          <div :class="s.nameContainer">
            <p :class="s.name">{{ getName(tray) }}</p>
          </div>
        </div>
        <div :class="s.middle">
          <span v-if="tray.paper_name" :class="s.paper">
            {{ tray.paper_name }}
          </span>
          <span v-if="tray.paper_size" :class="s.paper">
            {{ tray.paper_size }}
          </span>
          <span v-if="tray.paper_color && tray.paper_color !== 'WHITE'" :class="s.paper">
            {{ tray.paper_color }}
          </span>
        </div>
        <div :class="s.right">
          <IconButton
            icon="ban"
            title="Papier verwijderen"
            :disabled="loading"
            @click="() => unassign(tray.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconButton from "./IconButton.vue";
export default {
  components: {
    IconButton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      trays: [],
    };
  },
  async created() {
    let response = await fetch(this.route("printers.trays.index", { printer: this.id }), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    this.trays = await response.json();
  },
  mounted() {
    window.Echo.channel(`printers_${this.id}`).listen("PrinterTrayUpdate", ({ tray }) => {
      const index = this.trays.findIndex((item) => item.id === tray.id);
      this.trays[index] = tray;
    });
  },
  methods: {
    async unassign(id) {
      this.loading = true;

      const response = await fetch(
        this.route("printers.trays.unassign", { printer: this.id, tray: id }),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({}),
        },
      );

      if (response.ok) {
        console.log(await response.json());
        this.loading = false;
      } else {
        await response.text();
      }
    },
    columnTrays(column) {
      let trays = [];
      for (const tray of this.trays) {
        if (tray.column === column) {
          trays.push(tray);
        }
      }

      trays = trays.sort(function (a, b) {
        return a.position - b.position;
      });

      return trays;
    },
    getName(tray) {
      let trays = this.trays.sort(function (a, b) {
        if (a.column === b.column) {
          return a.position - b.position;
        }
        return a.column - b.column;
      });

      let number = 0;
      for (const item of trays) {
        number++;
        if (item.id === tray.id) {
          return number;
        }
      }

      return "error";
    },
  },
  computed: {
    ...mapGetters(["route"]),
    columns() {
      let columns = [];
      for (const tray of this.trays) {
        columns.push(tray.column);
      }

      columns = columns.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

      columns = columns.sort();

      return columns;
    },
  },
};
</script>

<style module="s">
.container {
  display: flex;
  gap: 5px;
}
.column {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.tray {
  background-color: lightgray;
  border: 1px solid black;
  width: 200px;
  height: 60px;
  display: flex;
  justify-content: space-between;
}
.left {
  display: flex;
}
.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1px;
}
.paper {
  color: black;
  font-weight: bold;
}
.nameContainer {
  display: flex;
  width: 24px;
  height: 24px;
  border-right: 1px solid black;
  border-bottom: 1px solid;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.name {
  margin: 0;
}
</style>
