<template>
  <div class="tailwind">
    <div class="#flex #flex-col #gap-x-4 #gap-y-4 #my-4">
      <div class="#w-full #flex #gap-x-4 #justify-between #items-stretch">
        <div id="tasks" class="#bg-white #shadow-admin #p-6 #flex-col #flex #gap-y-4 #w-1/3">
          <h2 class="#font-bold #text-lg">Openstaande taken</h2>
          <ServicePendingtasks
            :employeeid="employeeid"
            :pending-tasks="pendingTasks"
          ></ServicePendingtasks>
        </div>
        <div class="#bg-white #w-1/3 #shadow-admin #p-6 #pr-0">
          <DashboardClickup type="customer-service" height="28rem"></DashboardClickup>
        </div>
        <div id="assigning" class="#bg-white #shadow-admin #p-6 #w-1/3">
          <h2 class="#font-bold #text-lg #mb-4">Taakverdeling</h2>
          <ServiceRoles
            :employees="customerServiceEmployees"
            :employeeid="employeeid"
            @get-pending="getPendingTasks"
          ></ServiceRoles>
        </div>
      </div>
      <div class="#w-full #flex #justify-between #gap-x-4">
        <div id="scoreboard" class="#bg-white #w-2/3 #shadow-admin">
          <div class="#pl-6 #py-6">
            <h2 class="#mb-4 #font-bold #text-lg">SLA Telefonie</h2>
            <iframe
              width="505"
              height="313"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRpJO8wjxMH_UvEv_raP7rawWT9CXH5UxzJABoDsy5kYLdvmXKFk9xbxJHsStqhK8ytZ6ONYDjDePTr/pubchart?oid=1461654168&amp;format=interactive"
            ></iframe>
          </div>
        </div>
        <div id="scoreboard" class="#bg-white #w-2/3 #shadow-admin">
          <div class="#pl-6 #py-6">
            <h2 class="#mb-4 #font-bold #text-lg">SLA Mail</h2>
            <iframe
              width="500"
              height="313"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRpJO8wjxMH_UvEv_raP7rawWT9CXH5UxzJABoDsy5kYLdvmXKFk9xbxJHsStqhK8ytZ6ONYDjDePTr/pubchart?oid=281672736&amp;format=interactive"
            ></iframe>
          </div>
        </div>
      </div>
      <div class="#w-full #flex #justify-between #gap-x-4">
        <div id="scoreboard" class="#bg-white #w-2/3 #shadow-admin">
          <div class="#pl-6 #py-6">
            <h2 class="#mb-4 #font-bold #text-lg">Team progressie 2024</h2>
            <iframe
              width="505"
              height="313"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRpJO8wjxMH_UvEv_raP7rawWT9CXH5UxzJABoDsy5kYLdvmXKFk9xbxJHsStqhK8ytZ6ONYDjDePTr/pubchart?oid=856376915&amp;format=interactive"
            ></iframe>
          </div>
        </div>
        <div id="scoreboard" class="#bg-white #w-2/3 #shadow-admin">
          <div class="#pl-6 #py-6">
            <h2 class="#mb-4 #font-bold #text-lg">SLA Chat</h2>
            <iframe
              width="500"
              height="313"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRpJO8wjxMH_UvEv_raP7rawWT9CXH5UxzJABoDsy5kYLdvmXKFk9xbxJHsStqhK8ytZ6ONYDjDePTr/pubchart?oid=389638534&amp;format=interactive"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ServicePendingtasks from "../components/ServicePendingtasks.vue";
import ServiceRoles from "../components/ServiceRoles.vue";
import DashboardClickup from "../components/DashboardClickup.vue";

export default {
  components: {
    DashboardClickup,
    ServicePendingtasks,
    ServiceRoles,
  },
  data() {
    return {
      customerServiceEmployees: [],
      pendingTasks: [],
      employeeid: null,
    };
  },
  computed: {
    ...mapGetters(["session", "route"]),
  },
  mounted() {
    this.employeeid = parseInt(this.session("employee_id"));
    this.getCustomerServiceEmployees();

    this.getPendingTasks();

    this.pendingTasksInterval = setInterval(() => {
      this.getPendingTasks();
    }, 60000);
  },
  beforeUnmounted() {
    clearInterval(this.pendingTasksInterval);
  },
  created() {},
  methods: {
    async getCustomerServiceEmployees() {
      const response = await fetch(this.route("employees.customer.service"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      if (response.ok) {
        this.customerServiceEmployees = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
    async getPendingTasks() {
      const response = await fetch(this.route("tasks.pending"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      if (response.ok) {
        this.pendingTasks = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
  },
};
</script>

<style>
.imperfect-rectangle {
  clip-path: polygon(0 0, 100% 10%, 100% 90%, 2% 100%);
}
.imperfect-rectangle-mirrored {
  clip-path: polygon(0 4%, 97% 0, 100% 100%, 0 94%);
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
.loader-animation {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f6f7;
  background: linear-gradient(to right, #f6f6f7 8%, #fff 18%, #f6f6f7 33%);
  background-size: 800px 104px;
}
</style>
