<template>
  <div class="#h-72 #overflow-y-scroll">
    <table class="#w-full">
      <tbody v-if="loading === true">
        <tr
          v-for="loader in 20"
          :key="loader"
          class="#border-y-[1px] #border-solid #border-[#a8a8a8] #h-[30px]"
        >
          <td class="#pl-4 #py-1 #w-1/2">
            <div class="#flex #items-center #justify-start #gap-x-2">
              <div class="loader-animation #w-2 #h-2 #rounded-full"></div>
              <div class="loader-animation #rounded-[3px] #w-3/4 #h-3"></div>
            </div>
          </td>
          <td>
            <div class="loader-animation #rounded-[3px] #w-12 #h-3"></div>
          </td>
          <td class="#text-right #pr-4 #py-1">
            <div class="loader-animation #rounded-[3px] #w-20 #h-3 #ml-auto"></div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          v-for="order in orders"
          :key="order.id"
          class="#group #border-y-[1px] #h-10 #border-solid #border-[#a8a8a8] #cursor-pointer hover:#bg-inkdroplight"
          @click="visitLink(order.parent)"
          @mousedown.middle="visitLink(order.parent)"
        >
          <td class="#pl-4 #py-1 #w-1/2">
            <div class="#flex #items-center #justify-start #gap-x-2">
              <div
                class="#w-2 #h-2 #rounded-full"
                :class="internalStatus(order.internal_delivery_time_color)"
              ></div>
              <div>
                <p class="group-hover:#text-vividmagenta group-hover:#underline #overflow-ellipsis">
                  {{ order.id }}
                </p>
                <p class="#text-[9px]">({{ order.product }})</p>
              </div>
              <p
                v-if="!order.is_fast_production"
                class="#bg-orange-400 #text-white #px-3 #rounded-r-full"
              >
                spoed
              </p>
              <p class="#opacity-0 group-hover:#opacity-50">{{ timeStamp(order.last_action) }}</p>
            </div>
          </td>
          <td>
            <p
              class="#py-1 #px-2 #w-max #text-center #font-bold #border-2 #border-transparent"
              :class="deadlineStyling(order.type)"
            >
              {{ order.internal_delivery_time }}
            </p>
          </td>
          <td class="#text-right #pr-4 #py-1">
            <span v-if="order.status === 'In productie'" class="#opacity-50">
              ({{ order.machine }})</span
            >
            {{ order.status }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["session"]),
  },
  methods: {
    visitLink(order) {
      window.open(`/admin.php?page=orders&sub=detail&id=${order}`, "_blank", "noopener,noreferrer");
    },
    internalStatus(color) {
      if (color === "red") {
        return "#bg-red-600";
      }
      if (color === "orange") {
        return "#bg-orange-400";
      }
      return "#bg-white #border-2 #border-solid #border-red-600";
    },
    deadlineStyling(type) {
      if (type === "Koerier") {
        return "#bg-[#B2EEB9] !#border-[#00C818]";
      }
      if (type === "Afhalen 16u") {
        return "#bg-[#FBB2B2] !#border-[#F63838]";
      }
      if (type === "Afhalen 10u") {
        return "#bg-[#B2CEFF] !#border-[#0060FF]";
      }
      if (type === "PostNL brievenbuspakje") {
        return "!#border-inkdropdark";
      }
    },
    timeStamp(date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>
