<template>
  <div v-if="hasData" class="mistake-chart-container">
    <Doughnut :data="chartData" :options="chartOptions" :plugins="plugins" />
  </div>
</template>
<script>
import { Doughnut } from "vue-chartjs";
import DoughnutChartMixin from "../mixins/DoughnutChart";
import MistakeDoughnutChart from "../mixins/MistakeDoughnutChart";

export default {
  components: { Doughnut },
  mixins: [DoughnutChartMixin, MistakeDoughnutChart],
  props: {
    categories: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    selectedCategories: {
      type: Array,
      required: true,
    },
    selectedStations: {
      type: Array,
      required: true,
    },
    stations: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: "Alle fouten",
    };
  },
};
</script>
