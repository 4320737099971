<template>
  <div v-for="deliveryMethod in Object.keys(data)" :key="deliveryMethod">
    <Line :options="getChartOptions(deliveryMethod)" :data="getChartData(deliveryMethod)" />
  </div>
</template>
<script>
import { Line } from "vue-chartjs";

export default {
  components: { Line },
  props: {
    data: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      colors: ["#1cad2a", "#ffcd56", "#ff6384", "#2674f5", "#e74c3c"],
    };
  },
  methods: {
    setChartData(deliveryMethod) {
      const labels = [];
      const datasets = [];
      let count = 0;

      const data = this.data[deliveryMethod];

      for (const [key, item] of Object.entries(data)) {
        for (const [k, v] of Object.entries(item)) {
          if (count === 0) {
            labels.push(k);
          }
          const existingIndex = datasets.findIndex((obj) => obj.label === key);
          let value;

          if (this.mode === "amount") {
            value = v.amount;
          } else if (this.mode === "costs") {
            value = v.costs;
          } else if (this.mode === "percentage") {
            value = v.percentage;
          }

          if (existingIndex !== -1) {
            datasets[existingIndex].data.push(value);
            datasets[existingIndex].raw.push({
              amount: v.amount,
              costs: v.costs,
              percentage: v.percentage,
            });
          } else {
            datasets.push({
              label: key,
              raw: [
                {
                  amount: v.amount,
                  costs: v.costs,
                  percentage: v.percentage,
                },
              ],
              data: [value],
              borderColor: this.colors[count],
              tension: 0.1,
            });
          }
        }

        count++;
      }

      return { labels: labels, datasets: datasets };
    },
    getChartData(deliveryMethod) {
      const data = this.setChartData(deliveryMethod);

      return {
        labels: data.labels,
        datasets: data.datasets,
      };
    },
    getChartOptions(deliveryMethod) {
      return {
        responsive: true,
        plugins: {
          tooltip: this.setTooltip(),
          title: {
            display: true,
            text: deliveryMethod.charAt(0).toUpperCase() + deliveryMethod.slice(1),
            font: {
              size: 20,
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: this.period === "month" ? "Maanden" : "Weeknummer",
            },
          },
          y: {
            title: {
              display: true,
              text: this.toMode(),
            },
          },
        },
      };
    },
    setTooltip() {
      return {
        boxPadding: 4,
        callbacks: {
          label: (item) => {
            return [
              item.dataset.label,
              `Totaal: ${item.dataset.raw[item.dataIndex].amount}`,
              `Schade: €${item.dataset.raw[item.dataIndex].costs}`,
              `Fouten percentage van totale bestellingen: ${
                item.dataset.raw[item.dataIndex].percentage
              }%`,
            ];
          },
        },
      };
    },
    toMode() {
      if (this.mode === "amount") {
        return "Aantal";
      } else if (this.mode === "costs") {
        return "Schade";
      } else if (this.mode === "percentage") {
        return "Percentage";
      }
    },
  },
};
</script>
