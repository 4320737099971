<template>
  <div class="tailwind">
    <h1
      class="#flex #text-[18px] #leading-9 #mt-0 #mb-2 #pl-9 #pt-2 #border-b #border-black #border-dotted #font-bold"
    >
      Canon printer statistieken
      <img v-if="loading" src="/admin/images/loading.gif" />
    </h1>
    <table
      class="#font-[arial] #text-left #ml-px #mb-2 #border-solid #border-[#3C3C3C] #border-2 #border-collapse #w-full"
    >
      <thead>
        <tr>
          <th
            class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          >
            Periode
          </th>
          <th
            class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-5 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          >
            <span v-if="['table'].includes(view)">Periode</span>
            <span v-if="!['table'].includes(view)">Weergave</span>
          </th>
          <th
            class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-5 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          >
            <span v-if="['table'].includes(view)">Weergave</span>
            <span v-if="['seperate', 'combined'].includes(view)">Modus</span>
            <span v-if="['history'].includes(view)">Handelingen</span>
          </th>
          <th
            class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-5 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          >
            <span v-if="['table'].includes(view)">Modus</span>
            <span v-if="['seperate', 'combined'].includes(view)">Handelingen</span>
            <span v-if="['history'].includes(view)">Printer</span>
          </th>
          <th
            class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-5 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          >
            <span v-if="['seperate', 'combined'].includes(view)">Medewerker</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="#p-1 #align-top #w-1/5">
            <div class="#flex #space-x-4">
              <div class="#flex #flex-col">
                <a
                  v-if="!isProduction()"
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('test')"
                  >Test</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('today')"
                  >Vandaag</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('yesterday')"
                  >Gister</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('week')"
                  >Deze week</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('month')"
                  >Deze maand</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('year')"
                  >Dit jaar</a
                >
              </div>
              <div class="#flex #flex-col">
                <input
                  ref="from"
                  v-model="from"
                  type="text"
                  readonly=""
                  placeholder="Van"
                  class="#px-1 #border #border-solid #border-black #mb-1"
                />
                <input
                  ref="to"
                  v-model="to"
                  type="text"
                  readonly=""
                  placeholder="Tot"
                  class="#px-1 #border #border-solid #border-black"
                />
              </div>
            </div>
          </td>
          <td v-show="['table'].includes(view)" class="#p-1 #pl-5 #align-top #w-1/6">
            <div>
              <input id="day" v-model="period" type="radio" value="day" />
              <label for="day" class="#ml-1">Per dag</label>
            </div>
            <div>
              <input id="week" v-model="period" type="radio" value="week" />
              <label for="week" class="#ml-1">Per week</label>
            </div>
            <div>
              <input id="month" v-model="period" type="radio" value="month" />
              <label for="month" class="#ml-1">Per maand</label>
            </div>
          </td>
          <td class="#p-1 #pl-5 #align-top #w-1/6">
            <div>
              <input id="seperate" v-model="view" type="radio" value="seperate" />
              <label for="seperate" class="#ml-1">Apart</label>
            </div>
            <div>
              <input id="combined" v-model="view" type="radio" value="combined" />
              <label for="combined" class="#ml-1">Samengevoegd</label>
            </div>
            <div>
              <input id="table" v-model="view" type="radio" value="table" />
              <label for="table" class="#ml-1">Tabel</label>
            </div>
            <div>
              <input id="history" v-model="view" type="radio" value="history" />
              <label for="history" class="#ml-1">Geschiedenis</label>
            </div>
          </td>
          <td
            v-show="['seperate', 'combined', 'table'].includes(view)"
            class="#p-1 #pl-5 #align-top #w-1/6"
          >
            <div>
              <input id="time" v-model="mode" type="radio" value="time" />
              <label for="time" class="#ml-1">Aantal per uur</label>
            </div>
            <div v-if="view === 'table'">
              <input id="total-time" v-model="mode" type="radio" value="total-time" />
              <label for="total-time" class="#ml-1">Totale tijd</label>
            </div>
            <div>
              <input id="events" v-model="mode" type="radio" value="events" />
              <label for="events" class="#ml-1">Aantallen</label>
            </div>
          </td>
          <td
            v-show="['seperate', 'combined', 'history'].includes(view)"
            class="#p-1 #pl-5 #align-top #w-1/6"
          >
            <div>
              <input id="all-actions" v-model="action" type="radio" value="all" />
              <label for="all-actions" class="#ml-1">Alle</label>
            </div>
            <div>
              <input id="operator" v-model="action" type="radio" value="operator" />
              <label for="operator" class="#ml-1">Operator</label>
            </div>
            <div>
              <input id="other" v-model="action" type="radio" value="other" />
              <label for="other" class="#ml-1">Overige</label>
            </div>
          </td>
          <td v-show="['seperate', 'combined'].includes(view)" class="#p-1 #pl-5 #align-top #w-1/6">
            <div class="#flex #flex-1">
              <select v-model="employee" class="#border #border-solid #border-black #w-full">
                <option selected :value="null">(please select)</option>
                <option v-for="(name, id) in employees" :key="id" :value="id">
                  {{ name }}
                </option>
              </select>
            </div>
          </td>
          <td v-show="['history'].includes(view)" class="#p-1 #pl-5 #align-top #w-1/6">
            <div class="#flex #flex-1">
              <select v-model="printer" class="#border #border-solid #border-black #w-full">
                <option selected :value="null">(please select)</option>
                <option v-for="data in printers" :key="data.id" :value="data.id">
                  {{ data.name }}
                </option>
              </select>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="view === 'table'" class="#flex #flex-wrap">
      <PrinterEmployeeStats
        :data="employeeStats"
        :from="from"
        :mode="mode"
        :period="period"
        :to="to"
      />
    </div>
    <div v-else-if="view === 'history'" class="#flex #flex-wrap #justify-center #gap-2">
      <PrinterStatusHistory :data="printerHistory" />
    </div>
    <div v-else class="#flex #flex-wrap #justify-center">
      <PrinterStatusChart
        v-for="(data, name) in printerStats"
        :key="name"
        :data="data"
        :label="name"
        :mode="mode"
      />
    </div>
  </div>
</template>

<script>
import "chart.js/auto";
import DatePickerFilter from "../mixins/DatePickerFilter";
import { mapGetters } from "vuex";
import PrinterStatusChart from "../components/PrinterStatusChart.vue";
import PrinterEmployeeStats from "../components/PrinterEmployeeStats.vue";
import PrinterStatusHistory from "../components/PrinterStatusHistory.vue";

export default {
  components: {
    PrinterStatusChart,
    PrinterEmployeeStats,
    PrinterStatusHistory,
  },
  mixins: [DatePickerFilter],
  data() {
    return {
      action: "all",
      loading: false,
      employees: [],
      period: "day",
      printers: [],
      printerStats: [],
      printerHistory: [],
      employeeStats: [],
      from: null,
      to: null,
      employee: null,
      printer: null,
      mode: "time",
      view: "seperate",
      pickers: null,
    };
  },
  computed: {
    ...mapGetters(["route", "isProduction"]),
    formData() {
      return {
        from: moment(this.from, "DD-MM-YYYY HH:mm:ss").unix(),
        to: moment(this.to, "DD-MM-YYYY HH:mm:ss").unix(),
        period: this.period,
        printer_id: this.printer,
        employee_id: this.employee,
        view: this.view,
        operator: this.action,
        type: this.mode,
      };
    },
  },
  watch: {
    async formData(data) {
      if (!data.from || !data.to) {
        return;
      }

      this.loading = true;

      this.getEmployees(data);

      if (data.view === "table") {
        await this.getEmployeeStats(data);
      } else if (data.view === "history") {
        await this.getPrinterHistory(data);
      } else {
        await this.getPrinterStats(data);
      }

      this.loading = false;
    },
    view(newView, oldView) {
      if (oldView === "table" && this.mode === "total-time") {
        this.mode = "time";
      }
    },
  },
  mounted() {
    this.setupDatePickers();
    this.setRange(this.isProduction() ? "today" : "test");
    this.getPrinters();
  },
  methods: {
    async getEmployees(data) {
      let response = await fetch(this.route("printers.employees"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          from: data.from,
          to: data.to,
        }),
      });
      this.employees = await response.json();
    },
    async getPrinters() {
      let response = await fetch(this.route("printers.list"), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      this.printers = await response.json();
    },
    async getPrinterStats(data) {
      let response = await fetch(this.route("printers.stats"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });
      this.printerStats = await response.json();
    },
    async getPrinterHistory(data) {
      let response = await fetch(this.route("printers.history"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });
      this.printerHistory = await response.json();
    },
    async getEmployeeStats(data) {
      let response = await fetch(this.route("printers.employees.stats"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        this.employeeStats = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
  },
};
</script>
