<template>
  <div v-if="hasData">
    <Doughnut ref="chart" :style="size" :data="chartData" :options="options" :plugins="plugins" />
  </div>
</template>

<script>
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "vue-chartjs";
import DoughnutChartMixin from "../mixins/DoughnutChart";
import { printerJobStatusColor, toDuration } from "../helpers";

export default {
  components: { Doughnut },
  mixins: [DoughnutChartMixin],
  props: {
    label: {
      type: String,
      default: null,
    },
    subLabel: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: "time",
    },
  },
  data() {
    return {
      margin: 0,
      labels: [],
      results: [],
      percentages: [],
      colors: [],
      options: {
        responsive: true,
        layout: {
          padding: 50,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            bodyAlign: "center",
            boxPadding: 4,
            callbacks: {
              label: (item, data) => {
                const real = this.results[item.datasetIndex][item.dataIndex];
                const total = this.results[item.datasetIndex].reduce((a, b) => a + b, 0);

                if (this.mode === "time") {
                  const percentage = (real / total) * 100;

                  return [
                    this.labels[item.datasetIndex][item.dataIndex],
                    this.toFullDuration(real),
                    `${percentage.toFixed(1)}%`,
                  ];
                } else if (this.mode === "events") {
                  const percentage = (real / total) * 100;

                  return [
                    this.labels[item.datasetIndex][item.dataIndex],
                    `${real}x`,
                    `${percentage.toFixed(1)}%`,
                  ];
                }
              },
            },
          },
          datalabels: {
            labels: {
              categoryTime: {
                color: "#565d64",
                font: {
                  weight: "bold",
                  size: "12px",
                },
                anchor: "end",
                align: "end",
                textAlign: "center",
                formatter: (value, context) => {
                  const real = this.results[context.datasetIndex][context.dataIndex];

                  if (this.mode === "time") {
                    return context.chart.data.labels[context.dataIndex] + "\n" + toDuration(real);
                  } else if (this.mode === "events") {
                    return context.chart.data.labels[context.dataIndex] + "\n" + real + "x";
                  }
                },
              },
              percentage: {
                color: "white",
                font: {
                  weight: "bold",
                  size: "12px",
                },
                anchor: "center",
                align: "center",
                formatter: (value, context) => {
                  const total = this.results[context.datasetIndex].reduce((a, b) => a + b, 0);
                  const real = this.results[context.datasetIndex][context.dataIndex];
                  const percentage = (real / total) * 100;

                  return `${percentage.toFixed(1)}%`;
                },
              },
            },
          },
        },
      },
    };
  },
  computed: {
    plugins() {
      return [
        ChartDataLabels,
        {
          id: "innerLabel",
          afterDatasetDraw: (chart, args) =>
            this.setInnerLabel(chart, args, this.label, this.subLabel, "24px"),
        },
      ];
    },
    size() {
      return {
        height: "575px",
        width: "575px",
        position: "relative",
      };
    },
    chartData() {
      return {
        labels: this.labels[0],
        datasets: [
          {
            fill: "origin",
            data: this.percentages[0],
            backgroundColor: this.colors[0],
            borderWidth: 2.5,
          },
          {
            fill: "origin",
            data: this.percentages[1],
            backgroundColor: this.colors[1],
            borderWidth: 2.5,
            datalabels: {
              display: false,
            },
          },
        ],
      };
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.parseData(newValue);
      },
    },
    mode() {
      this.parseData(this.data);
    },
  },
  methods: {
    parseData(data) {
      let outerData = [];
      let outerPercentages = [];
      let outerLabels = [];
      let outerColors = [];
      let innerData = [];
      let innerPercentages = [];
      let innerLabels = [];
      let innerColors = [];

      for (const [action, items] of Object.entries(data)) {
        outerLabels.push(action);
        let color = printerJobStatusColor(action);
        if (color) {
          outerColors.push(color);
        }

        let total = 0;
        for (const [sub_action, { time, events }] of Object.entries(items)) {
          if (this.mode === "time") {
            total = total + time;
            innerData.push(time);
          } else if (this.mode === "events") {
            total = total + events;
            innerData.push(events);
          }

          innerLabels.push(sub_action);
          innerColors.push(outerColors[outerColors.length - 1]);
        }

        outerData.push(total);
      }

      const full = outerData.reduce((a, b) => a + b, 0);
      for (const [action, items] of Object.entries(data)) {
        let total = 0;
        for (const [sub_action, { time, events }] of Object.entries(items)) {
          if (this.mode === "time") {
            const percentage = (time / full) * 100;
            total = total + percentage;
            innerPercentages.push(percentage);
          } else if (this.mode === "events") {
            const percentage = (events / full) * 100;
            total = total + percentage;
            innerPercentages.push(percentage);
          }
        }
        outerPercentages.push(total);
      }

      this.labels = [outerLabels, innerLabels];
      this.results = [outerData, innerData];
      this.percentages = [outerPercentages, innerPercentages];
      this.colors = [outerColors, innerColors];
    },
    toFullDuration(sec) {
      const { days, hours, milliseconds, minutes, months, seconds, years } = moment.duration(
        sec,
        "seconds",
      )._data;

      return (
        (years ? `${years}y ` : "") +
        (months ? `${months}m ` : "") +
        (days ? `${days}d ` : "") +
        (hours ? `${hours}h ` : "") +
        (minutes ? `${minutes}m ` : "") +
        (seconds ? `${seconds}s ` : "")
      );
    },
  },
};
</script>
