<template>
  <div class="#flex #gap-4 #flex-col">
    <div class="#grid #grid-cols-2 #gap-x-4 #gap-y-8">
      <div v-for="task in tasks" :key="task.id" class="#w-full #flex #flex-col #gap-y-3 #gap-x-4">
        <h3 class="#text-inkdropdark #text-[14px]">{{ task.name }}</h3>
        <div v-for="employee of task.employees" :key="employee.id" class="#relative #w-full">
          <div
            class="imperfect-rectangle-mirrored #z-[1] #bg-brightcyan #w-full #top-0 #opacity-100 #flex #items-center #p-2"
          >
            <div
              class="#relative #flex #justify-center #items-center #overflow-hidden #bg-inkdroplight #w-6 #h-6 #rounded-full"
            >
              <img
                style="visibility: hidden"
                class="#absolute #w-9 #max-w-none #top-[-8px]"
                :src="`${employee.thumb}?force=true`"
                :alt="employee.firstname"
                onload="this.style.visibility ='visible'"
                onerror="this.onerror=null; this.src='/admin/images/no-image.jpg'"
              />
            </div>
            <p class="#text-white #text-sm #ml-2">{{ employee.firstname }}</p>
          </div>
          <div
            v-if="['asma', 'dewi'].includes(session('username')) || employee.id === employeeid"
            class="#p-[2px] #bg-white #border-solid #border-red-500 #border-2 #rounded-full #absolute #-top-2 #-right-2 #flex #justify-center #items-center #cursor-pointer #transition-all #group hover:#bg-red-500"
            @click="handleFinishTask(task.id, employee.id)"
          >
            <svg-use
              id="xmark"
              type="solid"
              class="#h-3 #w-3 #fill-red-500 #transition-all group-hover:#fill-white"
            />
          </div>
        </div>
        <div
          v-if="showDropdown !== task.id && isAssigned(task.employees, employeeid)"
          class="#relative #w-full"
        >
          <div
            class="#w-full #bg-inkdroplight #relative #flex #justify-center #items-center #p-2 #group #cursor-pointer"
            @click="handleTaskAssignment(task.id, employeeid)"
          >
            <svg-use
              id="circle-plus"
              type="solid"
              class="#h-[1.5em] #w-[1.5em] #fill-inkdropdark #transition-all group-hover:#opacity-50"
            />
          </div>
        </div>
        <select
          v-if="showDropdown === task.id"
          id="employees"
          v-model="selectedEmployee"
          name="employees"
          class="#text-xs #bg-inkdroplight #border-solid #border-[1px] #px-1 #py-2 #border-[#d5d5d5] #rounded-[4px]"
          @change="handleTaskAssignment(task.id, selectedEmployee.id)"
        >
          <option selected disabled value="Medewerker kiezen">Medewerker kiezen</option>
          <option
            v-for="employee of getFilteredEmployees(task.employees)"
            :key="employee"
            :value="employee"
          >
            {{ employee.firstname }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgUse from "../../vue3/components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
    employeeid: {
      type: Number,
      default: 0,
    },
  },
  emits: ["getPending"],
  data() {
    return {
      showDropdown: null,
      tasks: [],
      selectedEmployee: "Medewerker kiezen",
    };
  },
  computed: {
    ...mapGetters(["session", "route"]),
  },
  mounted() {
    this.getTasks();

    this.tasksInterval = setInterval(() => {
      this.getTasks();
    }, 60000);
  },
  beforeUnmounted() {
    clearInterval(this.tasksInterval);
  },
  methods: {
    isAssigned(obj, employee) {
      for (const item of obj) {
        if (item.employee_id === employee) {
          return false;
        }
      }
      return true;
    },
    async getTasks() {
      const obj = {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        ended_at: "not_exists",
      };

      const response = await fetch(this.route("tasks.index"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(obj),
      });

      if (response.ok) {
        this.tasks = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
    getFilteredEmployees(assignedEmployees) {
      return this.employees.filter(
        (employee) =>
          !assignedEmployees.some((assignedEmployee) => assignedEmployee.id === employee.id),
      );
    },
    async handleTaskAssignment(taskId, employee) {
      if (["asma", "dewi"].includes(this.session("username")) && this.showDropdown !== taskId) {
        this.showDropdown = taskId;
        return;
      }
      const obj = {
        task_id: taskId,
        employee_id: employee,
      };

      this.showDropdown = null;
      this.selectedEmployee = "Medewerker kiezen";

      const response = await fetch(this.route("tasks.assign"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(obj),
      });

      if (response.ok) {
        await response.json();
        this.getTasks();
        this.$emit("getPending");
      } else if (!response.ok) {
        await response.text();
      }
    },
    async handleFinishTask(taskId, employee) {
      const obj = {
        employee_id: employee,
        task_id: taskId,
      };

      const response = await fetch(this.route("tasks.finish"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(obj),
      });

      if (response.ok) {
        await response.json();
        this.getTasks();
        this.$emit("getPending");
      } else if (!response.ok) {
        await response.text();
      }
    },
  },
};
</script>
