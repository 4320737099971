<template>
  <Line
    v-if="data.length > 0 && labels.length > 0"
    id="fast"
    :options="chartOptions"
    :data="chartData"
  />

  <div v-if="showTables">
    <WorkedInAdvanceArticlesTable />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Line } from "vue-chartjs";
import WorkedInAdvanceArticlesTable from "./WorkedInAdvanceArticlesTable.vue";

export default {
  components: { Line, WorkedInAdvanceArticlesTable },
  props: {
    data: {
      type: Object,
      required: true,
    },
    labels: {
      type: Object,
      required: true,
    },
    showTables: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["route"]),
    chartData() {
      return {
        labels: this.labels,
        datasets: this.data,
      };
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Vooruitgewerkt (aantal)",
            font: {
              size: 20,
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Dag",
            },
          },
          y: {
            title: {
              display: true,
              text: "Aantal ",
            },
          },
        },
        layout: {
          padding: {
            top: 20,
          },
        },
      };
    },
  },
};
</script>
