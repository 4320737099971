<template>
  <h3 class="#font-bold #text-lg #text-inkdropdark #relative #z-[1] #mb-4 #pr-6">
    Clickup ({{ tasks.length }})

    <svg-use
      id="arrows-rotate"
      type="solid"
      class="#h-[20px] #w-[20px] #float-right #cursor-pointer"
      @click="refresh = true"
    />
  </h3>
  <NotificationMessage
    v-if="message"
    class="#ml-1 #mr-1"
    :type="message.type"
    :message="message.text"
    @close="message = null"
  />
  <div v-if="tasks.length === 0">
    <div class="#overflow-y-scroll #pl-1 #pt-1" :class="{ [`#h-[${height}]`]: true }">
      <div v-for="item of 15" :key="item" class="#mb-4 #bg-white #rounded-lg #shadow-sm #pr-6">
        <div class="#flex #justify-between #mb-2">
          <div class="#flex #gap-x-2 #justify-between #w-full">
            <div class="#flex #gap-2">
              <div class="loader-animation #shadow-admin #rounded-full #w-[80px]"></div>
              <div class="loader-animation #shadow-admin #w-[20px] #h-[20px]"></div>
              <div class="loader-animation #shadow-admin #w-[180px]"></div>
            </div>
            <div class="loader-animation #shadow-admin #w-[20px] #h-[20px]"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="#overflow-y-scroll #pl-1 #pt-1" :class="{ [`#h-[${height}]`]: true }">
    <div v-for="task of tasks" :key="task.id" class="#mb-4 #bg-white #rounded-lg #shadow-sm #pr-6">
      <div class="#flex #justify-between #mb-2">
        <div class="#flex #gap-x-2">
          <div>
            <select
              v-model="task.status.status"
              class="#p-1 #py-1 #rounded-full #font-medium #capitalize #focus:outline-none #transition-colors #duration-200 #w-[85px] #text-white"
              :style="{ backgroundColor: task.status.color }"
              @change="updateTaskStatus(task, task.status.status)"
            >
              <option
                v-for="status in statuses"
                :key="status.value"
                class="#px-4 #py-2 #cursor-pointer #transition-colors #duration-200 #capitalize"
                :selected="task.status.status === status.value"
                :value="status.value"
              >
                {{ status.text }}
              </option>
            </select>
          </div>
          <div>
            <button
              class="#flex #space-x-2 #focus:outline-none"
              @click="updateTaskStatus(task, 'done')"
            >
              <svg-use
                id="square-check"
                type="solid"
                class="#h-[15px] #w-[15px] #fill-gray-500 hover:#fill-green-700"
              />
            </button>
          </div>
          <p class="#text-sm" :title="task.description">
            {{ task.title }}
            <a
              class="#text-blue-500"
              :href="`https://app.clickup.com/t/${task.id}`"
              target="_blank"
              rel="noopener noreferrer"
            >
              (link)
            </a>
          </p>
        </div>

        <div class="#flex #space-x-2 #pr-1">
          <button
            class="#group #text-blue-500 hover:#underline #flex #space-x-2 #focus:outline-none"
            @click="task.showComment = !task.showComment"
          >
            <svg-use
              id="message-middle"
              type="solid"
              class="#h-[15px] #w-[15px] #fill-current group-hover:#opacity-50"
            />
          </button>
        </div>
      </div>
      <div v-if="task.showComment" class="#mt-4 #flex #space-x-2">
        <textarea
          v-model="task.comment"
          class="#p-2 #w-full #border #border-gray-300 #rounded-lg #shadow-sm #focus:outline-none #focus:ring-2 #focus:ring-blue-400 #focus:border-blue-400"
          rows="2"
        ></textarea>

        <button
          :disabled="!task.comment"
          class="#mt-auto #mb-auto #px-4 #py-2 #text-white #font-semibold #rounded-lg #focus:outline-none #focus:ring-2 #focus:ring-blue-400 #focus:ring-offset-2 #cursor-pointer"
          :class="!task.comment ? '#bg-blue-400' : '#bg-blue-500 hover:#bg-blue-600'"
          @click="comment(task)"
        >
          <svg-use id="paper-plane-top" type="solid" class="#h-[15px] #w-[15px] #fill-white" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NotificationMessage from "../components/NotificationMessage.vue";
import SvgUse from "../../vue3/components/SvgUse.vue";

export default {
  components: { NotificationMessage, SvgUse },
  props: {
    height: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      message: null,
      refresh: false,
      statuses: [],
      tasks: [],
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    refresh() {
      if (this.refresh) {
        this.getTasks();
        this.refresh = false;
      }
    },
  },
  mounted() {
    this.getTasks();
    this.getStatuses();
  },
  methods: {
    async getTasks() {
      this.tasks = [];

      const response = await fetch(this.route(`clickup.tasks.${this.type}`), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ refresh: this.refresh }),
      });

      if (response.ok) {
        this.tasks = await response.json();
      } else if (!response.ok) {
        this.message = { text: JSON.parse(await response.text()), type: "danger" };
      }
    },
    async getStatuses() {
      const response = await fetch(this.route("clickup.statuses"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ type: this.type }),
      });

      if (response.ok) {
        this.statuses = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
    async comment(task) {
      this.message = null;
      const obj = { id: task.id, comment: task.comment };

      const response = await fetch(this.route("clickup.comment"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(obj),
      });

      if (response.ok) {
        task.showComment = false;
        task.comment = "";
        this.message = { text: await response.json(), type: "success" };
      } else if (!response.ok) {
        const res = await response.json();
        this.message = { text: res.message, type: "danger" };
      }
    },
    async updateTaskStatus(task, status) {
      this.message = null;
      const obj = { id: task.id, status: status, type: this.type };

      const response = await fetch(this.route("clickup.update.status"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(obj),
      });

      if (response.ok) {
        this.message = { text: await response.json(), type: "success" };
        this.getTasks();
      } else if (!response.ok) {
        const res = await response.json();
        this.message = { text: res.message, type: "danger" };
      }
    },
  },
};
</script>
