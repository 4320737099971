<template>
  <div :class="s.control">
    <IconButton
      icon="arrows-rotate"
      title="Scan code en ververs lijst"
      :disabled="loading"
      @click="refresh"
    />
  </div>
  <div :class="s.wrapper">
    <main :class="s.main">
      <table class="data">
        <thead>
          <tr>
            <th>Origineel</th>
            <th>Vertaling</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(translation, source) in items"
            :key="source"
            :class="{ selected: selected.source === source }"
            @click="() => select(source, translation)"
          >
            <td>{{ source }}</td>
            <td>{{ translation }}</td>
          </tr>
        </tbody>
      </table>
      <footer :class="s.footer">
        <div :class="s.textareaContainer">
          <label for="source">Origineel</label>
          <div :class="s.container">
            <div :class="s.backdrop">
              <div :class="s.highlights" v-html="sourceMarked" />
            </div>
            <textarea v-model="selected.source" readonly spellcheck="false" :class="s.textarea" />
          </div>
        </div>
        <div :class="s.textareaContainer">
          <label for="translation">Vertaling</label>
          <div :class="s.container">
            <div :class="s.backdrop">
              <div :class="s.highlights" v-html="translationMarked" />
            </div>
            <textarea
              ref="translation"
              v-model="selected.translation"
              spellcheck="false"
              :class="s.textarea"
            />
          </div>
        </div>
      </footer>
    </main>
    <nav :class="s.nav">
      <h2>Vertaling suggestie</h2>
      <a href="javascript:;" @click="useSuggestion" v-html="suggestionSafe" />
      <h2>Gevonden in bestanden:</h2>
      <p v-for="(file, index) in selected.files" :key="`file_${file}_${index}`">
        {{ file }}
      </p>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash.debounce";
import IconButton from "../components/IconButton.vue";

export default {
  components: {
    IconButton,
  },
  data() {
    return {
      loading: false,
      regex: /(^ | $|^\.|\.$|%s|\s{2,})/gm,
      mark: '<mark style="color: transparent; border-radius: 3px; background-color: #b1d5e5">$1</mark>',
      items: [],
      selected: {
        source: "",
        translation: "",
        suggestion: "",
        files: [],
      },
    };
  },
  computed: {
    ...mapGetters(["route"]),
    sourceMarked() {
      return this.escapeHtml(this.selected.source).replace(this.regex, this.mark);
    },
    translationMarked() {
      return this.escapeHtml(this.selected.translation).replace(this.regex, this.mark);
    },
    suggestionSafe() {
      return this.escapeHtml(this.selected.suggestion);
    },
  },
  watch: {
    "selected.translation": function (...args) {
      this.debouncedUpdate(...args);
    },
  },
  async created() {
    this.debouncedUpdate = debounce(async (newValue, oldValue) => {
      let response = await fetch(this.route("translations.update"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          key: this.selected.source,
          value: newValue,
        }),
      });

      const { success } = await response.json();
      if (success) {
        this.items[this.selected.source] = newValue;
      }
    }, 500);

    let response = await fetch(this.route("translations.index"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    this.items = await response.json();
  },
  beforeUnmount() {
    this.debouncedUpdate.cancel();
  },
  methods: {
    useSuggestion() {
      if (!this.selected.suggestion) {
        return;
      }

      this.selected.translation = this.selected.suggestion;
    },
    select(source, translation) {
      this.selected.source = source;
      this.selected.translation = translation;
      this.selected.files = [];
      this.$refs.translation.focus();

      this.loadFiles();
      this.loadSuggestion();
    },
    async loadFiles() {
      let response = await fetch(this.route("translations.find"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          key: this.selected.source,
        }),
      });
      this.selected.files = await response.json();
    },
    async loadSuggestion() {
      let response = await fetch(this.route("translations.suggestion"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          value: this.selected.source,
        }),
      });
      response = await response.json();

      this.selected.suggestion = response.suggestion;
    },
    async refresh() {
      this.loading = true;
      let response = await fetch(this.route("translations.refresh"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      });
      response = await fetch(this.route("translations.index"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      this.items = await response.json();
      this.loading = false;
    },
    escapeHtml(unsafe) {
      return unsafe
        .replaceAll("&", "&amp;")
        .replaceAll("<", "&lt;")
        .replaceAll(">", "&gt;")
        .replaceAll('"', "&quot;")
        .replaceAll("'", "&#039;");
    },
  },
};
</script>

<style module="s">
.wrapper {
  display: flex;
}

.control {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: -48px;
}

.main,
.container,
.backdrop,
.textarea {
  width: 850px;
}

.main table {
  display: flex;
  flex-flow: column;
  margin: 0px;
  border: 1px solid #3c3c3c;
  border-bottom-width: 0px;
  width: 100%;
  height: 500px;
}

.main thead {
  flex: 0 0 auto;
}

.main tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.main tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.main tr:hover {
  background-color: #eeeeee;
}

.main tr.selected {
  background-color: lightblue;
}

.main td {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nav {
  flex: 1;
  display: flex;
  background-color: #eeeeee;
  border: 1px solid #3c3c3c;
  border-left-width: 0px;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav p {
  margin-top: 0;
}

.footer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #3c3c3c;
}

.textareaContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.textareaContainer label {
  font-weight: bold;
  flex: 1;
  padding: 5px;
  border: 1px solid #3c3c3c;
  background-color: #eeeeee;
}

.container,
.backdrop,
.textarea {
  height: 90px;
}

.container {
  display: block;
  margin: 0 auto;
  transform: translateZ(0);
  -webkit-text-size-adjust: none;
}

.backdrop,
.textarea {
  position: absolute;
  padding: 10px;
  margin: 0px;
  border: 1px solid #3c3c3c;
  border-top-width: 0px;
  border-bottom-width: 0px;
  box-sizing: border-box;
  overflow: hidden;
  transition: transform 1s;
}

.textarea,
.highlights {
  line-height: 15px;
  font-size: 11px;
}

.backdrop {
  z-index: 1;
  background-color: #fff;
  pointer-events: none;
}

.textarea {
  z-index: 2;
  background-color: transparent;
}

.highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
}
</style>
