<template>
  <input v-model="date" type="date" class="#rounded #p-1 #border #border-black" />
  <table id="worked-in-advance-articles" class="display" style="width: 100%">
    <thead>
      <tr>
        <th>Artikelnummer</th>
        <th>Opdrachtnummer</th>
        <th>Referentie</th>
        <th>Status</th>
        <th>Bedrag</th>
        <th>Product</th>
      </tr>
    </thead>
  </table>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      table: null,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    date: {
      handler() {
        this.table.ajax.reload();
      },
      immediate: false,
    },
  },
  beforeUnmount() {
    this.table.destroy();
    this.table = null;
  },
  mounted() {
    this.buildTable();
  },
  methods: {
    buildTable() {
      this.table = $("#worked-in-advance-articles").DataTable({
        ajax: {
          url: this.route("production.statistics.worked-in-advance"),
          data: (d) => {
            d["date"] = this.date;
          },
        },
        order: [[1, "desc"]],
        paging: true,
        pageLength: 25,
        columns: [
          {
            data: "id",
            render: (data, type, row) => {
              return `<a href="/admin.php?page=orders&sub=detail&id=${row.order_id}" target="_blank" rel="noopener noreferrer" class="link">${data}</a>`;
            },
          },
          {
            data: "order_id",
            render: (data) => {
              return `<a href="/admin.php?page=orders&sub=detail&id=${data}" target="_blank" rel="noopener noreferrer" class="link">${data}</a>`;
            },
          },
          { data: "reference" },
          { data: "status" },
          {
            data: "amount",
            render: (data) => {
              return `€${data}`;
            },
          },
          { data: "product" },
        ],
      });
    },
  },
};
</script>
