<template>
  <p class="#text-sm #mt-2">
    Gemiddelde skill percentage: <b>{{ averageSkillPercentage }}%</b>
  </p>
  <table id="skills" class="display" style="width: 100%">
    <thead>
      <tr>
        <th>Medewerker</th>
        <th>Totaal</th>
        <th>Werkt sinds</th>
        <th>Ingeroosterde werktijd</th>
        <th>Laatste vaardigheid geleerd</th>
        <th>Laatst iets nieuws geleerd</th>
      </tr>
    </thead>
  </table>

  <!-- MODAL -->
  <Modal :open="open" @close="selectedSkill = null">
    <div class="tailwind">
      <div class="#mb-5">
        <label for="title" class="#block #mb-2 #text-sm #font-medium #text-gray-900">Titel</label>
        <input
          id="title"
          v-model="selectedSkill.title"
          type="text"
          class="#bg-gray-300 #border #border-gray-300 #text-gray-900 #text-sm #rounded-lg #focus:ring-blue-500 #focus:border-blue-500 #block #w-full #p-2.5"
          required
          readonly
        />
        <p v-if="errors['title']" class="#text-red-600 #mt-1">
          {{ errors["title"] }}
        </p>
      </div>
      <div class="#mb-5">
        <label for="silver-points" class="#block #mb-2 #text-sm #font-medium #text-gray-900">
          Zilver
        </label>
        <input
          id="silver-points"
          v-model="selectedSkill.silver_points"
          type="number"
          min="0"
          class="#bg-gray-50 #border #border-gray-300 #text-gray-900 #text-sm #rounded-lg #focus:ring-blue-500 #focus:border-blue-500 #block #w-full #p-2.5"
          required
        />
        <p v-if="errors['silver_points']" class="#text-red-600 #mt-1">
          {{ errors["silver_points"] }}
        </p>
      </div>
      <div class="#mb-5">
        <label for="gold-points" class="#block #mb-2 #text-sm #font-medium #text-gray-900">
          Goud
        </label>
        <input
          id="gold-points"
          v-model="selectedSkill.gold_points"
          type="number"
          min="0"
          class="#bg-gray-50 #border #border-gray-300 #text-gray-900 #text-sm #rounded-lg #focus:ring-blue-500 #focus:border-blue-500 #block #w-full #p-2.5"
          required
        />
        <p v-if="errors['gold_points']" class="#text-red-600 #mt-1">
          {{ errors["gold_points"] }}
        </p>
      </div>
      <div class="#mb-5">
        <label for="bronze-percentage" class="#block #mb-2 #text-sm #font-medium #text-gray-900">
          Brons percentage
        </label>
        <input
          id="bronze-percentage"
          v-model="selectedSkill.bronze_percentage"
          type="number"
          min="0"
          max="100"
          class="#bg-gray-50 #border #border-gray-300 #text-gray-900 #text-sm #rounded-lg #focus:ring-blue-500 #focus:border-blue-500 #block #w-full #p-2.5"
          required
        />
        <p v-if="errors['bronze_percentage']" class="#text-red-600 #mt-1">
          {{ errors["bronze_percentage"] }}
        </p>
      </div>
      <div class="#mb-5">
        <label for="silver-percentage" class="#block #mb-2 #text-sm #font-medium #text-gray-900">
          Zilver percentage
        </label>
        <input
          id="silver-percentage"
          v-model="selectedSkill.silver_percentage"
          type="number"
          min="0"
          max="100"
          class="#bg-gray-50 #border #border-gray-300 #text-gray-900 #text-sm #rounded-lg #focus:ring-blue-500 #focus:border-blue-500 #block #w-full #p-2.5"
          required
        />
        <p v-if="errors['silver_percentage']" class="#text-red-600 #mt-1">
          {{ errors["silver_percentage"] }}
        </p>
      </div>
      <div class="#mb-5">
        <label for="gold-percentage" class="#block #mb-2 #text-sm #font-medium #text-gray-900">
          Goud percentage
        </label>
        <input
          id="gold-percentage"
          v-model="selectedSkill.gold_percentage"
          type="number"
          min="0"
          max="100"
          class="#bg-gray-50 #border #border-gray-300 #text-gray-900 #text-sm #rounded-lg #focus:ring-blue-500 #focus:border-blue-500 #block #w-full #p-2.5"
          required
        />
        <p v-if="errors['gold_percentage']" class="#text-red-600 #mt-1">
          {{ errors["gold_percentage"] }}
        </p>
      </div>
      <button
        type="submit"
        class="#text-white #bg-blue-700 #hover:bg-blue-800 #focus:ring-4 #focus:outline-none #focus:ring-blue-300 #font-medium #rounded-lg #text-sm #w-full #sm:w-auto #px-5 #py-2.5 #text-center"
        @click="updateSkill"
      >
        Wijzigen
      </button>
    </div>
  </Modal>
</template>
<script>
import Modal from "./Modal.vue";
import { mapGetters } from "vuex";

export default {
  components: { Modal },
  props: {
    averageSkillPercentage: {
      type: Number,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    skills: {
      type: Object,
      required: true,
    },
  },
  emits: ["skill-create-update", "loading-status"],
  data() {
    return {
      errors: {},
      message: "",
      selectedSkill: null,
      table: null,
    };
  },
  computed: {
    ...mapGetters(["route", "isAdminLevel"]),
    open() {
      return this.selectedSkill !== null;
    },
  },
  watch: {
    data: {
      handler() {
        this.buildTable();
      },
      immediate: false,
    },
  },
  mounted() {
    this.buildTable();

    this.$nextTick(() => {
      const tableBody = document.querySelector("#skills tbody");
      tableBody.addEventListener("click", this.showDetails);
    });
  },
  beforeUnmount() {
    document.removeEventListener("click", this.showDetails);
  },
  methods: {
    buildTable() {
      if (this.table) {
        this.table.destroy();
        this.table = null;
      }

      this.table = $("#skills").DataTable({
        serverSide: false,
        order: [[1, "asc"]],
        data: this.data,
        preDrawCallback: () => {
          this.$emit("loading-status", true);
        },
        drawCallback: () => {
          this.$emit("loading-status", false);
        },
        columns: [
          { data: "employee" },
          {
            data: "total",
            render: (data) => {
              return `${data}%`;
            },
          },
          {
            data: "start_date",
            render: {
              display: (data) => {
                return moment(data, moment.ISO_8601).isValid()
                  ? moment(data, moment.ISO_8601).format("DD-MM-YYYY")
                  : "";
              },
              sort: (data) => {
                return moment(data, moment.ISO_8601).isValid()
                  ? moment(data, moment.ISO_8601).format("YYYYMMDD")
                  : "";
              },
            },
          },
          { data: "scheduled_working_time" },
          { data: "last_skill_learned" },
          {
            data: "last_learned",
            render: {
              display: (data) => {
                return moment(data, moment.ISO_8601).isValid()
                  ? moment(data, moment.ISO_8601).format("DD-MM-YYYY")
                  : "";
              },
              sort: (data) => {
                return moment(data, moment.ISO_8601).isValid()
                  ? moment(data, moment.ISO_8601).format("YYYYMMDD")
                  : "";
              },
            },
          },
        ],
      });
    },
    async updateSkill() {
      this.message = "";
      this.errors = {};

      let response = await fetch(this.route("skills.edit"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(this.selectedSkill),
      });

      response = await response.json();

      if (response.success) {
        this.message = response.message;
        this.selectedSkill = null;
        this.$emit("skill-create-update", true);
      } else if (response.errors) {
        for (const [key, value] of Object.entries(response.errors)) {
          this.errors[key] = value[0];
        }
      }
    },
    getSkillRankingIcon(ranking) {
      switch (ranking) {
        case "bronze":
          return "skill_status_1.svg";
        case "silver":
          return "skill_status_2.svg";
        case "gold":
          return "skill_status_3.svg";
        case "diamond":
          return "skill_status_4.svg";
      }
    },
    showDetails(event) {
      const target = event.target;
      const tr = target.closest("tr");
      const row = this.table.row(tr);

      if (target.closest('span[id^="skill-"]')) {
        const span = target.closest("span");
        const skillId = span.id.split("-")[1];
        this.selectedSkill = this.skills.find((skill) => skill.id === parseInt(skillId));
      }

      if (row.child.isShown()) {
        row.child.hide();
      } else if (row.data()) {
        row.child(this.format(row.data())).show();
      }
    },
    format(employeeSkills) {
      return `
        <table id="order-details" class="display" style="width: 100%">
          <thead>
            <tr>
            <th></th>
            ${this.skills
              .map(
                (skill) => `
                <th class="#text-center">${skill.title.substring(0, 5) + ".."}
                  ${
                    this.isAdminLevel(5)
                      ? `<span id="skill-${skill.id}" class="#flex #justify-center">${svgUse({ id: "gear", width: "11px", height: "11px" })}</span>`
                      : ""
                  }
                </th>`,
              )
              .join("")}
          </tr>
            <tr>
              <td class="#font-bold">Ranking</td>
              ${this.skills
                .map(
                  (skill) => `
                  <td>
                    <img
                      style="height: 20px"
                      src="/admin/images/${this.getSkillRankingIcon(employeeSkills.skills[skill.title].ranking.id)}"
                      class="#m-auto"
                    />
                  </td>`,
                )
                .join("")}
            </tr>
            <tr>
              <td class="#font-bold">Ingewerkt door:</td>
              ${this.skills
                .map(
                  (skill) => `
                  <td class="#text-center">${employeeSkills.skills[skill.title].taught_by ?? "-"}</td>`,
                )
                .join("")}
            </tr>
            <tr>
              <td class="#font-bold">Ingewerkt op:</td>
              ${this.skills
                .map(
                  (skill) => `
                  <td class="#text-center">
                    ${
                      moment(
                        employeeSkills.skills[skill.title].created_at,
                        moment.ISO_8601,
                      ).isValid()
                        ? moment(employeeSkills.skills[skill.title].created_at).format("DD-MM-YYYY")
                        : "-"
                    }
                  </td>`,
                )
                .join("")}
            </tr>
          </thead>
        </table>`;
    },
  },
};
</script>
