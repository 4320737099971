<template>
  <h1>
    Vaardigheden overzicht
    <img v-if="loading" src="/admin/images/loading.gif" class="customer-overview-spinner" />
  </h1>
  <div class="tailwind">
    <div class="#border-black #bg-black #text-white">
      <p class="#pl-2 #text-xs">Filters</p>
    </div>
    <div class="#flex #space-x-4 #border #border-black #p-1 #mb-2">
      <div>
        <div>
          <p class="#font-bold">Weergave</p>
          <div class="#flex #items-center">
            <input
              id="radio-all"
              v-model="view"
              type="radio"
              value="all"
              class="#w-4 #h-4 #text-blue-600 #bg-gray-100 #border-gray-300 #rounded #focus:ring-blue-500 #focus:ring-2"
            />
            <label for="radio-all" class="#ml-1 #text-xs #font-medium #text-gray-900"> Alle </label>
          </div>
          <div class="#flex #items-center">
            <input
              id="radio-trained"
              v-model="view"
              type="radio"
              value="trained"
              class="#w-4 #h-4 #text-blue-600 #bg-gray-100 #border-gray-300 #rounded #focus:ring-blue-500 #focus:ring-2"
            />
            <label for="radio-trained" class="#ml-1 #text-xs #font-medium #text-gray-900">
              Ingewerkt
            </label>
          </div>
        </div>
      </div>
      <div v-if="view === 'all'">
        <SelectFilter v-model="body.employee" label="Medewerkers" :options="options.employees" />
      </div>
      <div v-if="view === 'all'">
        <p class="#font-bold">Totaal percentage</p>
        <div class="#flex #items-center">
          <input
            id="cb-less-than-25"
            v-model="body.total"
            type="checkbox"
            value="less_than_25"
            class="#w-4 #h-4 #text-blue-600 #bg-gray-100 #border-gray-300 #rounded #focus:ring-blue-500 #focus:ring-2"
          />
          <label for="cb-less-than-25" class="#ml-1 #text-xs #font-medium #text-gray-900">
            Minder dan 25%
          </label>
        </div>
        <div class="#flex #items-center">
          <input
            id="cb-25-to-50"
            v-model="body.total"
            type="checkbox"
            value="25_to_50"
            class="#w-4 #h-4 #text-blue-600 #bg-gray-100 #border-gray-300 #rounded #focus:ring-blue-500 #focus:ring-2"
          />
          <label for="cb-25-to-50" class="#ml-1 #text-xs #font-medium #text-gray-900">
            25% - 50%
          </label>
        </div>
        <div class="#flex #items-center">
          <input
            id="cb-50-to-75"
            v-model="body.total"
            type="checkbox"
            value="50_to_75"
            class="#w-4 #h-4 #text-blue-600 #bg-gray-100 #border-gray-300 #rounded #focus:ring-blue-500 #focus:ring-2"
          />
          <label for="cb-50-to-75" class="#ml-1 #text-xs #font-medium #text-gray-900">
            50% - 75%
          </label>
        </div>
        <div class="#flex #items-center">
          <input
            id="cb-more-than-75"
            v-model="body.total"
            type="checkbox"
            value="more_than_75"
            class="#w-4 #h-4 #text-blue-600 #bg-gray-100 #border-gray-300 #rounded #focus:ring-blue-500 #focus:ring-2"
          />
          <label for="cb-more-than-75" class="#ml-1 #text-xs #font-medium #text-gray-900">
            Meer dan 75%
          </label>
        </div>
      </div>
      <div v-if="view === 'all'">
        <p class="#font-bold">Ranking</p>
        <div class="#flex #items-center">
          <OptionFilter id="gold" v-model="body.ranking" label="Goud" value="gold" />
        </div>
        <div class="#flex #items-center">
          <OptionFilter id="silver" v-model="body.ranking" label="Zilver" value="silver" />
        </div>
        <div class="#flex #items-center">
          <OptionFilter id="bronze" v-model="body.ranking" label="Brons" value="bronze" />
        </div>
      </div>
      <div v-if="view === 'all'">
        <p class="#font-bold">Leer status</p>
        <div class="#flex #items-center">
          <OptionFilter
            id="nothing-learned"
            v-model="body.last_learned"
            label="Nog niets geleerd"
            value="nothing_learned"
          />
        </div>
        <div class="#flex #items-center">
          <OptionFilter
            id="recently-learned"
            v-model="body.last_learned"
            label="Recent iets geleerd"
            value="recently_learned"
          />
        </div>
        <div class="#flex #items-center">
          <OptionFilter
            id="long-time-not-learned"
            v-model="body.last_learned"
            label="Lange tijd niets geleerd"
            value="long_time_not_learned"
          />
        </div>
      </div>
      <div v-if="view === 'all'">
        <p class="#font-bold">Overige</p>
        <div class="#flex #items-center">
          <input
            id="cb-employees-active-today"
            v-model="body.active"
            type="checkbox"
            :value="true"
            class="#w-4 #h-4 #text-blue-600 #bg-gray-100 #border-gray-300 #rounded #focus:ring-blue-500 #focus:ring-2"
          />
          <label for="cb-employees-active-today" class="#ml-1 #text-xs #font-medium #text-gray-900">
            Toon medewerkers vandaag actief
          </label>
        </div>
        <div class="#flex #items-center">
          <input
            id="cb-production-employee"
            v-model="body.production_employee"
            type="checkbox"
            :value="true"
            class="#w-4 #h-4 #text-blue-600 #bg-gray-100 #border-gray-300 #rounded #focus:ring-blue-500 #focus:ring-2"
          />
          <label for="cb-production-employee" class="#ml-1 #text-xs #font-medium #text-gray-900">
            Productie medewerkers
          </label>
        </div>
      </div>
      <div v-if="view === 'trained'">
        <p class="#font-bold">Periode</p>
        <div class="#flex #flex-1">
          <div class="#flex #flex-1 #flex-col #mr-2">
            <a
              href="javascript:;"
              class="#w-fit #text-[11px] #text-[#0000EE] #underline"
              @click="() => setRange('last_month')"
              >Laastste maand</a
            >
            <a
              href="javascript:;"
              class="#w-fit #text-[11px] #text-[#0000EE] #underline"
              @click="() => setRange('last_three_months')"
              >Laastste 3 maanden</a
            >
            <a
              href="javascript:;"
              class="#w-fit #text-[11px] #text-[#0000EE] #underline"
              @click="() => setRange('last_six_months')"
              >Laatste 6 maanden</a
            >
            <a
              href="javascript:;"
              class="#w-fit #text-[11px] #text-[#0000EE] #underline"
              @click="() => setRange('last_year')"
              >Laatste jaar</a
            >
          </div>
          <div class="#flex #flex-1 #flex-col">
            <input
              ref="from"
              v-model="from"
              type="text"
              readonly=""
              placeholder="Van"
              class="#px-1 #border #border-solid #border-black #mb-1"
            />
            <input
              ref="to"
              v-model="to"
              type="text"
              readonly=""
              placeholder="Tot"
              class="#px-1 #border #border-solid #border-black"
            />
          </div>
        </div>
      </div>
      <div v-if="view === 'trained'">
        <SelectFilter
          v-model="body.taught_by"
          label="Ingewerkt door"
          :options="trainedOptions.taught_by"
        />
        <SelectFilter
          v-model="body.employee"
          label="Medewerkers"
          :options="trainedOptions.employees"
        />
      </div>
      <div v-if="view === 'trained'">
        <SelectFilter v-model="body.skill" label="Vaardigheden" :options="trainedOptions.skills" />
      </div>
    </div>
    <p v-if="error" class="#text-red-600 #text-xs">{{ error }}</p>
    <div v-if="view === 'all'">
      <SkillStats
        :data="skillStats"
        :skills="skills"
        :average-skill-percentage="averageSkillPercentage"
        @skill-create-update="fetchSkillStats"
        @loading-status="loadingStatus"
      />
    </div>
    <div v-if="view === 'trained'">
      <Trained :data="trainedStats" :floor-manager-stats="floorManagerStats" />
    </div>
  </div>
</template>
<script>
import DatePickerFilter from "../mixins/DatePickerFilter";
import Trained from "../components/Trained.vue";
import { mapGetters } from "vuex";
import OptionFilter from "../components/OptionFilter.vue";
import SelectFilter from "../components/SelectFilter.vue";
import SkillStats from "../components/SkillStats.vue";

export default {
  components: { Trained, OptionFilter, SkillStats, SelectFilter },
  mixins: [DatePickerFilter],
  data() {
    return {
      averageSkillPercentage: 0,
      body: {
        active: false,
        employee: [],
        from: null,
        last_learned: "",
        production_employee: false,
        ranking: "",
        skill: [],
        taught_by: [],
        to: null,
        total: [],
      },
      error: "",
      from: null,
      to: null,
      loading: true,
      trainedOptions: {
        employees: [],
        skills: [],
        taught_by: [],
      },
      trainedStats: [],
      options: {
        employees: [],
      },
      skills: [],
      skillStats: [],
      view: "all",
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    body: {
      handler() {
        if (this.view === "all") {
          this.fetchSkillStats();
        } else if (this.view === "trained") {
          this.fetchTrainedStats();
        }
      },
      immediate: true,
      deep: true,
    },
    view: {
      handler() {
        if (this.view === "trained") {
          this.fetchTrainedStats();

          this.$nextTick(() => {
            this.setupDatePickers();
          });
        } else if (this.view === "all") {
          this.fetchSkillStats();
        }
      },
    },
    from: "filterPeriod",
    to: "filterPeriod",
  },
  methods: {
    async fetchSkillStats() {
      this.error = "";

      let response = await fetch(this.route("skills.overview"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(this.body),
      });

      response = await response.json();

      if (response.success) {
        this.options.employees = response.options.employees;
        this.skills = response.skills;
        this.skillStats = response.employee_skills;
        this.averageSkillPercentage = response.average_skill_percentage;
      }
    },
    async fetchTrainedStats() {
      this.error = "";

      let response = await fetch(this.route("skills.trained"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(this.body),
      });

      response = await response.json();

      if (response.success) {
        this.trainedStats = response.employee_skills;
        this.trainedOptions.employees = response.options.employees;
        this.trainedOptions.skills = response.options.skills;
        this.trainedOptions.taught_by = response.options.taught_by;
        this.floorManagerStats = response.floor_manager_stats;
      } else if (response.errors) {
        this.error = response.message;
      }
    },
    loadingStatus(loading) {
      this.loading = loading;
    },
  },
};
</script>
