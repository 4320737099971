<template>
  <table
    v-if="isAdminLevel(4, 5)"
    class="#w-full #text-sm #text-left #rtl:text-right #text-gray-500 #mb-2"
  >
    <thead class="#text-xs #text-gray-700 #bg-gray-50">
      <tr>
        <th scope="row" class="#pl-2 #font-bold #text-gray-900 #whitespace-nowrap">Maanden</th>
        <th v-for="item of Object.keys(data)" :key="item" scope="col" class="#px-6 #py-3">
          {{ item }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="station in stations"
        :key="station"
        class="#odd:bg-white #odd:dark:bg-gray-900 #even:bg-gray-50 #even:dark:bg-gray-800 #border-b"
      >
        <th scope="row" class="#pl-2 #font-bold #text-gray-900 #whitespace-nowrap">
          {{ station.value }}
        </th>
        <td v-for="item of Object.keys(data)" :key="item.id" class="#px-6">
          <p class="#cursor-default" title="Percentage kosten in verhouding tot omzet">
            <b>Percentage: </b>
            {{
              data[item][station.id] ? `${data[item][station.id].costs_to_revenue_ratio}%` : "FOUT"
            }}
          </p>
          <p
            class="#cursor-default"
            :title="`Fouten in verhouding tot ${group === 'articles' ? 'artikelen' : 'opdrachten'}`"
          >
            <b>Fouten: </b>{{ data[item][station.id] ? data[item][station.id].amount : "FOUT" }}
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <Line id="mistakes-station" :options="chartOptions" :data="chartData" />
</template>
<script>
import { Line } from "vue-chartjs";
import { mapGetters } from "vuex";

export default {
  components: { Line },
  props: {
    data: {
      type: Object,
      required: true,
    },
    group: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    stations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      colors: [
        "#3498db",
        "#2ecc71",
        "#e74c3c",
        "#9b59b6",
        "#f39c12",
        "#1abc9c",
        "#d35400",
        "#34495e",
        "#16a085",
        "#c0392b",
        "#f1c40f",
      ],
      datasets: [],
      labels: [],
    };
  },
  computed: {
    ...mapGetters(["isAdminLevel"]),
    chartData() {
      const data = this.setChartData();

      return {
        labels: data.labels,
        datasets: data.datasets,
      };
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          tooltip: this.setTooltip(),
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Maanden",
            },
          },
          y: {
            title: {
              display: true,
              text: this.toMode(),
            },
          },
        },
      };
    },
  },
  methods: {
    setTooltip() {
      return {
        boxPadding: 4,
        callbacks: {
          label: (item) => {
            return [
              this.datasets[item.datasetIndex].label,
              `Totaal: ${this.datasets[item.datasetIndex].raw[item.dataIndex].amount}`,
              `Schade: €${this.datasets[item.datasetIndex].raw[item.dataIndex].costs}`,
              `Percentage: ${this.datasets[item.datasetIndex].raw[item.dataIndex].percentage}%`,
            ];
          },
        },
      };
    },
    setChartData() {
      const labels = [];
      const datasets = [];
      let colorIndex = 0;

      for (const [month, subCategories] of Object.entries(this.data)) {
        labels.push(month);

        for (const [subCategory, item] of Object.entries(subCategories)) {
          const existingIndex = datasets.findIndex((obj) => obj.label === item.sub_category);
          let value;

          if (this.mode === "amount") {
            value = item.amount;
          } else if (this.mode === "costs") {
            value = item.costs;
          } else if (this.mode === "percentage") {
            value = item.percentage;
          }

          if (existingIndex !== -1) {
            datasets[existingIndex].data.push(value);
            datasets[existingIndex].raw.push({
              amount: item.amount,
              costs: item.costs,
              percentage: item.percentage,
            });
          } else {
            datasets.push({
              label: item.sub_category,
              raw: [
                {
                  amount: item.amount,
                  costs: item.costs,
                  percentage: item.percentage,
                },
              ],
              data: [value],
              borderColor: this.colors[colorIndex % this.colors.length],
              tension: 0.1,
            });

            colorIndex++;
          }
        }
      }

      this.datasets = datasets;
      this.labels = labels;

      return { labels: labels, datasets: datasets };
    },
    toMode() {
      if (this.mode === "amount") {
        return "Aantal";
      } else if (this.mode === "costs") {
        return "Schade";
      } else if (this.mode === "percentage") {
        return "Percentage";
      }
    },
  },
};
</script>
