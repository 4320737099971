<template>
  <label class="#font-bold">{{ label }}</label>
  <div class="#flex select-filter">
    <div v-if="condition" class="#mr-2 condition">
      <select ref="select_condition">
        <option value="in">Een van</option>
        <option value="not_in">Niet een van</option>
        <option value="like">Bevat</option>
        <option value="not_like">Bevat niet</option>
      </select>
    </div>
    <div v-show="['in', 'not_in'].includes(selectedCondition)" class="select-container">
      <select ref="select" :multiple="multiple">
        <option v-if="!multiple">Kies...</option>
        <option v-for="option in options" :key="option.id ?? option" :value="option.id ?? option">
          {{ option.value ?? option }}
        </option>
      </select>
    </div>
    <div v-show="selectedCondition === 'like'">
      <StringFilter v-model="stringFilter" />
    </div>
    <div v-show="selectedCondition === 'not_like'">
      <StringFilter v-model="stringFilter" />
    </div>
  </div>
</template>
<script>
import StringFilter from "./StringFilter.vue";

export default {
  components: { StringFilter },
  props: {
    condition: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      stringFilter: this.modelValue,
      selectedCondition: "in",
      selectedOptions: [],
    };
  },
  watch: {
    modelValue: {
      handler() {
        if (this.modelValue.length) {
          $(this.$refs.select).val(this.modelValue).trigger("change");
          this.$emit("update:modelValue", this.modelValue);
        }
      },
      immediate: false,
    },
    selectedCondition: {
      handler() {
        if (["in", "not_in"].includes(this.selectedCondition)) {
          this.stringFilter = "";
          this.$emit("update:modelValue", this.stringFilter);
        }

        if (["like", "not_like"].includes(this.selectedCondition)) {
          $(this.$refs.select).val([]).trigger("change");
          this.$emit("update:modelValue", []);
        }
      },
    },
    stringFilter: {
      handler() {
        if (this.selectedCondition === "like") {
          this.$emit("update:modelValue", this.stringFilter);
        } else if (this.selectedCondition === "not_like") {
          this.$emit("update:modelValue", this.stringFilter ? `!${this.stringFilter}` : "");
        }
      },
    },
  },
  mounted() {
    $(this.$refs.select_condition).select2({ dropdownAutoWidth: true });
    $(this.$refs.select).select2({ dropdownAutoWidth: true });

    $(this.$refs.select).on("change.select2", () => {
      const selectedOptions = $(this.$refs.select)
        .select2("data")
        .filter((item) => item.id !== "")
        .filter((item) => item.id !== "Kies...")
        .map((item) => item.id);

      if (!this.condition) {
        return this.$emit("update:modelValue", selectedOptions);
      }

      if (!selectedOptions.length) {
        return this.$emit("update:modelValue", []);
      }

      this.$emit("update:modelValue", { condition: this.selectedCondition, data: selectedOptions });
    });

    $(this.$refs.select_condition).on("change.select2", (e) => {
      this.selectedCondition = e.target.value;
    });
  },
};
</script>
<style>
.select-filter .condition .select2-container {
  width: 75px !important;
}

.select-filter .select-container .select2-container {
  width: 125px !important;
}

.select-filter .select-container .select2-selection {
  display: flex;
  align-items: center;
  border: 1px solid #d4d4d4;
}
</style>
