<template>
  <div>
    <label class="#block #font-bold">
      {{ label }}
    </label>
    <input
      ref="searchInput"
      type="text"
      class="#block #border #border-black #rounded #h-8 #pl-2"
      @input="handleSearch"
    />
  </div>
</template>
<script>
import debounce from "lodash.debounce";

export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  watch: {
    modelValue: {
      handler() {
        if (!this.modelValue) {
          this.$refs.searchInput.value = "";
        }
      },
    },
  },
  methods: {
    handleSearch: debounce(function (e) {
      this.$emit("update:modelValue", e.target.value);
    }, 500),
  },
};
</script>
