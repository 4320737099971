<template>
  <div class="#mt-2">
    <label class="#font-bold #text-xs">Print operators</label>
    <SelectFilter v-model="selectedPrintOperators" :options="printOperators" />
  </div>

  <div
    v-if="!selectedPrintOperators.length"
    class="#w-[1150px] #h-[475px] #pt-5 #pb-12 #mt-2 #mb-2 #bg-gray-100 #flex #justify-center #items-center #text-xl"
  >
    Kies een print operator
  </div>

  <div
    v-else-if="loading"
    class="loader-animation #shadow-admin #w-[1150px] #h-[475px] #pt-5 #pb-12 #mt-2 #mb-2"
  ></div>

  <div
    v-else-if="message"
    class="#w-[1150px] #h-[475px] #pt-5 #pb-12 #mt-2 #mb-2 #bg-gray-100 #flex #justify-center #items-center #text-xl"
  >
    {{ message }}
  </div>

  <div
    v-else-if="!loading && !hasData"
    class="#w-[1150px] #h-[475px] #pt-5 #pb-12 #mt-2 #mb-2 #bg-gray-100 #flex #justify-center #items-center #text-xl"
  >
    Geen resultaten gevonden op basis van de gekozen filters.
  </div>

  <Line v-else id="cleaning-print-operators" :options="chartOptions" :data="chartData" />
</template>
<script>
import { Chart } from "chart.js";
import ChartAnnotation from "chartjs-plugin-annotation";
import debounce from "lodash.debounce";
import { Line } from "vue-chartjs";
import { mapGetters } from "vuex";
import SelectFilter from "../components/SelectFilter.vue";
import { toDuration } from "../helpers";

export default {
  components: { Line, SelectFilter },
  props: {
    from: {
      type: String,
      default: "",
    },
    period: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      average: null,
      selectedPrintOperators: [],
      chartData: {
        labels: [],
        datasets: [],
      },
      loading: null,
      message: null,
      printOperators: [],
    };
  },
  computed: {
    ...mapGetters(["route"]),
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          annotation: {
            annotations: {
              line1: {
                type: "line",
                yMin: this.average,
                yMax: this.average,
                borderDash: [6, 6],
                borderDashOffset: 0,
                borderColor: "red",
                borderWidth: 3,
                label: {
                  display: true,
                  content: `Gemiddeld: ${this.average}`,
                  position: "end",
                },
              },
            },
          },
          tooltip: {
            boxPadding: 4,
            callbacks: {
              label: (item) => {
                return [
                  `Aantal: ${this.chartData.datasets[item.datasetIndex].raw[item.dataIndex].events}`,
                  `Per uur: ${this.chartData.datasets[item.datasetIndex].raw[item.dataIndex].per_hour.toFixed(2)}`,
                  `Totale tijd: ${toDuration(this.chartData.datasets[item.datasetIndex].raw[item.dataIndex].total_time)}`,
                ];
              },
            },
          },
        },
        layout: {
          padding: {
            top: 20,
            bottom: 50,
          },
        },
      };
    },
    hasData() {
      return (
        this.chartData.datasets.length > 0 &&
        this.chartData.labels.length > 0 &&
        this.average !== null
      );
    },
  },
  watch: {
    from: {
      handler() {
        this.loading = true;
        this.getPrintOperators();
        this.getCleaningPrintOperatorsChart();
      },
      immediate: false,
    },
    to: {
      handler() {
        this.loading = true;
        this.getPrintOperators();
        this.getCleaningPrintOperatorsChart();
      },
      immediate: false,
    },
    selectedPrintOperators: {
      handler() {
        this.loading = true;
        this.getCleaningPrintOperatorsChart();
      },
      immediate: false,
    },
    period: {
      handler() {
        this.loading = true;
        this.getCleaningPrintOperatorsChart();
      },
      immediate: false,
    },
  },
  mounted() {
    Chart.register(ChartAnnotation);
    this.getPrintOperators();
  },
  methods: {
    getCleaningPrintOperatorsChart: debounce(async function () {
      this.message = null;
      this.chartData.labels = [];
      this.chartData.datasets = [];
      this.average = null;

      const obj = {
        from: moment(this.from, "DD-MM-YYYY HH:mm:ss").unix(),
        to: moment(this.to, "DD-MM-YYYY HH:mm:ss").unix(),
        period: this.period,
        employee_id: this.selectedPrintOperators,
      };

      const response = await fetch(this.route("printers.cleaning.print-operators"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(obj),
      });

      if (response.ok) {
        const data = await response.json();

        this.chartData.labels = data.labels;
        this.chartData.datasets = data.datasets;
        this.average = data.average;
      } else if (response.status === 422) {
        const data = await response.json();
        this.message = data.message;
      } else if (!response.ok) {
        await response.text();
      }

      this.loading = false;
    }, 500),
    async getPrintOperators() {
      const obj = {
        from: moment(this.from, "DD-MM-YYYY HH:mm:ss").unix(),
        to: moment(this.to, "DD-MM-YYYY HH:mm:ss").unix(),
      };

      const response = await fetch(this.route("printers.print-operators.options"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(obj),
      });

      if (response.ok) {
        this.printOperators = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
  },
};
</script>
