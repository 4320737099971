import * as Sentry from "@sentry/vue";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import mitt from "mitt";
import { createApp } from "vue";
import { loadStore } from "./store";
import PrinterStats from "./apps/PrinterStats.vue";
import Printers from "./apps/Printers.vue";
import PrinterControl from "./apps/PrinterControl.vue";
import CallbackRequests from "./apps/CallbackRequests.vue";
import CallHistory from "./apps/CallHistory.vue";
import CallQueue from "./apps/CallQueue.vue";
import CallDialer from "./apps/CallDialer.vue";
import CallMissed from "./apps/CallMissed.vue";
import CallStatistics from "./apps/CallStatistics.vue";
import CustomerOverview from "./apps/CustomerOverview.vue";
import DialPad from "./apps/DialPad.vue";
import Keywords from "./apps/Keywords.vue";
import OrdersOverview from "./apps/OrdersOverview.vue";
import PdfToolboxFailed from "./apps/PdfToolboxFailed.vue";
import TranslationsManage from "./apps/TranslationsManage.vue";
import EndShift from "./apps/EndShift.vue";
import PasswordConfirm from "./apps/PasswordConfirm.vue";
import AutoLogout from "./apps/AutoLogout.vue";
import BackInOrderModal from "./apps/BackInOrderModal.vue";
import BackInOrder from "./apps/BackInOrder.vue";
import Intercept from "./apps/Intercept.vue";
import Mistake from "./apps/Mistake.vue";
import MistakeStatistics from "./apps/MistakeStatistics.vue";
import CustomerCommunications from "./apps/CustomerCommunications.vue";
import PrintOperator from "./apps/PrintOperator.vue";
import PrinterEmployeeLog from "./apps/PrinterEmployeeLog.vue";
import FeedbackMistakeEmployeee from "./apps/FeedbackMistakeEmployee.vue";
import IncomingCall from "./apps/IncomingCall.vue";
import AddFeedback from "./apps/AddFeedback.vue";
import PerformancePoints from "./apps/PerformancePoints.vue";
import MessagesPopup from "./apps/MessagesPopup.vue";
import SkillOverview from "./apps/SkillOverview.vue";
import EmployeePoints from "./apps/EmployeePoints.vue";
import ProductionStatistic from "./apps/ProductionStatistic.vue";
import ArticleFinished from "./apps/ArticleFinished.vue";
import ServiceDashboard from "./apps/ServiceDashboard.vue";
import UnassignedTasksPopup from "./apps/UnassignedTasksPopup.vue";
import TaskAssignment from "./apps/TaskAssignment.vue";
import RequestConversation from "./apps/RequestConversation.vue";
import FloormanagerDashboard from "./apps/FloormanagerDashboard.vue";
import CouponOverview from "./apps/CouponOverview.vue";
import ManagePhotobooth from "./apps/ManagePhotobooth.vue";
import Campaign from "./apps/Campaign.vue";
import ArticleOverview from "./apps/ArticleOverview.vue";
import EmployeeOverview from "./apps/EmployeeOverview.vue";

const apps = {
  "printer-stats": PrinterStats,
  printers: Printers,
  "printer-control": PrinterControl,
  "callback-requests": CallbackRequests,
  "call-history": CallHistory,
  "call-missed": CallMissed,
  "call-queue": CallQueue,
  "call-dialer": CallDialer,
  "call-statistics": CallStatistics,
  "customer-overview": CustomerOverview,
  "dial-pad": DialPad,
  keywords: Keywords,
  "orders-overview": OrdersOverview,
  "pdf-toolbox-failed": PdfToolboxFailed,
  "printer-employee-log": PrinterEmployeeLog,
  "translations-manage": TranslationsManage,
  "end-shift": EndShift,
  "back-in-order-modal": BackInOrderModal,
  "back-in-order": BackInOrder,
  intercept: Intercept,
  mistake: Mistake,
  "password-confirm": PasswordConfirm,
  "auto-logout": AutoLogout,
  "customer-communications": CustomerCommunications,
  "printer-operator": PrintOperator,
  "mistake-statistics": MistakeStatistics,
  "feedback-mistake-employee": FeedbackMistakeEmployeee,
  "incoming-call": IncomingCall,
  "add-feedback": AddFeedback,
  "performance-points": PerformancePoints,
  "messages-popup": MessagesPopup,
  "skill-overview": SkillOverview,
  "employee-points": EmployeePoints,
  "production-statistic": ProductionStatistic,
  "article-finished": ArticleFinished,
  "service-dashboard": ServiceDashboard,
  "unassigned-tasks-popup": UnassignedTasksPopup,
  "task-assignment": TaskAssignment,
  "request-conversation": RequestConversation,
  "floormanager-dashboard": FloormanagerDashboard,
  "coupon-overview": CouponOverview,
  "manage-photo-booth": ManagePhotobooth,
  campaign: Campaign,
  "article-overview": ArticleOverview,
  "employee-overview": EmployeeOverview,
};

loadStore().then((store) => {
  window.Pusher = Pusher;
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: store.getters.config("broadcasting.key"),
    wsHost: "socket.printenbind.nl",
    forceTLS: true,
    encrypted: true,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
  });

  window.emitter = mitt();
  for (const [id, app] of Object.entries(apps)) {
    var items = document.getElementsByClassName(`vue-app-${id}`);
    for (const [index, item] of Object.entries(items)) {
      let name = "name" in app ? app.name : id;
      if (items.length > 1) {
        name = name + " " + (parseInt(index) + 1);
      }

      const vapp = createApp({ ...app, name });
      vapp.config.globalProperties.emitter = window.emitter;

      const dsn = store.getters.config("services.sentry.js_dsn");
      if (dsn) {
        Sentry.init({
          app: vapp,
          dsn: dsn,
          integrations: [],
        });
      }

      vapp.use(store).mount(item);
    }
  }

  $(document).trigger("vue-mounted");
});
