<template>
  <div :class="s.control">
    <IconButton icon="arrows-rotate" title="Verversen" :disabled="loading" @click="update" />
  </div>
  <div :class="s.container">
    <Printer v-for="printer in printers" v-bind="printer" :key="printer.id" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Printer from "../components/Printer.vue";
import IconButton from "../components/IconButton.vue";

export default {
  components: { Printer, IconButton },
  data() {
    return {
      printers: [],
      loading: false,
    };
  },
  async created() {
    await this.update();
  },
  methods: {
    async update() {
      this.loading = true;

      let response = await fetch(this.route("printers.index"));
      this.printers = await response.json();

      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["route"]),
  },
};
</script>

<style module="s">
.control {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.container {
  margin-top: -28px;
}
</style>
