<template>
  <Modal :open="open" :width="1030" @close="open = false">
    <div class="tailwind">
      <div v-if="successMessage" class="#flex #justify-center #items-center #h-48">
        <span class="#text-center #font-bold #text-lg #text-black">{{ successMessage }} </span>
      </div>
      <div v-else class="#h-full #grow">
        <div class="#font-bold #text-lg">
          <span>Onderscheppen</span>
        </div>
        <div v-if="page == 0">
          <span class="#font-medium #text-sm #block"
            >Opdracht {{ order_id }}. Selecteer de artikelen die je wilt onderscheppen.
          </span>
          <hr />
          <div class="#mt-5 #mb-5"></div>
          <div class="#grid #grid-cols-[298px_260px_210px_auto] #pt-2">
            <div class="#flex #items-end #cursor-pointer" @click="selectAllArticles">
              <input
                class="#text-center #w-6 #pl-1 #cursor-pointer"
                :checked="selectedArticles.length === articles.length"
                type="checkbox"
              />
              {{ selecttext }}
            </div>
            <div class="#flex #items-end">Kies actie(s)</div>
            <div class="#flex #items-end">Instructie</div>
            <div class="#flex #items-end">Niveau</div>
          </div>

          <div
            v-for="(article, index) in articles"
            :key="article.id"
            class="#h-full #grid #grid-cols-[20px_25px_50px_200px_250px_10px_200px_10px_180px_10px_25px] #py-2 #text-[12px]"
            :class="index % 2 === 0 ? '#bg-[#eeeeee]' : '#bg-[#fff]'"
          >
            <div
              class="#text-center #w-5 #pl-1 #cursor-pointer"
              @click="() => selectArticle(article)"
            >
              <input type="checkbox" :checked="selectedArticles.includes(article)" />
            </div>
            <div class="#w-[52px] #cursor-pointer" @click="() => selectArticle(article)"></div>

            <div>
              <img
                class="#max-w-[50px] #max-h-[50px] #border"
                :src="article.image ? article.image : '/admin/images/cart-empty-document.png'"
                onerror="this.onerror=null; this.src='/admin/images/cart-empty-document.png'; this.style.padding = '10px'"
              />
            </div>
            <div class="#w-100 #pl-2 #cursor-pointer" @click="() => selectArticle(article)">
              <span> Artikel {{ article.id }} - {{ article.number }}x {{ article.product }} </span
              ><br />
            </div>
            <div
              v-if="selectedArticles[findIndex(article)]"
              class="#w-250 #text-[12px] intercept-filter"
            >
              <SelectForIntercept
                :key="article.id"
                v-model="selectedArticles.find((selected) => selected.id === article.id).station"
                :options="stations"
                class="#h-full"
              />
            </div>
            <div></div>
            <div v-if="selectedArticles[findIndex(article)]" class="#w-100">
              <textarea
                v-model="selectedArticles[findIndex(article)].instruction"
                class="#block #w-full #resize-y #min-h-[52px] #rounded #border #border-[#d4d4d4] #border-solid #px-2 #py-1.5 #text-gray-900 focus:#border-brightcyan focus:#shadow-focus focus:#shadow-brightcyan focus-visible:#outline-none #placeholder:text-gray-400 #sm:text-sm #sm:leading-6"
              >
              </textarea>
            </div>
            <div></div>
            <div
              v-if="selectedArticles[findIndex(article)]"
              class="#w-100 select2-selection__rendered #min-h-8 intercept-filter"
            >
              <SelectForIntercept
                v-model="selectedArticles.find((selected) => selected.id === article.id).level"
                :options="levels"
                :multiple="false"
                class="#h-full"
              />
            </div>
            <div></div>
            <div
              v-if="
                selectedArticles.length > 0 &&
                article.id == selectedArticles[0].id &&
                isMultipleSelected
              "
              title="Kopieer naar alle geselecteerde artikelen"
            >
              <svg-use
                id="copy"
                type="solid"
                class="#h-[23px] #w-[23px]"
                @click="copyToAll(selectedArticles[0])"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!successMessage && !complete"
        class="#grid #grid-cols-[auto_200px_250px_160px] #pt-5 ;"
      >
        <span />
        <span />

        <span />
        <button
          v-if="!complete"
          class="#m-1 #grid-flow-row #w-40 #h-10 #flex-1 #transition-all #box-border #text-white #text-[13px] #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer hover:#border-0 #bg-brightcyan enabled:hover:#bg-[#31b0dc] disabled:#opacity-25"
          :disabled="!ready"
          @click="Complete"
        >
          Opslaan
        </button>
      </div>
      <div
        v-if="complete && !successMessage"
        class="#grid #grid-cols-[auto_120px_120px_180px] #pt-5"
      >
        <span />
        <span />
        <span />
        <button
          disabled
          class="#m-1 #grid-flow-row #w-40 #h-10 #flex-1 #transition-all #box-border #text-white #text-[13px] #font-medium #px-1 #py-1 #rounded-md #border-0 #bg-brightcyan disabled:#opacity-25"
        >
          <svg-use
            id="spinner"
            type="solid"
            class="#h-[23px] #w-[23px] #fill-[#31b0dc] #animate-spin"
          />
          Opslaan
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import SvgUse from "../../vue3/components/SvgUse.vue";
import SelectForIntercept from "../components/SelectForIntercept.vue";
import { mapGetters } from "vuex";

const initialState = () => {
  return {
    article_id: null,
    order_id: null,
    page: 0,
    type: null,
    level: null,
    open: false,
    intercepts: [],
    articles: [],
    complete: false,
    selectedNumbers: null,
    selectedArticles: [],
    selectedArticleIds: [],
    successMessage: "",
    selecttext: "Selecteer alles",
    levels: [],
    stations: [],
  };
};

export default {
  components: {
    Modal,
    SvgUse,
    SelectForIntercept,
  },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters(["session", "route"]),
    //Als er een reden is geselecteerd en een aantal is ingevuld kan je op in opdracht klikken
    ready() {
      if (this.complete) {
        return false;
      }
      if (
        this.selectedArticles.length > 0 &&
        this.selectedArticles.every(
          (article) =>
            article.station &&
            article.station.length > 0 &&
            article.instruction &&
            article.instruction.length > 0 &&
            article.level &&
            article.level.length > 0,
        )
      ) {
        return true;
      }
      if (this.selectedArticles.length == 0) {
        return true;
      }

      return false;
    },
    isMultipleSelected() {
      if (
        this.selectedArticles[0].station.length > 0 &&
        this.selectedArticles[0].level.length &&
        this.selectedArticles[0].instruction != "" &&
        this.selectedArticles.length > 0
      ) {
        return true;
      }
      return false;
    },
    loading() {
      return this.intercepts.length === 0;
    },
    //For Reach om 1 categorie terug te krijgen voor de kopje boven de klachten
    uniqueCategories() {
      const categories = new Set();
      this.intercepts.forEach((interceptt) => {
        categories.add(interceptt.category);
      });
      return Array.from(categories);
    },
  },

  //Zodra de vue wordt geopend laad hij de data in
  created() {
    this.emitter.on("intercept", (intercept) => {
      let reload = false;
      if (this.order_id && intercept.order_id && this.order_id !== intercept.order_id) {
        reload = true;
      }
      if (this.article_id && intercept.article_id && this.article_id !== intercept.article_id) {
        reload = true;
      }
      if (this.customer_id && intercept.customer_id && this.customer_id !== intercept.customer_id) {
        reload = true;
      }
      if (this.type && intercept.type && this.type !== intercept.type) {
        reload = true;
      }
      if (this.page === 0) {
        reload = true;
      }

      if (reload) {
        this.reset();
        this.type = intercept.type;
        this.article_id = intercept.article_id;
        this.order_id = intercept.order_id;
        this.couponCode = this.order_id + "_SORRY";
        this.customer_id = intercept.customer_id;
        this.getStations();
        this.getLevels();
        this.getArticles(this.order_id);
      }

      this.open = true;
    });
  },
  beforeUnmount() {
    this.emitter.off("intercept");
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
    },
    back() {
      if (this.page !== 0) {
        return true;
      }

      return false;
    },
    Complete() {
      this.complete = true;
      this.Intercept();
    },

    selectAllArticles() {
      if (this.selectedArticles.length === this.articles.length) {
        this.selectedArticles = [];
        this.selecttext = "Selecteer alles";
      } else {
        this.selectedArticles = this.articles;
        this.selectedArticleIds = this.selectedArticles.map((article) => article.id);
        this.selecttext = "Deselecteer alles";
      }
    },
    findIndex(article) {
      return this.selectedArticles.findIndex(
        (selectedArticle) => selectedArticle.id === article.id,
      );
    },
    copyToAll(source) {
      if (source && source.station && source.instruction && source.level) {
        this.selectedArticles.forEach((selected) => {
          if (selected.id !== source.id) {
            selected.station = source.station;
            selected.instruction = source.instruction;
            selected.level = source.level;
          }
        });
      }
    },
    //Zorgt ervoor dat jem meerdere acties kan selecteren
    selectArticle(article) {
      // Check if the article is already in the selectedArticles array
      const articleIndex = this.selectedArticles.findIndex(
        (selectedArticle) => selectedArticle.id === article.id,
      );

      if (articleIndex > -1) {
        // Create a new array without the deselected article
        this.selectedArticles = this.selectedArticles.filter(
          (selectedArticle) => selectedArticle.id !== article.id,
        );
      } else {
        // Create a new array by adding the newly selected article
        this.selectedArticles = [...this.selectedArticles, article];
      }

      // Update the selectedArticleIds array accordingly
      this.selectedArticleIds = this.selectedArticles.map((article) => article.id);
    },
    initializeSelectedArticles() {
      // Controleer of articles leeg is
      if (!this.articles || this.articles.length === 0) {
        this.selectedArticles = [];
        return;
      }

      // Filter artikelen die al een station hebben en voeg ze toe aan selectedArticles
      this.selectedArticles = this.articles.filter((article) => {
        const hasStation =
          article.station !== null && article.station !== undefined && article.station.length > 0;
        return hasStation;
      });

      // Controleer de geselecteerde artikelen na filtering
      this.selectedArticleIds = this.selectedArticles.map((article) => article.id);
    },

    //Ophalen van de artikelen
    async getArticles(order_id, article_id) {
      const response = await fetch(this.route("intercept.articles"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          order_id: order_id,
          article_id: article_id,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.articles = await response.json();

      setTimeout(() => {
        this.initializeSelectedArticles();
      }, 1000);

      return;
    },
    //Ophalen van de Klacht soorten
    async getStations() {
      const response = await fetch(this.route("intercept.stations"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.stations = await response.json();
    },
    //Ophalen van de Klacht soorten
    async getLevels() {
      const response = await fetch(this.route("intercept.levels"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.levels = await response.json();
    },
    //Het terug in opdracht plaatsen
    async Intercept() {
      const response = await fetch(this.route("intercept.intercept"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          articles: this.selectedArticles,
          order_id: this.order_id,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.response = await response.json();

      this.successMessage = "Onderschepping opgeslagen";

      setTimeout(() => {
        this.reset();
        this.open = false;
        this.successMessage = "";
        location.reload();
      }, 2000);
    },
  },
};
</script>
<style>
.spin {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.intercept-filter.select2-container {
  width: 100% !important;
  min-width: 180px;
}

.intercept-filter .select-container .select2-container {
  width: 100% !important;
  min-width: 180px;
}

.intercept-filter .select-container .select2-selection {
  display: flex;
  align-items: center;
  border: 1px solid #d4d4d4;
  min-height: 50px;
  min-width: 180px;
  width: 100%;
  box-sizing: border-box;
}

.intercept-filter
  .select-container
  .select2-container
  .selection
  .select2-selection
  .select2-selection__rendered {
  min-height: 50px;
}

.select2-results {
  font-size: 12px;
}
</style>
