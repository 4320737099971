<template>
  <div class="tailwind">
    <div
      v-if="message"
      class="#p-4 #mb-4 #text-sm #text-green-800 #rounded-lg #bg-green-50"
      role="alert"
    >
      <span class="#font-medium">{{ message }}</span>
    </div>
    <div class="#flex #gap-x-4">
      <div class="">
        <label class="#block #text-sm #font-bold #text-gray-900">Feedback over</label>
        <SelectFilter v-model="body.employee" :options="employees" />
        <p v-if="errors['employee']" class="#text-red-600">{{ errors["employee"] }}</p>
      </div>
      <div class="#w-1/3">
        <label class="#block #text-sm #font-bold #text-gray-900">Soort feedback</label>
        <AdminDropdown v-model="body.feedbackType" :items="feedbackTypes"></AdminDropdown>
      </div>
      <div v-if="body.feedbackType === 'machine'" class="#w-1/3">
        <label class="#block #text-sm #font-bold #text-gray-900">Vaardigheden</label>
        <SelectFilter v-model="body.skill_id" :options="skills" />
        <p v-if="errors['skill_id']" class="#text-red-600">{{ errors["skill_id"] }}</p>
      </div>
    </div>
    <p v-if="errors['feedbackType']" class="#text-red-600">{{ errors["feedbackType"] }}</p>
    <div class="#mt-2">
      <div v-if="body.feedbackType === 'hr'">
        <p class="#block #text-sm #font-bold #text-gray-900">Punten</p>
        <div class="#flex #justify-start #items-start #gap-x-3">
          <div class="#cursor-pointer">
            <label
              for="minuspoints"
              class="#px-[8px] #py-[4px] #rounded-[3px] #text-[13px] #select-none #font-medium #inline-block #border-[1px] #border-solid #cursor-pointer #min-w-max hover:!#border-[1px] hover:!#border-b-[1px] hover:#border-solid hover:#opacity-100 #bg-inkdroplight #text-inkdropdark #border-transparent hover:#bg-[#dbdbdf] hover:#text-inkdropdark hover:#border-transparent"
              :class="{
                '#opacity-50': hrFeedback === 'positive',
                '!#bg-[#dbdbdf]': hrFeedback === 'negative',
              }"
            >
              <input id="minuspoints" v-model="hrFeedback" type="radio" value="negative" hidden />
              Aftrek
            </label>
          </div>
          <div class="#cursor-pointer">
            <label
              for="pluspoints"
              class="#px-[8px] #py-[4px] #rounded-[3px] #text-[13px] #select-none #font-medium #inline-block #border-[1px] #border-solid #cursor-pointer #min-w-max hover:!#border-[1px] hover:!#border-b-[1px] hover:#border-solid hover:#opacity-100 #bg-inkdroplight #text-inkdropdark #border-transparent hover:#bg-[#dbdbdf] hover:#text-inkdropdark hover:#border-transparent"
              :class="{
                '#opacity-50': hrFeedback === 'negative',
                '!#bg-[#dbdbdf]': hrFeedback === 'positive',
              }"
            >
              <input id="pluspoints" v-model="hrFeedback" type="radio" value="positive" hidden />
              Toevoegen
            </label>
          </div>
        </div>
      </div>
      <div v-if="body.feedbackType === 'hr'" class="#mt-2">
        <div v-if="hrFeedback === 'negative'">
          <div
            v-for="point in negativePerformancePoints"
            :key="point.id"
            class="#flex #items-center"
          >
            <input
              :id="`point_${point.id}`"
              v-model="body.performance_point_id"
              type="radio"
              name="points"
              :value="point.id"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @click="showDescription(point.id, 'negative')"
            />
            <label
              :for="`point_${point.id}`"
              class="#ml-1 #ms-2 #text-sm #font-medium #text-gray-900"
            >
              {{ point.title }} <b>({{ point.value }})</b>
            </label>
          </div>
        </div>
        <div v-if="hrFeedback === 'positive'">
          <div
            v-for="point in positivePerformancePoints"
            :key="point.id"
            class="#flex #items-center"
          >
            <input
              :id="`point_${point.id}`"
              v-model="body.performance_point_id"
              type="radio"
              name="points"
              :value="point.id"
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              @click="showDescription(point.id, 'positive')"
            />
            <label
              :for="`point_${point.id}`"
              class="#ml-1 #ms-2 #text-sm #font-medium #text-gray-900"
            >
              {{ point.title }} <b>(+{{ point.value }})</b>
            </label>
          </div>
        </div>
        <p v-if="errors['performance_point_id']" class="#text-red-600">
          {{ errors["performance_point_id"] }}
        </p>
      </div>
      <div v-if="body.feedbackType && body.feedbackType !== 'hr'">
        <label class="#text-sm #text-gray-900 #font-bold">Type feedback</label>
        <div class="#flex #items-center">
          <input
            id="positive"
            v-model="body.type"
            type="radio"
            value="1"
            name="type"
            class="#w-4 #h-4 #text-blue-600 #bg-gray-100 #border-gray-300 #focus:ring-blue-500 #focus:ring-2"
          />
          <label for="positive" class="#ml-1 #text-sm #font-medium #text-gray-900">Positief</label>
        </div>
        <div class="#flex #items-center">
          <input
            id="tip"
            v-model="body.type"
            type="radio"
            value="2"
            name="negative"
            class="#w-4 #h-4 #text-blue-600 #bg-gray-100 #border-gray-300 #focus:ring-blue-500 #focus:ring-2"
          />
          <label for="tip" class="#ml-1 #text-sm #font-medium #text-gray-900">Tip / advies</label>
        </div>
        <div class="#flex #items-center">
          <input
            id="negative"
            v-model="body.type"
            type="radio"
            value="0"
            name="type"
            class="#w-4 #h-4 #text-blue-600 #bg-gray-100 #border-gray-300 #focus:ring-blue-500 #focus:ring-2"
          />
          <label for="negative" class="#ml-1 #text-sm #font-medium #text-gray-900">Negatief</label>
        </div>
        <p v-if="errors['type']" class="#text-red-600">{{ errors["type"] }}</p>
      </div>
      <div v-if="body.feedbackType" class="#mt-2 #mb-2 #w-1/2">
        <label class="#block #mb-2 #text-sm #font-bold #text-gray-900"> Beschrijving </label>
        <textarea
          id="message"
          v-model="body.description"
          rows="4"
          class="#block #p-2.5 #w-full #text-sm #text-gray-900 #bg-gray-50 #rounded-lg #border #border-gray-300 #focus:ring-blue-500 #focus:border-blue-500"
        ></textarea>
        <p v-if="errors['description']" class="#text-red-600">{{ errors["description"] }}</p>
      </div>
      <AdminButton @click="submitForm">Opslaan</AdminButton>
    </div>
  </div>
</template>

<script>
import SelectFilter from "../components/SelectFilter.vue";
import AdminButton from "../components/AdminButton.vue";
import AdminDropdown from "../components/AdminDropdown.vue";
import { mapGetters } from "vuex";

export default {
  components: { SelectFilter, AdminButton, AdminDropdown },
  data() {
    return {
      body: {
        description: "",
        employee: [],
        feedbackType: "",
        performance_point_id: null,
        type: "",
      },
      feedbackTypes: {
        external: "Gewone feedback",
      },
      hrFeedback: null,
      errors: {},
      employees: [],
      message: "",
      negativePerformancePoints: [],
      positivePerformancePoints: [],
      skills: [],
    };
  },
  computed: {
    ...mapGetters(["route", "isAdminLevel"]),
  },
  watch: {
    "body.feedbackType"() {
      if (!this.body.feedbackType === "hr") {
        this.body.performance_point_id = [];
      }
    },
    "body.skill_id"() {
      this.setEmployees();
    },
  },
  async mounted() {
    await this.getEmployees();
    await this.fetchData();
    this.getSkills();
    const urlParams = new URLSearchParams(window.location.search);

    const quickaction = urlParams.get("quickaction");
    const employee = urlParams.get("employee");

    if (quickaction === "late" && employee) {
      this.body.feedbackType = "hr";
      this.body.employee = [employee];
      this.hrFeedback = "negative";

      const point = this.negativePerformancePoints.find((i) => i.title === "Te laat komen");
      if (point) {
        this.body.performance_point_id = point.id;
      }
    }
    if (quickaction === "overtime" && employee) {
      this.body.feedbackType = "hr";
      this.body.employee = [employee];
      this.hrFeedback = "positive";

      const point = this.positivePerformancePoints.find(
        (i) => i.title === "Meer dan een uur overgewerkt",
      );
      if (point) {
        this.body.performance_point_id = point.id;
      }
    }

    if (this.isAdminLevel(4, 5)) {
      this.feedbackTypes["internal"] = "Interne feedback";
    }
    if (this.isAdminLevel(4, 5, 6)) {
      this.feedbackTypes["hr"] = "HR";
      this.feedbackTypes["machine"] = "Machine";
    }
  },
  methods: {
    async fetchData() {
      let response = await fetch(this.route("feedback.create"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      response = await response.json();

      if (response.success) {
        this.positivePerformancePoints = response.positive_performance_points;
        this.negativePerformancePoints = response.negative_performance_points;
      }
    },
    async getSkills() {
      const response = await fetch(this.route("skills.index"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const skills = await response.json();

        skills.forEach((skill) => {
          this.skills.push({ id: skill.id, value: skill.title });
        });
      } else if (!response.ok) {
        await response.text();
      }
    },
    async getEmployees() {
      const response = await fetch(this.route("employees.index"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const employees = await response.json();

        employees.forEach((employee) => {
          this.employees.push({ id: employee.id, value: employee.firstname });
        });
      } else if (!response.ok) {
        await response.text();
      }
    },
    async setEmployees() {
      const response = await fetch(this.route("employees.index"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(this.body),
      });

      if (response.ok) {
        const employees = await response.json();
        this.body.employee = employees.map((employee) => employee.id);
      } else if (!response.ok) {
        await response.text();
      }
    },
    async submitForm() {
      this.message = "";
      this.errors = {};

      let response = await fetch(this.route("feedback.store"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(this.body),
      });

      response = await response.json();

      if (response.success) {
        this.body = {};

        this.message = response.message;
      } else if (response.errors) {
        for (const [key, value] of Object.entries(response.errors)) {
          this.errors[key] = value[0];
        }
      }
    },
    showDescription(pointId, type) {
      const points =
        type === "positive" ? this.positivePerformancePoints : this.negativePerformancePoints;
      const sentence = points.find((point) => point.id === pointId).sentence;

      this.body.description = sentence;
    },
  },
};
</script>
