<template>
  <div>
    <input
      :id="id"
      type="radio"
      :value="value"
      :checked="modelValue === value"
      @click="handleFilter"
    />
    <label :for="id" class="label-align-middle #text-xs">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  methods: {
    handleFilter(e) {
      if (this.modelValue === this.value) {
        this.$emit("update:modelValue", "");
      } else {
        this.$emit("update:modelValue", e.target.value);
      }
    },
  },
};
</script>
