<template>
  <input v-model="date" type="date" class="#rounded #p-1 #border #border-black" />
  <table id="overdue" class="display" style="width: 100%">
    <thead>
      <tr>
        <th>Opdracht</th>
        <th>Bedrijf</th>
        <th>Naam</th>
        <th>Telefoonnummer</th>
        <th>Levermethode</th>
        <th>Leveroptie</th>
        <th>Adres</th>
        <th>Bedrag</th>
        <th>Gereed op</th>
        <th>Status</th>
        <th>Substatus</th>
      </tr>
    </thead>
  </table>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      table: null,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    date: {
      handler() {
        this.table.ajax.reload();
      },
      immediate: false,
    },
  },
  beforeUnmount() {
    this.table.destroy();
    this.table = null;
  },
  mounted() {
    this.buildTable();
  },
  methods: {
    buildTable() {
      this.table = $("#overdue").DataTable({
        ajax: {
          url: this.route("production.statistics.overdue"),
          data: (d) => {
            d["date"] = this.date;
          },
        },
        select: false,
        pageLength: 25,
        columns: [
          {
            data: "id",
            render: (data) => {
              return `<a href="/admin.php?page=orders&sub=detail&id=${data}" target="_blank" rel="noopener noreferrer" class="link">${data}</a>`;
            },
          },
          { data: "customer.company" },
          { data: "customer.name_contact" },
          { data: "customer.telephone" },
          { data: "sending.delivery_method" },
          { data: "sending.delivery_option" },
          { data: "sending.address" },
          {
            data: "amount",
            render: (data) => {
              return `€${data}`;
            },
          },
          { data: "internal_delivery_datetime" },
          { data: "status" },
          { data: "sub_status" },
        ],
      });
    },
  },
};
</script>
