export default {
  computed: {
    isRangeVisible() {
      return ["!between", "between"].includes(this.selectedCondition);
    },
    isSingleVisible() {
      return ["=", "!=", "<", "<=", ">=", ">"].includes(this.selectedCondition);
    },
  },
  methods: {
    validateFields() {
      this.error = { text: "", type: "" };

      if (!this.number.from && this.number.to) {
        this.error = { text: "Vanaf is verplicht.", type: "info" };
      }

      if (this.number.from && !this.number.to) {
        this.error = { text: "Tot is verplicht.", type: "info" };
      }

      if (["price", "integer", "zipcode"].includes(this.fieldType)) {
        if (this.number.from && this.number.to) {
          if (this.number.from > this.number.to) {
            this.error = { text: "Vanaf kan niet groter zijn dan tot.", type: "info" };
          }

          if (this.number.from === this.number.to) {
            this.error = { text: "Vanaf kan niet hetzelfde zijn als tot.", type: "info" };
          }
        }
      }

      if (this.fieldType === "price") {
        this.validatePrice();
      } else if (this.fieldType === "zipcode") {
        this.validateZipcode();
      }

      return !this.error.text && !this.error.type;
    },
    validatePrice() {
      const priceRegex = /^(\d+([,.]\d{1,2})?)$/;
      const single = this.number.single;
      const from = this.number.from;
      const to = this.number.to;

      if (!single && !from && !to) {
        this.error = { text: "Prijs is verplicht.", type: "warning" };
      }

      if (single && !priceRegex.test(single)) {
        this.error = { text: "Vul een geldige prijs in.", type: "warning" };
      }

      if (from && !priceRegex.test(from)) {
        this.error = { text: "Vul een geldige vanaf prijs in.", type: "warning" };
      }

      if (to && !priceRegex.test(to)) {
        this.error = { text: "Vul een geldige tot prijs in.", type: "warning" };
      }
    },
    validateDate() {
      this.error = { text: "", type: "" };

      if (!this.unix.from && this.unix.to) {
        this.error = { text: "Vanaf is verplicht.", type: "info" };
      }

      if (this.unix.from && !this.unix.to) {
        this.error = { text: "Tot is verplicht.", type: "info" };
      }

      if (this.unix.from && this.unix.to) {
        if (this.unix.from > this.unix.to) {
          this.error = { text: "Vanaf kan niet groter zijn dan tot.", type: "info" };
        }

        if (this.unix.from === this.unix.to) {
          this.error = { text: "Vanaf kan niet hetzelfde zijn als tot.", type: "info" };
        }
      }

      return !this.error.text && !this.error.type;
    },
    validateZipcode() {
      const single = this.number.single;
      const from = this.number.from;
      const to = this.number.to;

      if (single && single.toString().length < 4) {
        this.error = { text: "Voer een geldige postcode in", type: "info" };
      }

      if (from && from.toString().length < 4) {
        this.error = { text: "Voer een geldige vanaf postcode in", type: "info" };
      }

      if (to && to.toString().length < 4) {
        this.error = { text: "Voer een geldige tot postcode in", type: "info" };
      }
    },
  },
};
