<template>
  <div :class="s.control">
    <IconButton
      v-if="admin"
      icon="bars-staggered"
      title="Abonneren wachtrij updates"
      :disabled="loading"
      @click="queues"
    />
    <IconButton
      v-if="admin"
      icon="file"
      title="Abonneren lade updates"
      :disabled="loading"
      @click="resources"
    />
    <IconButton
      v-if="admin"
      icon="bell"
      title="Abonneren status updates"
      :disabled="loading"
      @click="status"
    />
    <IconButton
      v-if="admin"
      icon="bell-slash"
      title="Afmelden van alle updates"
      :disabled="loading"
      @click="unsubscribe"
    />
    <IconButton
      icon="arrows-rotate"
      title="Refesh lades en queues"
      :disabled="loading"
      @click="refresh"
    />
  </div>
  <div :class="s.topContainer">
    <div>
      <h2>Lades</h2>
      <PrinterTrays :id="id" />
    </div>
    <div :class="s.notificationContainer">
      <h2>Meldingen</h2>
      <PrinterNotifications :id="id" />
    </div>
  </div>
  <h2>Jobs</h2>
  <PrinterJobs :id="id" />
</template>

<script>
import { mapGetters } from "vuex";
import PrinterTrays from "../components/PrinterTrays.vue";
import PrinterJobs from "../components/PrinterJobs.vue";
import PrinterNotifications from "../components/PrinterNotifications.vue";
import IconButton from "../components/IconButton.vue";
export default {
  components: {
    PrinterTrays,
    PrinterJobs,
    PrinterNotifications,
    IconButton,
  },
  data() {
    return {
      id: null,
      loading: false,
      admin: false,
    };
  },
  created() {
    this.id = parseInt(this.session("printers_overview_printer"));
    this.admin = ["jelle", "marijn"].includes(this.session("username").toLowerCase());
  },
  methods: {
    async queues() {
      this.loading = true;

      const response = await fetch(this.route("printers.subscribe", { printer: this.id }), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          type: "queues",
        }),
      });

      if (response.ok) {
        console.log(await response.json());
        this.loading = false;
      } else {
        await response.text();
      }
    },
    async resources() {
      this.loading = true;

      const response = await fetch(this.route("printers.subscribe", { printer: this.id }), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          type: "resources",
        }),
      });

      if (response.ok) {
        console.log(await response.json());
        this.loading = false;
      } else {
        await response.text();
      }
    },
    async status() {
      this.loading = true;

      const response = await fetch(this.route("printers.subscribe", { printer: this.id }), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          type: "status",
        }),
      });

      if (response.ok) {
        console.log(await response.json());
        this.loading = false;
      } else {
        await response.text();
      }
    },
    async unsubscribe() {
      this.loading = true;

      const response = await fetch(this.route("printers.unsubscribe", { printer: this.id }), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      });

      if (response.ok) {
        console.log(await response.json());
        this.loading = false;
      } else {
        await response.text();
      }
    },
    async refresh() {
      this.loading = true;
      const response = await fetch(this.route("printers.load", { printer: this.id }), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      });

      if (response.ok) {
        console.log(await response.json());
        this.loading = false;
      } else {
        await response.text();
      }
    },
  },
  computed: {
    ...mapGetters(["session", "route"]),
  },
};
</script>

<style module="s">
.topContainer {
  display: flex;
  gap: 14px;
  margin-top: -28px;
}

.notificationContainer {
  flex: 1;
}

.control {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
</style>
