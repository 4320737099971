<template>
  <div class="tailwind">
    <div class="#relative #overflow-x-auto #shadow-md #sm:rounded-lg #mb-4">
      <table
        v-if="isAdminLevel(4, 5)"
        class="#w-full #text-sm #text-left #rtl:text-right #text-gray-500"
      >
        <thead class="#text-xs #text-gray-700 #bg-gray-50">
          <tr>
            <th scope="row" class="#pl-2 #py-4 #font-bold #text-gray-900 #whitespace-nowrap">
              Maanden
            </th>
            <th v-for="item of revenue" :key="item" scope="col" class="#px-6 #py-3">
              {{ toMonth(item.month) }} {{ item.year }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="#odd:bg-white #odd:dark:bg-gray-900 #even:bg-gray-50 #even:dark:bg-gray-800 #border-b"
          >
            <th scope="row" class="#pl-2 #py-4 #font-bold #text-gray-900 #whitespace-nowrap">
              Omzet
            </th>
            <td v-for="item of revenue" :key="item" class="#px-6 #py-4">
              € {{ parseFloat(item.revenue).toLocaleString("nl-NL", { minimumFractionDigits: 2 }) }}
            </td>
          </tr>
          <tr
            class="#odd:bg-white #odd:dark:bg-gray-900 #even:bg-gray-50 #even:dark:bg-gray-800 #border-b"
          >
            <th scope="row" class="#pl-2 #py-4 #font-bold #text-gray-900 #whitespace-nowrap">
              Percentage fouten in verhouding tot omzet
            </th>
            <td v-for="item of revenue" :key="item" class="#px-6 #py-4">
              {{ item.percentage }}
            </td>
          </tr>
          <tr
            class="#odd:bg-white #odd:dark:bg-gray-900 #even:bg-gray-50 #even:dark:bg-gray-800 #border-b"
          >
            <th scope="row" class="#pl-2 #py-4 #font-bold #text-gray-900 #whitespace-nowrap">
              Fouten in verhouding tot {{ group === "articles" ? "artikelen" : "opdrachten" }}
            </th>
            <td v-for="item of revenue" :key="item" class="#px-6 #py-4">
              {{ item.mistakeCount }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <Line id="mistakes-month" :options="chartOptions" :data="chartData" />
</template>
<script>
import { Line } from "vue-chartjs";
import { mapGetters } from "vuex";

export default {
  components: { Line },
  props: {
    data: {
      type: Object,
      required: true,
    },
    group: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    revenue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      datasets: [],
    };
  },
  computed: {
    ...mapGetters(["isAdminLevel"]),
    chartData() {
      const data = this.setChartData();

      return {
        labels: data.labels,
        datasets: data.dataset,
      };
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: this.mode === "percentage",
          },
          tooltip: this.setTooltip(),
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Maanden",
            },
          },
          y: {
            title: {
              display: true,
              text: this.toMode(),
            },
          },
        },
      };
    },
  },
  methods: {
    toMonth(month) {
      const months = [
        "Januari",
        "Februari",
        "Maart",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Augustus",
        "September",
        "Oktober",
        "November",
        "December",
      ];

      return months[month - 1];
    },
    toMode() {
      if (this.mode === "amount") {
        return "Aantal";
      } else if (this.mode === "costs") {
        return "Schade";
      } else if (this.mode === "percentage") {
        return "Percentage";
      }
    },
    setTooltip() {
      return {
        boxPadding: 4,
        callbacks: {
          label: (item) => {
            const data = this.datasets[item.datasetIndex].raw[item.dataIndex];

            if (item.datasetIndex === 0) {
              return [
                `Aantal: ${data.amount}`,
                `Schade: €${data.costs}`,
                `Fouten percentage van totale bestellingen: ${data.percentage}%`,
              ];
            } else if (item.datasetIndex === 1) {
              return [`Schade percentage van de maandelijkse omzet: ${data.percentage}`];
            }
          },
        },
      };
    },
    setChartData() {
      const labels = Object.keys(this.data);
      const datasets = [];

      const data = Object.values(this.data).map((item) => {
        if (this.mode === "amount") {
          return item.amount;
        } else if (this.mode === "costs") {
          return item.costs;
        } else if (this.mode === "percentage") {
          return item.percentage;
        }
      });

      datasets.push({
        label: "Fouten percentage van totale bestellingen",
        data: data,
        raw: Object.values(this.data).map((item) => ({
          amount: item.amount,
          costs: item.costs,
          percentage: item.percentage,
        })),
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      });

      if (this.mode === "percentage") {
        datasets.push({
          label: "Schade percentage van de maandelijkse omzet",
          data: this.revenue.map((entry) => parseFloat(entry.percentage)),
          raw: Object.values(this.revenue).map((item) => ({ percentage: item.percentage })),
          borderColor: "black",
          tension: 0.1,
        });
      }

      this.datasets = datasets;

      return { dataset: datasets, labels: labels };
    },
  },
};
</script>
