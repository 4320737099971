<template>
  <Modal :open="open" :width="1030" @close="reset">
    <BackInOrder v-if="article_id" type="finished" :article-i-d="article_id" :grid-position-id="grid_position_id"  @done="reset" />
  </Modal>
</template>

<script>
import Modal from "../components/Modal.vue";
import BackInOrder from "./BackInOrder.vue";
import { mapGetters } from "vuex";

const initialState = () => {
  return {
    article_id: null,
    grid_position_id: null,
    open: false,
  };
};

export default {
  components: {
    Modal,
    BackInOrder,
  },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters(["route"]),
  },

  //Zodra de vue wordt geopend laad hij de data in
  created() {
    this.emitter.on("back-in-order", (backinorder) => {
      this.article_id = backinorder.article_id;
      this.grid_position_id = backinorder.grid_position;
      this.open = true;
    });
  },
  beforeUnmount() {
    this.emitter.off("back-in-order");
  },
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
    },
  },
};
</script>
