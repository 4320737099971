<template>
  <h1>Afgerond</h1>
  <div class="tailwind">
    <div v-if="finishedPercentage" class="#w-full">
      <div
        class="#h-[2vw] #mx-auto #flex #align-middle #mb-[1vw] #border-solid #border-2 #border-black #rounded-md #overflow-hidden"
      >
        <div
          class="#flex #justify-center #items-center #h-full #bg-[#2CD11A]"
          :style="{ width: finishedPercentage + '%' }"
        >
          <p>{{ articlesFinished }} / {{ articlesTotal }}</p>
        </div>
        <div class="#flex #justify-center #items-center #h-full #grow #bg-[#EEA72E]"></div>
      </div>
    </div>
    <div v-else class="#w-full">
      <div
        class="#h-[2vw] #mx-auto #flex #align-middle #mb-[1vw] #border-solid #border-2 #border-white #rounded-md #overflow-hidden"
      ></div>
    </div>
    <div class="#flex #gap-x-6">
      <div class="#w-[450px]">
        <div v-if="articleFinished">
          <div
            class="#cursor-pointer #w-full #overflow-hidden #mb-5 #rounded-lg #bg-[#cbcbcb] #shadow-md #ring-1 #ring-black #ring-opacity-5"
            @click="backInOrder"
          >
            <div class="#p-4">
              <div class="#flex #items-center">
                <svg-use
                  id="arrow-rotate-right"
                  type="regular"
                  class="#h-[2em] #w-[17px] #fill-black"
                />
                <div class="#ml-3 #w-0 #flex-1 #pt-0.5">
                  <p class="#text-lg #font-medium #text-gray-900">
                    Artikel {{ lastArticleID }} terug in opdracht
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
    v-for="(error, index) in errors"
    :key="index"
    class="#pointer-events-auto #w-full #overflow-hidden #mb-5 #rounded-lg #bg-white #shadow-md #ring-1 #ring-black #ring-opacity-5"
  >
    <div class="#p-4">
      <div class="#flex #items-center">
        <svg-use
          id="triangle-exclamation"
          type="regular"
          class="#h-[23px] #w-[23px] #fill-[#E7A11C]"
        />
        <div class="#ml-3 #w-0 #flex-1">
          <p class="#text-lg #font-medium #text-gray-900" v-html="error.text"></p>
        </div>
      </div>
    </div>
  </div>

        <div
  v-if="intercept && intercept.article_id"
  class="#pointer-events-auto #w-full #overflow-hidden #mb-5 #rounded-lg #bg-white #shadow-md #ring-1 #ring-black #ring-opacity-5"
>
  <div class="#p-4">
    <div class="#flex #items-center">
      <svg-use
        id="nfc"
        type="regular"
        class="#h-[23px] #w-[23px] #fill-black"
      />
      <div class="#ml-3 #w-0 #flex-1">
        <p class="#text-lg #font-medium #text-gray-900" v-html="interceptMessage"></p>
      </div>
    </div>
  </div>
</div>

<div v-else>

  <div
    v-for="(notification, index) in notifications"
    :key="index"
    class="#pointer-events-auto #w-full #overflow-hidden #mb-5 #rounded-lg #bg-[white] #shadow-md #ring-1 #ring-black #ring-opacity-5"
  >
    <div class="#p-4">
      <div class="#flex #items-center">
        <svg-use
          v-if="notification.text.includes('Gebruik <strong>vak')"
          id="arrow-down-to-bracket"
          type="regular"
          class="#h-[23px] #w-[23px] #fill-black"
        />
        <svg-use
          v-else-if="notification.text.includes('Haal artikelen uit')"
          id="arrow-up-from-bracket"
          type="regular"
          class="#h-[23px] #w-[23px] #fill-black"
        />
        <svg-use
          v-else
          id="check"
          type="regular"
          class="#h-[23px] #w-[23px] #fill-green-500"
        />
        <div class="#ml-3 #w-[360px] #flex">
          <p class="#text-lg #font-medium #text-gray-900" v-html="notification.text"></p>
        </div>
      </div>
    </div>
  </div>

  <div
          v-for="(warning, index) in warnings"
          :key="index"
          class="#pointer-events-auto #w-full #overflow-hidden #mb-5 #rounded-lg #bg-white #shadow-md #ring-1 #ring-black #ring-opacity-5"
        >
          <div
            class="#p-4"
            :class="{ check: warning.checked, '#cursor-pointer #group': !warning.checked }"
            @click="handleWarningClick(warning)"
          >
            <div class="#flex #items-center">
              <svg-use
                id="triangle-exclamation"
                type="regular"
                class="#h-[23px] #w-[23px] #fill-[#E7A11C]"
              />
              <div class="#ml-3 #w-0 #flex-1">
                <p class="#text-lg #font-medium #text-gray-900" v-html="warning.text"></p>
              </div>
              <div v-if="!articleFinished" class="#p-5 #ml-3 #flex">
                <svg-use
                  v-if="allWarningsChecked"
                  id="spinner"
                  type="solid"
                  class="#h-[23px] #w-[23px] #fill-[#31b0dc] #animate-spin"
                />
                <svg-use
                  v-else-if="warning.checked === true"
                  id="check"
                  type="regular"
                  class="#h-[23px] #w-[23px] #fill-green-500"
                />
                <svg-use
                  v-else-if="warning.checked === false"
                  id="xmark"
                  type="regular"
                  class="#h-[23px] #w-[23px] #fill-red-500 group-hover:#fill-red-800"
                />
              </div>
            </div>
          </div>
  </div>

</div>
        <div
          v-if="scanningArticle"
          class="#pointer-events-auto #w-full #overflow-hidden #mb-5 #rounded-lg #bg-white #shadow-md #ring-1 #ring-black #ring-opacity-5"
        >
          <div class="#p-4">
            <div class="#flex #items-center">
              <svg-use
                id="triangle-exclamation"
                type="regular"
                class="#h-[23px] #w-[23px] #fill-[#E7A11C]"
              />
              <div class="#ml-3 #w-0 #flex-1">
                <p class="#text-lg #font-medium #text-gray-900">
                  Artikel {{ articleID }} wordt verwerkt
                </p>
              </div>
              <div class="#ml-3 #w-[20px] #flex">
                <svg-use
                  id="spinner"
                  type="solid"
                  class="#h-[23px] #w-[23px] #fill-[#31b0dc] #animate-spin"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div>
        <div class="#w-[675px] #h-[520px] #relative #flex #justify-center #overflow-hidden">
          <template v-if="frozen">
            <div class="#flex #flex-1 #justify-center #flex-col #items-center #bg-red-500">
              <p class="#text-xl #font-bold #text-white">Webcam vastgelopen</p>
              <a
                class="#text-xl #font-bold #text-white #cursor-pointer hover:#underline"
                @click="reloadPage"
              >
                Klik hier om de pagina te herladen
              </a>
            </div>
          </template>
          <template v-else-if="!webcamFound">
            <div class="#flex #flex-1 #justify-center #flex-col #items-center #bg-orange-500">
              <p class="#text-xl #font-bold #text-white">
                Webcam niet gevonden, controleer aansluiting en
              </p>
              <a
                class="#text-xl #font-bold #text-white #cursor-pointer hover:#underline"
                @click="reloadPage"
              >
                klik hier om de pagina te herladen
              </a>
            </div>
          </template>
          <template v-else>
            <video
              id="webcamVideo"
              ref="webcamVideo"
              class="#bg:#636363 #rotate-180 #max-w-none #h-full #relative"
              autoplay
            ></video>
            <div id="image_container" :class="imageContainerClass">
              <canvas id="webcamCanvas" ref="webcamCanvas" class="#w-[170px] #h-[150px]"></canvas>
            </div>
          </template>
        </div>
        <div class="#w-[500px]">
          <div class="#sm:p-6">
            <h3 class="#text-sm #font-semibold #leading-6 #text-gray-900">Print verzendlabel</h3>
            <div>
              <div class="#mt-2 #mb-2 #flex">
                <input
                  v-model="orderID"
                  data-lpignore="true"
                  autocomplete="off"
                  placeholder="Opdrachtnummer:"
                  :disabled="disabled"
                  :class="InputStyle()"
                />
                <button
                  :disabled="disabled"
                  type="submit"
                  :class="BtnStyle()"
                  @click="handleOrderSubmitClick"
                >
                  Print
                </button>
              </div>
            </div>
          </div>
          <div class="#sm:p-6">
            <h3 class="#text-sm #font-semibold #leading-6 #text-gray-900">
              Wijzig status (+ evt. foto)
            </h3>
            <div>
              <div class="#mt-2 #mb-2 #flex">
                <input
                  v-model="articleID"
                  data-lpignore="true"
                  autocomplete="off"
                  placeholder="Artikelnummer:"
                  :disabled="disabled"
                  :class="InputStyle()"
                />
                <button :disabled="disabled" :class="BtnStyle()" @click="handleArticleSubmitClick">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgUse from "../../vue3/components/SvgUse.vue";

const initialState = () => {
  return {
    articleID: null,
    orderID: null,
    lastArticleID: null,
    lastGridPosition: null,
    scanningArticle: false,
    showOverlay: false,
    finishedPercentage: null,
    articlesTotal: null,
    articlesFinished: null,
    articleFinished: false,
    intercept: [],
    errors: [],
    notifications: [],
    warnings: [],
    interceptMessage: null
  };
};

export default {
  components: {
    SvgUse,
  },
  emits: ["back-in-order"],

  data() {
    return {
      ...initialState(),
      stream: null,
      streamInterval: null,
      frameCounter: 0,
      frozen: false,
      webcamFound: true,
    };
  },
  computed: {
    ...mapGetters(["route"]),
    imageContainerClass() {
      return {
        "#absolute": true,
        "#bottom-0": true,
        "#right-0": true,
        "#m-4": true,
        "#border-2": true,
        "#border-solid": true,
        "#border-gray-400": true,
        "#border-opacity-25": true,
        "#hidden": !this.showOverlay,
      };
    },
    allWarningsChecked() {
      if (this.warnings.length === 0) {
        return true;
      }

      return this.warnings.every((warning) => warning.checked);
    },
    disabled() {
      if (this.scanningArticle) {
        return true;
      }
      if (!this.allWarningsChecked) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    this.initializeWebcam();

    window.onArticleScan = (value) => {
      this.articleID = value;

      this.handleArticleSubmitClick();
    };
    window.onRfidScan = (value) => {
      this.rfid_csn = value;
      if((this.intercept && this.intercept.article_id)) {

        this.handleArticleSubmitClick();
      }

    };

    window.onOrderScan = (value) => {
      this.orderID = value;
      this.handleOrderSubmitClick();
    };
    window.onAccessoryScan = (value) => {
      for (let i = 0; i < this.warnings.length; i++) {
        if (this.warnings[i].barcode === value) {
          console.log("Barcode match found:", value);
          this.warnings[i].checked = true;
          this.handleArticleSubmitClick();
          break;
        }
      }
    };
  },
  beforeUnmount() {
    if (this.streamInterval) {
      clearInterval(this.streamInterval);
    }
  },
  methods: {
    playAudio(src) {
      const audio = new Audio(src);
      audio.play();
    },
    reset() {
      Object.assign(this.$data, initialState());
    },
    reloadPage() {
      window.location.reload(true);
    },
    BtnStyle() {
      return "#ml-2 #w-20 #h-8 #transition-all #box-border #text-white #text-[13px] #font-medium  #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer hover:#border-0 #bg-brightcyan enabled:hover:#bg-[#31b0dc] disabled:#opacity-2";
    },
    InputStyle() {
      return "#block #pl-2 #h-8  #rounded-md #border-0 #py-1.5 #text-gray-900 #shadow-sm #ring-1 #ring-inset #ring-gray-300 #placeholder:text-gray-400 #focus:ring-2 #focus:ring-inset #focus:ring-indigo-600 #text-[14px] sm:leading-6";
    },
    shouldAnimate(warning) {
      return (
        this.warnings.length > 1 &&
        warning.checked === false &&
        warning.checked !== warning.previousState
      );
    },
    handleWarningClick(warning) {
      warning.checked = !warning.checked;
      this.handleArticleSubmitClick();
    },
    backInOrder() {
      this.emitter.emit("back-in-order", {
        article_id: this.lastArticleID,
        type: "finished",
        grid_position: this.lastGridPosition,
      });
      this.emitter.on("articleFinishedReset", () => {
        this.reset();
      });
    },
    detectFrozenWebcam() {
      const deliveredFrames = this.stream.stats.deliveredFrames;
      if (deliveredFrames <= this.frameCounter) {
        clearInterval(this.streamInterval);
        this.frozen = true;
      } else {
        this.frameCounter = deliveredFrames;
      }
    },
    SnapWebcam() {
      const video = this.$refs.webcamVideo;
      const canvas = this.$refs.webcamCanvas;
      const cropAmount = (video.videoWidth - video.videoWidth / (4 / 3)) / 2;
      const sourceX = cropAmount;
      const sourceY = 0;
      const sourceWidth = video.videoWidth - 2 * cropAmount;
      const sourceHeight = video.videoHeight;

      canvas.width = sourceWidth;
      canvas.height = sourceHeight;

      const context = canvas.getContext("2d");
      context.setTransform(-1, 0, 0, -1, sourceWidth, sourceHeight);
      context.drawImage(
        video,
        sourceX,
        sourceY,
        sourceWidth,
        sourceHeight,
        0,
        0,
        canvas.width,
        canvas.height,
      );

      return canvas.toDataURL("image/webp");
    },
    PrintHtml(printURL, orderID) {
      console.log("print send doc van opdracht " + orderID);

      if (window.factory.printing) {
        window.factory.printing.printBackground = true;
        window.factory.printing.footer = "";
        window.factory.printing.header = "";
        window.factory.printing.leftMargin = 0;
        window.factory.printing.topMargin = 0;
        window.factory.printing.bottomMargin = 0;
        window.factory.printing.rightMargin = 0;
        window.factory.printing.SetPrintScale(97);
        window.factory.printing.printer = "Afrondtafel";
        console.log("start printing send doc with printer " + window.factory.printing.printer);
        window.factory.printing.PrintHTML(printURL);
        this.playAudio("/admin/sounds/correct-answer.mp3");
      } else {
        console.log("Geen label printer gevonden");
      }
    },

    showFlashingEffect() {
      this.$refs.webcamVideo.classList.add("flash-effect");
      setTimeout(() => {
        this.$refs.webcamVideo.classList.remove("flash-effect");
      }, 500);
    },
    async initializeWebcam() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { ideal: 3840 },
            height: { ideal: 2160 },
          },
          audio: false,
        });
        const video = this.$refs.webcamVideo;
        video.srcObject = stream;
        this.stream = stream.getVideoTracks()[0];
        this.streamInterval = setInterval(this.detectFrozenWebcam, 1000);
      } catch (error) {
        console.error("Error accessing webcam:", error);
        this.webcamFound = false;
      }
    },

    async handleArticleSubmitClick() {

      if (!this.articleID) {
        return;
      }
      if (!this.allWarningsChecked && !this.intercept) {
        return;
      }

      if (this.scanningArticle) {
        return;
      }

      this.scanningArticle = true;

      // build request body
      const requestBody = {
        articleID: this.articleID,
      };

      if (this.warnings.length > 0 && this.allWarningsChecked) {
        requestBody.override = true;
        requestBody.confirm = true;
      }

      if((this.intercept && this.intercept.article_id && this.rfid_csn) ) {
        requestBody.rfid_csn = this.rfid_csn;
      }

       // partial reset
      let initial = initialState();
      delete initial.articleID;
      delete initial.orderID;
      delete initial.lastArticleID;
      delete initial.lastGridPosition;
      delete initial.scanningArticle;
      Object.assign(this.$data, initial);

      let phase1Response = await fetch(this.route("article.finish.phase1"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!phase1Response.ok) {
        await phase1Response.text();

        return;
      }

      const phase1Data = await phase1Response.json();

      this.intercept = phase1Data.intercept;
      this.interceptMessage = phase1Data.interceptMessage;
      this.errors = phase1Data.errors;
      this.warnings = phase1Data.warnings.map((warning) => ({ ...warning, checked: false }));
      this.notifications = phase1Data.notifications;

      if (this.errors.length > 0 || this.warnings.length > 0 || (this.intercept && this.intercept.article_id) ) {
        this.playAudio("/admin/sounds/error.mp3");
      } else {
        if (
          phase1Data.printURL &&
          this.isLastArticle(1, phase1Data.articlesTotal, phase1Data.articlesFinished)
        ) {
          this.PrintHtml(phase1Data.printURL, phase1Data.orderID);
        }

        requestBody.image_data_url = this.SnapWebcam();

        let phase2Response = await fetch(this.route("article.finish.phase2"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!phase2Response.ok) {
          await phase2Response.text();

          return;
        }

        const phase2Data = await phase2Response.json();

        this.showFlashingEffect();
        this.showOverlay = true;
        this.notifications = phase2Data.notifications;
        this.finishedPercentage = phase2Data.finishedPercentage;
        this.articlesFinished = phase2Data.articlesFinished;
        this.articlesTotal = phase2Data.articlesTotal;
        this.lastGridPosition = phase2Data.lastGridPosition;
        this.lastArticleID = this.articleID;
        this.articleFinished = true;
        this.articleID = null;

        if (this.isLastArticle(2, this.articlesTotal, this.articlesFinished)) {
          this.playAudio("/admin/sounds/click-sound-effect_all.mp3");

          if (this.articlesFinished === this.articlesTotal && this.articlesTotal > 1) {
            setTimeout(() => {
              this.playAudio("/admin/sounds/warning2.mp3");
            }, 2000);
          }
        } else {
          this.playAudio("/admin/sounds/click-sound-effect.mp3");
        }
      }

      this.scanningArticle = false;
    },
    isLastArticle(phase, articlesTotal, articlesFinished) {
      if (articlesTotal === 1) {
        return true;
      }
      if (phase === 1 && articlesFinished === articlesTotal - 1) {
        return true;
      }
      if (phase === 2 && articlesFinished === articlesTotal) {
        return true;
      }

      return false;
    },
    handleOrderSubmitClick() {
      const order_id = this.orderID;

      if (!order_id) {
        return;
      }

      this.printPackingList();
    },
    async printPackingList() {
      let response = await fetch(this.route("article.finish.packinglist"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          orderID: this.orderID,
        }),
      });

      if (!response.ok) {
        await response.text();

        return;
      }

      response = await response.json();
      if (response && response.printURL) {
        this.PrintHtml(response.printURL, this.orderID);
        this.orderID = null;
      }
    },
  },
};
</script>

<style scoped>
/* Flashing effect */
.flash-effect {
  animation: flash 0.25s alternate infinite;
}

@keyframes flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
