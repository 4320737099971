<template>
  <table id="communications" class="display" style="width: 100%">
    <thead>
      <tr>
        <th>Kanaal</th>
        <th>Onderwerp</th>
        <th>Van</th>
        <th>Naar</th>
        <th>Verstuurd</th>
        <th>Ontvangen</th>
        <th>Gelezen</th>
        <th>Order</th>
        <th>Artikel</th>
        <th>Klant</th>
        <th>Datum</th>
        <th />
      </tr>
    </thead>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      table: null,
      merge: false,
    };
  },
  created() {
    this.merge = this.session("merge_customer_detail_info", false);
  },
  mounted() {
    this.buildTable();
  },
  beforeUnmount() {
    this.table.destroy();
    this.table = null;
  },
  methods: {
    buildTable() {
      this.table = $("#communications").DataTable({
        initComplete: function () {
          $("#communications_wrapper").addClass("#-mt-4");
        },
        pageLength: 100,
        ajax: {
          url: this.route("customer.communications", {
            customer: this.session("customer_id"),
          }),
          data: {
            merge: this.merge,
          },
        },
        order: [[10, "desc"]],
        select: false,
        columns: [
          { data: "channel" },
          { data: "summary" },
          {
            data: "employee.firstname",
            render: function (data, type, row) {
              return data ? data : "";
            },
          },
          {
            data: "recipient",
            render: function (data, type, row) {
              if (!data) return "";

              if (row.channel === "EMAIL") {
                return `<a href="mailto:${data}" target="_blank" class="link">${data}</a>`;
              }
              if (row.channel === "SMS") {
                return `<a href="tel:${data}" class="link">${data}</a>`;
              }

              return data;
            },
          },
          {
            data: "sent",
            searchable: false,
            sortable: false,
            render: function (data, type, row) {
              return data ? "Ja" : "Nee";
            },
          },
          {
            data: "received",
            searchable: false,
            sortable: false,
            render: function (data, type, row) {
              return data ? "Ja" : "Nee";
            },
          },
          {
            data: "read",
            searchable: false,
            sortable: false,
            render: function (data, type, row) {
              return ["SMS"].includes(row.channel) ? "-" : data ? "Ja" : "Nee";
            },
          },
          {
            data: "order_id",
            render: function (data, type, row) {
              if (!data) return "";

              return `<a href="/admin.php?page=orders&sub=detail&id=${data}" target="_blank" class="link">${data}</a>`;
            },
          },
          {
            data: "article_id",
            render: function (data, type, row) {
              if (!data) return "";

              return `<a href="/admin.php?page=orders&sub=detail&id=${row.order_id}&matches=full_match&terms=${data}" target="_blank" class="link">${data}</a>`;
            },
          },
          {
            data: "customer_id",
            visible: this.merge,
            render: function (data, type, row) {
              return `<a href="/admin.php?page=customers&sub=detail&customer_id=${data}" target="_blank" class="link">${data}</a>`;
            },
          },
          { data: "created_at" },
          {
            className: "dt-control",
            orderable: false,
            data: null,
            defaultContent: "",
          },
        ],
      });

      this.table.on("click", "tbody tr", (event) => {
        let row = this.table.row(event.target);

        if (row.child.isShown()) {
          row.child.hide();
        } else {
          row.child(this.getDetails(row.data()), "no-hover").show();
        }
      });
    },
    getDetails(job) {
      const url = this.route("customer.communication", {
        customer: job.customer_id,
        communication: job.id,
      });

      return `
        <iframe
          src="${url}"
          onload='javascript:(function(o){o.style.height = 0;o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
          style="width: 100%; border:none; height: 0px; overflow:hidden;"
        >
        </iframe>
      `;
    },
  },
  computed: {
    ...mapGetters(["route", "session"]),
  },
};
</script>
