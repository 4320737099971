<template>
  <table
    class="#font-[arial] #text-left #ml-px #border-solid #border-[#3C3C3C] #border-2 #border-collapse #w-full"
  >
    <thead>
      <tr>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          width="2%"
        />
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          width="16%"
        >
          <span>Printer</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          width="16%"
        >
          <span>Status</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          width="18%"
        >
          <span>Substatus</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          width="16%"
        >
          <span>Medewerker</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          width="16%"
        >
          <span>Tijd</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          width="16%"
        >
          <span>Moment</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(row, id) in data" :key="id">
        <td :style="{ 'background-color': colorStatus(row.status) }" />
        <td class="#p-1 #align-top">
          {{ row.printer.name }}
        </td>
        <td class="#p-1 #align-top">
          {{ row.status }}
        </td>
        <td class="#p-1 #align-top">
          {{ row.sub_status }}
        </td>
        <td class="#p-1 #align-top">
          {{ row.employee_id ? row.employee.firstname : "" }}
        </td>
        <td class="#p-1 #align-top">
          {{ toDuration(row.time) }}
        </td>
        <td class="#p-1 #align-top">
          {{ toDate(row.created_at) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { printerJobStatusColor, toDuration } from "../helpers";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      toDuration,
    };
  },
  methods: {
    toDate(iso) {
      var date = moment(iso, moment.ISO_8601);
      if (date.isSame(moment(), "day")) {
        return date.format("HH:mm:ss");
      }

      return date.format("HH:mm:ss DD-MM-YYYY");
    },
    colorStatus(status) {
      return printerJobStatusColor(status);
    },
  },
};
</script>
