<template>
  <h1>Photobooth</h1>
  <div class="tailwind">
    <div>
      <NotificationMessage
        v-if="message"
        :type="message.type"
        :message="message.text"
        @close="message = null"
      />
      <div v-if="pendingPhoto && isAdminLevel(6)" class="#mt-2">
        <img
          class="#m-auto #rounded #border #border-black"
          :src="pendingPhoto"
          :alt="employee.firstname"
          onerror="this.onerror=null; this.src='/admin/images/thumb-empty.png'; this.style.height='100px'"
        />
        <div class="#mt-2 #flex #space-x-2 #justify-center">
          <button
            type="button"
            class="#flex #items-center #space-x-2 #px-3 #py-2 #text-sm #font-medium #text-white #bg-red-700 #rounded-lg hover:#bg-red-800"
            @click="managePhotobooth('delete')"
          >
            <svg-use id="trash" type="solid" class="#h-[15px] #w-[15px] #fill-white"></svg-use>
            <span>Verwijderen</span>
          </button>

          <button
            type="button"
            class="#flex #items-center #space-x-2 #px-3 #py-2 #text-sm #font-medium #text-white #bg-green-700 #rounded-lg hover:#bg-green-800"
            @click="managePhotobooth('accept')"
          >
            <svg-use
              id="circle-check"
              type="solid"
              class="#h-[15px] #w-[15px] #fill-white"
            ></svg-use>
            <span>Goedkeuren</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NotificationMessage from "../components/NotificationMessage.vue";
import SvgUse from "../../vue3/components/SvgUse.vue";

export default {
  components: { NotificationMessage, SvgUse },
  data() {
    return {
      employee: null,
      message: null,
      pendingPhoto: null,
    };
  },
  computed: {
    ...mapGetters(["route", "isAdminLevel"]),
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.getEmployee(urlParams.get("employee"));
  },
  methods: {
    async getEmployee(employeeId) {
      const response = await fetch(this.route("employees.details"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ employee_id: employeeId }),
      });

      if (response.ok) {
        this.employee = await response.json();
        this.getPendingPhoto();
      } else if (!response.ok) {
        await response.text();
      }
    },
    async managePhotobooth(action) {
      const obj = { employee_id: this.employee.id, action: action };
      const response = await fetch(this.route("employees.photobooth.manage"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(obj),
      });
      if (response.ok) {
        this.message = { text: await response.json(), type: "success" };
        this.pendingPhoto = null;
      } else if (!response.ok) {
        this.message = { text: JSON.parse(await response.text()), type: "danger" };
      }
    },
    async getPendingPhoto() {
      const response = await fetch(this.route("employees.photobooth.pending-photo"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ employee_id: this.employee.id }),
      });

      if (response.ok) {
        this.pendingPhoto = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
  },
};
</script>
