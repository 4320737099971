<template>
  <Modal :open="open" @close="open = false">
    <div class="tailwind">
      <form @submit.prevent="submit">
        <div class="#flex #flex-1 #justify-center #items-center">
          <label class="#text-sm">Wachtwoord</label>
          <input
            v-model="password"
            type="password"
            class="#text-sm #mx-2 #border-2 #rounded-md"
            @focus="() => clearErrors('password')"
          />
          <AdminButton :disabled="submitting" btn-class="primary" @click="submit"
            >Bevestig
          </AdminButton>
        </div>
        <div class="#flex #flex-1 #justify-center #items-center">
          <p v-if="hasError('password')" class="#mb-0 #text-[11px] #text-red-500">
            {{ firstError("password") }}
          </p>
          <p v-else class="#mb-0 #text-[11px]">
            Deel nooit je wachtwoord en maak hem niet te makkelijk
          </p>
        </div>
      </form>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "../components/Modal.vue";
import AdminButton from "../components/AdminButton.vue";
import { form } from "../mixins";
export default {
  components: {
    Modal,
    AdminButton,
  },
  mixins: [form],
  data() {
    return {
      open: false,
      submitting: false,
      password: "",
      value: null,
    };
  },

  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    open(newValue) {
      if (!newValue) {
        this.submitting = false;
        this.password = "";
      }
    },
  },
  created() {
    this.emitter.on("open-password-confirm", (value) => {
      this.open = true;
      this.value = value;
    });
  },
  beforeUnmount() {
    this.emitter.off("open-password-confirm");
  },
  methods: {
    async submit() {
      this.submitting = true;

      let response = await fetch(this.route("password.confirm"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          password: this.password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          this.emitter.emit("password-confirmed", this.value);
          this.open = false;
        }
      } else if (response.status === 422) {
        const data = await response.json();
        this.formErrors = data.errors;
      } else {
        await response.text();
      }

      this.submitting = false;
    },
  },
};
</script>
