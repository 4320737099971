<template>
  <a
    ref="button"
    :href="href"
    :target="target"
    :disabled="disabled"
    type="submit"
    :style="fixed"
    class="#select-none #font-medium #inline-block #border-[1px] #border-solid #cursor-pointer #min-w-max hover:!#border-[1px] hover:!#border-b-[1px] hover:#border-solid hover:#opacity-100"
    :class="[styling, { waiting: disabled }]"
    @click="$emit('click', $event)"
  >
    <span
      v-if="disabled"
      class="spinner #flex #justify-center #items-center #gap-x-1 #relative #w-full"
    >
      <div :style="rect1" />
      <div :style="rect2" />
      <div :style="rect3" />
    </span>
    <template v-else>
      <svg-use
        v-if="icon === 'angle-left'"
        id="angle-left"
        type="regular"
        class="#inline-block #mt-[-3px] #mr-[5px] #w-[0.75em] #h-[1em] #fill-inkdropdark"
      />
      <span :class="{ 'max-[350px]:#hidden': icon === 'angle-left' }">
        <slot></slot>
      </span>
      <svg-use
        v-if="icon === 'angle-right'"
        id="angle-right"
        type="regular"
        class="#inline-block #mt-[-3px] #mr-[5px] #w-[0.75em] #h-[1em]"
        :class="btnClass === 'grey' ? '#fill-inkdropdark' : '#fill-white'"
      />
    </template>
  </a>
</template>

<script>
import SvgUse from "../../vue3/components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  props: {
    btnClass: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "sm",
    },
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    href: {
      type: String,
      default: "javascript:;",
    },
    target: {
      type: String,
      required: false,
      default: "_self",
    },
  },
  emits: ["click"],
  data() {
    return {
      width: null,
      height: null,
    };
  },
  computed: {
    fixed() {
      if (!this.disabled) {
        return {};
      }
      return {
        height: this.height + "px !important",
        width: this.width + "px !important",
      };
    },
    styling() {
      return {
        "#bg-brightcyan #text-white #border-[#1fc5ff] hover:#bg-[#05bfff] hover:!#text-white hover:#border-[#00a7e0]":
          this.btnClass === "primary",
        "#bg-vividmagenta #text-white #border-[#ff1c6d] hover:#bg-[#ff035c] hover:!#text-white hover:#border-[#de004f]":
          this.btnClass === "secondary",
        "#bg-inkdroplight #text-inkdropdark #border-transparent hover:#bg-[#dbdbdf] hover:#text-inkdropdark hover:#border-transparent":
          this.btnClass === "grey",
        "#text-lg #px-[18px] #py-[12px]": this.size === "lg",
        "#px-[8px] #py-[4px] #rounded-[3px] #text-[13px]": this.size === "sm",
        "#px-[2px] #py-[2px] #rounded-[3px] #text-[13px]": this.size === "xs",
      };
    },
    rect1() {
      return {
        "background-color": "#636363",
        width: "7px",
        height: "25px",
        animation: "rect 1.2s infinite ease-in-out",
      };
    },
    rect2() {
      return {
        "background-color": "#e6007e",
        width: "7px",
        height: "25px",
        animation: "rect2 1.2s -1.1s infinite ease-in-out",
      };
    },
    rect3() {
      return {
        "background-color": "#398ccb",
        width: "7px",
        height: "25px",
        animation: "rect3 1.2s -1s infinite ease-in-out",
      };
    },
  },
  watch: {
    disabled() {
      this.height = this.$refs.button.offsetHeight;
      this.width = this.$refs.button.offsetWidth;
    },
  },
};
</script>

<style>
@keyframes rect {
  0% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
}

@keyframes rect2 {
  0% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
}

@keyframes rect3 {
  0% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }

  40% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
}
</style>
