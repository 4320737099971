<template>
  <h1>
    Taakverdeling registratie
    <a class="filter-btn manage_button" @click="showFilter = !showFilter">
      Filteren ({{ usedFilters }})
    </a>
  </h1>
  <div class="tailwind">
    <div v-show="showFilter" class="#mb-4">
      <div class="#flex #space-x-4 #mb-2">
        <div>
          <label class="#font-bold">Vanaf</label>
          <div class="#flex">
            <input
              v-model="body.from"
              type="date"
              class="#rounded #p-1 #border #border-black"
              :max="maxDate"
            />
          </div>
        </div>
        <div>
          <label class="#font-bold">Tot</label>
          <div class="#flex">
            <input
              v-model="body.to"
              type="date"
              class="#rounded #p-1 #border #border-black"
              :max="maxDate"
            />
          </div>
        </div>
        <div>
          <SelectFilter
            v-model="body.employee_id"
            :options="employees"
            label="Medewerker"
            :multiple="false"
          />
        </div>
      </div>
    </div>
    <Tabs :tabs="tabs" :view="view" class="#mb-2" @view="setView" />
    <div v-if="tasks.length && view === 'overview'">
      <TaskEmployeeTable :tasks="tasks" @refresh-data="getTasks" />
    </div>
    <div v-if="metrics.length && view === 'metrics'">
      <TaskEmployeeMetrics :tasks="metrics" ? @refresh-data="getMetrics"></TaskEmployeeMetrics>
    </div>
  </div>
</template>
<script>
import TaskEmployeeTable from "../components/TaskEmployeeTable.vue";
import TaskEmployeeMetrics from "../components/TaskEmployeeMetrics.vue";
import SelectFilter from "../components/SelectFilter.vue";
import { mapGetters } from "vuex";
import Tabs from "../components/Tabs.vue";

export default {
  components: { SelectFilter, TaskEmployeeTable, TaskEmployeeMetrics, Tabs },
  data() {
    return {
      body: {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        employee_id: null,
      },
      employees: [],
      metrics: [],
      tabs: [
        { text: "Overzicht", value: "overview" },
        { text: "Statistieken", value: "metrics" },
      ],
      tasks: [],
      showFilter: false,
      view: "overview",
    };
  },
  computed: {
    ...mapGetters(["route"]),
    maxDate() {
      return moment().format("YYYY-MM-DD");
    },
    usedFilters() {
      return Object.values(this.body).filter((value) => value).length;
    },
  },
  watch: {
    body: {
      handler() {
        this.getTasks();
        this.getMetrics();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getEmployees();
  },
  methods: {
    setView(view) {
      this.view = view;
    },
    async getTasks() {
      let response = await fetch(this.route("tasks.index"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(this.body),
      });

      if (response.ok) {
        this.tasks = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
    async getMetrics() {
      const obj = { ...this.body, ended_at: "exists" };

      let response = await fetch(this.route("tasks.metrics"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(obj),
      });

      if (response.ok) {
        this.metrics = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
    async getEmployees() {
      let response = await fetch(this.route("employees.customer.service"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      if (response.ok) {
        const employees = await response.json();

        employees.forEach((employee) => {
          this.employees.push({ id: employee.id, value: employee.firstname });
        });
      } else if (!response.ok) {
        await response.text();
      }
    },
  },
};
</script>
