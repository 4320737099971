<template>
  <div class="#h-72 #overflow-y-scroll">
    <table class="#w-full #table-fixed">
      <tbody v-if="loading === true">
        <tr v-for="loader of 15" :key="loader" class="#h-6">
          <td class="#w-48 #py-1">
            <div class="#flex #items-center #justify-start #gap-x-2">
              <div class="loader-animation #w-2 #h-2 #rounded-full"></div>
              <div class="loader-animation #rounded-[3px] #w-3/4 #h-3"></div>
            </div>
          </td>
          <td class="#w-12 #py-1">
            <div class="loader-animation #rounded-[3px] #w-3/4 #h-3"></div>
          </td>
          <td class="#py-1">
            <div class="#flex #justify-start #items-center #gap-x-2">
              <div class="loader-animation #rounded-full #w-4 #h-4"></div>
              <div class="loader-animation #rounded-[3px] #w-3/4 #mr-4 #h-3"></div>
            </div>
          </td>
          <td class="#w-12 #text-right #py-1 #pr-4">
            <div class="loader-animation #rounded-[3px] #w-full #h-3"></div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr
          v-for="employee of employees"
          :key="employee.name"
          class="#h-6"
          :class="employee.working ? '' : '#opacity-50'"
        >
          <td class="#w-48 #py-1 #relative #group #cursor-default">
            <div class="#flex #items-center #justify-start #gap-x-2">
              <div class="#w-2 #h-2 #rounded-full" :class="getStatusColor(employee.color)"></div>
              <p>{{ employee.name }}</p>
              <svg-use
                v-if="employee.learning === true"
                id="graduation-cap"
                type="solid"
                class="#h-3 #w-4 #fill-inkdropdark"
              />
              <p class="#opacity-0 group-hover:#opacity-50">
                {{ timeStamp(employee.last_action) }}
              </p>
            </div>
          </td>
          <td class="#w-10 #py-1">
            <p class="#font-bold">{{ employee.skill_percentage }}%</p>
          </td>
          <td class="#py-1">
            <div class="#flex #justify-start #items-center #gap-x-2">
              <img class="#w-4" :src="getSkillBadge(employee.skill_ranking)" alt="" />
              <p class="#overflow-ellipsis #overflow-x-clip #whitespace-nowrap #w-4/5">
                {{ employee.machine }}
              </p>
            </div>
          </td>
          <td class="#w-12 #text-right #py-1 #pr-4">
            <p>{{ employee.work_end ?? "-" }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import SvgUse from "../../vue3/components/SvgUse.vue";

export default {
  components: {
    SvgUse,
  },
  props: {
    employees: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getStatusColor(color) {
      if (color === "red") {
        return "#bg-red-600";
      }
      if (color === "orange") {
        return "#bg-orange-400";
      }
      return "#bg-green-500";
    },
    getSkillBadge(ranking) {
      if (ranking === "diamond") {
        return `/admin/images/skill_status_4.svg`;
      }
      if (ranking === "gold") {
        return `/admin/images/skill_status_3.svg`;
      }
      if (ranking === "silver") {
        return `/admin/images/skill_status_2.svg`;
      }
      if (ranking === "bronze") {
        return `/admin/images/skill_status_1.svg`;
      }
      return `/admin/images/skill_status_0.svg`;
    },
    timeStamp(date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>
