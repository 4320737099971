<template>
  <h1>
    Marketing campagnes
    <img v-if="loading" src="/admin/images/loading.gif" style="margin-bottom: -9px" />
    <a class="filter-btn manage_button" @click="isShowFilter = !isShowFilter">Filteren</a>
  </h1>
  <div class="tailwind">
    <div v-show="isShowFilter" class="#grid #grid-cols-2 #gap-4 #mb-2">
      <div>
        <JoinFilter
          v-model="filters.orders"
          table="orders"
          :columns="orderColumns"
          label="Opdracht"
        />
      </div>
      <div>
        <JoinFilter
          v-model="filters.articles"
          table="articles"
          :columns="articleColumns"
          label="Artikelen"
        />
      </div>
    </div>

    <Tabs :tabs="tabs" :view="view" class="#mb-2" @view="setView" />

    <div v-if="view === 'overview'">
      <CampaignOverview :filters="filters" @loading="setLoading" />
    </div>

    <div v-if="view === 'revenue' && campaignsRevenue">
      <CampaignRevenue :data="campaignsRevenue" @loading="setLoading" />
    </div>
  </div>
</template>
<script>
import JoinFilter from "../components/JoinFilter.vue";
import Tabs from "../components/Tabs.vue";
import CampaignOverview from "../components/CampaignOverview.vue";
import CampaignRevenue from "../components/CampaignRevenue.vue";
import { mapGetters } from "vuex";

export default {
  components: { CampaignOverview, CampaignRevenue, JoinFilter, Tabs },
  data() {
    return {
      articleColumns: [
        { type: "integer", text: "Bedrag", value: "amount", fieldType: "price" },
        { type: "select", text: "Campagne", value: "campaign" },
        { type: "select", text: "Product", value: "product" },
        { type: "select", text: "Status", value: "status" },
      ],
      campaignsRevenue: null,
      filters: {
        orders: {
          amount: {},
          posted_datetime: {
            condition: "between",
            format: "dd-MM-yyyy hh:mm",
            formatted_date: [
              moment().startOf("day").subtract(3, "months").unix(),
              moment().endOf("day").unix(),
            ],
          },
          couponcode: "",
          status: [],
        },
        articles: {
          amount: {},
          campaign: [],
          product: [],
          status: [],
        },
      },
      orderColumns: [
        { type: "integer", text: "Bedrag", value: "amount", fieldType: "price" },
        { type: "date", text: "Besteld op", value: "posted_datetime", width: "#w-24" },
        { type: "string", text: "Couponcode", value: "couponcode" },
        { type: "select", text: "Status", value: "status" },
      ],
      isShowFilter: false,
      loading: true,
      tabs: [
        { text: "Overzicht", value: "overview" },
        { text: "Winst per campagne", value: "revenue" },
      ],
      view: "overview",
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    filters: {
      handler() {
        if (this.view === "revenue") {
          this.getCampaignsRevenue();
        }
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    this.getCampaignsRevenue();
  },
  methods: {
    setView(view) {
      this.view = view;
    },
    setLoading(loading) {
      this.loading = loading;
    },
    async getCampaignsRevenue() {
      const filters = {};

      for (const [categoryKey, category] of Object.entries(this.filters)) {
        for (const [filterKey, filter] of Object.entries(category)) {
          if (filter && Object.keys(filter).length > 0) {
            if (!filters[categoryKey]) {
              filters[categoryKey] = {};
            }

            filters[categoryKey][filterKey] = filter;
          }
        }
      }

      const response = await fetch(this.route("articles.campaigns.revenue"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(filters),
      });

      if (response.ok) {
        this.campaignsRevenue = await response.json();
      } else if (!response.ok) {
        await response.text();
      }
    },
  },
};
</script>
