<template>
  <table id="call_missed_table" class="display" style="width: 100%">
    <thead>
      <tr>
        <th>Nummer</th>
        <th>Tijd</th>
        <th>Tijd in wachtrij</th>
        <th>Klant ID</th>
        <th>Naam</th>
        <th>Bedrijf</th>
        <th>Acties</th>
      </tr>
    </thead>
  </table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      table: null,
      interval: null,
      handled: [],
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  mounted() {
    this.buildTable();
    this.interval = setInterval(() => {
      this.table.ajax.reload();
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
    this.table.destroy();
    this.table = null;
  },
  methods: {
    buildTable() {
      this.table = $("#call_missed_table").DataTable({
        ajax: this.route("call.missed"),
        searching: false,
        paging: true,
        pageLength: 5,
        select: false,
        sort: false,
        columns: [
          {
            data: "number",
            render: (data) => {
              return `<a href="tel:${data}">${data}</a>`;
            },
          },
          {
            data: "updated_at",
            render: function (data) {
              return moment(data).format("HH:mm:ss");
            },
          },
          {
            data: "waited",
            render: function (data) {
              const readable = moment().subtract(data, "seconds").fromNow(true);
              const title = `${data} seconden`;

              return `<span title="${title}">${readable}</span>`;
            },
          },
          { data: "customer.id" },
          { data: "customer.name_contact" },
          { data: "customer.company" },
          {
            data: "handled",
            render: (data, type, row) => {
              let html = '<a href="javascript:;" class="custom_handle">Afgehandeld</a>';

              if (!this.isPWA()) {
                html = "Gebruik de admin app op de wachtrij te gebruiken";
              }

              return html;
            },
          },
        ],
      });
      $("#call_missed_table").on("click", "a.custom_handle", async (e) => {
        e.preventDefault();
        const row = this.table.row($(e.target).parents("tr"));
        const id = parseInt(row.data().id);

        const response = await fetch(this.route("call.missed.handle", { call: id }), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify({ handled: true }),
        });

        const responseData = await response.json();

        if (responseData.success) {
          $("#call_missed_table").DataTable().ajax.reload();
        } else {
          alert(responseData.errors);
        }
      });
    },
    isPWA() {
      return ["standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia("(display-mode: " + displayMode + ")").matches,
      );
    },
  },
};
</script>
