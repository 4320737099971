<template>
  <div class="tailwind">
    <div class="#flex #gap-x-[15px] #my-4">
      <div class="#bg-white #relative #shadow-admin #p-4 #pr-0 #w-1/5">
        <h3 class="#font-bold #text-lg #text-inkdropdark #mb-4 #relative #z-[1]">
          Algemene meldingen
        </h3>
        <FloormanagerMessages
          class="#z-[1] #relative"
          :reminders="nonCriticalReminders"
          :loading="remindersLoading"
          @delete-task="handleReminder"
        ></FloormanagerMessages>
      </div>
      <div class="#bg-white #shadow-admin #w-2/5 #relative">
        <h3 class="#p-4 #font-bold #text-lg #text-inkdropdark #relative #z-[1]">Artikelen</h3>
        <FloormanagerOrders
          class="#relative #z-[1]"
          :orders="orders"
          :loading="ordersLoading"
        ></FloormanagerOrders>
        <div class="#flex #px-4 #py-2 #border-t-[1px] #border-[#a8a8a8] #border-solid #mt-2">
          <div class="#flex-1">
            <h4 class="#font-bold #mb-1">Normaal</h4>
            <div class="#flex #items-start #gap-x-4">
              <div class="#flex #items-center #gap-x-2">
                <div class="#w-2 #h-2 #rounded-full #border-solid #border-2 #border-red-600"></div>
                <p>Eerdere dag</p>
              </div>
              <div class="#flex #items-center #gap-x-2">
                <div class="#w-2 #h-2 #rounded-full #bg-red-600"></div>
                <p>3 uur</p>
              </div>
              <div class="#flex #items-center #gap-x-2">
                <div class="#w-2 #h-2 #rounded-full #bg-orange-400"></div>
                <p>1 uur</p>
              </div>
            </div>
          </div>
          <div class="#flex-1">
            <h4 class="#font-bold #mb-1">1 uur voor deadline</h4>
            <div class="#flex #items-start #gap-x-4">
              <div class="#flex #items-center #gap-x-2">
                <div class="#w-2 #h-2 #rounded-full #bg-red-600"></div>
                <p>30 min</p>
              </div>
              <div class="#flex #items-center #gap-x-2">
                <div class="#w-2 #h-2 #rounded-full #bg-orange-400"></div>
                <p>Niet af</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="#bg-white #shadow-admin #pr-0 #w-2/5 #relative">
        <h3 class="#font-bold #text-lg #text-inkdropdark #p-4 #z-[1] #relative">Medewerkers</h3>
        <FloormanagerEmployees
          class="#relative #z-[1] #pl-4"
          :employees="employees"
          :loading="employeesLoading"
        ></FloormanagerEmployees>
        <div class="#px-4 #py-2 #border-t-[1px] #border-[#a8a8a8] #border-solid #mt-2">
          <h4 class="#font-bold #mb-1">Normaal</h4>
          <div class="#flex #items-start #gap-x-4">
            <div class="#flex #items-center #gap-x-2">
              <div class="#w-2 #h-2 #rounded-full #bg-red-600"></div>
              <p>1 uur</p>
            </div>
            <div class="#flex #items-center #gap-x-2">
              <div class="#w-2 #h-2 #rounded-full #bg-orange-400"></div>
              <p>30 min</p>
            </div>
            <div class="#flex #items-center #gap-x-2">
              <div class="#w-2 #h-2 #rounded-full #bg-green-500"></div>
              <p>Goed bezig</p>
            </div>
            <div class="#flex #items-center #gap-x-2 #ml-4">
              <svg-use id="graduation-cap" type="solid" class="#h-3 #w-4 #fill-inkdropdark" />
              <p>Iets nieuws leren</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="#flex #gap-x-[15px] #mt-[15px]">
      <div class="#bg-white #relative #shadow-admin #p-4 #pr-0 #w-1/5">
        <h3 class="#font-bold #text-lg #text-inkdropdark #mb-4 #relative #z-[1]">
          Kritische meldingen
        </h3>
        <FloormanagerMessages
          class="#z-[1] #relative"
          :reminders="criticalReminders"
          :loading="remindersLoading"
          @delete-task="handleReminder"
        ></FloormanagerMessages>
      </div>
      <div class="#bg-white #shadow-admin #pr-0 #w-2/5 #relative">
        <div class="#bg-white #relative #p-6 #pr-0">
          <DashboardClickup type="floor-manager" height="21rem"></DashboardClickup>
        </div>
      </div>
      <div class="#bg-white #shadow-admin #pr-0 #w-2/5 #relative">
        <h3 class="#font-bold #text-lg #text-inkdropdark #p-4 #z-[1] #relative">Feedback geven</h3>
        <AdminFeedbackform class="#px-4 #pb-4"></AdminFeedbackform>
      </div>
    </div>
  </div>
</template>

<script>
import FloormanagerMessages from "../components/FloormanagerMessages.vue";
import FloormanagerEmployees from "../components/FloormanagerEmployees.vue";
import FloormanagerOrders from "../components/FloormanagerOrders.vue";
import DashboardClickup from "../components/DashboardClickup.vue";
import AdminFeedbackform from "../components/AdminFeedbackform.vue";
import SvgUse from "../../vue3/components/SvgUse.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    FloormanagerMessages,
    FloormanagerEmployees,
    FloormanagerOrders,
    DashboardClickup,
    AdminFeedbackform,
    SvgUse,
  },
  data() {
    return {
      orders: [],
      ordersLoading: true,
      employees: [],
      employeesLoading: true,
      criticalReminders: [],
      nonCriticalReminders: [],
      remindersLoading: true,
    };
  },
  computed: {
    ...mapGetters(["route", "session"]),
  },
  mounted() {
    this.getOrders();
    this.getEmployees();
    this.getReminders();

    this.getOrdersInterval = setInterval(() => {
      this.getOrders();
    }, 60000);

    this.getEmployeesInterval = setInterval(() => {
      this.getEmployees();
    }, 60000);

    this.getRemindersInterval = setInterval(() => {
      this.getReminders();
    }, 60000);
  },
  beforeUnmounted() {
    clearInterval(this.getOrdersInterval);
    clearInterval(this.getEmployeesInterval);
    clearInterval(this.getRemindersInterval);
  },
  methods: {
    async getOrders() {
      const response = await fetch(this.route("dashboard.deadlines"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        this.orders = await response.json();
        this.ordersLoading = false;
      } else if (!response.ok) {
        await response.text();
        this.ordersLoading = true;
      }
    },
    async getEmployees() {
      const response = await fetch(this.route("dashboard.employees"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        this.employees = await response.json();
        this.employeesLoading = false;
      } else if (!response.ok) {
        await response.text();
        this.employeesLoading = true;
      }
    },
    async getReminders() {
      const response = await fetch(this.route("dashboard.reminders.index"), {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      if (response.ok) {
        const reminders = await response.json();

        this.criticalReminders = reminders.critical_reminders;
        this.nonCriticalReminders = reminders.non_critical_reminders;
        this.remindersLoading = false;
      } else if (!response.ok) {
        await response.text();
        this.remindersLoading = true;
      }
    },
    async handleReminder(id) {
      const obj = {
        handled: true,
        reminder_id: id,
      };

      const response = await fetch(this.route("dashboard.reminders.handled"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(obj),
      });

      if (response.ok) {
        this.getReminders();
      } else if (!response.ok) {
        await response.text();
      }
    },
  },
};
</script>

<style>
@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
.loader-animation {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f6f7;
  background: linear-gradient(to right, #f6f6f7 8%, #e7e7e7 18%, #f6f6f7 33%);
  background-size: 800px 104px;
}
</style>
