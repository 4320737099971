<template>
  <h1>
    Medewerkers overzicht
    <img
      v-if="loading"
      src="/admin/images/loading.gif"
      class="feedback-employee-overview-spinner"
    />
  </h1>
  <div class="tailwind">
    <table
      class="#font-[arial] #text-left #ml-px #mb-2 #border-solid #border-[#3C3C3C] #border-2 #border-collapse #w-full"
    >
      <thead>
        <tr>
          <th
            class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
          >
            Periode
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="#p-1 #align-top">
            <div class="#flex #space-x-4">
              <div class="#flex #flex-col">
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('today')"
                  >Vandaag</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('yesterday')"
                  >Gister</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('week')"
                  >Deze week</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('month')"
                  >Deze maand</a
                >
                <a
                  href="javascript:;"
                  class="#w-fit #text-[11px] #text-[#0000EE] #underline"
                  @click="() => setRange('year')"
                  >Dit jaar</a
                >
              </div>
              <div class="#flex #flex-col">
                <input
                  ref="from"
                  v-model="from"
                  type="text"
                  readonly=""
                  placeholder="Van"
                  class="#px-1 #border #border-solid #border-black #mb-1"
                />
                <input
                  ref="to"
                  v-model="to"
                  type="text"
                  readonly=""
                  placeholder="Tot"
                  class="#px-1 #border #border-solid #border-black"
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <table id="feedback_mistake_employees" class="display" style="width: 100%">
    <thead>
      <tr>
        <th>Medewerker</th>
        <th>Interne productiefout</th>
        <th>Klacht klant</th>
        <th>Totale klachten</th>
        <th>Totale gewerkte uren</th>
        <th>Gemiddeld aantal klachten per uur</th>
        <th>Totale acties</th>
        <th>Percentage in verhouding tot aantal acties</th>
      </tr>
    </thead>
  </table>
</template>
<script>
import DatePickerFilter from "../mixins/DatePickerFilter";
import { mapGetters } from "vuex";

export default {
  mixins: [DatePickerFilter],
  data() {
    return {
      body: {
        from: moment().subtract(1, "weeks").startOf("isoWeek").format("DD-MM-YYYY HH:mm:ss"),
        to: moment().subtract(1, "weeks").endOf("isoWeek").format("DD-MM-YYYY HH:mm:ss"),
      },
      data: [],
      from: moment().subtract(1, "weeks").startOf("isoWeek").format("DD-MM-YYYY HH:mm:ss"),
      loading: true,
      table: null,
      to: moment().subtract(1, "weeks").endOf("isoWeek").format("DD-MM-YYYY HH:mm:ss"),
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    body: {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true,
    },
    from: "filterPeriod",
    to: "filterPeriod",
  },
  mounted() {
    this.setupDatePickers();
    this.buildTable();
  },
  beforeUnmount() {
    this.table.destroy();
    this.table = null;
  },
  methods: {
    buildTable() {
      if (this.table) {
        this.table.destroy();
        this.table = null;
      }

      this.table = $("#feedback_mistake_employees").DataTable({
        serverSide: false,
        data: this.data,
        select: false,
        order: [5, "desc"],
        columns: [
          { data: "name" },
          { data: "number_of_internal_mistakes" },
          { data: "number_of_external_mistakes" },
          { data: "number_of_all_mistakes" },
          { data: "total_working_hours" },
          { data: "average_number_of_mistakes_per_hour" },
          { data: "number_of_actions" },
          {
            data: "percentage",
            render: function (data) {
              return `${data}%`;
            },
          },
        ],
      });
    },
    async fetchData() {
      this.loading = true;

      let response = await fetch(this.route("mistakes.employees"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(this.body),
      });

      response = await response.json();

      if (response.success) {
        this.data = response.employees;
        this.buildTable();
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.feedback-employee-overview-spinner {
  margin-bottom: -9px;
}
</style>
