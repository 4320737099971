<template>
  <h1>
    Totale overzicht
    <img v-if="loading" src="/admin/images/loading.gif" style="margin-bottom: -9px" />
  </h1>
  <div class="tailwind">
    <div class="#mt-1 #flex #items-center">
      <img class="#h-5" :src="`/admin/images/${getWarningIcon(0)}`" />
      <span class="#ml-1 #mr-1">Geen waarschuwing <b>(0)</b></span>

      <img class="#h-5" :src="`/admin/images/${getWarningIcon(1)}`" />
      <span class="#ml-1 #mr-1">Mondelinge waarschuwing <b>(1)</b></span>

      <img class="#h-5" :src="`/admin/images/${getWarningIcon(2)}`" />
      <span class="#ml-1 #mr-1">Officiële waarschuwing <b>(2)</b></span>

      <img class="#h-5" :src="`/admin/images/${getWarningIcon(3)}`" />
      <span class="#ml-1 #mr-1">Laatste waarschuwing <b>(3)</b></span>
    </div>
    <table id="employees" class="display" style="width: 100%">
      <thead>
        <tr>
          <th>Medewerker</th>
          <th>Punten</th>
          <th>Totaal aantal punten waarschuwingen</th>
          <th
            v-for="warning of warnings"
            :key="warning.id"
            class="#cursor-default"
            :title="warning.title"
          >
            {{ warning.title.substring(0, 11) + ".." }}
          </th>
          <th>Acties</th>
        </tr>
      </thead>
    </table>
  </div>
</template>
<script scoped>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      employees: [],
      loading: true,
      table: null,
      warnings: [],
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  mounted() {
    this.fetchEmployees();
  },
  methods: {
    async fetchEmployees() {
      let response = await fetch(this.route("employees.overview"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(this.body),
      });

      response = await response.json();

      if (response.success) {
        this.employees = response.employees;
        this.warnings = response.warnings;

        this.$nextTick(() => {
          this.buildTable();
        });
      }
    },
    buildTable() {
      this.table = $("#employees").DataTable({
        serverSide: false,
        order: [[2, "desc"]],
        data: this.employees,
        preDrawCallback: () => {
          this.loading = true;
        },
        drawCallback: () => {
          this.loading = false;
        },
        columns: this.getColumns(),
      });
    },
    getColumns() {
      const columns = [
        {
          data: "name",
          render: (data, settings, row) => {
            return `<a href="/admin.php?page=special&sub=employees&sub_sub=overview&employee_id=${row.id}" class="link">${data}</a>`;
          },
        },
        { data: "points" },
        {
          data: "total_warnings",
          render: (data) => {
            return data;
          },
        },
      ];

      for (let i = 0; i < this.warnings.length; i++) {
        columns.push({
          data: `warnings.${i}.status`,
          orderDataType: "dom-text",
          type: "numeric",
          render: (data) => {
            return `<img class="#h-5" src="/admin/images/${this.getWarningIcon(data)}" />`;
          },
        });
      }

      columns.push({
        searchable: false,
        sortable: false,
        data: null,
        className: "center",
        render: function (data, settings, row) {
          return `<a
            href="/admin.php?page=feedback&sub=overview&employee=${row.id}&hr=1"
            class="#text-indigo-700 #cursor-pointer #underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Feedback bekijken
          </a>`;
        },
      });

      return columns;
    },
    getWarningIcon(status) {
      switch (status) {
        case 0: // groen
          return "warning_status_0.svg";
        case 1: // oranje
          return "warning_status_1.svg";
        case 2: // paars
          return "warning_status_2.svg";
        case 3: // rood
          return "warning_status_3.svg";
      }
    },
  },
};
</script>
