<template>
  <div class="tailwind">
    <div v-if="successMessage || gridMessage" class="#grid #justify-center #items-center #h-48">
      <p class="#text-center #font-bold #text-lg #text-black">{{ successMessage }}</p>
      <p v-if="gridMessage" class="#text-center #font-bold #text-lg #text-black">
        {{ gridMessage }}
      </p>
    </div>
    <div v-else class="#h-full #w-full #text-black">
      <div class="#rounded-md">
        <div class="#font-bold #text-lg #text-black">
          <span>Terug in Opdracht</span>
          <span class="#font-medium #text-sm #block"
            >Kies de reden voor het terug in opdracht plaatsen van artikel {{ article_id }}</span
          >
          <hr />
        </div>
        <div class="#pr-7 #pl-7">
          <div v-for="category in uniqueCategories" :key="category">
            <span class="#mt-2 #mb-1 #text-black #font-bold #text-[14px] #block">{{
              category
            }}</span>
            <div>
              <button
                v-for="mistake in getCategoryMistakes(category)"
                :key="mistake.id"
                class="#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer"
                :class="mistakeBtnStyle(mistake)"
                @click="selectMistake(mistake.id)"
              >
                {{ mistake.text }}
              </button>
            </div>
          </div>
          <div class="#font-bold #text-[14px] #text-black #mt-3 #mb-1">
            Toelichting
            <span v-if="[65, 66, 67, 69, 70, 71].includes(selectedMistake)" class="#text-red-500"
              >* verplicht</span
            >
          </div>
          <textarea
            v-model="mistakeComment"
            class="#p-2 #border-2 #rounded-md"
            placeholder=""
            rows="3"
            cols="70"
          />
          <br /><br />
          <div
            v-if="uniqueCategories.includes('Stickers')"
            class="#font-bold #text-[14px] #text-black"
          >
            Aantal stickers opnieuw
          </div>
          <div v-else class="#font-bold #text-[14px] #text-black">
            <span v-if="covers.length || frontCovers.length || backCovers.length">
              Binnenwerken opnieuw
            </span>
            <span v-else> Aantal opnieuw </span>
          </div>
          <button
            v-for="number in numbers"
            :key="number.id"
            class="#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-black #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #inline;"
            :class="numberBtnStyle(number)"
            :disabled="isButtonDisabled()"
            @click="selectNumbers(number.id)"
          >
            {{ number.text }}
          </button>
          <input
            v-model="totalnumbers"
            placeholder="Aangepast aantal"
            type="number"
            :class="numbersInputStyle()"
            class="#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-center #text-black #font-medium #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #flex #items-center #justify-center"
            min="0"
            :max="maxAmount()"
            @input="validateInputNumber()"
          />
          <br /><br />
          <div v-if="covers.length" class="#font-bold #text-[14px]">Aantal omslagen</div>
          <button
            v-for="cover in covers"
            :key="cover.id"
            class="#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-black #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer"
            :class="coverBtnStyle(cover)"
            @click="selectCovers(cover.id)"
          >
            {{ cover.text }}
          </button>
          <input
            v-if="covers.length"
            v-model="totalnumbersCovers"
            placeholder="Aangepast aantal"
            type="number"
            :class="numbersInputCoversStyle()"
            class="#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-center #text-black #font-medium #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #flex #items-center #justify-center"
            min="0"
            :max="maxAmount()"
             @input="validateInputCover()"
          />
          <br v-if="covers.length" /><br v-if="covers.length" />
          <div v-if="frontCovers.length" class="#font-bold #text-[14px]">Aantal voorkanten</div>
          <button
            v-for="frontCover in frontCovers"
            :key="frontCover.id"
            class="#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-black #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer"
            :class="frontBtnStyle(frontCover)"
            @click="selectFrontCovers(frontCover.id)"
          >
            {{ frontCover.text }}
          </button>
          <input
            v-if="frontCovers.length"
            v-model="totalnumbersFrontCovers"
            placeholder="Aangepast aantal"
            type="number"
            :class="numbersInputFrontCoversStyle()"
            class="#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-center #text-black #font-medium #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #flex #items-center #justify-center"
            min="0"
            :max="maxAmount()"
            @input="validateInputFrontCover()"
          />
          <br v-if="frontCovers.length" /><br v-if="frontCovers.length" />
          <div v-if="backCovers.length" class="#font-bold #text-[14px]">Aantal achterkanten</div>
          <button
            v-for="backCover in backCovers"
            :key="backCover.id"
            class="#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-black #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer"
            :class="backBtnStyle(backCover)"
            @click="selectBackCovers(backCover.id)"
          >
            {{ backCover.text }}
          </button>
          <input
            v-if="backCovers.length"
            v-model="totalnumbersBackCovers"
            placeholder="Aangepast aantal"
            type="number"
            :class="numbersInputBackCoversStyle()"
            class="#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-center #text-black #font-medium #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #flex #items-center #justify-center"
            min="0"
            :max="maxAmount()"
            @input="validateInputBackCover()"
          />
          <br v-if="backCovers.length" /><br v-if="backCovers.length" />
        </div>
        <div class="#font-bold #text-lg">
          <span class="title">Feedback</span>
          <span class="#font-medium #text-sm #text-black #block"
            >Selecteer op welke actie(s) je feedback wilt toevoegen</span
          >
          <hr />
        </div>
        <div class="#pr-7 #pl-7">
          <div>
            <button :class="machineMistakeBtnStyle()" @click="selectMachineMistake()">
              Machine fout
            </button>
            <button
              v-for="action in actions"
              v-if="!selectedMachineMistake"
              :key="action.id"
              :title="action.article_id"
              :class="actionBtnStyle(action)"
              @click="() => selectAction(action.id)"
            >
              {{ action.text }}
            </button>
            <div
              v-if="!selectedActions.length"
              class="#font-bold #text-[14px] #text-black #mt-3 #mb-1"
            >
              Toelichting feedback
              <span class="#text-red-500">* verplicht</span>
            </div>
            <textarea
              v-if="!selectedActions.length"
              v-model="feedbackComment"
              class="#p-2 #border-2 #rounded-md"
              placeholder=""
              rows="2"
              cols="70"
            />
            <br /><br />
          </div>
          <div class="#flex #items-center #gap-x-8 #relative #group #pt-3">
            <h3 class="#font-medium #text-sm #block #text-gray-900">
              Artikel plaatsen in Opnieuw Kast
            </h3>
            <label class="#relative #inline-flex #items-center #cursor-pointer">
              <input
                v-model="againGrid"
                type="checkbox"
                true-value="true"
                false-value="false"
                class="#sr-only #peer"
              />
              <div
                class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
              />
            </label>
          </div>

          <div v-if="NeedFMCheck" class="#flex #items-center #gap-x-8 #relative #group #pt-3">
          <div class="#flex #items-center #gap-x-8 #relative #group #pt-3">
            <h3 v-if="CheckedByFM" class="#font-medium #text-sm #block #text-gray-900">
              Goedgekeurd door Floormanager ({{ FmFirstname }})
            </h3>
            <h3 v-else class="#font-medium #text-sm #block #text-gray-900">
              Laat de Floormanager zijn bandje scannen
            </h3>
            <label class="#relative #inline-flex #items-center #cursor-pointer">
              <input
                v-model="CheckedByFM"
                type="checkbox"
                class="#sr-only #peer"
                :disabled="!CheckedByFM"
              />
              <div
                class="#w-14 #h-7 #bg-gray-200 peer-focus:#outline-none #rounded-full peer peer-checked:after:#translate-x-[110%] peer-checked:after:#border-white after:#content-[''] after:#absolute after:#top-0.5 after:#left-[2px] after:#bg-white after:#border-gray-300 after:#border after:#rounded-full after:#h-6 after:#w-6 after:#transition-all peer-checked:#bg-brightcyan"
              />
            </label>
          </div>
        </div>
          <div class="#grid #grid-cols-[auto_20px_220px_180px] #mt-2.5;">
            <span />
            <span />
            <span />
            <button
              class="#m-1 #grid-flow-row #w-40 #h-10 #flex-1 #transition-all #box-border #text-white #text-[13px] #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer hover:#border-0 #bg-brightcyan enabled:hover:#bg-[#ff035c] disabled:#opacity-25"
              :disabled="!ready"
              @click="placeBackInOrder()"
            >
              In Opdracht
            </button>
          </div>
        </div>
        <br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

const initialState = () => {
  return {
    article_id: null,
    action_id: null,
    grid_position_id: null,
    mistakes: [],
    numbers: [],
    covers: [],
    backCovers: [],
    frontCovers: [],
    actions: [],
    againGrid: false,
    selectedMistake: null,
    selectedNumbers: null,
    selectedCovers: null,
    selectedBackCovers: null,
    selectedFrontCovers: null,
    selectedActions: [],
    selectedMachineMistake: false,
    successMessage: "",
    gridMessage: "",
    mistakeComment: "",
    feedbackComment: "",
    totalnumbers: null,
    checkInputAmount: null,
    totalnumbersCovers: null,
    totalnumbersFrontCovers: null,
    totalnumbersBackCovers: null,
    NeedFMCheck: false,
    CheckedByFM: false,
    FmFirstname: "",
    rfid_csn: "",
  };
};

export default {
  props: {
    articleID: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: "action",
    },
    gridPositionId: {
      type: Number,
      default: null,
    },
  },
  emits: ["done"],
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters(["session", "route"]),

    //Als er een reden is geselecteerd en een aantal is ingevuld kan je op in opdracht klikken
    ready() {
      if(this.NeedFMCheck == true && this.CheckedByFM == false) {
        return false;
      }


      if (this.selectedMistake === null) {
        return false;
      }

      if (this.selectedMachineMistake === true && this.feedbackComment === "") {
        return false;
      }

      if ([65, 66, 67, 69, 70, 71].includes(this.selectedMistake) && this.mistakeComment === "") {
        return false;
      }

      if (this.selectedActions.length === 0 && this.feedbackComment === "") {
        return false;
      }

      if (
        this.numbers.length &&
        (this.totalnumbers === null || this.totalnumbers === 0) &&
        this.selectedNumbers === null
      ) {
        return false;
      }

      if (
        this.covers.length &&
        (this.totalnumbersCovers === null || this.totalnumbersCovers === 0) &&
        this.selectedCovers === null
      ) {
        return false;
      }

      if (
        this.frontCovers.length &&
        (this.totalnumbersFrontCovers === null || this.totalnumbersFrontCovers === 0) &&
        this.selectedFrontCovers === null
      ) {
        return false;
      }

      if (
        this.backCovers.length &&
        (this.totalnumbersBackCovers === null || this.totalnumbersBackCovers === 0) &&
        this.selectedBackCovers === null
      ) {
        return false;
      }

      return true;
    },
    loading() {
      return this.mistakes.length === 0;
    },
    //For Reach om 1 categorie terug te krijgen voor de kopje boven de klachten
    uniqueCategories() {
      const categories = new Set();
      this.mistakes.forEach((mistaket) => {
        categories.add(mistaket.category);
      });
      return Array.from(categories);
    },
  },

  mounted() {
    window.onRfidScan = (value) => {
      if (value) {
        const modifiedValue = `i${value}`;
        console.log("RFID scan gedetecteerd:", modifiedValue);
        this.onRfidScan(modifiedValue);
      } else {
        console.warn("Geen waarde ontvangen bij RFID scan.");
      }
    };
  },
  //Zodra de vue wordt geopend laad hij de data in
  created() {
    this.article_id = this.articleID || window.articleID;
    this.action_id = window.actionID;
    this.grid_position_id = this.gridPositionId;
    this.getMistakes(this.article_id);
    this.getNumbers(this.article_id);
    this.getActions(this.article_id, 0);
    this.getBackCovers(this.article_id);
    this.getFrontCovers(this.article_id);
    if (this.type !== "finished") {
      this.getCovers(this.article_id);
    }

  },
  beforeUnmount() {},
  methods: {
    reset() {
      Object.assign(this.$data, initialState());
    },
    //Ophalen van max aantal opnieuw in inputveld
    maxAmount() {
      if (this.numbers.find((number) => number.id === 3)) {
        const numberAmount = this.numbers.find((number) => number.id === 3);
        this.checkInputAmount = numberAmount.amount;
        return this.checkInputAmount;
      }

      if (this.numbers.find((number) => number.id === 2)) {
        const numberAmount = this.numbers.find((number) => number.id === 2);
        this.checkInputAmount = numberAmount.amount;
        return this.checkInputAmount;
      }

      if (this.numbers.find((number) => number.id === 1)) {
        const numberAmount = this.numbers.find((number) => number.id === 1);
        this.checkInputAmount = numberAmount.amount;
        return this.checkInputAmount;
      }
    },
    validateInputCover() {
      if(this.totalnumbersCovers > this.checkInputAmount){
        this.totalnumbersCovers = this.checkInputAmount;
      }
    },
    validateInputNumber() {
      if(this.totalnumbers > this.checkInputAmount){
        this.totalnumbers = this.checkInputAmount;
      }
    },
    validateInputFrontCover() {
      if(this.totalnumbersFrontCovers > this.checkInputAmount){
        this.totalnumbersFrontCovers = this.checkInputAmount;
      }
    },
    validateInputBackCover() {
      if(this.totalnumbersBackCovers > this.checkInputAmount){
        this.totalnumbersBackCovers = this.checkInputAmount;
      }
    },
    //Als inputveld aantal opnieuw is ingevuld, andere knoppen blokkeren
    isButtonDisabled() {
      return this.totalnumbers > 0;
    },
    //Ophalen van de categorieën.
    getCategoryMistakes(category) {
      return this.mistakes.filter((mistaket) => mistaket.category === category);
    },
    //Ophalen van de kleur voor de buttons
    mistakeBtnStyle(mistake) {
      return {
        "#bg-brightcyan #text-black": this.selectedMistake === mistake.id,
        "#bg-[#f1eff0] #text-black": this.selectedMistake !== mistake.id,
      };
    },
    numbersInputStyle() {
      if (this.totalnumbers === null || this.totalnumbers === 0) {
        var totalnumbers = true;
      }
      return {
        "#bg-[#f1eff0]": totalnumbers === true,
        "#bg-brightcyan": this.totalnumbers > 0,
      };
    },
    numbersInputCoversStyle() {
      if (this.totalnumbersCovers === null || this.totalnumbersCovers === 0) {
        var totalnumbersCovers = true;
      }
      return {
        "#bg-[#f1eff0]": totalnumbersCovers === true,
        "#bg-brightcyan": this.totalnumbersCovers > 0,
      };
    },
    numbersInputFrontCoversStyle() {
      if (this.totalnumbersFrontCovers === null || this.totalnumbersFrontCovers === 0) {
        var totalnumbersFrontCovers = true;
      }
      return {
        "#bg-[#f1eff0]": totalnumbersFrontCovers === true,
        "#bg-brightcyan": this.totalnumbersFrontCovers > 0,
      };
    },
    numbersInputBackCoversStyle() {
      if (this.totalnumbersBackCovers === null || this.totalnumbersBackCovers === 0) {
        var totalnumbersBackCovers = true;
      }
      return {
        "#bg-[#f1eff0]": totalnumbersBackCovers === true,
        "#bg-brightcyan": this.totalnumbersBackCovers > 0,
      };
    },
    numberBtnStyle(number) {
      return {
        "#opacity-25": this.totalnumbers > 0,
        "#bg-brightcyan": this.selectedNumbers === number.id,
        "#bg-[#f1eff0]": this.selectedNumbers !== number.id,
      };
    },
    coverBtnStyle(cover) {
      return {
        "#opacity-25": this.totalnumbersCovers > 0,
        "#bg-brightcyan": this.selectedCovers === cover.id,
        "#bg-[#f1eff0]": this.selectedCovers !== cover.id,
      };
    },
    frontBtnStyle(front) {
      return {
        "#opacity-25": this.totalnumbersFrontCovers > 0,
        "#bg-brightcyan": this.selectedFrontCovers === front.id,
        "#bg-[#f1eff0]": this.selectedFrontCovers !== front.id,
      };
    },
    backBtnStyle(back) {
      return {
        "#opacity-25": this.totalnumbersBackCovers > 0,
        "#bg-brightcyan": this.selectedBackCovers === back.id,
        "#bg-[#f1eff0]": this.selectedBackCovers !== back.id,
      };
    },
    actionBtnStyle(action) {
      return {
        "#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-white #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #bg-brightcyan  #items-center #justify-center ":
          this.selectedActions.includes(action.id),
        "#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-black #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #bg-[#f1eff0]  #items-center #justify-center":
          !this.selectedActions.includes(action.id),
      };
    },
    machineMistakeBtnStyle() {
      return {
        "#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-white #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #bg-brightcyan  #items-center #justify-center ":
          this.selectedMachineMistake,
        "#m-1 #w-56 #h-10 #transition-all #text-[13px] #box-border #text-black #font-medium #inline-block #px-1 #py-1 #rounded-md #border-0 enabled:#cursor-pointer #bg-[#f1eff0]  #items-center #justify-center":
          !this.selectedMachineMistake,
      };
    },
    selectMistake(mistakeId) {
      this.getActions(this.article_id, mistakeId);

      this.selectedActions = [];
      this.selectedMistake = mistakeId;
      return true;
    },
    selectNumbers(numberId) {
      this.selectedNumbers = numberId;
      const totalnbm = this.numbers.find((number) => number.id === this.selectedNumbers);

      if ((totalnbm.amount * totalnbm.costs) > 80) {
        this.NeedFMCheck = true;
        if(this.session("user_level").includes('4')) {
          this.CheckedByFM = true;
          this.FmFirstname = this.session("username");
        }
      } else {
        this.NeedFMCheck = false;
      }

      return true;
    },
    selectCovers(coverId) {
      this.selectedCovers = coverId;

      return true;
    },
    selectFrontCovers(backId) {
      this.selectedFrontCovers = backId;

      return true;
    },
    selectBackCovers(backId) {
      this.selectedBackCovers = backId;

      return true;
    },
    //Zorgt ervoor dat jem meerdere acties kan selecteren
    selectAction(actionId) {
      if (this.selectedActions.includes(actionId)) {
        // Als de actie al geselecteerd is, verwijder deze dan uit de geselecteerde acties
        const index = this.selectedActions.indexOf(actionId);
        if (index > -1) {
          this.selectedActions.splice(index, 1);
        }
      } else {
        // Voeg de actie toe aan de geselecteerde acties
        this.selectedActions.push(actionId);
      }
    },
    selectMachineMistake() {
      this.selectedMachineMistake = !this.selectedMachineMistake;
      if (this.selectedMachineMistake === true) {
        this.selectedActions = [];
      }
    },
    //Ophalen van de Klacht soorten
    async getMistakes(article_id) {
      const response = await fetch(this.route("mistakes"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          article_id: article_id,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.mistakes = await response.json();
    },
    //Ophalen aantal opnieuw
    async getNumbers(article_id) {
      const response = await fetch(this.route("numbers"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          article_id: article_id,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.numbers = await response.json();
    },

    //Ophalen covers
    async getCovers(article_id) {
      const response = await fetch(this.route("covers"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          article_id: article_id,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.covers = await response.json();
    },
    async onRfidScan(rfid) {
      console.log(rfid);
      let response = await fetch(this.route("rfidScan"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          rfid: rfid,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      response = await response.json();

      if (response && response.success) {
        this.CheckedByFM = true;
        this.FmFirstname = response.name;
      }



    },
    //Ophalen voorkanten
    async getFrontCovers(article_id) {
      const response = await fetch(this.route("frontCovers"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          article_id: article_id,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.frontCovers = await response.json();
    },

    //Ophalen Achterkanten
    async getBackCovers(article_id) {
      const response = await fetch(this.route("backCovers"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          article_id: article_id,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.backCovers = await response.json();
    },

    //Ophalen Acties
    async getActions(article_id, mistake_id) {
      const response = await fetch(this.route("actions"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          article_id: article_id,
          mistake_id: mistake_id,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.actions = await response.json();

      // ACTION AUTO SELECT
      // if(this.actions) {
      //   const actionId = this.actions[0].id;
      //   this.selectAction(actionId)
      // }
    },

    //Het terug in opdracht plaatsen
    async placeBackInOrder() {
      const number = this.numbers.find((number) => number.id === this.selectedNumbers);
      const numberCovers = this.covers.find((number) => number.id === this.selectedCovers);
      const numberFrontCovers = this.frontCovers.find(
        (number) => number.id === this.selectedFrontCovers,
      );
      const numberBackCovers = this.backCovers.find(
        (number) => number.id === this.selectedBackCovers,
      );

      const response = await fetch(this.route("internalMistake"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          amount: this.totalnumbers ? this.totalnumbers : number ? number.amount : null,
          amountCovers: this.totalnumbersCovers
            ? this.totalnumbersCovers
            : numberCovers
              ? numberCovers.amount
              : null,
          amountFrontCovers: this.totalnumbersFrontCovers
            ? this.totalnumbersFrontCovers
            : numberFrontCovers
              ? numberFrontCovers.amount
              : null,
          amountBackCovers: this.totalnumbersBackCovers
            ? this.totalnumbersBackCovers
            : numberBackCovers
              ? numberBackCovers.amount
              : null,
          article_id: this.article_id,
          mistake: this.selectedMistake,
          actions: this.selectedActions,
          comment: this.mistakeComment,
          feedback: this.feedbackComment,
          type: this.type,
          gridPosition_id: this.gridPositionId,
          action_id: this.action_id,
          againGrid: this.againGrid,
          FmFirstname: this.FmFirstname,
        }),
      });
      if (!response.ok) {
        await response.text();

        return;
      }
      this.response = await response.json();
      this.successMessage = "Artikel " + this.article_id + " staat opnieuw in opdracht.";
      if (this.response.placeInGrid) {
        this.gridMessage = this.response.placeInGrid;
      }

      if (this.type == "finished") {
        setTimeout(() => {
          this.emitter.emit("articleFinishedReset");
          this.$emit("done");
          this.reset();
        }, 5000);
      }
    },
  },
};
</script>
