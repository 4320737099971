<template>
  <h1>Kortingscode overzicht</h1>
  <div class="tailwind">
    <table id="coupons" class="display" style="width: 100%">
      <thead class="#bg-gray-600 #text-white">
        <tr>
          <th>ID</th>
          <th>Code</th>
          <th>Eenmalig</th>
          <th>Klant</th>
          <th>Percentage</th>
          <th>Bedrag</th>
          <th title="Extra controle">Extra..</th>
          <th>Campagne</th>
          <th title="Aantal keer gebruikt">Aantal..</th>
          <th title="Laatst gebruikt">Laatst..</th>
          <th>Verloopdatum</th>
          <th>Aangemaakt</th>
          <th>Acties</th>
        </tr>
      </thead>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      editor: null,
      table: null,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  mounted() {
    this.buildTable();
  },
  methods: {
    buildTable() {
      this.table = $("#coupons").DataTable({
        ajax: {
          url: this.route("coupons.index"),
        },
        pageLength: 50,
        select: false,
        order: [[0, "desc"]],
        columns: [
          {
            data: "id",
            render: (data, type, row) => {
              let titleParts = [];

              if (row.comment) {
                titleParts.push(`Opmerking: ${row.comment}`);
              }

              if (row.products) {
                titleParts.push(`Producten: ${row.products}`);
              }

              let title = titleParts.join("&#013");

              return `<span
                style="${row.comment ? "color: #0099ff" : ""}"
                title="${title}"
              >
                ${data}
              </span>`;
            },
          },
          { data: "code" },
          {
            data: "once",
            render: function (data) {
              return data ? "Ja" : "";
            },
          },
          {
            data: "customer_id",
            render: function (data) {
              if (!data) return "";

              const url = `<a title="${data}" target="_blank" rel="noopener noreferrer" href="/admin.php?page=customers&sub=detail&customer_id=${data}" class="link">${data}</a>`;

              return `<div class="ellipsis" style="max-width:105px;">${url}</div>`;
            },
          },
          {
            data: "percentage",
            render: function (data) {
              if (!data) return "";

              return `${data}%`;
            },
          },
          {
            data: "amount",
            render: function (data) {
              if (!data) return "";

              return `€ ${data}`;
            },
          },
          {
            data: "check_code",
            render: function (data) {
              return data ? "Ja" : "";
            },
          },
          { data: "campaign" },
          { data: "times_used", searchable: false },
          {
            data: "last_used",
            searchable: false,
            render: function (data) {
              if (!data) return "";

              return moment(data).format("DD-MM-YYYY");
            },
          },
          {
            data: "expiration_date",
            render: function (data) {
              return moment(data).format("DD-MM-YYYY");
            },
          },
          { data: "employee.firstname" },
          {
            data: null,
            className: "center",
            width: "120px",
            render: () => {
              let html = '<div class="action-container">';
              html +=
                '<a href="javascript:;" class="editor-edit #underline #text-indigo-700">Wijzigen</a>';
              html += " | ";
              html +=
                '<a href="javascript:;" class="editor-delete #underline #text-indigo-700">Verwijderen</a>';
              html += "</div>";

              return html;
            },
            orderable: false,
            searchable: false,
          },
        ],
        rowCallback: function (row, data) {
          if (data.times_used) {
            $(row).find("div.action-container").html("");
          }
        },
      });

      this.editor = new $.fn.dataTable.Editor({
        ajax: this.route("coupons.update"),
        fields: [
          {
            name: "id",
            attr: {
              type: "hidden",
            },
          },
          {
            label: "Percentage",
            name: "percentage",
            attr: {
              type: "number",
            },
          },
          {
            label: "Bedrag",
            name: "amount",
            attr: {
              type: "number",
            },
          },
          {
            label: "Geen verzendkosten",
            name: "delivery_free",
            type: "checkbox",
            separator: "|",
            options: [{ label: "", value: true }],
          },
          {
            label: "Geen spoedkosten",
            name: "production_free",
            type: "checkbox",
            separator: "|",
            options: [{ label: "", value: true }],
          },
          {
            label: "Toon productiemethode Extra budget",
            name: "show_production_slow",
            type: "checkbox",
            separator: "|",
            options: [{ label: "", value: true }],
          },
          {
            label: "Opmerking",
            name: "comment",
            type: "textarea",
          },
          {
            label: "Verloop datum",
            name: "expiration_date",
            type: "datetime",
            setFormatter: (val) => {
              console.log(val);
              return moment(val, "YYYY-MM-DD").format("YYYY-MM-DD");
            },
          },
          {
            label: "Klant ID",
            name: "customer_id",
            type: "textarea",
            setFormatter: (val) => {
              return val === 0 ? "" : val;
            },
          },
          {
            label: "Extra	controle",
            name: "check_code",
            type: "checkbox",
            separator: "|",
            options: [{ label: "", value: true }],
          },
          {
            label: "Campagne",
            name: "campaign",
          },
          {
            label: "Max aantal",
            name: "number_limit",
            attr: {
              type: "number",
            },
          },
          {
            label: "Product(en)",
            name: "products",
            type: "textarea",
          },
          {
            label: "Eenmalig",
            name: "once",
            type: "checkbox",
            separator: "|",
            options: [{ label: "", value: true }],
          },
        ],
        table: "#coupons",
      });

      this.table.on("click", "a.editor-edit", (e) => {
        e.preventDefault();

        this.editor.edit(e.target.closest("tr"), { buttons: "Wijzigen" });
      });

      this.table.on("click", "a.editor-delete", (e) => {
        e.preventDefault();

        const tr = e.target.closest("tr");
        const row = this.table.row(tr);

        const editor = new $.fn.dataTable.Editor({
          ajax: this.route("coupons.destroy"),
          table: "#coupons",
          fields: [],
        });

        editor.remove($(e.target).parents("tr"), {
          message: `Bevestig dat je de couponcode <b>${row.data().code}</b> wilt verwijderen`,
          buttons: editor.i18n.remove.button,
        });
      });
    },
  },
};
</script>
