<template>
  <div class="#flex #space-x-4">
    <div class="#w-36">
      <SelectFilter
        v-model="selectedColumns"
        :label="label"
        :options="columns.map((obj) => obj.text)"
      />
    </div>
    <div>
      <div v-for="(column, index) of selectedColumns" :key="column">
        <div :class="index === 0 ? '' : '#mt-1'">
          <SelectFilter
            v-if="columns.find((obj) => obj.text === column).type === 'select'"
            v-model="filters[columns.find((obj) => obj.text === column).value]"
            :label="column"
            :options="options[columns.find((obj) => obj.text === column).value]"
            :condition="true"
          />
        </div>
        <div :class="index === 0 ? '' : '#mt-1'">
          <IntegerFilter
            v-if="columns.find((obj) => obj.text === column).type === 'integer'"
            v-model="filters[columns.find((obj) => obj.text === column).value]"
            :label="column"
            :width="columns.find((obj) => obj.text === column).width"
            :field-type="columns.find((obj) => obj.text === column).fieldType"
          />
        </div>
        <div :class="index === 0 ? '' : '#mt-1'">
          <DateFilter
            v-if="columns.find((obj) => obj.text === column).type === 'date'"
            v-model="filters[columns.find((obj) => obj.text === column).value]"
            :label="column"
            :width="columns.find((obj) => obj.text === column).width"
          />
        </div>
        <div :class="index === 0 ? '' : '#mt-1'">
          <StringFilter
            v-if="columns.find((obj) => obj.text === column).type === 'string'"
            v-model="filters[columns.find((obj) => obj.text === column).value]"
            :label="column"
          />
        </div>
        <div
          v-if="columns.find((obj) => obj.text === column).type === 'option'"
          :class="index === 0 ? '' : '#mt-1'"
        >
          <span class="#font-bold">{{ columns.find((obj) => obj.text === column).text }}</span>
          <div class="#flex #space-x-2">
            <div
              v-for="option of columns.find((obj) => obj.text === column).options"
              :key="option.value"
            >
              <OptionFilter
                v-model="filters[columns.find((obj) => obj.text === column).value]"
                :label="option.label"
                :value="option.value"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DateFilter from "./DateFilter.vue";
import IntegerFilter from "./IntegerFilter.vue";
import OptionFilter from "./OptionFilter.vue";
import SelectFilter from "./SelectFilter.vue";
import StringFilter from "./StringFilter.vue";

export default {
  components: { DateFilter, IntegerFilter, OptionFilter, SelectFilter, StringFilter },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    table: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue", "loading"],
  data() {
    return {
      options: [],
      filters: this.modelValue,
      selectedColumns: [],
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    filters: {
      handler() {
        this.$emit("update:modelValue", this.filters);
      },
      deep: true,
    },
    selectedColumns(newSelectedColumns, oldSelectedColumns) {
      const deletedColumn = oldSelectedColumns.filter(
        (element) => !newSelectedColumns.includes(element),
      )[0];

      if (deletedColumn) {
        this.filters[this.columns.find((obj) => obj.text === deletedColumn).value] = [];
      }

      const column = newSelectedColumns.filter(
        (element) => !oldSelectedColumns.includes(element),
      )[0];
      this.handleSelect(column);
    },
  },
  methods: {
    async fetchOptions(column) {
      try {
        this.$emit("loading", true);
        const data = { table: this.table, column: column };

        for (const [filterKey, filter] of Object.entries(this.filters)) {
          if (filter && Object.keys(filter).length > 0) {
            data[filterKey] = filter;
          }
        }

        let response = await fetch(this.route("options.index"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify(data),
        });

        const options = await response.json();
        this.options[column] = options;
        this.$emit("loading", false);
      } catch ($e) {
        alert("Something went wrong");
      }
    },
    handleSelect(column) {
      const columnObject = this.columns.find((obj) => obj.text === column);

      if (this.selectedColumns.length === 0) {
        this.$emit("update:modelValue", {});
      }

      if (columnObject && columnObject.type === "select") {
        this.fetchOptions(columnObject.value);
      }
    },
  },
};
</script>
