<template>
  <div class="tailwind">
    <input v-model="unfinishedDate" type="date" class="#rounded #p-1 #border #border-black" />
    <p class="#font-bold #text-sm #mt-2">Onafgerond</p>
    <table :id="`unfinished-${type}`" class="display" style="width: 100%">
      <thead>
        <tr>
          <th>Opdracht ID</th>
          <th>Status</th>
          <th>Substatus</th>
          <th>Bedrag</th>
        </tr>
      </thead>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      table: null,
      unfinished: [],
      unfinishedDate: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    unfinishedDate: {
      handler() {
        this.getUnfinishedOrdersByDate();
      },
      immediate: true,
    },
  },
  methods: {
    buildTable() {
      this.table = $(`#unfinished-${this.type}`).DataTable({
        serverSide: false,
        order: [[0, "asc"]],
        data: this.unfinished,
        columns: [
          {
            data: "id",
            render: (data) => {
              return `<a href="/admin.php?page=orders&sub=detail&id=${data}" class="link">${data}</a>`;
            },
          },
          { data: "status" },
          { data: "sub_status" },
          {
            data: "amount",
            render: (data) => {
              return `€${data}`;
            },
          },
        ],
      });
    },
    showDetails(event) {
      const target = event.target;
      const tr = target.closest("tr");
      const row = this.table.row(tr);

      if (row.child.isShown()) {
        row.child.hide();
      } else if (row.data() && row.data().articles.length > 0) {
        row.child(this.format(row.data().articles)).show();
      }
    },
    format(articles) {
      return `
        <table id="order-details" class="display" style="width: 100%">
          <thead>
            <tr>
              <th>Artikel ID</th>
              <th>Product</th>
              <th>Status</th>
              <th>Bedrag</th>
              <th>Aantal</th>
            </tr>
            ${articles
              .map(
                (article) => `
                <tr>
                  <td>${article.id}</td>
                  <td>${article.product}</td>
                  <td>${article.status}</td>
                  <td>€${article.amount}</td>
                  <td>${article.number}</td>
                </tr>`,
              )
              .join("")}
          </thead>
        </table>`;
    },
    async getUnfinishedOrdersByDate() {
      const obj = { unfinished_date: this.unfinishedDate, type: this.type };
      const response = await fetch(this.route("production.statistics.unfinished"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(obj),
      });

      if (response.ok) {
        this.unfinished = await response.json();
        this.rebuildTable();
      } else if (!response.ok) {
        await response.text();
      }
    },
    rebuildTable() {
      if (this.table) {
        this.table.destroy();
        this.table = null;
        document.removeEventListener("click", this.showDetails);
      }

      this.buildTable();
      const tableBody = document.querySelector(`#unfinished-${this.type} tbody`);

      if (tableBody) {
        tableBody.addEventListener("click", this.showDetails);
      }
    },
  },
};
</script>
