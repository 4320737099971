<template>
  <table id="campaigns-revenue" class="display" style="width: 100%">
    <thead>
      <tr>
        <th style="width: 500px !important">Campagne</th>
        <th>Winst</th>
      </tr>
    </thead>
  </table>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  emits: ["loading"],
  data() {
    return {
      table: null,
    };
  },
  watch: {
    data: {
      handler() {
        this.table.destroy();
        this.table = null;

        this.buildTable();
      },
      deep: true,
      immediate: false,
    },
  },
  beforeUnmount() {
    this.table.destroy();
    this.table = null;
  },
  mounted() {
    this.buildTable();
  },
  methods: {
    buildTable() {
      this.table = $("#campaigns-revenue").DataTable({
        serverSide: false,
        data: this.data,
        select: false,
        pageLength: 50,
        preDrawCallback: () => {
          this.$emit("loading", true);
        },
        drawCallback: () => {
          this.$emit("loading", false);
        },
        columns: [
          { data: "campaign" },
          {
            data: "revenue",
            render: function (data) {
              return `€${data.toFixed(2)}`;
            },
          },
        ],
      });
    },
  },
};
</script>
