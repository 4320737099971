<template>
  <Modal :open="open">
    <div class="end-shift">
      <div class="pane">
        <div class="header">
          <span class="title">Opruimen werkplek</span>
          <span class="subtitle"
            >Je werkdag zit er bijna op, zorg ervoor dat je werkplek netjes achterlaat. Werk de
            volgende acties even af.</span
          >
          <hr />
        </div>
        <div class="content">
          <table>
            <thead>
              <tr>
                <th class="actions">Acties</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <template v-for="item in actions" :key="item.id">
                <tr>
                  <td>{{ item.name }}</td>
                  <td
                    class="validate tailwind"
                    :class="{ check: item.checked }"
                    @click="item.checked = !item.checked"
                  >
                    <svg-use
                      v-if="item.checked === true"
                      id="check"
                      type="regular"
                      class="#h-[1em] #w-[17px] #fill-green-500"
                    />
                    <svg-use
                      v-if="item.checked === false"
                      id="xmark"
                      type="regular"
                      class="#h-[1em] #w-[17px] #fill-red-500"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="footer">
          <span />
          <span />
          <span />
          <button :disabled="confirmDisabled" @click="confirmForm">Bevestigen</button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "../components/Modal.vue";
import SvgUse from "../../vue3/components/SvgUse.vue";
export default {
  components: {
    Modal,
    SvgUse,
  },
  data() {
    return {
      open: false,
      trigger: 10,
      user_levels: false,
      work_end: null,
      interval: null,
      actions: [
        { id: 1, name: "Je werkplek netjes achtergelaten?", checked: false },
        {
          id: 2,
          name: "Zijn de karretjes terug op de juiste plek?",
          checked: false,
        },
        { id: 3, name: "Papier opgeruimd?", checked: false },
        { id: 4, name: "Meld je af bij de floormanager", checked: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["session", "route"]),
    confirmDisabled() {
      for (const action of this.actions) {
        if (action.checked === false) {
          return true;
        }
      }

      return false;
    },
  },
  created() {
    const user_levels = this.session("user_level");

    //Only Klantenservice, productie, printers
    if (user_levels.includes(1) || user_levels.includes(2) || user_levels.includes(3)) {
      this.user_levels = true;
    }
    this.employee_id = this.session("employee_id");
  },
  mounted() {
    this.interval = setInterval(this.checkShift, 60000);
    this.checkShift();
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    async checkShift() {
      if (!this.user_levels) {
        this.open = false;
        return;
      }

      let response = await fetch(this.route("checkshift"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const { work_end, work_confirmed } = await response.json();
      if (!work_end || work_confirmed) {
        this.open = false;
        return;
      }

      const diff = moment(work_end, "HH:mm:ss").diff(moment(), "minutes");
      this.open = diff < this.trigger;
    },
    async confirmForm() {
      let response = await fetch(this.route("endshift"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      });
      response = await response.json();
      this.checkShift();
    },
  },
};
</script>

<style>
.end-shift {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.end-shift .pane {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  width: 800px;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  padding: 15px;
}
.end-shift .pane .header {
  font-size: 16px;
  font-weight: bold;
  min-height: 32px;
}
.end-shift .pane .header .title {
  padding-top: 5px;
  display: inline-block;
  padding-left: 2px;
  width: 100%;
}
.end-shift .pane .header .subtitle {
  padding-left: 3px;
  margin-top: 5px;
  display: inline-block;
  font-size: 14px;
  width: 100%;
  font-weight: normal;
}
.end-shift .pane .content table {
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 20px;
}
.end-shift .pane .content table th {
  border-bottom: 1px dashed gray;
}
.end-shift .pane .content table td,
.end-shift .pane .content table th {
  padding: 5px;
}
.end-shift .pane .content table td.actions,
.end-shift .pane .content table th.actions {
  width: 35%;
}
.end-shift .pane .content table td.validate,
.end-shift .pane .content table th.validate {
  width: 30px;
}
.end-shift .pane .content table td.validate {
  cursor: pointer;
  padding-left: 6px;
  font-size: 20px;
}
.end-shift .pane .footer {
  display: grid;
  grid-template-columns: auto 120px 120px 120px;
  margin-top: 10px;
}
.end-shift .pane .footer .unauthorized {
  color: red;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 40px;
}
.end-shift .pane .footer button {
  cursor: pointer;
  margin-right: 10px;
  padding: 5px;
}
.end-shift .pane .footer button:last-child {
  margin-right: 0;
}
</style>
