<template>
  <table id="campaigns" class="display" style="width: 100%">
    <thead>
      <tr>
        <th>Opdracht</th>
        <th>Artikel</th>
        <th>Bedrag</th>
        <th>Bedrijf</th>
        <th>Naam</th>
        <th>Product</th>
        <th>Status</th>
        <th>Datum</th>
        <th>Campagne</th>
      </tr>
    </thead>
  </table>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  emits: ["loading"],
  data() {
    return {
      table: null,
    };
  },
  computed: {
    ...mapGetters(["route"]),
  },
  watch: {
    filters: {
      handler() {
        this.table.ajax.reload();
      },
      deep: true,
    },
  },
  mounted() {
    this.buildTable();
  },
  beforeUnmount() {
    this.table.destroy();
    this.table = null;
  },
  methods: {
    buildTable() {
      this.table = $("#campaigns").DataTable({
        ajax: {
          url: this.route("articles.campaigns"),
          data: (d) => {
            for (const [categoryKey, category] of Object.entries(this.filters)) {
              for (const [filterKey, filter] of Object.entries(category)) {
                if (filter && Object.keys(filter).length > 0) {
                  if (!d[categoryKey]) {
                    d[categoryKey] = {};
                  }

                  d[categoryKey][filterKey] = filter;
                }
              }
            }
          },
        },
        select: false,
        order: [[7, "desc"]],
        pageLength: 50,
        preDrawCallback: () => {
          this.$emit("loading", true);
        },
        drawCallback: () => {
          this.$emit("loading", false);
        },
        columns: [
          {
            data: "order.id",
            render: function (data) {
              return `<a href="/admin.php?page=orders&sub=detail&id=${data}" class="link">${data}</a>`;
            },
          },
          {
            data: "id",
            render: function (data, type, row) {
              return `<a href="/admin.php?page=orders&sub=detail&id=${row.order.id}" class="link">${data}</a>`;
            },
          },
          {
            data: "amount",
            render: function (data) {
              return `€${data}`;
            },
          },
          { data: "customer.company" },
          { data: "customer.name_contact" },
          { data: "product" },
          { data: "status" },
          {
            data: "order.posted_datetime",
            render: function (data) {
              return moment(data).format("DD-MM-YYYY HH:mm");
            },
          },
          { data: "campaign" },
        ],
      });
    },
  },
};
</script>
