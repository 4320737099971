<template>
  <h1>
    Belgeschiedenis
    <img v-if="loading" src="/admin/images/loading.gif" class="call-history-spinnner" />
    <a class="filter-btn manage_button" @click="showFilters">Filteren</a>
    <a class="filter-btn manage_button" :href="exportUrl">CSV Export</a>
  </h1>
  <div class="tailwind">
    <div v-show="isShowFilter" class="#grid #grid-cols-4 #text-xs">
      <div>
        <div v-for="checkbox in checkboxes" :key="checkbox.id">
          <BooleanFilter v-model="filters[checkbox.id]" :label="checkbox.label" />
        </div>
      </div>
      <div>
        <div class="#flex">
          <span class="#mr-2">Op verzoek: </span>
          <div v-for="checkbox in onRequestCheckboxes" :key="checkbox.id">
            <OptionFilter
              :id="checkbox.id"
              v-model="filters.request"
              :label="checkbox.label"
              :value="checkbox.value"
              class="#mr-2"
            />
          </div>
        </div>
        <div class="#flex #mt-2">
          <span class="#mr-2">Richting: </span>
          <div v-for="checkbox in directionCheckboxes" :key="checkbox.id">
            <OptionFilter
              :id="checkbox.id"
              v-model="filters.direction"
              :label="checkbox.label"
              :value="checkbox.value"
              class="#mr-2"
            />
          </div>
        </div>
      </div>
      <div>
        <DateFilter v-model="filters.date" label="Gebeld om" />
      </div>
    </div>
    <table id="calls" class="display" style="width: 100%">
      <thead>
        <tr>
          <th />
          <th>Wanneer</th>
          <th>Nummer</th>
          <th>Medewerker</th>
          <th>Richting</th>
          <th>Op verzoek</th>
          <th>Lengte / Gewacht</th>
          <th>Klant ID</th>
          <th>Naam</th>
          <th>Bedrijf</th>
          <th>Status Code</th>
          <th>Acties</th>
        </tr>
      </thead>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BooleanFilter from "../components/BooleanFilter.vue";
import OptionFilter from "../components/OptionFilter.vue";
import DateFilter from "../components/DateFilter.vue";

export default {
  components: { BooleanFilter, DateFilter, OptionFilter },
  data() {
    return {
      checkboxes: [
        { id: "missed", label: "Gemiste oproepen" },
        { id: "suppliers_number", label: "Leverancier" },
      ],
      onRequestCheckboxes: [
        { label: "Ja", value: "yes" },
        { label: "Nee", value: "no" },
      ],
      directionCheckboxes: [
        { label: "In", value: "in" },
        { label: "Uit", value: "out" },
      ],
      filters: {
        date: {},
        direction: "",
        missed: false,
        request: "",
        suppliers_number: false,
      },
      interval: null,
      isShowFilter: false,
      loading: true,
      table: null,
    };
  },
  computed: {
    ...mapGetters(["route"]),
    exportUrl() {
      const route = this.route("call.history.export");
      const queryParameters = [];

      for (const [key, value] of Object.entries(this.filters)) {
        if (value) {
          if (key === "date") {
            const dateValues = Object.entries(value);

            for (const [dateKey, dateValue] of dateValues) {
              if (Array.isArray(dateValue)) {
                for (const date of dateValue) {
                  queryParameters.push(`date[${dateKey}][]=${date}`);
                }
              } else {
                queryParameters.push(`date[${dateKey}]=${dateValue}`);
              }
            }
          } else {
            queryParameters.push(`${key}=${value}`);
          }
        }
      }

      const exportUrl = route + (queryParameters.length > 0 ? `?${queryParameters.join("&")}` : "");

      return exportUrl;
    },
  },
  watch: {
    filters: {
      handler() {
        this.loading = true;
        this.table.ajax.reload();
      },
      deep: true,
    },
  },
  mounted() {
    this.buildTable();
    this.interval = setInterval(() => {
      this.table.ajax.reload();
    }, 60000);
    this.loading = false;
  },
  beforeUnmount() {
    clearInterval(this.interval);
    this.table.destroy();
    this.table = null;
  },
  methods: {
    buildTable() {
      this.table = $("#calls").DataTable({
        ajax: {
          url: this.route("call.history"),
          data: (d) => {
            for (const [key, value] of Object.entries(this.filters)) {
              if (value) {
                d[key] = value;
              }
            }
          },
        },
        select: false,
        order: [[1, "desc"]],
        pageLength: 100,
        drawCallback: () => {
          this.loading = false;
        },
        columns: [
          {
            searchable: false,
            sortable: false,
            data: null,
            width: "150px",
            render: (data) => {
              if (data.response_time !== null && data.response_time > 0) {
                const time = moment().subtract(data.response_time, "seconds").fromNow(true);

                return `${time} te laat`;
              }

              return "";
            },
          },
          {
            data: "created_at",
            render: function (data) {
              const createdAt = moment(data, moment.ISO_8601);
              const readable = createdAt.locale("nl").fromNow();
              const title = createdAt.format("DD-MM-YYYY HH:mm:ss");

              return `<span title="${title}">${readable}</span>`;
            },
          },
          {
            data: "number",
            render: function (data) {
              return `<a href="tel:${data}">${data}</a>`;
            },
          },
          { data: "employee.firstname" },
          {
            data: "direction",
            searchable: false,
            render: function (data) {
              return data === "in" ? "In" : "Uit";
            },
          },
          {
            data: "request",
            searchable: false,
            sortable: false,
            render: function (data) {
              return data ? "Ja" : "nee";
            },
          },
          {
            data: "duration",
            render: function (data, type, row) {
              let field = data;
              if (["hangup", "no-answer"].includes(row.status)) {
                field = row.waited;
              }

              const readable = moment().subtract(field, "seconds").fromNow(true);
              const title = `${field} seconden`;

              return `<span title="${title}">${readable}</span>`;
            },
          },
          {
            data: "customer_id",
            render: function (data) {
              if (!data) return "";

              return `<a href="/admin.php?page=customers&sub=detail&customer_id=${data}" target="_blank" style="color:#0000ee">${data}</a>`;
            },
          },
          { data: "customer.name_contact" },
          { data: "customer.company" },
          { data: "status" },
          {
            searchable: false,
            sortable: false,
            data: null,
            className: "center",
            render: function (data, type, row) {
              if (!row.recording_url) return "";

              let html = `<a href="${row.recording_url}" target="_blank" style="color:#0000ee">Luisteren</a>`;

              return html;
            },
          },
        ],
        rowCallback: (row, data) => {
          if (data.response_time === null && !["hangup", "no-answer"].includes(data.status)) {
            return;
          }

          if (["hangup", "no-answer"].includes(data.status) && data.waited < 10) {
            return;
          }

          let status = "red";
          if (["hangup", "no-answer"].includes(data.status) && data.waited >= 10) {
            status = "red";
          } else if (data.response_time === 0) {
            status = "green";
          } else if (data.response_time < 300) {
            status = "orange";
          }

          $(row)
            .find("td:eq(0)")
            .addClass(status + "prio");
        },
      });
    },
    showFilters() {
      this.isShowFilter = !this.isShowFilter;
    },
  },
};
</script>
<style>
.call-history-spinnner {
  margin-bottom: -9px;
}

.filter-btn {
  cursor: pointer;
  text-decoration: underline;
}

.label-align-middle {
  margin-left: 2px;
  display: inline-block;
  vertical-align: middle;
}
</style>
