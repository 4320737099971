<template>
  <div :class="s.control">
    <IconButton
      v-if="isPWA()"
      :icon="notificationIcon"
      title="Geluid afspelen als er een gesprek in de wachtrij staat"
      :style="notificationStyle"
      @click="toggleNotifications"
    />
    <IconButton icon="circle-info" title="Telefoonnummers en uitbellen" @click="openDialer" />
    <IconButton
      v-if="isAdminLevel(5, 6, 4)"
      icon="clock-rotate-left"
      title="Belgeschiedenis"
      @click="goToHistory"
    />
    <IconButton
      v-if="isAdminLevel(5, 6, 9)"
      icon="gear"
      title="Instellingen voor bellen"
      @click="goToSettings"
    />
  </div>
  <div :class="s.container">
    <table id="call_queue_table" class="display" style="width: 100%">
      <thead>
        <tr>
          <th />
          <th>Nummer</th>
          <th>Tijd in wachtrij</th>
          <th>Klant ID</th>
          <th>Naam</th>
          <th>Bedrijf</th>
          <th>Acties</th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import IconButton from "../components/IconButton.vue";

export default {
  components: { IconButton },
  data() {
    return {
      calls: [],
      table: null,
      interval: null,
    };
  },
  computed: {
    ...mapGetters(["route", "isAdminLevel"]),
    ...mapState(["callNotifications"]),
    notificationIcon() {
      if (this.callNotifications) {
        return "bell-on";
      }

      return "bell";
    },
    notificationStyle() {
      if (this.callNotifications) {
        return { color: "white", backgroundColor: "#28a745" };
      }

      return { color: "white", backgroundColor: "#dc3545" };
    },
  },
  mounted() {
    this.startPWA();
    this.buildTable();
    this.interval = setInterval(() => {
      this.table.ajax.reload();
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
    this.table.destroy();
    this.table = null;
  },
  methods: {
    ...mapMutations(["notifications"]),
    startPWA() {
      if ("serviceWorker" in navigator) {
        const link = document.createElement("link");
        link.rel = "manifest";
        link.href = "/admin/manifest.json";
        document.head.appendChild(link);

        navigator.serviceWorker
          .register("/admin/sw.js")
          .then((swReg) => {
            window.sw = swReg;
          })
          .catch(function (err) {
            console.error(err);
          });
      }
    },
    async toggleNotifications() {
      if (!this.callNotifications) {
        if (Notification.permission === "denied") {
          return alert("Notficaties niet toegestaan, verander in browser settings!");
        }
        if (Notification.permission !== "granted") {
          const status = await Notification.requestPermission();
          if (status !== "granted") {
            return alert("Notficaties niet toegestaan, verander in browser settings!");
          }
        }
      }

      this.notifications(!this.callNotifications);
    },
    buildTable() {
      this.table = $("#call_queue_table").DataTable({
        ajax: this.route("call.index"),
        searching: false,
        paging: false,
        select: false,
        sort: false,
        order: [[2, "desc"]],
        columns: [
          {
            searchable: false,
            data: null,
            width: "0px",
            render: function (data, type, row) {
              return "";
            },
          },
          { data: "number" },
          {
            data: "updated_at",
            render: function (data, type, row) {
              let updatedAt = moment(data, moment.ISO_8601);

              return updatedAt.locale("nl").fromNow(true);
            },
          },
          { data: "customer_id" },
          { data: "customer.name_contact" },
          { data: "customer.company" },
          {
            searchable: false,
            data: null,
            className: "center",
            render: (data, type, row) => {
              let html = '<a href="javascript:;" class="custom_pickup">Opnemen</a>';

              if (!this.isPWA()) {
                html = "Gebruik de admin app op de wachtrij te gebruiken";
              }

              return html;
            },
          },
        ],
        rowCallback: function (row, data) {
          let updatedAt = moment(data.updated_at, moment.ISO_8601);
          let timeInQueue = moment().diff(updatedAt, "seconds");

          let status = "red";
          if (timeInQueue < 90) {
            status = "orange";
          }
          if (timeInQueue < 30) {
            status = "green";
          }
          if (timeInQueue < 15) {
            status = "darkgreen";
          }

          $(row)
            .find("td:eq(0)")
            .addClass(status + "prio");
        },
      });
      $("#call_queue_table").on("click", "a.custom_pickup", (e) => {
        e.preventDefault();
        const row = this.table.row($(e.target).parents("tr"));
        this.emitter.emit("take-call", {
          id: row.data().id,
          number: row.data().number,
        });
      });
    },
    goToSettings() {
      window.location.href = "/admin.php?page=service&sub=callback_settings";
    },
    goToHistory() {
      window.location.href = "/admin.php?page=service&sub=callback_history";
    },
    openDialer() {
      this.emitter.emit("open-call-dialer");
    },
    isPWA() {
      return ["standalone", "minimal-ui"].some(
        (displayMode) => window.matchMedia("(display-mode: " + displayMode + ")").matches,
      );
    },
  },
};
</script>

<style module="s">
.control {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.container {
  margin-top: -28px;
}
</style>
