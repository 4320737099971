<template>
  <div :class="s.container">
    <template v-for="(notification, index) in notifications" :key="`notification_${id}_${index}`">
      <div
        v-if="notification.type === 'load'"
        class="alert alert-warning"
        :class="s.notification"
        role="alert"
      >
        <span>
          Vul lade <b>{{ notification.tray_name }}</b> met
          <b>{{ notification.paper_weight }}</b> grams
          <b>{{ notification.paper_name }} {{ notification.paper_size }}</b>
          <small> ({{ notification.fill }} cm | {{ notification.amount }} vellen) </small>
        </span>
        <a
          href="javascript:;"
          class="alert-link"
          :disabled="loading"
          @click="() => assign(notification)"
          >BEVESTIGEN</a
        >
      </div>
      <div
        v-if="notification.type === 'prepare'"
        class="alert alert-info"
        :class="s.notification"
        role="alert"
      >
        <span>
          Bereid alvast voor
          <b>{{ notification.paper_weight }}</b> grams
          <b>{{ notification.paper_name }} {{ notification.paper_size }}</b>
          <small> ({{ notification.fill }} cm | {{ notification.amount }} vellen) </small>
        </span>
      </div>
      <div
        v-if="notification.type === 'add'"
        class="alert alert-danger"
        :class="s.notification"
        role="alert"
      >
        <span>
          Te weinig gevuld
          <b>{{ notification.paper_weight }}</b> grams
          <b>{{ notification.paper_name }} {{ notification.paper_size }}</b>
          <small> ({{ notification.fill }} cm | {{ notification.amount }} vellen) </small>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      notifications: [],
    };
  },
  mounted() {
    window.Echo.channel(`printers_${this.id}`).listen("PrinterTrayUpdate", () => {
      this.update();
    });
    window.Echo.channel(`printers_${this.id}`).listen("PrinterJobCreated", () => {
      this.update();
    });
    window.Echo.channel(`printers_${this.id}`).listen("PrinterJobUpdate", ({ id, key, value }) => {
      const updateAt = ["COMPLETED", "ABORTED", "HELD"];
      if (key === "status" && updateAt.includes(value)) {
        this.update();
      }
    });
  },
  async created() {
    await this.update();
  },
  methods: {
    async update() {
      let response = await fetch(this.route("printers.notifications", { printer: this.id }), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      this.notifications = await response.json();
    },
    async assign(notification) {
      this.loading = true;

      const response = await fetch(
        this.route("printers.trays.assign", {
          printer: this.id,
          tray: notification.tray,
        }),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            type: notification.paper_type,
            size: notification.paper_size,
            color: notification.paper_color,
          }),
        },
      );

      if (response.ok) {
        console.log(await response.json());
        this.loading = false;
      } else {
        await response.text();
      }
    },
  },
  computed: {
    ...mapGetters(["route"]),
  },
};
</script>

<style module="s">
.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.notification {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  /* text-align: left; */
}
</style>
