<template>
  <table
    class="#font-[arial] #text-left #ml-px #border-solid #border-[#3C3C3C] #border-2 #border-collapse #w-full"
  >
    <thead>
      <tr>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
        >
          <span>Medewerker</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
        >
          <span>Print tijd</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
        >
          <span>Idle tijd</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
        >
          <span>Sheets</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
        >
          <span>Cleanings</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
        >
          <span>Papier tekort</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
        >
          <span>Papier onbekend</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
        >
          <span>Inkt afval vol</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
        >
          <span>Stacker vol</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
        >
          <span>Inkt tekort</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
        >
          <span>QA papier tekort</span>
        </th>
        <th
          class="#text-left #font-bold #bg-[#3C3C3C] #text-white #pl-1 #pb-[2px] #border-b-2 #border-b-[#0099ff]"
        >
          <span>Taak in de wacht</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="data.length === 0">
        Geen resulaten
      </tr>
      <tr
        v-for="(row, id) in data"
        :ref="'employee-' + id"
        :key="id"
        @click="setSelectEmployee(id)"
      >
        <td class="#p-1 #align-top">
          {{ row.name }}
        </td>
        <td class="#p-1 #align-top">
          {{ toDuration(row.running) }}
        </td>
        <td class="#p-1 #align-top">
          {{ toDuration(row.idle) }}
        </td>

        <td class="#p-1 #align-top" :class="cellClasses('sheets', id)">
          {{ mode === "time" ? `${row.sheets.per_hour} per uur` : "" }}
          {{ mode === "events" ? row.sheets.events : "" }}
          {{ mode === "total-time" ? toDuration(row.sheets.total_time) : "" }}
        </td>
        <td class="#p-1 #align-top" :class="cellClasses('cleaning', id)">
          {{ mode === "time" ? `${row.cleaning.per_hour} per uur` : "" }}
          {{ mode === "events" ? row.cleaning.events : "" }}
          {{ mode === "total-time" ? toDuration(row.cleaning.total_time) : "" }}
        </td>
        <td class="#p-1 #align-top" :class="cellClasses('paper_short', id)">
          {{ mode === "time" ? `${row.paper_short.per_hour} per uur` : "" }}
          {{ mode === "events" ? row.paper_short.events : "" }}
          {{ mode === "total-time" ? toDuration(row.paper_short.total_time) : "" }}
        </td>
        <td class="#p-1 #align-top" :class="cellClasses('paper_unknown', id)">
          {{ mode === "time" ? `${row.paper_unknown.per_hour} per uur` : "" }}
          {{ mode === "events" ? row.paper_unknown.events : "" }}
          {{ mode === "total-time" ? toDuration(row.paper_unknown.total_time) : "" }}
        </td>
        <td class="#p-1 #align-top" :class="cellClasses('waste_full', id)">
          {{ mode === "time" ? `${row.waste_full.per_hour} per uur` : "" }}
          {{ mode === "events" ? row.waste_full.events : "" }}
          {{ mode === "total-time" ? toDuration(row.waste_full.total_time) : "" }}
        </td>
        <td class="#p-1 #align-top" :class="cellClasses('stacker_full', id)">
          {{ mode === "time" ? `${row.stacker_full.per_hour} per uur` : "" }}
          {{ mode === "events" ? row.stacker_full.events : "" }}
          {{ mode === "total-time" ? toDuration(row.stacker_full.total_time) : "" }}
        </td>
        <td class="#p-1 #align-top" :class="cellClasses('ink_empty', id)">
          {{ mode === "time" ? `${row.ink_empty.per_hour} per uur` : "" }}
          {{ mode === "events" ? row.ink_empty.events : "" }}
          {{ mode === "total-time" ? toDuration(row.ink_empty.total_time) : "" }}
        </td>
        <td class="#p-1 #align-top" :class="cellClasses('qa_empty', id)">
          {{ mode === "time" ? `${row.qa_empty.per_hour} per uur` : "" }}
          {{ mode === "events" ? row.qa_empty.events : "" }}
          {{ mode === "total-time" ? toDuration(row.qa_empty.total_time) : "" }}
        </td>
        <td class="#p-1 #align-top" :class="cellClasses('job_suspended', id)">
          {{ mode === "time" ? `${row.job_suspended.per_hour} per uur` : "" }}
          {{ mode === "events" ? row.job_suspended.events : "" }}
          {{ mode === "total-time" ? toDuration(row.job_suspended.total_time) : "" }}
        </td>
      </tr>
    </tbody>
  </table>

  <div class="#mt-2 #mr-2">
    <label class="#font-bold #text-xs">Cleaning weergave</label>
    <select v-model="view" class="#border #border-solid #border-[#d4d4d4] #w-full #h-8 #rounded">
      <option value="printers">Printers</option>
      <option value="print-operators">Print operators</option>
    </select>
  </div>

  <CleaningPrintOperatorsChart
    v-if="view === 'print-operators'"
    :from="from"
    :to="to"
    :period="period"
  />

  <CleaningLineChart v-if="view === 'printers'" :from="from" :to="to" :period="period" />
</template>

<script>
import CleaningLineChart from "../components/CleaningLineChart.vue";
import CleaningPrintOperatorsChart from "../components/CleaningPrintOperatorsChart.vue";
import { toDuration } from "../helpers";

export default {
  components: { CleaningPrintOperatorsChart, CleaningLineChart },
  props: {
    data: {
      type: Object,
      required: true,
    },
    from: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "time",
    },
    period: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {
      toDuration,
    };
  },
  data() {
    return {
      view: "printers",
      selectedEmployeeId: null,
    };
  },
  computed: {
    selectedEmployee() {
      if (this.selectedEmployeeId === null) {
        return null;
      }

      return this.data[this.selectedEmployeeId];
    },
  },
  watch: {
    from() {
      this.selectedEmployeeId = null;
    },
    to() {
      this.selectedEmployeeId = null;
    },
  },
  methods: {
    setSelectEmployee(id) {
      if (id === this.selectedEmployeeId) {
        this.selectedEmployeeId = null;
      } else {
        this.selectedEmployeeId = id;
      }
    },
    cellClasses(type, id) {
      if (this.selectedEmployeeId === null || this.mode === "total-time") {
        return null;
      }

      const value = this.mode === "time" ? "per_hour" : "events";

      let own = this.data[id][type][value];
      let reference = this.selectedEmployee[type][value];

      return {
        "#bg-red-500": own > reference,
        "#bg-green-500": own < reference,
        "#bg-gray-300": id === this.selectedEmployeeId,
        "#text-white": own !== reference,
      };
    },
  },
};
</script>
