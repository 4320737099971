<template>
  <div
    class="#flex #items-center #p-2 #mb-4 #text-sm #rounded-lg"
    :class="containerStyling"
    role="alert"
  >
    <div v-html="message"></div>
    <button
      type="button"
      class="#ms-auto #-mx-1.5 #-my-1.5 #rounded-lg #focus:ring-2 #focus:ring-blue-400 #p-1.5 #hover:bg-blue-200 #inline-flex #items-center #justify-center #h-8 #w-8"
      aria-label="Close"
      @click="$emit('close')"
    >
      <svg
        class="#w-3 #h-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "success",
      validator(value) {
        return ["success", "warning", "danger"].includes(value);
      },
    },
    message: {
      type: String,
      required: true,
    },
  },
  emits: ["close"],
  computed: {
    containerStyling() {
      return {
        "#text-green-800 #border #border-green-300 #bg-green-50": this.type === "success",
        "#text-red-800 #border #border-red-300 #bg-red-50": this.type === "danger",
        "#text-orange-800 #border #border-orange-300 #bg-orange-50": this.type === "warning",
      };
    },
  },
};
</script>
