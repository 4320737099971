<template>
  <label class="#font-bold">{{ label }}</label>
  <div class="#flex">
    <div v-if="condition" class="#mr-2 condition">
      <select ref="select_condition" class="filter-select2">
        <option value="in">Een van</option>
        <option value="like">Bevat</option>
        <option value="not_like">Bevat niet</option>
      </select>
    </div>
    <div v-show="selectedCondition === 'in'" style="width: 100%;" class="select-container">
      <select
        ref="select"
        style="width: 100%"
        class="filter-select2"
        :multiple="multiple"
        :disabled="disabled"
      >
        <option v-if="!multiple">Kies...</option>
        <option v-for="option in options" :key="option.id ?? option" :value="option.id ?? option">
          {{ option.value ?? option }}
        </option>
      </select>
    </div>
    <div v-show="selectedCondition === 'like'">
      <StringFilter v-model="stringFilter" />
    </div>
    <div v-show="selectedCondition === 'not_like'">
      <StringFilter v-model="stringFilter" />
    </div>
  </div>
</template>
<script>
import StringFilter from "./StringFilter.vue";

export default {
  components: { StringFilter },
  props: {
    clear: {
      type: Boolean,
      default: false,
    },
    condition: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      stringFilter: this.modelValue,
      selectedCondition: "in",
      selectedOptions: [],
    };
  },
  watch: {
    modelValue: {
      handler() {
        if (this.modelValue.length) {
          $(this.$refs.select).val(this.modelValue).trigger("change");
          this.$emit("update:modelValue", this.modelValue);
        }
      },
      immediate: false,
    },
    clear: {
      handler() {
        if (this.clear) {
          $(this.$refs.select).val([]).trigger("change");
        }
      },
    },
    selectedCondition: {
      handler() {
        if (this.selectedCondition === "in") {
          this.stringFilter = "";
          this.$emit("update:modelValue", this.stringFilter);
        }

        if (["like", "not_like"].includes(this.selectedCondition)) {
          $(this.$refs.select).val([]).trigger("change");
        }
      },
    },
    stringFilter: {
      handler() {
        if (this.selectedCondition === "like") {
          this.$emit("update:modelValue", this.stringFilter);
        } else if (this.selectedCondition === "not_like") {
          this.$emit("update:modelValue", this.stringFilter ? `!${this.stringFilter}` : "");
        }
      },
    },
    selected: {
      handler() {
        if (this.selected.length > 0) {
          $(this.$refs.select).val(this.selected).trigger("change");
          this.$emit("update:modelValue", this.selected);
        }
      },
    },
  },
  mounted() {
  const self = this; // Bewaar de component context

  $(self.$refs.select_condition).select2({ dropdownAutoWidth: true });
  $(self.$refs.select).select2({ dropdownAutoWidth: true });

  if (self.modelValue.length) {
    self.$nextTick(() => {
      $(self.$refs.select).val(self.modelValue).trigger("change");
      self.$emit("update:modelValue", self.modelValue);
    });
  }

  $(self.$refs.select).on("change.select2", () => {
    const selectedOptions = [];
    const selectedData = $(self.$refs.select).select2("data");

    for (const item of selectedData) {
      if (item.id === "") continue;
      selectedOptions.push(item.id);
    }

    if (self.multiple) {
      self.$emit("update:modelValue", selectedOptions);
    } else {
      self.$emit("update:modelValue", selectedOptions[0]);
    }
  });

  $(self.$refs.select_condition).on("change.select2", (e) => {
    self.selectedCondition = e.target.value;
  });

  if (self.multiple) {
    $(self.$refs.select).on('select2:open', () => {
      setTimeout(() => {
        const select2SearchInput = document.querySelector('.select2-container--open .select2-search__field');
        if (select2SearchInput) {
          $(select2SearchInput).off('keydown');
          $(select2SearchInput).on('keydown', (e) => {
            if (e.ctrlKey && e.key === "a") {
              e.preventDefault();
              const allOptions = $(self.$refs.select).find('option').map((_, option) => option.value).get();
              $(self.$refs.select).val(allOptions).trigger("change");
              self.$emit("update:modelValue", allOptions);
              $(self.$refs.select).select2('close');
            }
            // Toevoegen van Ctrl+X snelkoppeling om alle opties te deselecteren
            if (e.ctrlKey && e.key === "x") {
              e.preventDefault();
              $(self.$refs.select).val([]).trigger("change");
              self.$emit("update:modelValue", []);
              $(self.$refs.select).select2('close');
            }
          });
        }
      }, 0);
    });
  }
},


};
</script>
<style>
.condition .select2-container {
  width: 75px !important;
}

.select-container .select2-container {
  width: 250px !important;
}

.select-container .select2-selection {
  display: flex;
  align-items: center;
  border: 1px solid #d4d4d4;
}
</style>
