import ChartDataLabels from "chartjs-plugin-datalabels";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      colors: [],
      initialColors: [
        "#48e28d",
        "#1cad2a",
        "#ffcd56",
        "#ff6384",
        "#2674f5",
        "#e74c3c",
        "#9354e3",
        "#ffb142",
        "#1e824c",
        "#f0a7a0",
        "#ffc940",
        "#7a82ab",
        "#b52b65",
        "#30bfbf",
        "#ff69b4",
        "#f4a460",
      ],
      labels: [],
      percentages: [],
      results: [],
      totalAmount: 0,
      totalDamageCosts: 0,
    };
  },
  computed: {
    ...mapGetters(["route"]),
    plugins() {
      return [
        ChartDataLabels,
        {
          id: "innerLabel",
          afterDatasetDraw: (chart, args) => this.setInnerLabel(chart, args),
        },
      ];
    },
    chartOptions() {
      return {
        layout: {
          padding: 90,
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: this.setTooltip(),
          datalabels: this.setDataLabels(),
        },
      };
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        this.getMistakeStatisticsData();
      },
    },
    mode() {
      this.getMistakeStatisticsData();
    },
    selectedCategories() {
      if (this.type === "category") {
        this.getMistakeStatisticsData();
      }
    },
    selectedStations() {
      if (this.type === "sub_category") {
        this.getMistakeStatisticsData();
      }
    },
  },
  methods: {
    getMistakeStatisticsData() {
      let innerColors = [];
      let innerData = [];
      let innerLabels = [];
      let outerColors = [];
      let outerData = [];
      let outerLabels = [];

      let counter = 0;
      let totalDamageCosts = 0;
      let totalAmount = 0;

      const filterArray =
        this.type === "category" ? this.selectedCategories : this.selectedStations;

      for (const [categoryOrStation, item] of Object.entries(this.data)) {
        if (filterArray.includes("ALL") || filterArray.includes(categoryOrStation)) {
          const labels = this.type === "category" ? this.categories : this.stations;
          const label = labels.find((category) => categoryOrStation === category.id);

          outerLabels.push(label.value);
          outerColors.push(this.initialColors[counter]);
          this.mode === "amount" ? outerData.push(item.amount) : outerData.push(item.costs);

          totalDamageCosts += parseFloat(item.costs);
          totalAmount += parseInt(item.amount);

          for (const [mistake, mistakeItem] of Object.entries(item.mistakes)) {
            innerLabels.push(mistake);
            innerColors.push(outerColors[outerColors.length - 1]);
            this.mode === "amount"
              ? innerData.push(mistakeItem.amount)
              : innerData.push(mistakeItem.costs);
          }
          counter++;
        }
      }

      this.calculatePercentages(totalAmount, totalDamageCosts, filterArray);

      this.totalAmount = totalAmount;
      this.totalDamageCosts = totalDamageCosts.toFixed(2);

      this.colors = [outerColors, innerColors];
      this.labels = [outerLabels, innerLabels];
      this.results = [outerData, innerData];
    },
    setDataLabels() {
      return {
        labels: {
          categoryOrStation: {
            color: "#565d64",
            font: {
              weight: "bold",
              size: "12px",
            },
            anchor: "end",
            align: "end",
            textAlign: "center",
            rotation: function (ctx) {
              const valuesBefore = ctx.dataset.data
                .slice(0, ctx.dataIndex)
                .reduce((a, b) => a + b, 0);
              const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
              const rotation = ((valuesBefore + ctx.dataset.data[ctx.dataIndex] / 2) / sum) * 360;

              return rotation < 180 ? rotation - 90 : rotation + 90;
            },
            formatter: (value, context) => {
              return this.labels[context.datasetIndex][context.dataIndex];
            },
          },
          amountOrCost: {
            color: "white",
            font: {
              weight: "bold",
              size: "12fpx",
            },
            anchor: "center",
            align: "center",
            formatter: (value, context) => {
              const real = this.results[context.datasetIndex][context.dataIndex];

              return this.mode === "amount" ? real : `€${real}`;
            },
          },
        },
      };
    },
    setTooltip() {
      return {
        bodyAlign: "center",
        boxPadding: 4,
        callbacks: {
          label: (item) => {
            const label = this.labels[item.datasetIndex][item.dataIndex];
            const real = this.results[item.datasetIndex][item.dataIndex];

            return [label, this.mode === "amount" ? `Aantal: ${real}` : `Schade: €${real}`];
          },
        },
      };
    },
    calculatePercentages(totalAmount, totalDamageCosts, filterArray) {
      let innerPercentages = [];
      let outerPercentages = [];

      for (const [action, item] of Object.entries(this.data)) {
        if (filterArray.includes("ALL") || filterArray.includes(action)) {
          let total = 0;

          for (const [sub_action, mistakeItem] of Object.entries(item.mistakes)) {
            if (this.mode === "amount") {
              const percentage = Math.max((mistakeItem.amount / totalAmount) * 100, 1.5);
              total = total + percentage;
              innerPercentages.push(percentage);
            } else if (this.mode === "costs") {
              const percentage = Math.max((mistakeItem.costs / totalDamageCosts) * 100, 1.5);
              total = total + percentage;
              innerPercentages.push(percentage);
            }
          }
          outerPercentages.push(total);
        }
      }

      this.percentages = [outerPercentages, innerPercentages];
    },
    setInnerLabel(chart, args) {
      const { ctx } = chart;
      const meta = args.meta;

      if (meta.data[0]) {
        const xCoor = meta.data[0].x;
        const yCoor = meta.data[0].y;

        ctx.save();
        ctx.textAlign = "center";
        ctx.fillStyle = "#565d64";
        ctx.font = "18px sans-serif";

        const labelAmount = `Aantal: ${this.totalAmount}`;
        const labelCosts = `Schade: €${this.totalDamageCosts}`;

        ctx.fillText(this.title, xCoor, yCoor - 24);
        ctx.fillText(labelAmount, xCoor, yCoor);
        ctx.fillText(labelCosts, xCoor, yCoor + 24);

        ctx.restore();
      }
    },
  },
};
