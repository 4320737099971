<template>
  <h1>
    Overzicht
    <img v-if="loading" src="/admin/images/loading.gif" class="customer-overview-spinner" />
    <a class="filter-btn manage_button" @click="isShowFilter = !isShowFilter">Filteren</a>
    <a class="filter-btn manage_button" @click="confirmExport">CSV Export</a>
  </h1>
  <div class="tailwind">
    <div class="#mb-1">
      <BooleanFilter v-model="filters.customer.show_all_customers" label="Alle klanten tonen" />
    </div>
    <div v-show="isShowFilter" class="#grid #grid-cols-2 #gap-4">
      <div>
        <JoinFilter
          v-model="filters.customer"
          table="customers"
          :columns="customerColumns"
          label="Klant"
          @loading="loadingStatus"
        />
      </div>
      <div>
        <JoinFilter
          v-model="filters.article"
          table="articles"
          :columns="articleColumns"
          label="Artikel"
          @loading="loadingStatus"
        />
      </div>
      <div class="#mt-2 #mb-2">
        <JoinFilter
          v-model="filters.orders"
          table="orders"
          :columns="orderColumns"
          label="Opdracht"
          @loading="loadingStatus"
        />
      </div>
      <div>
        <JoinFilter
          v-model="filters.sending"
          table="sending"
          :columns="sendingColumns"
          label="Verzending"
          @loading="loadingStatus"
        />
      </div>
    </div>
    <table id="customers" class="display" style="width: 100%">
      <thead>
        <tr>
          <th>Klant ID</th>
          <th>Bedrijf</th>
          <th>Naam</th>
          <th>Email</th>
          <th>Telefoonnummer</th>
          <th>Type</th>
          <th>Wachtlijst</th>
          <th>Laatst ingelogd</th>
          <th>Aantal opdrachten</th>
          <th>Totaal bedrag</th>
          <th>Laatse bestelling</th>
        </tr>
      </thead>
    </table>
  </div>
</template>
<script>
import BooleanFilter from "../components/BooleanFilter.vue";
import JoinFilter from "../components/JoinFilter.vue";
import { mapGetters } from "vuex";

export default {
  components: { BooleanFilter, JoinFilter },
  data() {
    return {
      articleColumns: [
        { type: "select", text: "Campagne", value: "campaign" },
        { type: "select", text: "Product", value: "product" },
        { type: "select", text: "Product groep", value: "product_group" },
      ],
      customerColumns: [
        { type: "string", text: "Klant ID", value: "id" },
        { type: "option", text: "API token", value: "api_token", options: this.yesNoOptions() },
        { type: "string", text: "Bedrijf", value: "company" },
        {
          type: "option",
          text: "Betaal op rekening",
          value: "pay_on_account",
          options: this.yesNoOptions(),
        },
        { type: "option", text: "BTW nummer", value: "vat_number", options: this.yesNoOptions() },
        { type: "select", text: "Default leverwijze", value: "send_delivery_method" },
        { type: "string", text: "Email", value: "email" },
        { type: "option", text: "Gast", value: "is_guest", options: this.yesNoOptions() },
        { type: "date", text: "Laatst ingelogd", value: "last_login", width: "#w-24" },
        { type: "select", text: "Land", value: "country" },
        { type: "string", text: "Naam", value: "name_contact" },
        {
          type: "option",
          text: "Nieuwsbrief",
          value: "keep_updated",
          options: this.yesNoOptions(),
        },
        {
          type: "integer",
          text: "Postcode",
          value: "zipcode",
          width: "#w-20",
          fieldType: "zipcode",
        },
        { type: "option", text: "SMS", value: "allow_sms", options: this.yesNoOptions() },
        { type: "string", text: "Telefoonnummer", value: "telephone" },
        { type: "select", text: "Type klant", value: "contact_type" },
        { type: "select", text: "Voorkeurstaal", value: "preferred_locale" },
        {
          type: "option",
          text: "Wachtlijst",
          value: "is_waitinglisted",
          options: this.yesNoOptions(),
        },
        { type: "date", text: "Wanneer aangemaakt", value: "creation_datetime", width: "#w-24" },
        { type: "select", text: "Woonplaats", value: "city" },
      ],
      filters: {
        article: {
          campaign: [],
          product: [],
          product_group: [],
        },
        customer: {
          id: "",
          allow_sms: "",
          api_token: "",
          city: [],
          company: "",
          contact_type: [],
          country: [],
          creation_datetime: {},
          email: "",
          is_guest: "",
          is_waitinglisted: "",
          keep_updated: "",
          last_login: {},
          name_contact: "",
          pay_on_account: "",
          preferred_locale: [],
          send_delivery_method: [],
          show_all_customers: false,
          telephone: "",
          vat_number: "",
          zipcode: {},
        },
        orders: {
          amount: {},
          api_created: "",
          delayed_orders_count: {},
          orders_count: {},
          orders_sum_amount: {},
          payment_method: [],
          posted_datetime: {},
          production_method: [],
          status: [],
        },
        sending: {
          anonymous: "",
          country: [],
          delivery_option: [],
          delivery_method: [],
        },
      },
      isShowFilter: false,
      loading: true,
      orderColumns: [
        { type: "integer", text: "Aantal bestellingen", value: "orders_count", width: "#w-20" },
        {
          type: "integer",
          text: "Aantal keer vertraagd",
          value: "delayed_orders_count",
          width: "#w-20",
        },
        { type: "option", text: "API", value: "api_created", options: this.yesNoOptions() },
        { type: "integer", text: "Bedrag", value: "amount", width: "#w-20", fieldType: "price" },
        { type: "date", text: "Besteld op", value: "posted_datetime", width: "#w-24" },
        { type: "select", text: "Betaalwijze", value: "payment_method" },
        { type: "string", text: "Kortingscode", value: "couponcode" },
        { type: "select", text: "Productiewijze", value: "production_method" },
        { type: "select", text: "Status", value: "status" },
        {
          type: "integer",
          text: "Totaal bedrag",
          value: "orders_sum_amount",
          width: "#w-20",
          fieldType: "price",
        },
      ],
      sendingColumns: [
        {
          type: "option",
          text: "Blanco versturen",
          value: "anonymous",
          options: this.yesNoOptions(),
        },
        { type: "select", text: "Leveroptie", value: "delivery_option" },
        { type: "select", text: "Leverwijze", value: "delivery_method" },
        { type: "select", text: "Land", value: "country" },
      ],
      table: null,
    };
  },
  computed: {
    ...mapGetters(["route"]),
    exportUrl() {
      const route = this.route("customer.overview.export");
      const queryParameters = [];
      const numberAndDateFilters = [
        "last_login",
        "zipcode",
        "amount",
        "posted_datetime",
        "creation_datetime",
        "orders_count",
        "delayed_orders_count",
        "orders_sum_amount",
      ];

      for (const [categoryKey, filters] of Object.entries(this.filters)) {
        for (const [filterKey, filter] of Object.entries(filters)) {
          if (filter || Object.keys(filter).length) {
            if (numberAndDateFilters.includes(filterKey)) {
              for (const [key, value] of Object.entries(filter)) {
                if (Array.isArray(value)) {
                  for (const val of value) {
                    queryParameters.push(`${categoryKey}[${filterKey}][${key}][]=${val}`);
                  }
                } else {
                  queryParameters.push(`${categoryKey}[${filterKey}][${key}]=${value}`);
                }
              }
              continue;
            }

            if (Array.isArray(filter)) {
              for (const value of filter) {
                queryParameters.push(`${categoryKey}[${filterKey}][]=${value}`);
              }
            } else {
              queryParameters.push(`${categoryKey}[${filterKey}]=${filter}`);
            }
          }
        }
      }

      const exportUrl = route + (queryParameters.length > 0 ? `?${queryParameters.join("&")}` : "");

      return exportUrl;
    },
  },
  watch: {
    filters: {
      handler() {
        this.table.ajax.reload();
        // this.updateUrl();
      },
      deep: true,
    },
  },
  mounted() {
    let params = new URL(document.location).searchParams;

    params.forEach((value, key) => {
      if (key.includes("orders") || key.includes("article") || key.includes("sending")) {
        let table = "";
        let columns = {};

        if (key.includes("orders")) {
          table = "orders";
          columns = this.orderColumns;
        } else if (key.includes("article")) {
          table = "article";
          columns = this.articleColumns;
        } else if (key.includes("sending")) {
          table = "sending";
          columns = this.sendingColumns;
        } else if (key.includes("customer")) {
          table = "customer";
          columns = this.articleColumns;
        }

        let category = "";
        const matches = key.match(/\[(.*?)\]/);

        if (matches) {
          category = matches[1];
        }

        const foundColumn = columns.find((column) => column.value === category);

        if (foundColumn && ["option", "string"].includes(foundColumn.type)) {
          this.filters[table][category] = value;
        }

        if (foundColumn && foundColumn.type === "integer") {
          if (key.includes("condition")) {
            this.filters[table][category]["condition"] = value;
          } else if (key.includes("value")) {
            value = params.getAll(key);
            this.filters[table][category]["value"] = value;
          }
        }

        if (foundColumn && foundColumn.type === "select") {
          if (key.includes("[]")) {
            value = params.getAll(key);
          }

          this.filters[table][category] = value;
        }

        if (foundColumn && foundColumn.type === "date") {
          if (key.includes("condition")) {
            this.filters[table][category]["condition"] = value;
          } else if (key.includes("formatted_date")) {
            if (key.includes("[formatted_date][]")) {
              value = params.getAll(key);
            }

            this.filters[table][category]["formatted_date"] = value;
          } else if (key.includes("format")) {
            this.filters[table][category]["format"] = value;
          }
        }
      }
    });

    this.buildTable();

    const tableBody = document.querySelector("#customers tbody");
    tableBody.addEventListener("click", this.showDetails);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.showDetails);
  },
  methods: {
    buildTable() {
      this.table = $("#customers").DataTable({
        ajax: {
          url: this.route("customer.overview") + this.exportUrl.replace("/customers/export", ""),
          data: (d) => {
            for (const [categoryKey, category] of Object.entries(this.filters)) {
              for (const [filterKey, filter] of Object.entries(category)) {
                if (filter || Object.keys(filter).length > 0) {
                  if (!d[categoryKey]) {
                    d[categoryKey] = {};
                  }

                  d[categoryKey][filterKey] = filter;
                }
              }
            }
          },
        },
        select: false,
        searching: false,
        order: [[0, "desc"]],
        pageLength: 50,
        preDrawCallback: () => {
          this.loading = true;
        },
        drawCallback: () => {
          this.loading = false;
        },
        columns: [
          {
            data: "id",
            render: function (data) {
              if (!data) return "";

              return `<a href="/admin.php?page=customers&sub=detail&customer_id=${data}" class="link">${data}</a>`;
            },
          },
          { data: "company" },
          { data: "name_contact" },
          {
            data: "email",
          },
          {
            data: "telephone",
          },
          { data: "contact_type" },
          { data: "is_waitinglisted" },
          {
            data: "last_login",
            render: (data) => {
              const formattedDate = moment(data).format("DD-MM-YYYY HH:mm");

              if (formattedDate === "Invalid date") {
                return "-";
              } else {
                return `<span>${formattedDate}</span>`;
              }
            },
          },
          {
            data: "orders_count",
            sortable: false,
          },
          {
            data: "orders_sum_amount",
            sortable: false,
            render: (data) => {
              return `<span>€ ${data}</span>`;
            },
          },
          {
            data: "orders_max_posted_datetime",
            sortable: false,
            render: (data) => {
              const formattedDate = moment(data).format("DD-MM-YYYY HH:mm");

              if (formattedDate === "Invalid date") {
                return "-";
              } else {
                return `<span>${formattedDate}</span>`;
              }
            },
          },
        ],
      });
    },
    yesNoOptions() {
      return [
        { label: "Ja", value: "yes" },
        { label: "Nee", value: "no" },
      ];
    },
    loadingStatus(loading) {
      this.loading = loading;
    },
    confirmExport() {
      const numberOfRows = prompt("Vul een limiet in");
      const exportUrl = this.updateExportUrl(numberOfRows);

      if (isNaN(numberOfRows) || numberOfRows <= 0) {
        alert("Voer een geldig nummer in.");
        return;
      }

      if (confirm(`Bevestig dat je ${numberOfRows} klanten wilt exporteren?`)) {
        window.location.href = exportUrl;
      }
    },
    async showDetails(event) {
      const tr = event.target.closest("tr");
      const row = this.table.row(tr);

      if (row.child.isShown()) {
        row.child.hide();
      } else {
        row.child(this.format(row.data().orders)).show();
      }
    },
    format(orders) {
      if (orders.length === 0) {
        return "Geen bestellingen";
      }

      return `
        <table id="order-details" class="display" style="width: 100%">
          <thead>
            <tr>
              <th>ID</th>
              <th>Status</th>
              <th>Substatus</th>
              <th>Bedrag</th>
              <th>Besteldatum</th>
            </tr>
          </thead>
          <tbody>
            ${orders
              .map(
                (order) => `
                  <tr>
                    <td>
                      <a href="/admin.php?page=orders&sub=detail&id=${order.id}" class="link">
                        ${order.id}
                      </a>
                    </td>
                    <td>${order.status}</td>
                    <td>${order.sub_status}</td>
                    <td>€ ${order.amount}</td>
                    <td>
                      ${
                        moment(order.posted_datetime).isValid()
                          ? `${moment(order.posted_datetime).format("DD-MM-YYYY HH:mm")}`
                          : ""
                      }
                    </td>
                  </tr>
                `,
              )
              .join("")}
          </tbody>
        </table>`;
    },
    updateUrl() {
      let documentUrl = window.location.origin + window.location.pathname;
      documentUrl = documentUrl + "?page=customers&sub=overview";
      const searchUrl = this.exportUrl.replace("/customers/export", "");

      if (searchUrl) {
        const url = `${documentUrl}${searchUrl.startsWith("/") ? "" : "&"}${searchUrl.slice(1)}`;
        window.history.replaceState({}, "", url);
      }
    },
    updateExportUrl(numberOfRows) {
      const order = this.table.order();
      const column = this.table.settings().init().columns;

      const sign = this.exportUrl.includes("?") ? "&" : "?";
      let exportUrl =
        this.exportUrl + `${sign}sort=${column[order[0][0]].data}&direction=${order[0][1]}`;
      exportUrl += `&limit=${numberOfRows}`;

      return exportUrl;
    },
  },
};
</script>
<style>
.customer-overview-spinner {
  margin-bottom: -9px;
}
</style>
